import { useState } from "react";
import { Topbar1 } from "../../components";
import SetupLeft from "./SetupLeft";
import SetupRight from "./SetupRight";
import { examSetupSteps } from "../../constant/Constants";
import { useParams } from "react-router-dom";

const Setup = () => {
  const [hasCameraPermission, sethasCameraPermission] = useState();
  const [hasMicPermission, sethasMicPermission] = useState();
  const [isMicWorking, setisMicWorking] = useState();
  const [hasInternetConnection, setHasInternetConnection] = useState();
  const { step } = useParams();
  return (
    <div className="">
      <div className="mb-10">
        <Topbar1 />
      </div>
      <div className="card flex items-center justify-center w-[95%] h-[90%] mx-auto my-5">
        <div className="w-[50%] h-full rounded-l-[25px] flex flex-col justify-center items-center gradientPrimary">
          <SetupLeft
            step={step}
            hasCameraPermission={hasCameraPermission}
            sethasCameraPermission={sethasCameraPermission}
            hasMicPermission={hasMicPermission}
            sethasMicPermission={sethasMicPermission}
            setisMicWorking={setisMicWorking}
            hasInternetConnection={hasInternetConnection}
            setHasInternetConnection={setHasInternetConnection}
          />
        </div>
        <div className="w-[50%] h-[70vh] flex flex-col">
          <div className="flex gap-2.5 my-12 ml-[10%]">
            {examSetupSteps.map((stp) => (
              <div
                key={stp}
                className="h-[5px] w-[114px]"
                style={{
                  backgroundColor: `${step === stp ? "#8A24F1" : "#E4E4E4"}`,
                }}
              />
            ))}
          </div>
          <SetupRight
            step={step}
            hasCameraPermission={hasCameraPermission}
            hasMicPermission={hasMicPermission}
            isMicWorking={isMicWorking}
            hasInternetConnection={hasInternetConnection}
            // setisScreenSharing={setisScreenSharing}
          />
        </div>
      </div>
    </div>
  );
};

export default Setup;
