import { toast } from "react-toastify";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { auth } from "../../../config/firebase";
import { useDispatch } from "react-redux";
import { deleteQuestion } from "../../../redux/features/questionsSlice";
import { RxCross2 } from "react-icons/rx";

const QuestionMenu = ({
  question,
  setViewQuestion,
  libraryType,
  setQuestionEditOpen,
  questionEditOpen,
}) => {
  const dispatch = useDispatch();
  const deleteQues = async () => {
    const quesId = question?.id;
    const collectionName =
      libraryType === "custom" ? "customQuestionLibrary" : "questionLibrary";

    try {
      await dispatch(deleteQuestion({ id: quesId, collectionName }));
      setViewQuestion(false);
      toast.success("Success! Question has been deleted");
    } catch (error) {
      console.log(error);
      toast.error(`Error! ${error.message}`);
    }
  };

  return (
    <div className="flex gap-4 items-center">
      {!questionEditOpen &&
        (auth.currentUser.displayName === "Naskay Technologies" ||
          auth.currentUser.displayName === "Testing" ||
          libraryType === "custom") && (
          <>
            <FaRegEdit
              className="w-5 h-5 cursor-pointer"
              onClick={() => setQuestionEditOpen(true)}
            />
            <MdDeleteOutline
              onClick={deleteQues}
              className="w-6 h-6 cursor-pointer text-red-500"
            />
          </>
        )}

      <RxCross2
        onClick={() => {
          questionEditOpen
            ? setQuestionEditOpen(false)
            : setViewQuestion(false);
        }}
        className="w-6 h-6 cursor-pointer "
      />
    </div>

    // <div className="absolute top-8 right-0 flex flex-col justify-center rounded-xl overflow-hidden border-2 bg-white z-50 w-[180px]">
    //   {menuOptions.map((option, index) => (
    //     <div
    //       key={index}
    //       className={`cursor-pointer text-sm hover:bg-gray-100 p-2 px-4 font-medium w-full capitalize ${
    //         option === "delete" ? "text-red-500" : ""
    //       }`}
    //       onClick={action}
    //     >
    //       {option}
    //     </div>
    //   ))}
    // </div>
  );
};

export default QuestionMenu;
