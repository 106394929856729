import { useCallback, useEffect, useState } from 'react'

const useTabSwithcDetection = () => {
    const [violation, setViolation] = useState(false)
    const [tabSwitch, setTabSwitch] = useState(false)

    const handleVisibilityChange = useCallback(() => {
        setViolation(false);
        if (document.visibilityState !== "visible") {
            setTabSwitch(true);
        } else {
            if (tabSwitch) {
                setViolation(true);
            }
            setTabSwitch(false);
        }
    }, [tabSwitch])

    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange);
        // Cleanup function 
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [handleVisibilityChange]);

    return violation
}

export default useTabSwithcDetection