import React from "react";
import { motion } from "framer-motion";

import CameraLeft from "./CameraLeft";
import MicLeft from "./MicLeft";
import { InternetLeft } from "./InternetLeft";
import { examSetupSteps } from "../../constant/Constants";

const SetupLeft = ({
  step,
  hasCameraPermission,
  sethasCameraPermission,
  hasMicPermission,
  sethasMicPermission,
  setisMicWorking,
  hasInternetConnection,
  setHasInternetConnection,
}) => {
  return (
    <>
      {step === "camera" && (
        <CameraLeft
          hasCameraPermission={hasCameraPermission}
          sethasCameraPermission={sethasCameraPermission}
        />
      )}

      {step === "mic" && (
        <MicLeft
          hasMicPermission={hasMicPermission}
          sethasMicPermission={sethasMicPermission}
          setisMicWorking={setisMicWorking}
        />
      )}

      {step === "internet" && (
        <InternetLeft
          hasInternetConnection={hasInternetConnection}
          setHasInternetConnection={setHasInternetConnection}
        />
      )}

      {step === "instruction" && (
        <div className="flex flex-col justify-center items-center">
          <motion.div
            className="z-10"
            transition={{ ease: "linear", duration: 0.5 }}
            variants={{
              initial: { scale: 1 },
              final: { scale: 2 },
            }}
            animate="final"
          >
            <img src="/img/refresh.png" alt="refresh" width="120px" />
          </motion.div>
        </div>
      )}
    </>
  );
};

export default SetupLeft;
