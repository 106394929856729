import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteAssessment } from "../../../redux/features/assessmentsSlice";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { toast } from "react-toastify";

const AssessmentMenu = ({ assessment }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deleteAssess = async () => {
    const assessmentId = assessment?.id;
    try {
      await dispatch(deleteAssessment({ id: assessmentId }));
      navigate(-1);
      toast.success("Success! Assessment has been deleted");
    } catch (error) {
      console.log(error);
      toast.error(`Error! ${error.message}`);
    }
  };

  return (
    <div className="flex gap-5 items-center">
      <FaRegEdit
        onClick={() => {
          navigate(
            `/admin/assessments/edit/${assessment.id}?type=${assessment.type}&position=${assessment.position}&name=${assessment.name}&experience=${assessment.experience}&domain=${assessment.domain}`
          );
        }}
        className="w-5 h-5 flex items-center justify-center cursor-pointer "
      />
      <MdDeleteOutline
        onClick={deleteAssess}
        className="w-6 h-6 flex items-center justify-center cursor-pointer text-red-500"
      />
      <Link
        to="/admin/assessments"
        className="text-3xl flex items-center justify-center cursor-pointer "
      >
        ×
      </Link>
    </div>
  );
};

export default AssessmentMenu;
