export const jobListingColumns = [
  {
    name: "jobOpeningId",
    label: "Job ID",
    width: "150px",
    open: true,
    sort: true,
    sortType: "",
  },
  {
    name: "positionTitle",
    label: "Position Title",
    width: "200px",
    open: true,
    sort: true,
    sortType: "",
  },
  {
    name: "assignedRecruiter",
    label: "Assigned Recruiter",
    width: "200px",
    open: true,
    sort: false,
  },
  {
    name: "jobPostedDate",
    label: "Posted Date",
    width: "180px",
    open: true,
    sort: true,
    sortType: "",
  },
  {
    name: "lastDateToApply",
    label: "Last Date to Apply",
    width: "180px",
    open: true,
    sort: true,
    sortType: "",
  },
  {
    name: "targetDate",
    label: "Target Date",
    width: "180px",
    open: true,
    sort: true,
    sortType: "",
  },
  {
    name: "departmentManager",
    label: "Department Manager",
    width: "200px",
    open: true,
    sort: false,
  },
  {
    name: "jobType",
    label: "Job Type",
    width: "120px",
    open: true,
    sort: false,
  },
  {
    name: "jobPrefence",
    label: "Job Preference",
    width: "150px",
    open: true,
    sort: false,
  },
  {
    name: "jobStatus",
    label: "Job Status",
    width: "120px",
    open: true,
    sort: false,
  },
];
