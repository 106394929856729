const displayTime = ({ dateTime }) => {
  const formatDate = (dateTime) => {
    // Convert Firestore timestamp to JS Date
    const date = dateTime.toDate();
    const now = new Date();

    // Set to midnight for date comparisons
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    // Get the date to compare
    const compareDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    // Calculate days difference
    const diffTime = today.getTime() - compareDate.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // Today - show only time
    if (compareDate.getTime() === today.getTime()) {
      return date.toLocaleTimeString("en-IN", {
        hour: "numeric",
        minute: "2-digit",
      });
    }

    // Yesterday
    if (compareDate.getTime() === yesterday.getTime()) {
      return "Yesterday";
    }

    // Less than 7 days ago
    if (diffDays > 0 && diffDays < 7) {
      return `${diffDays}d ago`;
    }

    // More than 7 days ago
    return date.toLocaleDateString("en-IN", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return formatDate(dateTime);
};

export default displayTime;
