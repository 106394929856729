import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Popup } from "../../../components";
import { UploadSingleQuestion } from "./UploadSingleQuestion";
import { useDispatch } from "react-redux";
import { setTitle } from "../../../redux/features/pageTitleSlice";
import UploadCSV from "./UploadCSV";

const LibQuestionUpload = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTitle({ title: "Upload Question", showBack: true }));
  }, [dispatch]);

  const [showSuccessModule, setShowSuccessModule] = useState(false);

  return (
    <>
      {showSuccessModule && (
        <Popup closePopup={() => setShowSuccessModule(false)}>
          <div className="p-6 px-10 flex flex-col justify-center items-center gap-5">
            <div className="w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center">
              <p className="text-white text-4xl">✓</p>
            </div>
            <h1 className="text-lg">Question was Successfully added !</h1>
            <Link to="/admin/questionLibrary">
              <Button
                text=" OK "
                width="6rem"
                handleClick={() => setShowSuccessModule(false)}
              />
            </Link>
          </div>
        </Popup>
      )}
      <div className="main-container relative lg:h-[90vh] mx-auto md:ml-20 mt-2 flex flex-col lg:flex-row gap-3 rounded-3xl overflow-hidden">
        <div className="w-full lg:w-[60%] h-full card p-6">
          <UploadSingleQuestion />
        </div>
        <div className="w-full card lg:w-[40%] h-[30rem] lg:h-full">
          <UploadCSV />
        </div>
      </div>
    </>
  );
};

export default LibQuestionUpload;
