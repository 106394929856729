import {
  getAuth,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  reauthenticateWithCredential,
} from "firebase/auth";
import { app } from "../config/firebase";
import { handleFirebaseAuthError } from "./firebaseAuthErrors";

export class FirebaseAuthService {
  auth;

  constructor() {
    this.auth = getAuth(app);
  }

  async createAccount({ email, password, name }) {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );

      // Update profile with name
      await updateProfile(userCredential.user, {
        displayName: name,
      });

      return userCredential.user;
    } catch (error) {
      console.log("Error creating account:", error);
      throw error;
    }
  }

  async sendEmailVerification() {
    try {
      await sendEmailVerification(this.auth.currentUser);
      return true;
    } catch (error) {
      console.log("Error sending email verification:", error);
      throw error;
    }
  }

  async login({ email, password }) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      return userCredential.user;
    } catch (error) {
      const authError = handleFirebaseAuthError(error);
      throw new Error(authError.message);
    }
  }

  async getCurrentUser() {
    try {
      const user = this.auth?.currentUser;
      return user ? user : null;
    } catch (error) {
      console.log("Error getting current user:", error);
      throw error;
    }
  }

  async logout() {
    try {
      await signOut(this.auth);
      return true;
    } catch (error) {
      console.log("Error logging out:", error);
      throw error;
    }
  }

  async passwordReset(email) {
    try {
      await sendPasswordResetEmail(this.auth, email);
      return true;
    } catch (error) {
      console.log("Error resetting password:", error);
      throw error;
    }
  }

  async updatePassword(password) {
    try {
      const user = await this.getCurrentUser();
      await user.updatePassword(password);
      return true;
    } catch (error) {
      console.log("Error updating password:", error);
      throw error;
    }
  }

  async reauthenticateCredential(credential) {
    try {
      const user = await this.getCurrentUser();
      await reauthenticateWithCredential(user, credential);
      return true;
    } catch (error) {
      console.log("Error re-authenticating credential:", error);
      throw error;
    }
  }
}

const authService = new FirebaseAuthService();
export default authService;
