import Button from "./formComponents/button/Button";
import Logo from "./logo/Logo";
import { Link } from "react-router-dom";

const NoPageFound = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen gap-6">
      <Link to="/" className="absolute top-10 left-20 ">
        <Logo width="200px" />
      </Link>

      <h1 className="text-6xl font-extrabold text-gray-500">Oopss !</h1>
      <h2 className="text-xl font-semibold text-gray-600">
        404 - Page Not found
      </h2>
      <p className="text-sm text-gray-700 font-medium text-center">
        The page you are looking for might have been removed,
        <br /> had its name changed or is temporarily unavailable.
      </p>
      <Link to="/">
        <Button text="GO TO HOME PAGE" width="260px" />
      </Link>
    </div>
  );
};

export default NoPageFound;
