import React, { useState, useEffect, useCallback } from "react";

const MicProctoring = ({
  isListening,
  setIsListening,
  showViolations,
  setMicProctorLoading,
}) => {
  const threshold = 40;
  const [audioLevel, setAudioLevel] = useState(0);
  const [error, setError] = useState(null);

  // Buffer for smoothing speech levels
  const [speechBuffer] = useState(new Array(10).fill(0));
  let bufferIndex = 0;

  const startAudioMonitoring = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioContext = new AudioContext();
      const source = audioContext.createMediaStreamSource(stream);
      const analyzer = audioContext.createAnalyser();

      analyzer.fftSize = 2048;
      analyzer.smoothingTimeConstant = 0.8;
      source.connect(analyzer);

      const dataArray = new Uint8Array(analyzer.frequencyBinCount);

      const checkAudioLevel = () => {
        if (!isListening) return;

        analyzer.getByteFrequencyData(dataArray);

        // Calculate overall noise level
        const average =
          dataArray.reduce((acc, value) => acc + value, 0) / dataArray.length;
        const noisePercentage = (average / 255) * 100;

        // Focus on speech frequencies (85-255 Hz)
        const speechFrequencies = dataArray.slice(4, 13);
        let speechAverage =
          speechFrequencies.reduce((acc, value) => acc + value, 0) /
          speechFrequencies.length;

        // More aggressive noise floor reduction
        const noiseFloor = 45; // Increased noise floor
        speechAverage = Math.max(0, speechAverage - noiseFloor);

        // Apply more aggressive scaling to reduce baseline readings
        const speechPercentage = Math.max(0, (speechAverage / 210) * 100 * 0.5);

        // Update rolling average for speech
        speechBuffer[bufferIndex] = speechPercentage;
        bufferIndex = (bufferIndex + 1) % speechBuffer.length;

        // Calculate smoothed speech level with noise gate
        const smoothedSpeechLevel =
          speechBuffer.reduce((acc, val) => acc + val, 0) / speechBuffer.length;
        const finalSpeechLevel =
          smoothedSpeechLevel < 8 ? 0 : smoothedSpeechLevel; // Higher noise gate

        const level = (noisePercentage + finalSpeechLevel) / 2;
        setAudioLevel(level);

        // Determine warning type based on levels
        // console.log("Audio level:", Math.round(level));
        if (level > threshold) {
          showViolations("voice");
        }

        requestAnimationFrame(checkAudioLevel);
      };

      setIsListening(true);
      checkAudioLevel();

      return () => {
        stream.getTracks().forEach((track) => track.stop());
        audioContext.close();
        setIsListening(false);
      };
    } catch (err) {
      setError(err.message);
      console.error("Error accessing microphone:", err);
    } finally {
      setMicProctorLoading(false);
    }
  }, [threshold, isListening, speechBuffer, showViolations]);

  useEffect(() => {
    startAudioMonitoring();
  }, [startAudioMonitoring]);

  return (
    <div className="space-y-4">
      <div className="text-sm text-gray-500">
        {isListening ? (
          <div>
            <div className="w-[260px] bg-gray-200 rounded-full h-2.5">
              <div
                className={`h-2.5 rounded-full ${
                  audioLevel > threshold ? "bg-red-500" : "bg-green-500"
                }`}
                style={{
                  width: `${audioLevel + 1}%`,
                }}
              ></div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* {warning && (
        <div>
          <p>Audio Warning</p>
          <span>
            "Please maintain silence and ensure you are in a quiet environment!"
          </span>
        </div>
      )} */}
    </div>
  );
};

export default MicProctoring;
