import { useSelector } from "react-redux";
import { SelectInput } from "../../../components";

const PositionTitle = ({ setJobId }) => {
  const { jobSelectData } = useSelector((state) => state.jobs);
  return (
    <div>
      <label className="text-sm"> Job Position*</label>
      <div className="grid md: grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        <SelectInput
          className="basic-single w-[260px] "
          inputName="jobPosition"
          placeholderText="Select Job Position"
          selectOptions={jobSelectData}
          isRequired={true}
          handleChange={(selectedOption) => {
            setJobId(selectedOption?.jobId);
          }}
        />
      </div>
    </div>
  );
};

export default PositionTitle;
