import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  RadioInput,
  SelectInput,
  TextInput,
} from "../../../components";
import { newAssessmentPopupFields } from "../../../constant/formFields/newAssessmentPopupFields";

const NewAssessmentPopup = ({
  assessmentDetails,
  setShowNewAssessmentModule,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    position: "",
    experienceLevel: "",
    domain: "",
    questionnireType: "",
  });
  const fields = newAssessmentPopupFields;
  const navigate = useNavigate();
  const formRef = useRef();
  const [error, setError] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = formRef.current;
    const name = formData.name.value;
    const position = formData.position.value;
    const experienceLevel = formData.experienceLevel.value;
    const domain = formData.domain.value;
    const questionnaireType = formData.questionnireType.value;

    if (questionnaireType) {
      navigate(
        `/admin/assessments/createNew?type=${questionnaireType}&name=${name}&position=${position}&experience=${experienceLevel}&domain=${domain}${
          assessmentDetails?.editDocId
            ? `&editId=${assessmentDetails.editDocId}`
            : ""
        } `
      );
      setShowNewAssessmentModule(false);
    } else {
      return setError("Select Questionnaire Type");
    }
  };
  useEffect(() => {
    if (assessmentDetails) {
      setFormData({
        name: assessmentDetails.name,
        position: assessmentDetails.position,
        questionnireType: assessmentDetails.type,
        domain: {
          value: assessmentDetails.domain,
          label: assessmentDetails.domain,
        },
        experienceLevel: {
          value: assessmentDetails.experience,
          label: assessmentDetails.experience,
        },
      });
    }
  }, [assessmentDetails]);

  return (
    <div className="absolute top-0 left-0 w-full h-screen bg-black/20 backdrop-blur-[2px] flex items-center justify-center z-50">
      <div className="relative card p-10 pt-5 space-y-6 w-2/5">
        <div
          className="absolute top-4 right-6 text-2xl bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center cursor-pointer"
          onClick={() => setShowNewAssessmentModule(false)}
        >
          ×
        </div>
        <h1 className="text-lg font-bold">
          {assessmentDetails?.editDocId
            ? "Update Assessment"
            : "Create New Assessment"}
        </h1>
        <form onSubmit={handleSubmit} ref={formRef} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {fields?.map((field) => (
              <div key={field.name}>
                <label className="text-xs font-medium" htmlFor={field.name}>
                  {field.label}
                  {field.isRequired && "*"}
                </label>
                {field.type === "select" ? (
                  <SelectInput
                    inputName={field.name}
                    isRequired={field.isRequired}
                    value={formData[field.name]}
                    placeholderText={field.placeholder}
                    selectOptions={field.options}
                    handleChange={(e) =>
                      setFormData({ ...formData, [field.name]: e })
                    }
                  />
                ) : (
                  <TextInput
                    type={field.type}
                    inputName={field.name}
                    inputValue={formData[field.name]}
                    placeholderText={field.placeholder}
                    isRequired={field.isRequired}
                    handleChange={(e) =>
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                )}
              </div>
            ))}
          </div>

          <div className="space-y-2">
            <label htmlFor="questionnaireType" className="text-xs font-medium">
              Questionnaire Type*
            </label>
            <div className="flex items-center gap-4 font-medium">
              <RadioInput
                text="Add Custom Questions"
                inputName="questionnireType"
                inputValue="custom"
                iniputId="questionnireType1"
                handleChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                checked={formData.questionnireType === "custom"}
              />
              <RadioInput
                text="Use Questions from Library"
                inputName="questionnireType"
                inputValue="library"
                iniputId="questionnireType2"
                handleChange={(e) =>
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                }
                checked={formData.questionnireType === "library"}
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-xs font-medium text-red-500">{error}</span>
            <Button type="submit">
              {assessmentDetails?.editDocId ? "Save" : "Proceed"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewAssessmentPopup;
