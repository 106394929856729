// this email is sent to candiate when he cleared the round
// email contain details for the scheduled next round and some instructions for the assessment

import envConfig from "../../config/envConfig"

const emailTemplate = ({ candidateName, candidateEmail, examCode, jobTitle, companyName, startDate, expDate }) => {
    //content to be changed
    const subject = `Congratulations! You've Advanced to the Next Round`
    const body = `
    Hello <b>${candidateName}</b>,<br>

        We're thrilled to inform you that you've successfully passed the online assessment for the ${jobTitle} position at ${companyName}! Congratulations!<br><br>

        Your performance on the test impressed us, and we'd like to invite you to participate in the next stage of the hiring process.<br><br>
        
        <b>Next Round Assessment Instructions - </b><br>
            1. You have to attempt the test in the time window given below.<br>
            2. Make sure to join from a Laptop/PC with a working webcam and microphone.<br><br>

            <b>Assessment Date & Time - </b></br>
            ${startDate?.date} - ${startDate?.time} to ${expDate?.date} - ${expDate?.time}<br><br>
        
        <b>Test Credentials - </b><br>
            <b>Assessment Link - </b> <a href = ${envConfig.examLink}>${envConfig.examLink}</a> <br>
            <b>Login ID - </b> ${candidateEmail}<br>
            <b>Login Code - </b> ${examCode}<br> <br>
        
        <b>Need to make changes to this event?</b> <br>
        Login with the same credentials and reschedule the test <br> <br>
        
        <b>Please follow our LinkedIn page for Future Job Updates</b> <br>
        LinkedIn - <a href='https://www.linkedin.com/company/talentigo/'>Linkedin-Talentigo</a> <br><br>
        
        Good Luck!<br>
        <b>${companyName}</b>
    `
    return { subject, body }
}

export default emailTemplate