import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./redux/store";
import { Provider } from "react-redux";
// import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <GoogleOAuthProvider clientId="829333526368-jaksj1vh4njbtahspis2ecuh0qbkk6au.apps.googleusercontent.com">
  <Provider store={store}>
    <App />
  </Provider>
  // </GoogleOAuthProvider>
);
