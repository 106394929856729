import React from "react";
import { Timestamp } from "firebase/firestore";
import { Button, RadioInput } from "../../../components";
import { useDispatch } from "react-redux";
import { updateJob as updateJobReducer } from "../../../redux/features/jobSlice";
import { toast } from "react-toastify";

const UpdateJobStatus = ({
  setOpenEditStatus,
  jobData,
  jobStatus,
  setJobStatus,
}) => {
  const statusArr = ["in-progress", "closed", "on hold", "inactive"];
  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const dispatch = useDispatch();

  const changeStatus = async (e) => {
    e.preventDefault();
    const selectedStatus = e.target.status.value;
    const timelineData = [
      ...jobData.timeline,
      { name: selectedStatus, time: Timestamp.fromDate(new Date()) },
    ];
    const documentId = jobData?.jobOpeningId;
    const updateData = {
      jobStatus: selectedStatus,
      timeline: timelineData,
    };
    try {
      await dispatch(
        updateJobReducer({ id: documentId, data: updateData })
      ).unwrap();
      // setJobStatus(selectedStatus);
      toast.success("Job status updated successfully");
      setOpenEditStatus(false);
    } catch (error) {
      console.error("Error updating document:", error.message);
    }
  };
  return (
    <div className="relative border-2 border-gray-200 card shadow-md h-fit w-[20rem] py-4 px-8">
      <h1 className="text-lg font-semibold">Change Status</h1>
      <form onSubmit={changeStatus} className="mt-2 w-full">
        <label htmlFor="status" className="text-sm">
          Select Job Status
        </label>
        <div className="grid grid-cols-2 gap-3">
          {statusArr.map((status) => (
            <RadioInput
              key={status}
              text={status}
              inputName="status"
              inputValue={status}
              iniputId={status}
              defaultChecked={jobStatus === status}
              handleChange={() =>
                jobStatus === status
                  ? setButtonDisabled(true)
                  : setButtonDisabled(false)
              }
            />
          ))}
        </div>
        <div className="flex justify-end gap-4 mt-6">
          <Button
            version="secondary"
            text="Cancel"
            handleClick={() => setOpenEditStatus(false)}
          />
          <Button
            version="primary"
            type="submit"
            text="Update"
            isDisabled={buttonDisabled}
          />
        </div>
      </form>
    </div>
  );
};

export default UpdateJobStatus;
