import React from "react";
// import { motion } from 'framer-motion'
import { LuLoaderCircle } from "react-icons/lu";

import "./Button.css";

const Button = ({
  className,
  text,
  children,
  type,
  isDisabled,
  version,
  handleClick,
  inputId,
  display,
  width,
  loading,
}) => {
  return (
    <button
      className={`btn btn-${
        version ? version : "primary"
      } ${className} flex space-x-2`}
      type={type}
      disabled={isDisabled || loading}
      onClick={handleClick}
      style={{ display: `${display}`, minWidth: `${width}` }}
      id={inputId}
    >
      {text && <span>{text}</span>}
      {children && <span>{children}</span>}
      {loading && (
        <span>
          <LuLoaderCircle className="animate-spin" />
        </span>
      )}
    </button>
  );
};

export default Button;
