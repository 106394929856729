import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import JobListing from "./JobListing";
import JobView from "./jobView/JobView";
import { FaArrowCircleUp } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setTitle } from "../../redux/features/pageTitleSlice";
import JobFilterMenu from "./jobComponents/JobFilterMenu";
import AddedFilters from "./jobComponents/AddedFilters";
import { useAutoClosePopups } from "../../utils/hooks";

const Jobs = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [openJobView, setOpenJobView] = useState(false);
  const [openedJobId, setOpenedJobId] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTitle({ title: "Jobs" }));
  }, [dispatch]);

  useEffect(() => {
    if (jobId) {
      setOpenJobView(true);
      setOpenedJobId(jobId);
    } else {
      setOpenJobView(false);
      setOpenedJobId(null);
    }
  }, [jobId]);

  const scrollableContainerRef = useRef(null);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const scrollToTop = () => {
    scrollableContainerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScroll = useCallback(() => {
    scrollableContainerRef.current.scrollTop > 200
      ? setShowScrollToTop(true)
      : setShowScrollToTop(false);
  }, []);
  useEffect(() => {
    const container = scrollableContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  const [filters, setFilters] = useState({});
  const filterPopupRef = useRef();
  const [openFilterMenu, setOpenFilterMenu] =
    useAutoClosePopups(filterPopupRef);

  return (
    <div className="main-container w-full mx-auto md:ml-20 mt-1 flex flex-col lg-px-10">
      <div className="card w-full relative lg:h-[90vh] overflow-y-hidden">
        <div className="application-filter-bar flex gap-2 h-[10%] pr-6">
          <div className="left-item w-full flex items-center gap-3 py-2 px-4">
            <h1 className="heading1 ml-2">All Posted Jobs</h1>
          </div>
          <div className="right-item flex items-center gap-2">
            <AddedFilters filters={filters} setFilters={setFilters} />
            <div className="relative py-2" ref={filterPopupRef}>
              <Button
                text=""
                version="outline-white"
                className="filter-btn w-32"
                handleClick={() => setOpenFilterMenu(!openFilterMenu)}
              >
                <div className="flex gap-2">
                  <span className="text-base">Filter</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                    />
                  </svg>
                </div>
              </Button>

              {openFilterMenu && (
                <JobFilterMenu
                  setOpenFilterMenu={setOpenFilterMenu}
                  setFilters={setFilters}
                  filters={filters}
                />
              )}
            </div>
            <div className="invite-candidate flex items-center justify-center py-2">
              <Button
                width="200px"
                text=""
                handleClick={() => navigate("createNew")}
              >
                <div> Add new Job </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="w-[100%] h-[calc(100vh-172px)] overflow-hidden px-4 py-1">
          <div className="w-full h-full overflow-x-auto scrollable rounded-xl border-2 border-gray-200">
            <JobListing
              scrollableContainerRef={scrollableContainerRef}
              filters={filters}
            />
            <div
              className={`absolute right-12 rounded-full shadow-md transition-all duration-300 backdrop-blur-[2px] ${
                showScrollToTop
                  ? "bottom-8 opacity-100"
                  : "-bottom-12 opacity-0"
              }`}
            >
              <FaArrowCircleUp
                className="w-12 h-12 text-gray-600/60 cursor-pointer"
                onClick={scrollToTop}
              />
            </div>
          </div>
        </div>
      </div>
      {openJobView && <JobView jobId={openedJobId} openJobView={openJobView} />}
    </div>
  );
};

export default Jobs;
