import "./App.css";
import "./assets/sass/style.scss";
import { useEffect } from "react";

// react router dom imports
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

// admin pages imports
import {
  AdminLogin,
  Admin,
  Dashboard,
  ATSMain,
  CandidateProfile,
  NewCandidate,
  Jobs,
  NewJob,
  AdminProfile,
  Settings,
  // Notifications,
  QuestionLibrary,
  Assessments,
  NewAssessment,
  LibQuestionUpload,
  RegisterLayout,
} from "./admin";

// candidate pages imports
import {
  ApplyLayout,
  ApplyStep1,
  CandidateLayout,
  CandidateDetailsForm,
  CandidateLogin,
  CandidateRegistration,
  CandidateDashboardPage,
  Jobs as CandidateJobs,
} from "./candidate";

// exam pages imports
import {
  Login,
  Start,
  Setup,
  ExamInstructions,
  ExamPage,
  // Test
} from "./pages";

// other imports
import { NoPageFound } from "./components";
import AdminAuthProtected from "./utils/AuthLayout";
import ExamAuthProtected from "./utils/ExamAuhLayout";
import { ToastContainer } from "react-toastify";
import { Zoom } from "react-toastify";

// import { StopRecordingPopup } from "./components";
// import {candidateLoginWindow} from './constant/Constants';
// import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

const App = () => {
  // const [isScreenSharing, setisScreenSharing] = useState(true);
  // const [recordingStopPopUp, setRecordingStopPopUp] = useState(false);
  // useEffect(() => {
  //   // console.log("isScreenSharing:"+isScreenSharing)
  //   if (isScreenSharing) {
  //     setRecordingStopPopUp(false);
  //   } else {
  //     setRecordingStopPopUp(true);
  //   }
  // }, [isScreenSharing]);

  // redirect to landing page
  // const RedirectToTalentigoLanding = () => {
  //   useEffect(() => {
  //     window.location.href = "https://talentigo.ai/";
  //   }, []);
  // };

  return (
    <div className="relative flex flex-col h-[100vh] overflow-hidden">
      <ToastContainer
        autoClose={3000}
        pauseOnHover
        newestOnTop
        transition={Zoom}
      />
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<RedirectToTalentigoLanding />} /> */}
          <Route path="/" element={<Navigate to="/admin" />} />

          {/* for testing */}
          {/* <Route path="/test" element={</>} /> */}

          {/*admin Auth*/}
          <Route
            path="/login"
            element={
              <AdminAuthProtected authenticate={false}>
                <AdminLogin />
              </AdminAuthProtected>
            }
          />
          <Route
            path="/register"
            element={
              <AdminAuthProtected authenticate={false}>
                <RegisterLayout />
              </AdminAuthProtected>
            }
          />

          {/* admin Dashboard */}
          <Route
            path="/admin"
            element={
              <AdminAuthProtected authenticate>
                <Admin />
              </AdminAuthProtected>
            }
          >
            {/* dashboard */}
            <Route index element={<Navigate to="dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />

            {/* ats  */}
            <Route path="ats" element={<Outlet />}>
              <Route index element={<ATSMain />} />
              <Route path="newCandidate" element={<NewCandidate />} />
              <Route
                path="candidateResults/:applicationId"
                element={<CandidateProfile />}
              />
            </Route>

            {/* jobs  */}
            <Route path="jobs" element={<Outlet />}>
              <Route index element={<Jobs />} />
              <Route path=":jobId" element={<Jobs />} />
              <Route path="createNew" element={<NewJob />} />
              <Route path="edit/:editId" element={<NewJob />} />
            </Route>

            {/* questions  */}
            <Route path="questionLibrary" element={<Outlet />}>
              <Route index element={<QuestionLibrary />} />
              <Route path="custom" element={<QuestionLibrary tab="custom" />} />
              <Route
                path="library"
                element={<QuestionLibrary tab="library" />}
              />
              <Route path="createNew" element={<LibQuestionUpload />} />
              {/* <Route path="edit/:questionId" element={<LibQuestionUpload />} /> */}
            </Route>

            {/* assessments  */}
            <Route path="assessments" element={<Outlet />}>
              <Route index element={<Assessments />} />
              <Route path=":assessmentId" element={<Assessments />} />
              <Route path="createNew" element={<NewAssessment />} />
              <Route path="edit/:assessmentId" element={<NewAssessment />} />
            </Route>

            {/* admin profile, settings, notifications  */}
            <Route path="profile" element={<AdminProfile />} />
            <Route path="setting" element={<Settings />} />
            {/* <Route path="/notifications" element={<Notifications />} /> */}
          </Route>

          {/*candidate apply*/}
          <Route path="/candidateApply" element={<ApplyLayout />}>
            <Route index element={<ApplyStep1 />} />
            {/* <Route path="step2" element={<ApplyStep2 />} /> */}
            {/* <Route path="step3" element={<ApplyStep3 />} /> */}
          </Route>

          {/* candidate Auth  */}
          <Route
            path="/candidate/registration"
            element={<CandidateRegistration />}
          />
          <Route path="/candidate/login" element={<CandidateLogin />} />

          {/* candidate Dashboard */}
          <Route path="/candidate" element={<CandidateLayout />}>
            <Route index element={<Navigate to="dashboard" />} />
            <Route path="dashboard" element={<CandidateDashboardPage />} />
            <Route path="myJobs" element={<CandidateJobs tab={"myJobs"} />} />
            <Route path="jobs" element={<CandidateJobs tab={"allJobs"} />} />
            <Route path="profileDetails" element={<CandidateDetailsForm />} />
          </Route>

          {/* Assessmnt Test  */}
          <Route path="/assessment/login" element={<Login />} />
          <Route
            path="/assessment"
            element={
              <ExamAuthProtected authenticate>
                <Outlet />
              </ExamAuthProtected>
            }
          >
            <Route path="start" element={<Start />} />
            <Route path="setup" element={<Outlet />}>
              <Route index element={<Navigate to="camera" />} />
              <Route path=":step" element={<Setup />} />
            </Route>
            <Route path="instructions" element={<ExamInstructions />} />
            <Route path="test" element={<ExamPage />} />
          </Route>

          {/* no page found  */}
          <Route path="*" element={<NoPageFound />} />

          {/* old routes  */}
          {/* archive routes */}
          {/* <Route path="/archiveCandidates" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="archive" tab='candidates' /> : <AdminLogin/>} />
					<Route path="/archiveQuestions" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="archive" tab= 'questions'/> : <AdminLogin/>} />
					<Route path="/archiveAssessments" element={adminLoginLoading ? <LogoLoading/> : isAdminLogedIn ? <Admin page="archive" tab= 'assessments' /> : <AdminLogin/>} /> */}
        </Routes>
      </BrowserRouter>

      {/*{recordingStopPopUp && (*/}
      {/*  <div className="moduleCover">*/}
      {/*    <StopRecordingPopup*/}
      {/*      setisScreenSharing={setisScreenSharing}*/}
      {/*      setRecordingStopPopUp={setRecordingStopPopUp}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default App;
