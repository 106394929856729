import { Link } from "react-router-dom";
import LoadingCard from "./LoadingCard";
import { useSelector } from "react-redux";
import CardCandidate from "./CardCandidate";

const RecentAndTop = ({ type }) => {
  const { dashboardData, loading } = useSelector(
    (state) => state.dashboardData
  );

  return (
    <div className="card p-6">
      <div className="flex justify-between">
        <h3 className="text-lg font-semibold">
          {type === "recentPassed" ? "Recent Passed Candidates" : "Top Scorers"}
        </h3>
        <Link
          to="/admin/ats"
          className="text-sm pt-2 hover:underline hover:text-[#8A24F1]"
        >
          View All
        </Link>
      </div>

      {loading ? (
        <LoadingCard />
      ) : dashboardData?.[type]?.length === 0 ? (
        <div className="flex justify-center items-center w-full h-full ">
          <span className="text-xl font-semibold text-gray-400">
            No Data Exist
          </span>
        </div>
      ) : (
        dashboardData?.[type]?.map((candidate, i) => {
          return <CardCandidate candidate={candidate} key={i} />;
        })
      )}
    </div>
  );
};

export default RecentAndTop;
