import React, { useState, useEffect, useCallback } from "react";
import { Country, State, City } from "country-state-city";
import TextInput from "../../../components/formComponents/textInput/TextInput";
import RadioInput from "../../../components/formComponents/radioInput/RadioInput";
import { auth } from "../../../config/firebase";
// import { RichTextInput, SelectInput } from "../../../components";

const jobTypeOptions = [
  "Full-Time",
  "Part-Time",
  "Contract",
  "Freelance",
  "Internship",
];
const experianceLevelOptions = [
  "Entry Level",
  "Mid Level",
  "Senior Level",
  "Manager",
  "Director",
  "Executive",
];
const workExperianceOptions = [
  "None",
  "Fresher",
  "0-1 Year",
  "2-3 Years",
  "4-5 Years",
  "6-7 Years",
  "8-9 Years",
  "9-10 Years",
  "10+ Years",
];
const Step1 = ({
  setJobDetails,
  jobDetails,
  jobError,
  setJobError,
  editJobData,
}) => {
  const [jobId, setJobId] = useState();
  useEffect(() => {
    setJobId(
      `${auth?.currentUser?.displayName?.slice(0, 3).toUpperCase()}_Job_${(
        Math.random() + 1
      )
        .toString(36)
        .substring(6)}`
    );
  }, []);

  const setEditJobDataDetails = useCallback(() => {
    const convertDate = (date) => {
      const formatedDate = new Date(date.toDate())
        .toLocaleString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/,/g, "");
      return formatedDate;
    };
    setSelectedcountry(editJobData.address.country);
    setSelectedState(editJobData.address.state);

    setJobDetails({
      jobOpeningId: editJobData.jobOpeningId,
      positionTitle: editJobData.positionTitle,
      departmentManager: editJobData.departmentManager,
      assignedRecruiter: editJobData.assignedRecruiter,
      lastDateToApply: convertDate(editJobData.lastDateToApply) || null,
      targetDate: convertDate(editJobData.targetDate) || null,
      jobType: editJobData.jobType,
      workExperiance: editJobData.workExperiance,
      experianceLevel: editJobData.experianceLevel || null,
      minimumSalary: editJobData.salary.min || null,
      maximumSalary: editJobData.salary.max || null,
      numberOfPosition: editJobData.numberOfPosition,
      jobPrefence: editJobData.jobPrefence,
      country: editJobData.address.country,
      state: editJobData.address.state || null,
      city: editJobData.address.city || null,
      postalCode: editJobData.address.postalCode || null,
      jobDescription: editJobData.jobDescription,
    });
  }, [editJobData, setJobDetails]);

  useEffect(() => {
    const initial = {
      jobOpeningId: jobId,
      positionTitle: "",
      departmentManager: "",
      assignedRecruiter: "",
      lastDateToApply: "",
      targetDate: "",
      jobType: "",
      workExperiance: "",
      experianceLevel: "",
      minimumSalary: "",
      maximumSalary: "",
      numberOfPosition: "",
      jobPrefence: "wfo",
      country: "",
      state: "",
      city: "",
      postalCode: "",
      jobDescription: "",
    };
    if (editJobData) {
      setEditJobDataDetails();
    } else {
      setJobDetails(initial);
    }
  }, [jobId, setJobDetails, editJobData, setEditJobDataDetails]);

  const [allcountry, setAllCounty] = useState([]);
  const [selectedCountry, setSelectedcountry] = useState();
  const [allState, setAllState] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [allCity, setAllCity] = useState();
  // const [description, setDescription] = useState("");

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setAllCounty(allCountries?.map((country) => country.name));
    const countryISoCode = allCountries.filter(
      (country) => country.name === selectedCountry
    )[0]?.isoCode;
    const allStateOfSpecificCountry = State.getStatesOfCountry(countryISoCode);
    setAllState(allStateOfSpecificCountry?.map((sta) => sta.name));
    const stateCode = allStateOfSpecificCountry.filter(
      (state) => state.name === selectedState
    )[0]?.isoCode;
    const cityOfSelectedState = City.getCitiesOfState(
      countryISoCode,
      stateCode
    );
    setAllCity(cityOfSelectedState?.map((city) => city.name));
  }, [selectedCountry, selectedState]);

  const updateHandel = (e) => {
    // console.log(e.target.value)
    const updateData = jobDetails;
    updateData[e.target.name] = e.target.value;
    // if(e.target.name ==="")
    if (e.target.name === "country") setSelectedcountry(e.target.value);
    if (e.target.name === "state") setSelectedState(e.target.value);
    setJobDetails({ ...jobDetails, ...updateData });
  };

  const updateError = (e, field) => {
    e.target.value === ""
      ? setJobError({ ...jobError, [field]: "Required" })
      : setJobError({ ...jobError, [field]: false });
    // if(e.target.name === ('lastDateToApply')  && new Date(e.target.value).getTime() < Date.now() )setJobError({ ...jobError, [field]: "Enter a valid Date" })
    // if(e.target.name === ('targetDate') && new Date(e.target.value).getTime() < Date.now() )setJobError({ ...jobError, [field]: "Enter a valid Date" })
  };

  return (
    <div className="w-full h-full space-y-5">
      <div className="w-full h-full overflow-y-auto scrollable">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-6">
          <div>
            <label className="text-xs font-medium">Job Opening Id</label>
            <TextInput
              type="text"
              inputName="jobOpeningId"
              inputValue={jobDetails?.jobOpeningId}
              // isDisabled={true}
            />
          </div>
          <div>
            <label className="text-xs font-medium">Position Title*</label>
            <TextInput
              type="text"
              inputValue={jobDetails.positionTitle}
              inputName="positionTitle"
              handleChange={(e) => {
                updateHandel(e);
                updateError(e, "positionTitleError");
              }}
            />
            <div className="text-xs text-red-500 h-4">
              {jobError?.positionTitleError}
            </div>
          </div>
          <div>
            <label className="text-xs font-medium">Department Manager*</label>
            <TextInput
              type="text"
              inputValue={jobDetails.departmentManager}
              inputName="departmentManager"
              handleChange={(e) => {
                updateHandel(e);
                updateError(e, "departmentManagerError");
              }}
            />
            <div className="text-xs text-red-500 h-4">
              {jobError?.departmentManagerError}
            </div>
          </div>
          <div>
            <label className="text-xs font-medium">Assigned Recruiter*</label>
            <TextInput
              type="text"
              inputValue={jobDetails.assignedRecruiter}
              inputName="assignedRecruiter"
              handleChange={(e) => {
                updateHandel(e);
                updateError(e, "assignedRecruiterError");
              }}
              // selectOptions={assignedRecruiterOptions}
              // placeholderText={"Select Recruiter"}
            />
            <div className="text-xs text-red-500 h-4">
              {jobError?.assignedRecruiterError}
            </div>
          </div>
          <div>
            <label className="text-xs font-medium">Last Date to Apply*</label>
            <TextInput
              type="date"
              inputName="lastDateToApply"
              inputValue={jobDetails.lastDateToApply}
              handleChange={(e) => {
                updateHandel(e);
                updateError(e, "lastDateToApplyError");
              }}
            />
            <div className="text-xs text-red-500 h-4">
              {jobError?.lastDateToApplyError}
            </div>
          </div>
          <div>
            <label className="text-xs font-medium">Target Date*</label>
            <TextInput
              type="date"
              inputName="targetDate"
              inputValue={jobDetails.targetDate}
              handleChange={(e) => {
                updateHandel(e);
                updateError(e, "targetDateError");
              }}
            />
            <div className="text-xs text-red-500 h-4">
              {jobError?.targetDateError}
            </div>
          </div>
          <div>
            <label className="text-xs font-medium">Job Type*</label>
            <TextInput
              type="select"
              inputName="jobType"
              selectOptions={jobTypeOptions}
              inputValue={jobDetails.jobType}
              handleChange={(e) => {
                updateHandel(e);
                updateError(e, "jobTypeError");
              }}
            />
            <div className="text-xs text-red-500 h-4">
              {jobError?.jobTypeError}
            </div>
          </div>
          <div>
            <label className="text-xs font-medium">Work Experiance*</label>
            <TextInput
              type="select"
              inputName="workExperiance"
              selectOptions={workExperianceOptions}
              inputValue={jobDetails.workExperiance}
              handleChange={(e) => {
                updateHandel(e);
                updateError(e, "workExperianceError");
              }}
            />
            <div className="text-xs text-red-500 ">
              {jobError?.workExperianceError}
            </div>
          </div>
          <div>
            <label className="text-xs font-medium">Experiance Level*</label>
            <TextInput
              type="select"
              inputName="experianceLevel"
              selectOptions={experianceLevelOptions}
              inputValue={jobDetails.experianceLevel}
              handleChange={(e) => {
                updateHandel(e);
                updateError(e, "experianceLevelError");
              }}
            />
            <div className="text-xs text-red-500 ">
              {jobError?.experianceLevelError}
            </div>
          </div>
          <div>
            <label className="text-xs font-medium">
              Minimum Salary - Maximum Salary
            </label>
            <div className="flex items-center gap-1">
              <TextInput
                type="number"
                inputName="minimumSalary"
                inputValue={jobDetails.minimumSalary}
                handleChange={updateHandel}
                innerLabel="₹"
              />
              -
              <TextInput
                type="number"
                inputName="maximumSalary"
                inputValue={jobDetails.maximumSalary}
                handleChange={updateHandel}
                innerLabel="₹"
              />
            </div>
          </div>
          <div>
            <label className="text-xs font-medium">Number of positions*</label>
            <TextInput
              type="number"
              inputName="numberOfPosition"
              inputValue={jobDetails.numberOfPosition}
              handleChange={(e) => {
                updateHandel(e);
                updateError(e, "numberOfPositionError");
              }}
            />
            <div className="text-xs text-red-500 h-4">
              {jobError?.numberOfPositionError}
            </div>
          </div>
          <div>
            <label className="text-xs font-medium">Job Preference *</label>
            <div className="flex items-center gap-4">
              <RadioInput
                text="Work From Office "
                inputName="jobPrefence"
                inputValue="wfo"
                iniputId="jobPrefenceOption2"
                checked={jobDetails.jobPrefence === "wfo"}
                handleChange={(e) => {
                  updateHandel(e);
                  updateError(e, "jobPrefenceError");
                }}
              />
              <RadioInput
                text="Work From Home"
                inputName="jobPrefence"
                inputValue="wfh"
                iniputId="jobPrefenceOption1"
                checked={jobDetails.jobPrefence === "wfh"}
                handleChange={(e) => {
                  updateHandel(e);
                  updateError(e, "jobPrefenceError");
                }}
              />
              <RadioInput
                text="Hybrid"
                inputName="jobPrefence"
                inputValue="hybrid"
                iniputId="jobPrefenceOption3"
                checked={jobDetails.jobPrefence === "hybrid"}
                handleChange={(e) => {
                  updateHandel(e);
                  updateError(e, "jobPrefenceError");
                }}
              />
            </div>
            <div className="text-xs text-red-500 h-4">
              {jobError?.jobPrefenceError}
            </div>
          </div>
          <div>
            <label className="text-xs font-medium">Country*</label>
            <TextInput
              type="select"
              inputName="country"
              inputValue={jobDetails.country}
              handleChange={(e) => {
                updateHandel(e);
                updateError(e, "countryError");
              }}
              selectOptions={allcountry}
              placeholderText="Select Country"
            />
            <div className="text-xs text-red-500 h-4">
              {jobError?.countryError}
            </div>
          </div>
          <div className={allState?.length > 0 ? "" : "opacity-50"}>
            <label className="text-xs font-medium">
              State/Province{jobDetails?.jobPrefence !== "wfh" && "*"}
            </label>
            <TextInput
              type="select"
              inputName="state"
              inputValue={jobDetails.state}
              handleChange={(e) => {
                updateHandel(e);
                updateError(e, "stateError");
              }}
              selectOptions={allState}
              placeholderText="Select State"
              isDisabled={allState?.length > 0 ? false : true}
            />
            <div className="text-xs text-red-500 h-4">
              {jobError?.stateError}
            </div>
          </div>
          <div className={allCity?.length > 0 ? "" : "opacity-50"}>
            <label className="text-xs font-medium">
              City{jobDetails?.jobPrefence !== "wfh" && "*"}
            </label>
            <TextInput
              type="select"
              inputName="city"
              inputValue={jobDetails.city}
              handleChange={(e) => {
                updateHandel(e);
                updateError(e, "cityError");
              }}
              selectOptions={allCity}
              placeholderText="Select City"
              isDisabled={allCity?.length > 0 ? false : true}
            />
            <div className="text-xs text-red-500 ">{jobError?.cityError}</div>
          </div>

          <div>
            <label className="text-xs font-medium">Zip/Postal Code</label>
            <TextInput
              type="number"
              inputName="postalCode"
              inputValue={jobDetails.postalCode}
              handleChange={updateHandel}
            />
          </div>
          <div className="md:col-span-2 lg:col-span-3 xl:col-span-4">
            <label className="text-xs font-medium">Job Description*</label>
            {/* <RichTextInput
              toolbarOptions={[
                "bold",
                "italic",
                "underline",
                "sub_script",
                "super_script",
                "numbered_list",
                "bulleted_list",
                "image_base64",
                "format_media",
              ]}
              setOut={setDescription}
              defaultValue={description}
            /> */}
            <TextInput
              type="textbox"
              textRows={6}
              inputName="jobDescription"
              placeholderText=" Job Description..."
              inputValue={jobDetails.jobDescription}
              handleChange={(e) => {
                updateHandel(e);
                updateError(e, "jobDescriptionError");
              }}
            />
            <div className="text-xs text-red-500 h-4">
              {jobError?.jobDescriptionError}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
