import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dbService from "../../firebase/dbService";

export const fetchJobs = createAsyncThunk(
  "jobs/fetchJobs",
  async ({ sort }, { rejectWithValue }) => {
    try {
      const docs = await dbService.getAllJobs(sort);

      const jobData = docs.map((doc) => {
        const {
          jobOpeningId,
          positionTitle,
          assignedRecruiter,
          departmentManager,
          jobType,
          jobStatus,
          jobPrefence,
          jobPostedDate,
          lastDateToApply,
          targetDate,
        } = doc;
        return {
          id: doc.id,
          jobOpeningId,
          positionTitle,
          assignedRecruiter,
          departmentManager,
          jobType,
          jobStatus,
          jobPrefence,
          jobPostedDate: jobPostedDate ? jobPostedDate.toMillis() : null,
          lastDateToApply: lastDateToApply ? lastDateToApply.toMillis() : null,
          targetDate: targetDate ? targetDate.toMillis() : null,
        };
      });
      const selectData = jobData?.map((item) => {
        return {
          jobId: item.jobOpeningId,
          value: item.positionTitle,
          label:
            item.positionTitle.charAt(0).toUpperCase() +
            item.positionTitle.slice(1),
        };
      });
      return { jobData, selectData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createJob = createAsyncThunk(
  "jobs/createJob",
  async ({ data }, { rejectWithValue }) => {
    try {
      await dbService.createDocumentWithId(
        "jobsPosted",
        data,
        data.jobOpeningId
      );

      const addedDoc = await dbService.getdocument(
        "jobsPosted",
        data.jobOpeningId
      );

      const {
        jobOpeningId,
        positionTitle,
        assignedRecruiter,
        departmentManager,
        jobType,
        jobStatus,
        jobPrefence,
        jobPostedDate,
        lastDateToApply,
        targetDate,
      } = addedDoc;
      const addedJob = {
        id: addedDoc.id,
        jobOpeningId,
        positionTitle,
        assignedRecruiter,
        departmentManager,
        jobType,
        jobStatus,
        jobPrefence,
        jobPostedDate: jobPostedDate ? jobPostedDate.toMillis() : null,
        lastDateToApply: lastDateToApply ? lastDateToApply.toMillis() : null,
        targetDate: targetDate ? targetDate.toMillis() : null,
      };

      const addedSelectData = {
        jobId: addedDoc.jobOpeningId,
        value: addedDoc.positionTitle,
        label:
          addedDoc.positionTitle.charAt(0).toUpperCase() +
          addedDoc.positionTitle.slice(1),
      };

      return { addedJob, addedSelectData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateJob = createAsyncThunk(
  "jobs/updateJob",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      await dbService.updateDocument("jobsPosted", data, id);
      const updatedDoc = await dbService.getdocument("jobsPosted", id);
      const {
        jobOpeningId,
        positionTitle,
        assignedRecruiter,
        departmentManager,
        jobType,
        jobStatus,
        jobPrefence,
        jobPostedDate,
        lastDateToApply,
        targetDate,
      } = updatedDoc;
      const updatedJob = {
        id: updatedDoc.id,
        jobOpeningId,
        positionTitle,
        assignedRecruiter,
        departmentManager,
        jobType,
        jobStatus,
        jobPrefence,
        jobPostedDate: jobPostedDate ? jobPostedDate.toMillis() : null,
        lastDateToApply: lastDateToApply ? lastDateToApply.toMillis() : null,
        targetDate: targetDate ? targetDate.toMillis() : null,
      };

      const updatedSelectData = {
        jobId: updatedDoc.jobOpeningId,
        value: updatedDoc.positionTitle,
        label:
          updatedDoc.positionTitle.charAt(0).toUpperCase() +
          updatedDoc.positionTitle.slice(1),
      };

      return { id, updatedJob, updatedSelectData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteJob = createAsyncThunk(
  "jobs/deleteJob",
  async ({ id }, { rejectWithValue }) => {
    try {
      // await dbService.deleteDocument("jobsPosted", id);                   // actual delete
      await dbService.updateDocument("jobsPosted", { isDeleted: true }, id); // delet with updating isDeleted
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  jobData: [],
  jobSelectData: [],
  loading: false,
  error: null,
};

export const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchJobs.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchJobs.fulfilled, (state, action) => {
      state.jobData = action.payload.jobData;
      state.jobSelectData = action.payload.selectData;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchJobs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(createJob.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createJob.fulfilled, (state, action) => {
      state.jobData = [action.payload.addedJob, ...state.jobData];
      state.jobSelectData = [
        action.payload.addedSelectData,
        ...state.jobSelectData,
      ];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(createJob.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteJob.pending, (state) => {
      // state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteJob.fulfilled, (state, action) => {
      state.jobData = state.jobData.filter((job) => job.id !== action.payload);
      state.jobSelectData = [
        state.jobSelectData.filter((job) => job.jobId !== action.payload),
      ];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteJob.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateJob.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateJob.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;

      // Find the index of the job to update
      const jobIndex = state.jobData.findIndex(
        (job) => job.id === action.payload.id
      );
      // Update the job in the array if found
      if (jobIndex !== -1) {
        state.jobData[jobIndex] = {
          ...state.jobData[jobIndex],
          ...action.payload.updatedJob,
        };

        state.jobSelectData = [
          (state.jobSelectData[jobIndex] = {
            ...state.jobSelectData[jobIndex],
            ...action.payload.updatedSelectData,
          }),
        ];
      }
    });
    builder.addCase(updateJob.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default jobSlice.reducer;
