import { useEffect, useState } from "react";
import { BsLayoutThreeColumns } from "react-icons/bs";
import ColumnEditMenu from "../jobs/ColumnEditMenu";
import { LuChevronsUpDown } from "react-icons/lu";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import QuestionRow from "./QuestionRow";
const QuestionsListing = ({
  questions,
  loading,
  // setSortValue,
  // libraryType,
  setViewQuestion,
  setViewQuestionData,
}) => {
  const columns = [
    {
      name: "questionName",
      label: "Question",
      width: "40%",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "type",
      label: "Type",
      width: "12%",
      open: true,
      // sort: true,
      sortType: "",
    },
    {
      name: "time",
      label: "Time",
      width: "12%",
      open: true,
      sort: true,
      sortType: "",
    },
    {
      name: "difficulty",
      label: "Difficulty",
      width: "12%",
      open: true,
      // sort: true,
      sortType: "",
    },
    {
      name: "createdAt",
      label: "Created",
      width: "14%",
      open: true,
      sort: true,
      sortType: "",
    },
  ];

  const [sortedQuesitons, setSortedQuesitons] = useState();
  const [openColumnEdit, setOpenColumnEdit] = useState(false);
  const [columnList, setColumnList] = useState(columns);

  useEffect(() => {
    setSortedQuesitons(questions);
  }, [questions]);

  const handleSort = (field, sortType) => {
    const sortTy =
      sortType === "" ? "desc" : sortType === "desc" ? "asc" : "desc";
    const updateCols = columns.map((column) => {
      if (column.name === field) {
        return {
          ...column,
          sortType: sortTy,
        };
      }
      return column;
    });
    setColumnList(updateCols);

    let sortedArray;
    if (sortTy === "asc") {
      sortedArray =
        field === "questionName"
          ? [...questions].sort((a, b) =>
              a.questionName.localeCompare(b.questionName)
            )
          : [...questions].sort((a, b) => a[field] - b[field]);
    } else if (sortTy === "desc") {
      sortedArray =
        field === "questionName"
          ? [...questions].sort((a, b) =>
              b.questionName.localeCompare(a.questionName)
            )
          : [...questions].sort((a, b) => b[field] - a[field]);
    }

    setSortedQuesitons(sortedArray);
  };

  return (
    <>
      <div className="w-full h-full flex flex-col relative ">
        <div className="flex w-full py-5 pl-5 bg-[#E9EAEB] font-medium text-sm text-black md:rounded-t-xl">
          <div className="w-[5%] flex items-center">
            <BsLayoutThreeColumns
              className="w-5 h-5 cursor-pointer"
              onClick={() => setOpenColumnEdit(!openColumnEdit)}
            />
          </div>
          {columnList.map((column, index) => {
            const width = column.open ? column.width : "0";
            const opacity = column.open ? "1" : "0";
            return (
              <div
                key={index}
                className="flex items-center gap-1 transition-all duration-200 px-2"
                style={{ width, opacity }}
              >
                {column.open && (
                  <div className="flex ic">
                    <span>{column.label}</span>
                    {column.sort && (
                      <div
                        className="ml-1 cursor-pointer"
                        onClick={() => {
                          handleSort(column.name, column.sortType);
                        }}
                      >
                        {column.sortType === "desc" ? (
                          <IoIosArrowUp className="w-4 h-4" />
                        ) : column.sortType === "asc" ? (
                          <IoIosArrowDown className="w-4 h-4" />
                        ) : (
                          <LuChevronsUpDown className="w-4 h-4" />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div
          className="h-full overflow-y-scroll scrollable"
          // ref={scrollableContainerRef}
        >
          {loading ? (
            <div className="text-center font-semibold text-xl text-gray-400 mt-4">
              Loading...
            </div>
          ) : // ) : error ? (
          //   <div className="text-center font-semibold text-xl text-gray-400 mt-4">
          //     Something went wrong while fetching data, please try again
          //   </div>
          sortedQuesitons?.length === 0 ? (
            <div className="text-center w-screen font-semibold text-xl text-gray-400 mt-4">
              No Questions Added Here
            </div>
          ) : (
            sortedQuesitons?.map((question, index) => (
              <QuestionRow
                key={questions.id}
                index={index}
                columnList={columnList}
                setViewQuestionData={setViewQuestionData}
                setViewQuestion={setViewQuestion}
                question={question}
              />
            ))
          )}
        </div>

        <ColumnEditMenu
          openColumnEdit={openColumnEdit}
          setOpenColumnEdit={setOpenColumnEdit}
          columnList={columnList}
          setColumnList={setColumnList}
        />
      </div>
    </>
  );
};

export default QuestionsListing;
