import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Popup, SelectInput } from "../../components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ATSlisting from "./ATSlisting";
import Schedule from "./Schedule";
import { FaArrowCircleUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import dbService from "../../firebase/dbService";
import {
  fetchApplications,
  setFilterJobId,
} from "../../redux/features/applicationsSlice";
import { setTitle } from "../../redux/features/pageTitleSlice";

const ATSMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const urlparams = new URLSearchParams(location.search);
  const tabParam = urlparams.get("tab");
  const roundParam = urlparams.get("round");
  const tabs = ["applied", "assessed", "interviewed", "offered"];

  const [tab, setTab] = useState();
  const [roundName, setRoundName] = useState("");
  // const [sortValue, setSortValue] = useState("");
  const [showScheduleModule, setShowScheduleModule] = useState(false);

  const { filterJobId } = useSelector((state) => state.applications);
  const { jobSelectData } = useSelector((state) => state.jobs);

  useEffect(() => {
    dispatch(setTitle({ title: "ATS" }));
  }, [dispatch]);

  useEffect(() => {
    setTab(tabParam || "applied");
    if (tabParam) {
      try {
        dispatch(
          fetchApplications({
            sort: { fieldName: "appliedAt", fieldValue: "desc" },
            jobId: filterJobId,
            tab: tabParam,
          })
        )
          .unwrap()
          .catch((error) => {
            throw new Error(error);
          });
      } catch (err) {
        console.log(err);
      }
    }
  }, [tabParam, dispatch, filterJobId]);

  // make rounds options
  const [rounds, setRounds] = useState();
  useEffect(() => {
    const fetchJob = async () => {
      try {
        const job = await dbService.getdocument("jobsPosted", filterJobId);
        setRounds(job.rounds);
      } catch (error) {
        console.log(error);
      }
    };
    filterJobId && fetchJob();
  }, [filterJobId]);

  useEffect(() => {
    setRoundName(
      roundParam || rounds?.filter((r) => r.roundType === "assessment")[0]?.id
    );
  }, [roundParam, rounds]);

  // scrolling
  const scrollableContainerRef = useRef(null);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const scrollToTop = () => {
    scrollableContainerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScroll = useCallback(() => {
    scrollableContainerRef.current.scrollTop > 200
      ? setShowScrollToTop(true)
      : setShowScrollToTop(false);
  }, []);
  useEffect(() => {
    const container = scrollableContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  const [candidateToSchedule, setCandidateToSchedule] = useState(null);
  const [candidateToReschedule, setCandidateToReschedule] = useState();

  return (
    <div className="main-container w-full mx-auto md:ml-20 mt-1 flex flex-col lg-px-10">
      <div className="card pt-4 w-full relative lg:h-[90vh] overflow-y-hidden flex flex-col">
        <div className="application-filter-bar flex flex-col gap-1 h-fit pr-4">
          <div className="w-full h-10 flex items-center gap-4 px-4">
            <label htmlFor="jobPosition" className="font-medium text-gray-700">
              Select Job Position:
            </label>
            <SelectInput
              className={"basic-single w-[260px] "}
              inputName={"jobPosition"}
              placeholderText="Select Job Position"
              selectOptions={[
                {
                  jobId: null,
                  value: null,
                  label: "All Jobs",
                },
                ...jobSelectData,
              ]}
              handleChange={(selectedOption) => {
                dispatch(setFilterJobId(selectedOption?.jobId));
              }}
              defaultValue={
                filterJobId
                  ? jobSelectData?.find(
                      (option) => option.jobId === filterJobId
                    )
                  : {
                      jobId: null,
                      value: null,
                      label: "All Jobs",
                    }
              }
            />
          </div>
          <div className="left-item w-full flex items-center gap-3 px-4 overflow-x-auto scrollbar-none">
            {tabs?.map((tabName) => {
              return (
                <Link
                  to={`?tab=${tabName}`}
                  key={tabName}
                  className={`tab-btn capitalize text-sm font-medium rounded-full px-8 py-2 cursor-pointer ${
                    tab === tabName ? "" : "not-active"
                  }`}
                >
                  {tabName}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="application-filter-bar flex gap-2 h-fit pr-4 py-2 justify-between">
          <div className="left-item flex items-center gap-3 px-4 overflow-x-auto scrollbar-none">
            {/* {tab === "assessed" ? (
              <div className="application-filter-bar flex h-fit ml-5">
                {rounds
                  ?.filter((round) => round?.roundType === "assessment")
                  ?.map((round, index) => {
                    return (
                      <Link
                        to={`?tab=${tab}&round=${round?.id}`}
                        key={index}
                        className={`capitalize text-sm font-medium px-8 py-2 cursor-pointer border-b-4 ${
                          roundName === round?.id
                            ? "border-[#8A24F1]"
                            : "border-gray-300"
                        }`}
                      >
                        {round?.assessment}
                      </Link>
                    );
                  })}
              </div>
            ) : (
              <h1 className="heading2">All {tab} Candidates</h1>
            )} */}
            <h1 className="heading2">All {tab} Candidates</h1>
          </div>
          <div className="right-item flex items-center gap-4">
            {/* <div
              className="relative flex items-center gap-4 py-2"
              // onClick={() => setOpenfilterMenu(!openfilterMenu)}
            >
              <Button
                text=""
                version="outline-white"
                className="filter-btn w-32"
              >
                <div className="flex items-center gap-2">
                  <span className="text-base">Filter</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                    />
                  </svg>
                </div>
              </Button>
            </div> */}
            <div className="flex items-center justify-center">
              <Button
                className="h-full w-[200px]"
                text=""
                handleClick={() => navigate("newCandidate")}
              >
                <div className="flex items-center gap-2">
                  <span className="text-xl font-semibold">+</span>
                  <span> Add Candidate </span>
                </div>
              </Button>
            </div>
          </div>
        </div>

        <div className="w-[100%] h-[calc(100vh-100px)] overflow-hidden px-4 py-1 ">
          <div className="w-full h-full overflow-x-auto scrollable rounded-xl border-2 border-gray-200">
            <ATSlisting
              setShowScheduleModule={setShowScheduleModule}
              setCandidateToSchedule={setCandidateToSchedule}
              setCandidateToReschedule={setCandidateToReschedule}
              scrollableContainerRef={scrollableContainerRef}
            />
          </div>

          <div
            className={`absolute right-12 rounded-full shadow-md transition-all duration-300 backdrop-blur-[2px] ${
              showScrollToTop ? "bottom-8 opacity-100" : "-bottom-12 opacity-0"
            }`}
          >
            <FaArrowCircleUp
              className="w-12 h-12 text-gray-600/60 cursor-pointer"
              onClick={scrollToTop}
            />
          </div>
        </div>
      </div>
      {showScheduleModule && (
        <Popup closePopup={() => setShowScheduleModule()}>
          <Schedule
            type={showScheduleModule}
            setShowScheduleModule={setShowScheduleModule}
            candidate={
              showScheduleModule === "schedule"
                ? candidateToSchedule
                : candidateToReschedule
            }
          />
        </Popup>
      )}
    </div>
  );
};

export default ATSMain;
