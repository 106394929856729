import { Timestamp } from "firebase/firestore";
import dbService from "../../../firebase/dbService";
import resultPendingTemplate from "../../../utils/emailTemplates/resultPending";
import nextRoundScheduleTemplate from "../../../utils/emailTemplates/nextRoundSchedule";
import finalRoundCandidateTemplate from "../../../utils/emailTemplates/finalRoundCleared";
import finalRoundAdminTemplate from "../../../utils/emailTemplates/finalRoundClearedAdmin";
import roundNotClearedTemplate from "../../../utils/emailTemplates/roundNotCleared";
import { sendEmailHandler } from "../../../utils/helperFunctions/sendEmailHandler";

export const handleFullScreen = () => {
  if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen();
  } else if (document.documentElement.webkitRequestFullscreen) {
    document.documentElement.webkitRequestFullscreen();
  }
};

export const evaluateExam = ({ results }) => {
  const totalQuestions = results.length;

  const correctAnswers = results?.filter(
    (result) => result?.evaluation === "correct"
  )?.length;
  const attemptedQuestions = results?.filter(
    (result) => result?.evaluation !== "unattempted"
  ).length;
  const pendingEvaluation = results?.filter(
    (result) => result?.evaluation === "pending"
  ).length;
  const score =
    pendingEvaluation > 0
      ? "pending"
      : Math.round((correctAnswers / totalQuestions) * 100);

  return {
    totalQuestions,
    correctAnswers,
    attemptedQuestions,
    pendingEvaluation,
    score,
  };
};

export const updateResults = async ({
  startTime,
  timeTaken,
  totalQuestions,
  attemptedQuestions,
  correctAnswers,
  pendingEvaluation,
  score,
  result,
  assessmentResults,
  jobDetails,
  currentRound,
  applicationDetails,
  scheduleDetails,
  lastRound,
}) => {
  const resultData = {
    examDateTime: Timestamp.fromDate(startTime),
    timeTaken,
    totalQuestions,
    attemptedQuestions,
    correctAnswers,
    pendingEvaluation,
    score,
    result,
    resultDetails: assessmentResults,
  };
  let roundAttempted = jobDetails?.rounds[currentRound];

  const data = {
    currentRound: roundAttempted,
    assessmentsResults: [
      ...(applicationDetails?.assessmentsResults
        ? applicationDetails?.assessmentsResults
        : []),
      resultData,
    ],
  };

  if (lastRound && result === "fail") {
    data.interview = { status: "rejected", statusUpdateAt: Timestamp.now() };
  }

  try {
    await dbService.updateDocument(
      "candidateApplications",
      data,
      scheduleDetails?.applicationId
    );
  } catch (error) {
    console.log(error);
  }
};

export const addSchedule = async ({
  scheduleDetails,
  jobDetails,
  currentRound,
}) => {
  const examCode = (Math.random() + 1).toString(36).substring(6);
  const scheduleDate = new Date();
  const expDate = new Date(scheduleDate.getTime() + 1000 * 60 * 60 * 48);
  const scheduleData = {
    candidateId: scheduleDetails?.candidateId,
    applicationId: scheduleDetails?.applicationId,
    assessmentId: jobDetails?.rounds[currentRound + 1]?.id,
    jobId: scheduleDetails?.jobId,
    candidateEmail: scheduleDetails?.candidateEmail,
    companyName: scheduleDetails?.companyName,
    examCode,
    scheduleDate: Timestamp.fromDate(scheduleDate),
    expDate: Timestamp.fromDate(expDate),
    attempted: false,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  };
  try {
    await dbService.createDocument("assessmentTests", scheduleData);
    return scheduleData;
  } catch (error) {
    throw new Error(error);
  }
};

export const examSubmit = async ({
  setSubmitLoading,
  startTime,
  assessmentResults,
  currentRound,
  jobDetails,
  candidateDetails,
  scheduleDetails,
  companyDetails,
  applicationDetails,
}) => {
  setSubmitLoading(true);

  // collect assessment results and data
  const timeTaken = Date.now() - startTime;
  const {
    totalQuestions,
    correctAnswers,
    attemptedQuestions,
    pendingEvaluation,
    score,
  } = evaluateExam({
    results: assessmentResults,
  });

  let result;
  // find selection Criteria and compare with score
  const selectionCriteria = jobDetails?.rounds[currentRound]?.selectionCriteria;

  //Check if it a last assessment round
  let lastRound;
  const totalRounds = jobDetails?.rounds?.filter(
    (round) => round?.roundType === "assessment"
  )?.length;
  if (currentRound + 1 === totalRounds) {
    lastRound = true;
  } else {
    lastRound = false;
  }

  // sending emails according to results
  try {
    if (pendingEvaluation > 0) {
      result = "pending";
      await sendEmail({
        type: "pending",
        candidateDetails,
        positionTitle: jobDetails?.positionTitle,
        companyName: scheduleDetails?.companyName,
      });
    } else {
      if (score >= selectionCriteria) {
        result = "pass";
        if (!lastRound) {
          // add schedule for next round
          try {
            const scheduleData = await addSchedule({
              scheduleDetails,
              jobDetails,
              currentRound,
            });
            const scheduleDate = new Date(
              scheduleData?.scheduleDate?.toMillis()
            );
            const expDate = new Date(scheduleData?.expDate?.toMillis());
            scheduleDate &&
              (await sendEmail({
                type: "pass",
                candidateDetails,
                companyName: scheduleDetails?.companyName,
                positionTitle: jobDetails?.positionTitle,
                examCode: scheduleData?.examCode,
                scheduleDate,
                expDate,
              }));
          } catch (error) {
            throw new Error(error);
          }
        } else {
          // email to candidate and admin for clearing last round
          try {
            await sendEmail({
              type: "passLast",
              candidateDetails,
              companyName: scheduleDetails?.companyName,
              positionTitle: jobDetails?.positionTitle,
            });
            await sendEmail({
              type: "passLastAdmin",
              candidateDetails,
              companyName: scheduleDetails?.companyName,
              positionTitle: jobDetails?.positionTitle,
              companyDetails: companyDetails,
            });
          } catch (error) {
            throw new Error(error);
          }
        }
      } else {
        result = "fail";
        try {
          await sendEmail({
            type: "fail",
            candidateDetails,
            companyName: scheduleDetails?.companyName,
          });
        } catch (error) {
          throw new Error(error);
        }
      }
    }
    // add the results to the candidate Application
    try {
      await updateResults({
        startTime,
        timeTaken,
        totalQuestions,
        attemptedQuestions,
        correctAnswers,
        pendingEvaluation,
        score,
        result,
        assessmentResults,
        jobDetails,
        currentRound,
        applicationDetails,
        scheduleDetails,
        lastRound,
      });
    } catch (error) {
      throw new Error(error);
    }
  } catch (error) {
    console.log(error);
  }

  // -------------------------------------------------------
  // const nid = `nid_${Date.now()}`;
  // const notificationTime = new Date().toLocaleString("en-in", {
  //   hour: "numeric",
  //   minute: "numeric",
  // });
  // const notificationBody = `${candidateDetails?.name} had submitted the Assessment.`;
  // let notificationData = `${dataSheetId}|${nid}|${hrEmail}|${uid}|${new Date().toDateString()}|${notificationTime}|${notificationBody}`;
  // let addNotificationApi = process.env.REACT_APP_ADD_NOTIFICATION;
  // setTimeout(() => {
  //   axios.post(postResultsApi, sheetData);
  //   axios.post(addNotificationApi, notificationData);
  //   axios.post(mailApi, emailData);
  // }, 100);
  // --------------------------------------------------
};

export const sendEmail = async ({
  type,
  candidateDetails,
  companyName,
  companyDetails,
  positionTitle,
  examCode,
  scheduleDate,
  expDate,
}) => {
  const dateFormat = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const timeFormat = { hour: "numeric", minute: "2-digit", hour12: true };

  const data = {
    candidateName: candidateDetails?.name,
    candidateEmail: candidateDetails?.email,
    examCode,
    jobTitle: positionTitle,
    companyName,
    companyDetails,
    startDate: {
      date: scheduleDate?.toLocaleDateString("en-IN", dateFormat),
      time: scheduleDate?.toLocaleTimeString("en-IN", timeFormat),
    },
    expDate: {
      date: expDate?.toLocaleDateString("en-IN", dateFormat),
      time: expDate?.toLocaleTimeString("en-IN", timeFormat),
    },
  };
  let subject, body;
  if (type === "pass") {
    ({ subject, body } = nextRoundScheduleTemplate(data));
  } else if (type === "pending") {
    ({ subject, body } = resultPendingTemplate(data));
  } else if (type === "passLast") {
    ({ subject, body } = finalRoundCandidateTemplate(data));
  } else if (type === "passLastAdmin") {
    ({ subject, body } = finalRoundAdminTemplate(data));
  } else if (type === "fail") {
    ({ subject, body } = roundNotClearedTemplate(data));
  }

  // emil sending logic
  const emailTo =
    type === "passLastAdmin"
      ? companyDetails?.companyEmail
      : candidateDetails?.email;
  try {
    await sendEmailHandler({
      emailId: emailTo,
      subject,
      body,
    });
  } catch (error) {
    console.log(error);
  }
};
