import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import JobDetails from "./JobDetails";
import { Button, Popup, Topbar2 } from "../../components";
import dbService from "../../firebase/dbService";
// import { Helmet } from "react-helmet";
// import envConfig from "../../config/envConfig";

const ApplyLayout = () => {
  const [jobData, setJobData] = useState("");
  const [loading, setLoading] = useState("");
  const location = useLocation();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const jobIdParam = params.get("jobId");
    const getDataFromFirestore = async () => {
      const docId = jobIdParam;
      if (!docId) return;

      setLoading(true);
      try {
        const job = await dbService.getdocument("jobsPosted", docId);
        setJobData(job);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getDataFromFirestore();
  }, [location]);

  const [companyDetails, setCompanyDetails] = useState("");
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const company = await dbService.getCompanyDetailsWithName(
          jobData?.companyName
        );
        setCompanyDetails(company);
      } catch (error) {
        console.log(error);
      }
    };
    jobData && fetchCompanyDetails();
  }, [jobData]);

  return (
    <>
      {/* ----------------------------------------- */}
      {/* <Helmet>
        <title>{`Apply at ${
          companyDetails?.companyName || "Company Name"
        }`}</title>
        <meta
          property="og:title"
          content={`Apply at ${companyDetails?.companyName || "Company Name"}`}
        />
        <meta
          property="og:description"
          content={`Apply for this exciting opportunity as a ${
            jobData?.positionTitle || "Job Title"
          } in ${companyDetails?.companyName || "Company Name"}!`}
        />
        <meta
          property="og:url"
          content={`${envConfig?.appUrl}/candidateApply?jobId=${jobData?.id}`}
        />
        <meta property="og:image" content={companyDetails?.logoUrl} />
      </Helmet> */}

      {/* --------------------------------------------------- */}

      <Topbar2 hideDetails />
      <div className="space-y-4 lg:space-y-0 lg:flex lg:flex-row-reverse w-full h-full p-5 lg:px-10 xl:px-20 gap-4 overflow-y-scroll scrollable">
        <JobDetails
          jobData={jobData}
          companyDetails={companyDetails}
          loading={loading}
        />
        <div className="card shadow-md w-full h-full lg:w-2/3 overflow-hidden">
          <Outlet context={{ jobData, setShowSuccessPopup }} />
        </div>
        {showSuccessPopup && (
          <Popup closePopup={() => setShowSuccessPopup(false)}>
            <div className="card py-5 px-10 text-center space-y-5">
              <h1 className="heading2">Assessment Details Sent!</h1>
              <p className="w-[22rem]">
                Please go through the Interview details sent to your email id.
              </p>
              <div className="w-full flex justify-center">
                <Button
                  version="secondary"
                  text="Ok"
                  handleClick={() => {
                    // refresh page
                    window.location.reload(); //Todo : Needs improvment to handle further flow
                  }}
                />
              </div>
            </div>
          </Popup>
        )}
      </div>
    </>
  );
};

export default ApplyLayout;
