import { useEffect, useState } from "react";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";

const ATSrows = ({
  index,
  columnList,
  application,
  setShowScheduleModule,
  setCandidateToSchedule,
  setCandidateToReschedule,
}) => {
  const navigate = useNavigate();

  const [candidateDetails, setCandidateDetails] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [notAttemptedSchedules, setNotAttemptedSchedules] = useState([]);

  useEffect(() => {
    if (application) {
      setCandidateDetails(application);
      setSchedules(application.schedules);
    }
  }, [application]);

  useEffect(() => {
    if (schedules) {
      const filteredScheduleData = schedules.filter((schedule) => {
        // filter out schedules that have been attempted
        return !schedule.attempted;
      });
      setNotAttemptedSchedules(filteredScheduleData);
    }
  }, [schedules]);

  const openSchedulePopup = (e) => {
    e.stopPropagation();
    const type = e.target.innerText.toLowerCase();
    setShowScheduleModule(type);
    if (type === "schedule") {
      setCandidateToSchedule(candidateDetails);
    } else if (type === "reschedule") {
      setCandidateToReschedule(candidateDetails);
    }
  };

  return (
    <>
      <div
        className="flex w-full py-2 pl-5 text-xs font-medium even:bg-[#E9EAEB]/60 cursor-pointer"
        onClick={() => {
          navigate(`candidateResults/${candidateDetails?.id}`);
        }}
      >
        <div className="w-[50px] flex items-center">{index + 1}.</div>
        {columnList.map((column) => {
          const width = column.open ? column.width : "0";
          const opacity = column.open ? "1" : "0";
          const score = candidateDetails?.assessmentsResults?.[0]?.score;
          const scoreColor = score
            ? score < 40
              ? "#E5383B"
              : score < 70
              ? "#FFB800"
              : "#57AD55"
            : "#A5A5A5";
          return (
            <div
              key={column.name}
              className={`flex items-center gap-1 transition-all duration-200 overflow-hidden px-2
                ${column.name !== "email" && "capitalize"}
              `}
              style={{ width, opacity }}
            >
              {column.open &&
                (column.name === "appliedAt" ? (
                  new Date(candidateDetails?.appliedAt).toLocaleDateString(
                    "en-in",
                    {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }
                  )
                ) : column.name === "score" ? (
                  <div
                    className={`w-[50px] py-2 text-center  rounded-lg`}
                    style={{
                      color: scoreColor,
                      backgroundColor: `${scoreColor}40`,
                    }}
                  >
                    {score ? `${score}%` : "-"}
                  </div>
                ) : column.name === "schedule" ? (
                  !schedules?.length ? (
                    <Button
                      version="outline-blue"
                      handleClick={(e) => openSchedulePopup(e)}
                    >
                      Schedule
                    </Button>
                  ) : notAttemptedSchedules.length ? (
                    <Button
                      version={"outline-green"}
                      handleClick={(e) => openSchedulePopup(e)}
                    >
                      Reschedule
                    </Button>
                  ) : (
                    <div className=""></div>
                    // <Button isDisabled={true} version={"outline-red"}>
                    //   Rejected
                    // </Button>
                  )
                ) : candidateDetails?.[column.name] ? (
                  candidateDetails?.[column.name]
                ) : (
                  <span className="pl-10">-</span>
                ))}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ATSrows;
