import React, { useRef, useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import QuestionMenu from "./QuestionMenu";
import { UploadSingleQuestion } from "../questionUpload/UploadSingleQuestion";

const QuestionView = ({
  setViewQuestion,
  question,
  viewQuestion,
  libraryType,
}) => {
  // const [menuOpen, setMenuOpen] = useState(false);
  const [questionEditOpen, setQuestionEditOpen] = useState(false);
  const questionViewRef = useRef(null);
  // const menuPopupRef = useRef(null);
  // const contentToPrintRef = useRef(null);

  useEffect(() => {
    if (viewQuestion) {
      const handleClickOutside = (event) => {
        questionViewRef.current.contains(event.target) ||
          setViewQuestion(false);
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [viewQuestion, setViewQuestion]);

  return (
    <div className="absolute top-0 left-0 w-full h-screen bg-black/20 backdrop-blur-[2px] flex justify-end z-50">
      <div
        ref={(element) => {
          questionViewRef.current = element;
        }}
        className="relative w-full md:w-[80%] lg:w-1/2 xl:w-[40%] h-[100vh] bg-white shadow-md rounded-l-xl overflow-hidden text-sm font-medium"
      >
        <div className="bg-[#F8F8F8] w-full p-4">
          <div className="space-y-3 p-5">
            <div className="flex flex-col justify-center h-[40px] gap-2">
              <h1 className="font-semibold text-xl capitalize">
                {questionEditOpen ? " Update Question" : "Question Preview"}
              </h1>
              {!questionEditOpen && (
                <div>
                  <span className="font-bold">Category : </span>
                  <span className="capitalize">
                    {question.category.join(", ")}
                  </span>
                </div>
              )}
            </div>
            <div className="absolute top-2 right-6 flex items-center gap-8">
              {!questionEditOpen && question.difficulty && (
                <div className="flex gap-4 items-center mr-2">
                  <span className="text-gray-500 "> Difficulty :</span>
                  <span
                    className={
                      question.difficulty === 1
                        ? "text-green-500"
                        : question.difficulty === 2
                        ? "text-yellow-500"
                        : question.difficulty === 3
                        ? "text-red-500"
                        : null
                    }
                  >
                    {question.difficulty === 1
                      ? "Easy"
                      : question.difficulty === 2
                      ? "Medium"
                      : question.difficulty === 3
                      ? "Hard"
                      : null}
                  </span>
                </div>
              )}
              <QuestionMenu
                question={question}
                setViewQuestion={setViewQuestion}
                libraryType={libraryType}
                setQuestionEditOpen={setQuestionEditOpen}
                questionEditOpen={questionEditOpen}
              />
            </div>
          </div>
        </div>
        {questionEditOpen ? (
          <div className="w-full h-5/6 py-4">
            <UploadSingleQuestion
              updateQuestion={{
                ...question,
                libraryType: libraryType,
                editDocid: question.id,
              }}
              setQuestionEditOpen={setQuestionEditOpen}
            />
          </div>
        ) : (
          <div className="px-5 space-y-4">
            <div className="grid grid-cols-2 gap-4 p-4 my-1">
              <div>
                <p className="text-gray-500 capitalize"> Type </p>
                <p> {question.type === "mcq" ? "MCQ" : question.type}</p>
              </div>
              <div>
                <p className="text-gray-500 "> Skills</p>
                <p className="capitalize">{question.skills.join(", ")}</p>
              </div>
              <div>
                <p className="text-gray-500 ">Alloted Time </p>
                <p>{` ${Math.floor(question.time / 60)} min ${
                  question.time % 60
                }  sec`}</p>
              </div>
              <div>
                <p className="text-gray-500 ">Topic </p>
                <p className="capitalize">{question.topic.join(", ")}</p>
              </div>
            </div>

            <div className="w-full border-[2px] p-5 gap-3 rounded-lg space-y-3">
              <div className="">
                <p className="text-lg font-medium mb-1">Question</p>
                <pre className="whitespace-pre-wrap">{question.statement}</pre>
              </div>
              {question.type === "mcq" && (
                <div className="space-y-1">
                  <p className="font-medium">Options </p>
                  <div className="grid grid-cols-2 gap-2">
                    {question.options.map((data, i) => {
                      const alphabet = ["A.", "B.", "C.", "D."];
                      return (
                        <div
                          key={i}
                          className={`p-4 flex items-center justify-between gap-3 text-sm rounded-md ${
                            question.correctOption === data
                              ? "bg-[#F1EAF9]"
                              : "bg-[#d7cece37]"
                          } `}
                        >
                          <div className="flex">
                            <span className="pl-3 mr-2">{alphabet[i]}</span>
                            <span>{data}</span>
                          </div>

                          {question.correctOption === data && (
                            <span className="text-[#8A24F1] pr-4">Correct</span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {question?.answerSummary && (
                <div className="col-span-2">
                  <p className="font-medium">Answer Summary </p>
                  <p>{question.answerSummary}</p>
                </div>
              )}
              {question.type === "code" && (
                <div className="col-span-2 grid grid-cols-2 gap-4">
                  <div>
                    <div className="text-gray-500 ">Sample Input</div>
                    <div>{question?.sampleInput}</div>
                  </div>
                  <div>
                    <div className="text-gray-500 ">Sample Output</div>
                    <div>{question.sampleOutput}</div>
                  </div>
                  <div>
                    <div className="text-gray-500 ">Default Code</div>
                    <div>{question.defaultCode}</div>
                  </div>
                  <div>
                    <div className="text-gray-500 ">Constraints</div>
                    <div>{question.constraints}</div>
                  </div>
                  <div>
                    <div className="text-gray-500 ">Test Cases</div>
                    <div>{question.testCases?.join(", ")}</div>
                  </div>
                  <div>
                    <div className="text-gray-500 ">Expected Output</div>
                    <div>{question.expectedOutput?.join(", ")}</div>
                  </div>
                  <div>
                    <div className="text-gray-500 ">Programming Language</div>
                    <div>{question.language?.label}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionView;
