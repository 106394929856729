import React, { useState } from "react";
import {
  LineChart,
  ResponsiveContainer,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";
import SelectInterval from "../SelectInterval";
import useVacancyStatsData from "./useVacancyStatsData";
import VacancyStatsLoader from "./VacancyStatsLoader";

const VacancyStats = () => {
  const [selectValue, setSelectValue] = useState();
  const { chartData, loading } = useVacancyStatsData({
    chartPeriod: selectValue?.days,
  });

  const graphLines = [
    {
      name: "Applications",
      color: "#1F1174",
    },
    {
      name: "Invited",
      color: "#FB9701",
    },
    {
      name: "Attempted",
      color: "#6F490F",
    },
    {
      name: "Unprocessed",
      color: "#087AFF",
    },
    {
      name: "Pending",
      color: "#7022F0",
    },
    {
      name: "Shortlisted",
      color: "#05C718",
    },
    {
      name: "Rejected",
      color: "#FB0101",
    },
  ];

  const [mouseOnLegend, setMouseOnLegend] = useState(null);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // console.log(payload)
      return (
        <div className="w-full bg-gray-100 rounded-xl p-1 border-[1px] shadow-lg border-gray-300">
          <div className="w-full text-center py-2 border-b-2 text-sm border-gray-200">
            {label}
          </div>
          <div className="flex p-2">
            {payload.map((payData, i) => {
              const bulletColor = `${payData.stroke}`;
              return (
                <div
                  key={i}
                  className="border-r-2 last:border-r-0 w-[70px] text-center border-gray-200"
                >
                  <div className="flex justify-center">
                    <div
                      className={`w-[12px] h-[12px]  rounded-full`}
                      style={{ backgroundColor: bulletColor }}
                    ></div>
                    <div className="text-xs ml-2"> {payData.value}</div>
                  </div>
                  <div className="text-[10px]">{payData.dataKey}</div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  const CustomizedTick = ({ x, y, payload, axis }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={axis === "x" ? 12 : -5}
          y={axis === "y" ? -12 : 2}
          dy={16}
          textAnchor="end"
          fill="#888"
          fontSize={12}
        >
          {payload.value || ""}
        </text>
      </g>
    );
  };

  return (
    <div className="card w-full h-[620px] md:col-span-2 lg:col-span-4 px-6 py-4">
      <div className="flex justify-between text-[16px]">
        <h3 className="font-semibold text-lg">Vacancy Stats</h3>
        <SelectInterval
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          showAll={false}
        />
      </div>
      {loading ? (
        <VacancyStatsLoader />
      ) : (
        <>
          <div className="">
            <ResponsiveContainer width="100%" height="20%" aspect={3}>
              <LineChart
                data={chartData}
                margin={{ right: 50, top: 30, bottom: 20 }}
              >
                <CartesianGrid vertical={false} strokeDasharray="3" />
                <XAxis
                  dataKey="name"
                  interval={"preserveStartEnd"}
                  tick={<CustomizedTick axis="x" />}
                  tickLine={false}
                />
                <YAxis
                  type="number"
                  axisLine={false}
                  tickLine={false}
                  tick={<CustomizedTick axis="y" />}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  onMouseEnter={(o) => {
                    const { dataKey } = o;
                    setMouseOnLegend(dataKey);
                  }}
                  onMouseLeave={() => setMouseOnLegend(null)}
                />
                {graphLines?.map(({ name, color }) => (
                  <Line
                    key={name}
                    type="monotone"
                    strokeWidth={1.5}
                    dataKey={name}
                    stroke={color}
                    dot={""}
                    strokeOpacity={
                      mouseOnLegend === null || mouseOnLegend === name ? 1 : 0.2
                    }
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="w-full">
            <div className="text-sm font-medium">
              Total {selectValue && `(${selectValue?.label})`}
            </div>
            <div className="flex gap-3 mt-1">
              {graphLines.map(({ name, color }, i) => {
                return (
                  <div
                    key={name}
                    className="py-1 px-3 rounded-md"
                    style={{
                      color: color,
                      backgroundColor: `${color}30`,
                    }}
                  >
                    <span>
                      {name} {" : "}
                    </span>
                    <span className="font-medium">
                      {Math.round(
                        chartData.reduce(
                          (total, data) => total + data?.[name],
                          0
                        )
                      )
                        ?.toString()
                        ?.padStart(2, "0")}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>{" "}
        </>
      )}
    </div>
  );
};

export default VacancyStats;
