import { useState, useCallback, useEffect } from 'react';
import { collection, query, where, getDocs, documentId } from 'firebase/firestore';
import { db } from '../../../config/firebase';

// Custom error class
class QuestionFetchError extends Error {
    constructor(message, questionIds) {
        super(message);
        this.name = 'QuestionFetchError';
        this.questionIds = questionIds;
    }
}

const useFetchExamQues = (questionIds, assessmentType) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);
    const [questions, setQuestions] = useState([]);

    const fetchQuestions = useCallback(async (questionIds, options = {}) => {
        const {
            retryAttempts = 3,
            retryDelay = 1000
        } = options;

        try {
            setLoading(true);
            setError(null);
            setProgress(0);

            // Input validation
            if (!Array.isArray(questionIds)) {
                throw new Error('Question IDs must be an array');
            }

            if (questionIds.length === 0) {
                setQuestions([]);
                return [];
            }

            // Initialize progress tracking
            const totalQuestions = questionIds.length;
            const updateProgress = (completed) => {
                const progressPercentage = (completed / totalQuestions) * 100;
                setProgress(progressPercentage);
            };

            // Fetch questions in batches
            const batchSize = 10;
            const batches = [];

            for (let i = 0; i < questionIds.length; i += batchSize) {
                const batch = questionIds.slice(i, i + batchSize);
                batches.push(async () => {
                    let attempts = 0;
                    while (attempts < retryAttempts) {
                        try {
                            const collectionName = assessmentType === 'custom' ? 'customQuestionLibrary' : 'questionLibrary';
                            const q = query(
                                collection(db, collectionName),
                                where(documentId(), 'in', batch)
                            );
                            const snapshot = await getDocs(q);

                            // Verify all questions were fetched
                            if (snapshot.size !== batch.length) {
                                const fetchedIds = new Set(snapshot.docs.map(doc => doc.id));
                                const missingIds = batch.filter(id => !fetchedIds.has(id));
                                throw new QuestionFetchError(
                                    'Some questions could not be fetched',
                                    missingIds
                                );
                            }

                            return snapshot;
                        } catch (error) {
                            attempts++;
                            if (attempts === retryAttempts) throw error;
                            await new Promise(resolve => setTimeout(resolve, retryDelay));
                        }
                    }
                });
            }

            // Execute batches with progress tracking
            const fetchedQuestions = [];
            for (let i = 0; i < batches.length; i++) {
                const snapshot = await batches[i]();
                snapshot.forEach(doc => {
                    fetchedQuestions.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
                updateProgress(fetchedQuestions.length);
            }

            // Sort according to original order
            const orderedQuestions = fetchedQuestions.sort((a, b) =>
                questionIds.indexOf(a.id) - questionIds.indexOf(b.id)
            );

            setQuestions(orderedQuestions);
            return orderedQuestions;

        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setLoading(false);
        }
    }, [assessmentType]);

    useEffect(() => {
        const loadExamQuestions = async () => {
            try {
                await fetchQuestions(questionIds);
            } catch (error) {
                console.error("Error loading exam questions:", error);
                // Handle specific error cases
                if (error instanceof QuestionFetchError) {
                    console.error("Failed to fetch some questions:", error.questionIds);
                } else {
                    console.error("Error loading exam questions:", error);
                }
            }
        };
        questionIds && loadExamQuestions();
    }, [questionIds, fetchQuestions]);

    return {
        questions,
        loading,
        error,
        progress
    };
};

export default useFetchExamQues;