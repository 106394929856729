import React, { useState } from "react";
import { TextInput, Button } from "../../components";
import { EmailAuthProvider } from "firebase/auth";
import { RxCross2 } from "react-icons/rx";
import { validatePassword } from "../../utils/validate";
import { toast } from "react-toastify";
import authService from "../../firebase/authService";

const ChangePassword = ({ setOpenPasswordChange }) => {
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [reEnterNewPassword, setReEnterNewPassword] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setError("");
    if (!currentPassword) return setError("Please enter your Current Password");
    if (!newPassword) return setError("Please enter new Password");
    if (!validatePassword(newPassword))
      return setError(
        "Password must be at least 8 characters long and include  uppercase letter, lowercase letter, number, and special character."
      );

    if (newPassword !== reEnterNewPassword)
      return setError(" Password Should be same");

    const user = await authService.getCurrentUser();

    if (user) {
      setLoading(true);
      try {
        const credential = EmailAuthProvider.credential(
          user.email,
          currentPassword
        );

        await authService.reauthenticateCredential(credential);

        await authService.updatePassword(newPassword);

        // setOpenPasswordChange(false)
        toast.success("Password changed successfully");
        setLoading(false);
        setOpenPasswordChange(false);
        // console.log("Password changed successfully");
      } catch (error) {
        toast.error(
          error.message === "Firebase: Error (auth/invalid-credential)."
            ? " Current Password does not match"
            : error.message ===
              "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."
            ? "Too many request . Please Try later"
            : ""
        );
        setLoading(false);
        // console.log(`${error.message}`);
      }
    } else {
      console.log("No user is signed in");
    }
    // setError("")
  };
  return (
    <div className="absolute top-0 left-0 w-full h-screen py-2 z-50 flex items-center justify-center bg-[#000]/20 backdrop-blur-[2px]">
      <div className="relative card py-6 max-h-full px-4 overflow-y-scroll scrollable">
        <div>
          <RxCross2
            className="absolute top-6 right-8 w-8 h-8 bg-gray-300 p-1 rounded-full cursor-pointer z-10"
            onClick={() => setOpenPasswordChange(false)}
          />
        </div>
        <div className="w-96 h-80 px-3">
          <div className="text-xl font-semibold"> Change Password</div>
          {/* <div className="text-xl font-semibold" > Update Password </div> */}
          <div className="text-xs mt-3 font-semibold">Current Password</div>
          <TextInput
            type="password"
            placeholderText="Current Password"
            inputName="currentPassword"
            inputValue={currentPassword}
            handleChange={(e) => setCurrentPassword(e.target.value)}
          />
          <div className="text-xs mt-3 font-semibold">New Password</div>
          <TextInput
            type="password"
            placeholderText="New Password"
            inputName="newPassword"
            inputValue={newPassword}
            handleChange={(e) => setNewPassword(e.target.value)}
          />
          <div className="text-xs mt-3 font-semibold">Confirm Password</div>
          <TextInput
            type="password"
            placeholderText="Re-enter New Password"
            inputName="newPassword"
            inputValue={reEnterNewPassword}
            handleChange={(e) => setReEnterNewPassword(e.target.value)}
          />
          <div className="mt-5">
            <div className="text-xs text-red-500 mb-1"> {error}</div>
            <Button
              loading={loading}
              text="save"
              handleClick={handleChangePassword}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
