import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dbService from "../../firebase/dbService";

export const fetchAssessments = createAsyncThunk(
  "assessments/fetchAssessments",
  async ({ sort }, { rejectWithValue }) => {
    try {
      const docs = await dbService.getAllAssessments(sort);

      const assessmentsData = docs.map((doc) => {
        return {
          ...doc,
          createdAt: doc.createdAt ? doc.createdAt.toMillis() : null,
          updatedAt: doc.updatedAt ? doc.updatedAt.toMillis() : null,
        };
      });
      const selectData = assessmentsData?.map((item) => {
        return {
          assessmentId: item.id,
          assessmentName: item.name,
          questions: item.questions.length,
        };
      });
      return { assessmentsData, selectData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createAssessment = createAsyncThunk(
  "assesments/createAssessment",
  async ({ data }, { rejectWithValue }) => {
    try {
      const docRef = await dbService.createDocument("assessments", data);

      const addedDoc = await dbService.getdocument("assessments", docRef.id);

      const addedAssessmnet = {
        ...addedDoc,
        createdAt: addedDoc.createdAt ? addedDoc.createdAt.toMillis() : null,
        updatedAt: addedDoc.updatedAt ? addedDoc.updatedAt.toMillis() : null,
      };

      const addedSelectData = {
        assessmentId: addedDoc.id,
        assessmentName: addedDoc.name,
        questions: addedDoc.questions.length,
      };

      return { addedAssessmnet, addedSelectData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateAssessment = createAsyncThunk(
  "assessments/updateAssessment",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      await dbService.updateDocument("assessments", data, id);
      const updatedDoc = await dbService.getdocument("assessments", id);
      const updatedAssessment = {
        ...updatedDoc,
        id: updatedDoc.id,
        createdAt: updatedDoc.createdAt
          ? updatedDoc.createdAt.toMillis()
          : null,
        updatedAt: updatedDoc.updatedAt
          ? updatedDoc.updatedAt.toMillis()
          : null,
      };

      const updatedSelectData = {
        assessmentId: updatedAssessment.id,
        assessmentName: updatedAssessment.name,
        questions: updatedAssessment.questions.length,
      };

      return { id, updatedAssessment, updatedSelectData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteAssessment = createAsyncThunk(
  "assesments/deleteAssessment",
  async ({ id }, { rejectWithValue }) => {
    try {
      await dbService.deleteDocument("assessments", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  assessmentsData: [],
  assessmentSelectData: [],
  loading: false,
  error: null,
};

export const assessmentsSlice = createSlice({
  name: "assessments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAssessments.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAssessments.fulfilled, (state, action) => {
      state.assessmentsData = action.payload.assessmentsData;
      state.assessmentSelectData = action.payload.selectData;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchAssessments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(createAssessment.pending, (state) => {
      // state.loading = true;
      state.error = null;
    });
    builder.addCase(createAssessment.fulfilled, (state, action) => {
      state.assessmentsData = [
        action.payload.assessmentData,
        ...state.assessmentsData,
      ];
      state.assessmentSelectData = [
        action.payload.assessmentSelectData,
        ...state.assessmentSelectData,
      ];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(createAssessment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteAssessment.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteAssessment.fulfilled, (state, action) => {
      state.assessmentsData = state.assessmentsData.filter(
        (assesment) => assesment.id !== action.payload
      );
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteAssessment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateAssessment.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateAssessment.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;

      // Find the index of the assessment to update
      const index = state.assessmentsData.findIndex(
        (assesment) => assesment.id === action.payload.id
      );
      // Update the assessment in the array if found
      if (index !== -1) {
        state.assessmentsData[index] = {
          ...state.assessmentsData[index],
          ...action.payload.updatedAssessment,
        };

        state.assessmentSelectData = [
          (state.assessmentSelectData[index] = {
            ...state.assessmentSelectData[index],
            ...action.payload.updatedSelectData,
          }),
        ];
      }
    });
    builder.addCase(updateAssessment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default assessmentsSlice.reducer;
