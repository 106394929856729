import { useSelector } from "react-redux";
import Card from "./Card";
import LoadingCard from "./LoadingCard";
import { dashboardSmallCards } from "../../../constant/othersLists/dashboardSmallCards";
import { useMemo } from "react";
const SmallCards = () => {
  const { dashboardData, loading } = useSelector(
    (state) => state.dashboardData
  );
  const cards = useMemo(
    () => dashboardSmallCards(dashboardData),
    [dashboardData]
  );

  return (
    <div className="w-full grid md:grid-cols-2 lg:grid-cols-4 gap-3">
      {cards?.map((cardData) => (
        <div key={cardData.title}>
          {loading ? <LoadingCard /> : <Card cardData={cardData} />}
        </div>
      ))}
    </div>
  );
};

export default SmallCards;
