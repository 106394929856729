import React, { useEffect, useState } from "react";
import { Button } from "../../components";
import AssessmentsListing from "./AssessmentsListing";
import AssessmentView from "./assessmentView.jsx/AssessmentView";
import NewAssessmentPopup from "./newAssessment/NewAssessmentPopup";
// import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setTitle } from "../../redux/features/pageTitleSlice";
import { useParams } from "react-router-dom";

const Assessments = () => {
  const [openAssessmentView, setOpenAssessmentView] = useState(false);
  const [showNewAssessmentModule, setShowNewAssessmentModule] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTitle({ title: "Assessments" }));
  }, [dispatch]);

  const { assessmentId } = useParams();
  useEffect(() => {
    if (assessmentId) {
      setOpenAssessmentView(true);
    } else {
      setOpenAssessmentView(false);
    }
  }, [assessmentId, setOpenAssessmentView]);

  return (
    <div className="main-container w-full mx-auto md:ml-20 mt-1 flex flex-col lg-px-10">
      <div className="card p-2 pr-0 w-full relative lg:h-[90vh] overflow-y-hidden">
        <div className="application-filter-bar flex gap-2 h-[10%] pr-6">
          <div className="left-item w-full flex items-center gap-3 py-2 px-4 overflow-x-auto scrollbar-none">
            <h1 className="heading1 ml-2">All Assessments</h1>
          </div>
          <div className="right-item">
            <div className="invite-candidate flex items-center justify-center py-2">
              <Button
                width="200px"
                text=""
                handleClick={() => setShowNewAssessmentModule(true)}
              >
                <div> New Assessment </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="w-[100%] overflow-x-scroll h-[90%] px-4 py-2 scrollable">
          <AssessmentsListing />
        </div>
      </div>
      {openAssessmentView && (
        <AssessmentView openAssessmentView={openAssessmentView} />
      )}
      {showNewAssessmentModule && (
        <NewAssessmentPopup
          setShowNewAssessmentModule={setShowNewAssessmentModule}
        />
      )}
    </div>
  );
};

export default Assessments;
