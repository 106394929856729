import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { Outlet } from "react-router-dom";
import { auth } from "../../config/firebase";
import { Sidebar, Topbar3 } from "../../components";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { loginAdmin } from "../../redux/features/adminAuthSlice";
import { fetchJobs } from "../../redux/features/jobSlice";
import { fetchApplications } from "../../redux/features/applicationsSlice";
import { fetchAssessments } from "../../redux/features/assessmentsSlice";
import { fetchDashboardData as fetchDashData } from "../../redux/features/dashboardSlice";
import {
  fetchCategoryData,
  fetchQuestions,
} from "../../redux/features/questionsSlice";

const Admin = () => {
  const [, authLoading] = useAuthState(auth);
  const [sideBarOpen, setSideBarOpen] = useState("none");
  const [screen, setScreen] = useState();
  const dispatch = useDispatch();

  const { title } = useSelector((state) => state.pageTitle);
  useEffect(() => {
    document.title = title ? `Talentigo | ${title}` : "Talentigo";
  }, [title]);

  //find screen size
  useEffect(() => {
    window.innerWidth > 767 && setScreen("large");
    window.innerWidth > 767 && setSideBarOpen("small");
    window.addEventListener("resize", () => {
      window.innerWidth > 767
        ? setSideBarOpen("small")
        : setSideBarOpen("none");
      window.innerWidth > 767 ? setScreen("large") : setScreen("small");
    });
  }, []);

  // fetch admin details
  const fetchAdminDetails = useCallback(() => {
    try {
      dispatch(loginAdmin(""))
        .unwrap()
        .catch((error) => {
          throw new Error(error.message);
        });
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);
  useEffect(() => {
    const unsub = !authLoading && fetchAdminDetails();
    // return () => unsub?.();
  }, [fetchAdminDetails, authLoading]);

  // fetch dashboard details
  const { selectedJobId } = useSelector((state) => state.dashboardData);
  const fetchDashboardData = useCallback(() => {
    try {
      dispatch(fetchDashData({ jobId: selectedJobId }))
        .unwrap()
        .catch((error) => {
          throw new Error(error);
        });
    } catch (err) {
      console.log(err);
    }
  }, [dispatch, selectedJobId]);
  useEffect(() => {
    const unsub = !authLoading && fetchDashboardData();
    // return () => unsub?.();
  }, [fetchDashboardData, authLoading]);

  // fetch job data
  const fetchJobData = useCallback(() => {
    try {
      dispatch(
        fetchJobs({
          sort: { fieldName: "jobPostedDate", fieldValue: "desc" },
        })
      )
        .unwrap()
        .catch((error) => {
          throw new Error(error);
        });
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);
  useEffect(() => {
    const unsub = !authLoading && fetchJobData();
    // return () => unsub?.();
  }, [fetchJobData, authLoading]);

  // fetch application data
  const { filterJobId } = useSelector((state) => state.applications);
  const fetchApplicationData = useCallback(() => {
    try {
      dispatch(
        fetchApplications({
          sort: { fieldName: "appliedAt", fieldValue: "desc" },
          jobId: filterJobId,
        })
      )
        .unwrap()
        .catch((error) => {
          throw new Error(error);
        });
    } catch (err) {
      console.log(err);
    }
  }, [dispatch, filterJobId]);
  useEffect(() => {
    const unsub = !authLoading && fetchApplicationData();
    // return () => unsub?.();
  }, [fetchApplicationData, authLoading]);

  // fetch assessments Data
  const fetchAssessmentsData = useCallback(() => {
    try {
      dispatch(
        fetchAssessments({
          sort: { fieldName: "createdAt", fieldValue: "desc" },
        })
      )
        .unwrap()
        .catch((error) => {
          throw new Error(error);
        });
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);
  useEffect(() => {
    const unsub = !authLoading && fetchAssessmentsData();
    // return () => unsub?.();
  }, [fetchAssessmentsData, authLoading]);

  //fetch questionLibraryCategory data
  const fetchQuestionsCategories = useCallback(() => {
    try {
      dispatch(
        fetchCategoryData({
          sort: { fieldName: "createdAt", fieldValue: "desc" },
        })
      )
        .unwrap()
        .catch((error) => {
          throw new Error(error);
        });
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);
  useEffect(() => {
    const unsub = !authLoading && fetchQuestionsCategories();
    // return () => unsub?.();
  }, [fetchQuestionsCategories, authLoading]);

  //fetch questionLibraryCategory data
  const fetchQuestionsData = useCallback(() => {
    try {
      dispatch(
        fetchQuestions({
          sort: { fieldName: "createdAt", fieldValue: "desc" },
        })
      )
        .unwrap()
        .catch((error) => {
          throw new Error(error);
        });
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);
  useEffect(() => {
    const unsub = !authLoading && fetchQuestionsData();
    // return () => unsub?.();
  }, [fetchQuestionsData, authLoading]);

  // useEffect(() => {
  //   let loginWindow = 48 * 60 * 60 * 1000;
  //   if (Date.now() - admin?.loginTime > loginWindow) {
  //     localStorage.removeItem("adminLogin");
  //     window.location.href = "/admin";
  //   } else {
  //     const updateTime = () => {
  //       const loginData = JSON.parse(localStorage.getItem("adminLogin"));
  //       loginData.loginTime = Date.now();
  //       localStorage.setItem("adminLogin", JSON.stringify(loginData));
  //     };
  //     updateTime();
  //   }
  // }, [admin, page]);

  return (
    <div className="w-full">
      {/* ------------------- Topbar -----------------  */}
      <div className="header-bar sticky top-0 left-0 z-20 bg-gray-200/30 lg:bg-transparent backdrop-blur-md lg:backdrop-blur-0 drop-shadow-md lg:drop-shadow-none">
        <Topbar3 sideBarOpen={sideBarOpen} setSideBarOpen={setSideBarOpen} />
      </div>

      {/* ------------------- Sidebar -----------------  */}
      <div className="absolute top-0 left-0 h-full">
        <div className="h-full w-full relative">
          <motion.div
            className={`absolute z-20 top-0 left-0 w-full h-full bg-[#000]/10 transition-all ${
              sideBarOpen === "open" ? "flex" : "hidden"
            }`}
            onClick={() =>
              setSideBarOpen(
                screen === "large"
                  ? sideBarOpen === "small"
                    ? "open"
                    : "small"
                  : sideBarOpen === "none"
                  ? "open"
                  : "none"
              )
            }
            variants={{
              initial: { opacity: 0 },
              final: { opacity: 1 },
            }}
            initial="small"
            transition={{ duration: 0.3 }}
            animate={sideBarOpen === "open" ? "final" : "initial"}
          />
          <Sidebar
            sideBarOpen={sideBarOpen}
            setSideBarOpen={setSideBarOpen}
            screen={screen}
          />
        </div>
      </div>

      <div>
        {/* ------- heading (for smaller screens) -------  */}
        <div className="mt-4 flex sm:hidden items-center w-[90vw] mx-auto">
          {/* <div onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-8 h-8 cursor-pointer"
            >
              <path
                fillRule="evenodd"
                d="M15 10a.75.75 0 01-.75.75H7.612l2.158 1.96a.75.75 0 11-1.04 1.08l-3.5-3.25a.75.75 0 010-1.08l3.5-3.25a.75.75 0 111.04 1.08L7.612 9.25h6.638A.75.75 0 0115 10z"
                clipRule="evenodd"
              />
            </svg>
          </div> */}
          {/* <h1 className="heading1">{title}</h1> */}
        </div>
        <Outlet />

        {/* {page === "archive" && (
          <Archive
            tab={tab}
            candidates={allSchedules}
            schedulesLoading={schedulesLoading}
            scheduleSortBy={scheduleSortBy}
            setScheduleSortBy={setScheduleSortBy}
            scheduleSortOrder={scheduleSortOrder}
            setScheduleSortOrder={setScheduleSortOrder}
            allQuestions={allQuestions}
            assessmentNames={assessmentNames}
          />
        )} */}
      </div>
    </div>
  );
};

export default Admin;
