import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../components";
import { HiDotsHorizontal } from "react-icons/hi";
import { FaAngleDown, FaRegEdit } from "react-icons/fa";
import AssessmentMenu from "./AssessmentMenu";
import View from "./View";
import Preview from "./Preview";
import dbService from "../../../firebase/dbService";
import { db } from "../../../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { MdDeleteOutline } from "react-icons/md";

const AssessmentView = ({ openAssessmentView }) => {
  const { assessmentId } = useParams();
  const navigate = useNavigate();

  const [openPreview, setOpenPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assessment, setAssessment] = useState();

  const getAssessmentDetails = useCallback(async () => {
    if (!assessmentId) return;
    try {
      await dbService
        .getdocument("assessments", assessmentId)
        .then((doc) => {
          if (doc === false) {
            navigate(-1);
          } else {
            setAssessment(doc);
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    } catch (error) {
      navigate(-1);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [assessmentId, navigate]);

  const [questionLoading, setQuestionLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (assessment?.questions?.length) {
      setQuestionLoading(true);

      const fetchData = async (qeusId) => {
        const docRef = doc(db, "questionLibrary", qeusId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = { id: docSnap.id, ...docSnap.data() };
          setQuestions((prev) => [...prev, data]);
        } else {
          const customDocRef = doc(db, "customQuestionLibrary", qeusId);
          const customDocSnap = await getDoc(customDocRef);
          if (customDocSnap.exists()) {
            const data = { id: customDocSnap.id, ...customDocSnap.data() };
            setQuestions((prev) => [...prev, data]);
          }
        }
      };

      assessment.questions.forEach(fetchData);

      setQuestionLoading(false);
    }
  }, [assessment]);

  useEffect(() => {
    assessmentId && getAssessmentDetails();
  }, [assessmentId, getAssessmentDetails]);

  const assessmentViewRef = useRef(null);

  useEffect(() => {
    if (openAssessmentView) {
      const handleClickOutside = (event) => {
        assessmentViewRef.current.contains(event.target) || navigate(-1);
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [openAssessmentView, navigate]);

  return (
    <div className="absolute top-0 left-0 w-full h-full bg-black/30 backdrop-blur-[2px] flex justify-end items-center z-50">
      <div
        ref={assessmentViewRef}
        className={`relative bg-white rounded-l-[20px] shadow-md h-full flex flex-col overflow-y-scroll scrollable transition-all duration-300 ${
          openPreview ? "w-[70%]" : "w-[40%]"
        } `}
      >
        <div className="absolute top-4 right-6 flex items-center gap-8">
          <AssessmentMenu assessment={assessment} />
        </div>
        <div className="flex flex-col flex-grow">
          <div className="space-y-1 bg-[#F8F8F8] p-10">
            <h1 className="text-2xl font-semibold capitalize">
              {assessment?.name}
            </h1>
            <h1 className="font-medium capitalize">{assessment?.position}</h1>

            <div className="text-sm text-[#979797] font-medium">
              <span className="py-[2px]">
                {new Date(
                  assessment?.createdAt?.seconds * 1000
                )?.toLocaleDateString("en-in", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </span>
              <span className="px-2 text-base font-normal">|</span>
              <span className="py-[2px] capitalize">
                By {assessment?.createdBy}
              </span>
            </div>
          </div>
          {openPreview ? (
            <Preview
              assessment={assessment}
              loading={loading}
              questions={questions}
              questionLoading={questionLoading}
            />
          ) : (
            <View
              assessment={assessment}
              loading={loading}
              questions={questions}
            />
          )}
        </div>

        <div className="sticky bottom-0 right-0 w-full flex justify-end p-4 gap-4 shadow-lg border-t-2 border-gray-200">
          {openPreview ? (
            <Button
              version="outline-primary"
              text="Back"
              handleClick={() => setOpenPreview(false)}
            />
          ) : (
            <>
              <Button
                version="outline-primary"
                text="Preview Assessment"
                handleClick={() => setOpenPreview(true)}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentView;
