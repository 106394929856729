export const deployment = "staging1";

const envConfig = {
  //app
  appVersion: String(process.env.REACT_APP_VERSION),
  appName: String(process.env.REACT_APP_NAME),
  appFullName: String(process.env.REACT_APP_FULL_NAME),

  // appEmail: String(process.env.REACT_APP_EMAIL),
  appUrl:
    deployment === "production"
      ? String(process.env.REACT_APP_URL_PRODUCTION)
      : deployment === "staging1"
      ? String(process.env.REACT_APP_URL_STAGING1)
      : deployment === "staging2"
      ? String(process.env.REACT_APP_URL_STAGING2)
      : String(process.env.REACT_APP_URL_LOCAL),

  applyLinkPhpUrl: "apply-form.php",

  examLink:
    deployment === "production"
      ? `${String(process.env.REACT_APP_URL_PRODUCTION)}/assessment/login`
      : deployment === "staging1"
      ? `${String(process.env.REACT_APP_URL_STAGING1)}/assessment/login`
      : deployment === "staging2"
      ? `${String(process.env.REACT_APP_URL_STAGING2)}/assessment/login`
      : `${String(process.env.REACT_APP_URL_LOCAL)}/assessment/login`,
  //support
  // supportPhone: String(process.env.REACT_APP_SUPPORT_PHONE),
  //   supportEmail: String(process.env.REACT_APP_SUPPORT_EMAIL),

  // social links
  //   facebookLink: String(process.env.REACT_APP_FACEBOOK_LINK),
  //   twitterLink: String(process.env.REACT_APP_TWITTER_LINK),
  //   instagramLink: String(process.env.REACT_APP_INSTAGRAM_LINK),
  //   youtubeLink: String(process.env.REACT_APP_YOUTUBE_LINK),
  //   whatsappLink: String(process.env.REACT_APP_WHATSAPP_LINK),

  // Backeng url
  // backendUrl:
  //     deployment === "production"
  //         ? String(process.env.REACT_APP_BACKEND_URL_PRODUCTION)
  //         : deployment === "staging1"
  //             ? String(process.env.REACT_APP_BACKEND_URL_STAGING1)
  //             : deployment === "staging2"
  //                 ? String(process.env.REACT_APP_BACKEND_URL_STAGING2)
  //                 : String(process.env.REACT_APP_BACKEND_URL_LOCAL),

  // ipInfoKey:
  //     deployment === "production"
  //         ? String(process.env.REACT_APP_IPINFO_KEY_PRODUCTION)
  //         : deployment === "staging1"
  //             ? String(process.env.REACT_APP_IPINFO_KEY_STAGING1)
  //             : deployment === "staging2"
  //                 ? String(process.env.REACT_APP_IPINFO_KEY_STAGING2)
  //                 : String(process.env.REACT_APP_IPINFO_KEY_LOCAL),

  // Rapid api
  rapidApiUrl:
    deployment === "production"
      ? String(process.env.REACT_APP_RAPID_API_URL_PRODUCTION)
      : deployment === "staging1"
      ? String(process.env.REACT_APP_RAPID_API_URL_STAGING1)
      : deployment === "staging2"
      ? String(process.env.REACT_APP_RAPID_API_URL_STAGING2)
      : String(process.env.REACT_APP_RAPID_API_URL_LOCAL),

  rapidApiUrlBatch:
    deployment === "production"
      ? String(process.env.REACT_APP_RAPID_API_URL_BATCH_PRODUCTION)
      : deployment === "staging1"
      ? String(process.env.REACT_APP_RAPID_API_URL_BATCH_STAGING1)
      : deployment === "staging2"
      ? String(process.env.REACT_APP_RAPID_API_URL_BATCH_STAGING2)
      : String(process.env.REACT_APP_RAPID_API_URL_BATCH_LOCAL),

  rapidApiHost:
    deployment === "production"
      ? String(process.env.REACT_APP_RAPID_API_HOST_PRODUCTION)
      : deployment === "staging1"
      ? String(process.env.REACT_APP_RAPID_API_HOST_STAGING1)
      : deployment === "staging2"
      ? String(process.env.REACT_APP_RAPID_API_HOST_STAGING2)
      : String(process.env.REACT_APP_RAPID_API_HOST_LOCAL),

  rapidApiKey:
    deployment === "production"
      ? String(process.env.REACT_APP_RAPID_API_KEY_PRODUCTION)
      : deployment === "staging1"
      ? String(process.env.REACT_APP_RAPID_API_KEY_STAGING1)
      : deployment === "staging2"
      ? String(process.env.REACT_APP_RAPID_API_KEY_STAGING2)
      : String(process.env.REACT_APP_RAPID_API_KEY_LOCAL),

  // email api
  emailApi: String(process.env.REACT_APP_EMAIL_API),
};

export default envConfig;
