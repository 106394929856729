// this email is sent to candiate when he apply on Talentigo and firest assessment is scheduled
// email contain details for the scheduled first round and some instructions for the assessment

import envConfig from "../../config/envConfig"

const emailTemplate = ({ candidateName, candidateEmail, examCode, companyName, startDate, expDate }) => {
    const subject = `Assessment Rescheduled`
    const body = `
    Hello <b>${candidateName}</b>,<br>

    As per your request, we have rescheduled/updated your Interview.<br>

    Please, find the details of your updated interview round for the Online Assessment Test:<br><br>
        <b>Assessment Date & Time - </b></br>
        ${startDate?.date} - ${startDate?.time} to ${expDate?.date} - ${expDate?.time}<br><br>

    Kindly read the instruction and visit the link below :<br><br>

    Instructions - <br>
        1. Make sure to take the Assessment on the designated date and time.
            It won't open if you try on any other dates.<br>
        2. Make sure to join from a Laptop/PC with a working webcam and microphone.<br>
            
    Test Credentials -<br>
        Assessment Link - ${envConfig.examLink}<br>
        Login ID - ${candidateEmail}<br>
        Login Code - ${examCode}<br><br>
         
    Good Luck!<br>
    ${companyName}<br>
    `
    return { subject, body }
}

export default emailTemplate