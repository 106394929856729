import { useCallback, useEffect, useState } from "react";
import {
  Topbar2,
  ConfirmationModule,
  WarningModule,
  Popup,
} from "../../components";
import ExamTopPanel from "./ExamTopPanel";
import ExamQuestionPanel from "./ExamQuestionPanel";
import ExamOptionsPanel from "./ExamOptionsPanel";
import SubmitModule from "./SubmitModule";
import ExamLoadingModule from "./ExamLoadingModule";
import { violationTypes } from "../../constant/violationTypes";
import { handleFullScreen, examSubmit } from "./examFunctions/examFunctions";
import {
  useFullScreenDetection,
  useFetchExamDetails,
  useFetchExamQues,
  usePreventCopyPasteScrnShot,
  useTabSwithcDetection,
  useDevToolsCheck,
  useTerminateOnRefresh,
} from "./examFunctions";
import CameraProctoring from "./proctoring/CameraProctoring";
import MicProctoring from "./proctoring/MicProctoring";

// import NoScreenSharing from '../../components/popup/NoScreenSharing';

const ExamPage = () => {
  const [qusNum, setQusNum] = useState(0);
  const [startTime, setStartTime] = useState();
  const [timer, setTimer] = useState(60);
  const [timerPause, setTimerPause] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState([]);
  const [confirmationButton, setConfirmationButton] = useState([]);
  const [warningCount, setWarningCount] = useState(0);
  const [warningType, setWarningType] = useState("");
  const [warningMessage, setWarningMessage] = useState([]);
  const [violations, setViolations] = useState([]);
  const [maxViolations] = useState(5);
  const [showWarning, setShowWarning] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSubmitModule, setShowSubmitModule] = useState(false);
  const [assessmentResults, setAssessmentResults] = useState([]);

  // fetch all required details - assessment, schedule, candidate, application, job, company
  const {
    scheduleDetails,
    assessmentDetails,
    candidateDetails,
    applicationDetails,
    currentRound,
    jobDetails,
    companyDetails,
  } = useFetchExamDetails();

  // fetch questions
  const {
    questions,
    loading: questionLoading,
    // error,
    progress: questionLoadingProgress,
  } = useFetchExamQues(assessmentDetails?.questions, assessmentDetails?.type);

  // loading state managment
  const [loading, setLoading] = useState(true);
  const [loadingPercent, setLoadingPercent] = useState(0);
  const [cameraProctorLoading, setCameraProctorLoading] = useState(true);
  const [micProctorLoading, setMicProctorLoading] = useState(true);
  const [cameraDetection, setCameraDetection] = useState(false);
  const [isListening, setIsListening] = useState(false);

  useEffect(() => {
    // !questionLoading && setLoading(false);
    if (!questionLoading && !cameraProctorLoading && !micProctorLoading) {
      setLoading(false);
      setIsListening(true);
      setCameraDetection(true);
    }
    setLoadingPercent(Math.round(questionLoadingProgress));
  }, [
    questionLoading,
    cameraProctorLoading,
    micProctorLoading,
    questionLoadingProgress,
  ]);

  //  start timer
  useEffect(() => {
    setStartTime(new Date());
    setTimer(questions[0]?.time);
  }, [questions]);

  // show violations function
  const showViolations = useCallback((type) => {
    setTimerPause(true);
    setCameraDetection(false);
    setIsListening(false);
    type !== "voice" &&
      setViolations((prev) => [...prev, violationTypes[type]?.violation]);
    type !== "voice" && setWarningCount((prev) => prev + 1);
    setWarningType(type);
    setWarningMessage(violationTypes[type].message);
    setShowWarning(true);
  }, []);

  // hide warning Module
  const hideModule = () => {
    if (warningCount === maxViolations - 1) {
      setWarningMessage([
        `This is your last warning`,
        "If you violate the assessment rules again, Your assessment will be terminated.",
      ]);
      setWarningType("lastWarning");
    } else {
      closeWarning();
    }
  };
  const closeWarning = () => {
    handleFullScreen();
    setShowWarning(false);
    setTimerPause(false);
    // setcellPhoneDetection(0);
    setIsListening(true);
    setCameraDetection(true);
  };

  // Browser Proctoring
  const refreshViolation = useTerminateOnRefresh();
  const fullScreenViolation = useFullScreenDetection();
  const tabSwitchViolation = useTabSwithcDetection();
  const screenShotViolation = usePreventCopyPasteScrnShot();
  const { violation: devToolViolation, closeDevTools } = useDevToolsCheck();
  useEffect(() => {
    fullScreenViolation && showViolations("fullScreen");
    tabSwitchViolation && showViolations("tabSwitch");
    screenShotViolation && showViolations("screenShot");
    devToolViolation && !closeDevTools && showViolations("devtools");
    closeDevTools && setWarningType("devtools-close");
    refreshViolation && showViolations("refresh");
  }, [
    tabSwitchViolation,
    fullScreenViolation,
    screenShotViolation,
    devToolViolation,
    closeDevTools,
    showViolations,
    refreshViolation,
  ]);

  //handle cellPhone detection
  // const [cellPhoneDetection, setcellPhoneDetection] = useState(0);
  // useEffect(() => {
  //   if (!loading && cellPhoneDetection > 0) {
  //     showViolations("cellPhoneDetected");
  //   }
  // }, [cellPhoneDetection]);

  // handle Terminate
  const handleTerminate = () => {
    // setViolations([...(violations + "terminate")]);
    setShowWarning(false);
    handleSubmit();
  };

  // next question button
  const handleNext = ({ userAnswer, codeOutput, codeEvaluate, error }) => {
    const question = questions[qusNum];
    let evaluation = "";
    if (userAnswer === undefined || userAnswer === "") {
      evaluation = "unattempted";
    } else {
      if (question?.type === "mcq") {
        evaluation =
          question.options[userAnswer] === question.correctOption
            ? "correct"
            : "incorrect";
      } else if (question?.type === "subjective") {
        evaluation = "pending";
      } else if (question?.type === "code") {
        evaluation = codeEvaluate;
      }
    }

    const quesResult = {
      question: question,
      userAnswer:
        question?.type === "mcq" ? question.options[userAnswer] : userAnswer,
      evaluation: evaluation,
      codeTestCases: question?.type === "code" ? codeOutput : "",
      violations: violations,
    };
    setAssessmentResults([...assessmentResults, quesResult]);
    setViolations([]);

    if (qusNum < questions.length - 1) {
      setQusNum((prev) => prev + 1);
      setTimer(questions[qusNum + 1]?.time);
    } else {
      alertSubmit();
    }
  };

  // skip confirmation module
  const alertSkip = () => {
    setTimerPause(true);
    setConfirmationMessage([
      "Do you want to skip ?",
      "You will not be able to come back to this Question later.",
    ]);
    setConfirmationButton(["Skip Question", "Back to Question"]);
    setShowConfirmation(true);
  };

  //confirm skip
  const confirmSkip = () => {
    cancelAlert();
    handleNext({});
  };

  //cancel module
  const cancelAlert = () => {
    setShowConfirmation(false);
    setTimerPause(false);
  };

  //submit confirmation module
  const alertSubmit = () => {
    setTimerPause(true);
    if (qusNum !== questions.length - 1) {
      setConfirmationMessage([
        `${questions.length - qusNum - 1} Questions still remaining !`,
        "Are you sure you want to skip the pending questions and submit the test ?",
      ]);
      setConfirmationButton(["Submit Anyways", "Back to Test"]);
    } else {
      setConfirmationMessage([
        "Are you sure ?",
        "You want to submit the test ?",
      ]);
      setConfirmationButton(["Submit", "Back to Test"]);
    }
    setShowConfirmation(true);
  };

  //confirm submit
  const confirmSubmit = async () => {
    setTimerPause(true);
    await handleSubmit();
    setShowConfirmation(false);
  };

  //submit exam
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    setSubmitLoading(true);
    try {
      await examSubmit({
        setSubmitLoading,
        startTime,
        assessmentResults,
        currentRound,
        jobDetails,
        candidateDetails,
        scheduleDetails,
        companyDetails,
        applicationDetails,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitLoading(false);
      setShowSubmitModule(true);
      // clear local storage
      localStorage.removeItem("refresh");
      localStorage.removeItem("talentigo-examAuth");
    }
  };

  // Time's Up
  const handleTimeUp = () => {
    if (qusNum !== questions.length - 1) {
      handleNext({});
    } else {
      confirmSubmit();
    }
  };

  return (
    <div
      className="h-[70vh] select-none"
      onContextMenu={(e) => e.preventDefault()}
    >
      <div className="fixed w-full z-10">
        <Topbar2
          name={candidateDetails?.name}
          email={candidateDetails?.email}
        />
      </div>

      {showSubmitModule ? (
        <SubmitModule
          currentRound={currentRound}
          candidateDetails={candidateDetails}
          applicationDetails={applicationDetails}
        />
      ) : (
        <>
          <div className="absolute top-2 left-[50%] translate-x-[-50%] z-50">
            <CameraProctoring
              loading={loading}
              // setLoadingPercent={setLoadingPercent}
              setCameraProctorLoading={setCameraProctorLoading}
              cameraDetection={cameraDetection}
              showViolations={showViolations}
              setWarningType={setWarningType}
            />
            <MicProctoring
              isListening={isListening}
              setIsListening={setIsListening}
              showViolations={showViolations}
              setMicProctorLoading={setMicProctorLoading}
              // setLoadingPercent={setLoadingPercent}
            />
          </div>

          {loading && (
            <div className="absolute w-full h-[100vh] top-0 left-0 z-30 flex justify-center items-center bg-black/[0.15] backdrop-blur-[4px]">
              <ExamLoadingModule loadingPercent={loadingPercent} />
            </div>
          )}

          <div>
            <ExamTopPanel
              assessmentName={assessmentDetails?.name}
              qusNum={qusNum}
              duration={timer}
              timeUp={handleTimeUp}
              timerPause={timerPause}
              alertSubmit={alertSubmit}
              loading={loading}
              submitLoading={submitLoading}
            />
          </div>

          <div className="relative w-[95%] h-full mx-auto py-5 flex card">
            <ExamQuestionPanel
              questions={questions}
              qusNum={qusNum}
              loading={loading}
            />
            <ExamOptionsPanel
              questions={questions}
              handleNext={handleNext}
              qusNum={qusNum}
              alertSkip={alertSkip}
              loading={loading}
            />
          </div>

          {showConfirmation && (
            <Popup closePopup={cancelAlert}>
              <ConfirmationModule
                confirmationMessage={confirmationMessage}
                confirmationButton={confirmationButton}
                cancelAlert={cancelAlert}
                confirmSkip={confirmSkip}
                confirmSubmit={confirmSubmit}
                next={document.getElementById("next")}
                submitLoading={submitLoading}
              />
            </Popup>
          )}

          {showWarning && (
            <Popup>
              <WarningModule
                type={warningType}
                warningCount={warningCount}
                warningMessage={warningMessage}
                handleTerminate={handleTerminate}
                hideModule={hideModule}
                closeWarning={closeWarning}
                maxViolations={maxViolations}
              />
            </Popup>
          )}
        </>
      )}
    </div>
  );
};

export default ExamPage;
