import SmallCards from "./smallCards/SmallCards";
import VacancyStats from "./vacancyStats/VacancyStats";
import CandidateFunnel from "./candidateFunnel/CandidateFunnel";
import ScoreDistribution from "./scoreDistribution/ScoreDistribution";
import RecentAndTop from "./recent&top/Recent&Top";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../redux/features/pageTitleSlice";
import { SelectInput } from "../../components";
import { setSelectedJobId } from "../../redux/features/dashboardSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTitle({ title: "Dashboard" }));
  }, [dispatch]);
  const { selectedJobId } = useSelector((state) => state.dashboardData);
  const { jobSelectData } = useSelector((state) => state.jobs);

  return (
    <div className="main-container dashboard-container mx-auto md:ml-20 mt-0 h-full">
      <div className="w-full h-full overflow-y-scroll scrollable pr-2 space-y-3 py-2">
        <div className="flex justify-end">
          <SelectInput
            className={"basic-single w-[320px] bg-white rounded-xl p-1"}
            inputName={"jobPosition"}
            placeholderText="Select Job Position"
            selectOptions={[
              {
                jobId: null,
                value: null,
                label: "All Jobs",
              },
              ...jobSelectData,
            ]}
            handleChange={(selectedOption) => {
              dispatch(setSelectedJobId(selectedOption?.jobId));
            }}
            defaultValue={
              selectedJobId
                ? jobSelectData?.find(
                    (option) => option.jobId === selectedJobId
                  )
                : {
                    jobId: null,
                    value: null,
                    label: "All Jobs",
                  }
            }
          />
        </div>

        <SmallCards />
        <VacancyStats />
        {/* <CandidateFunnel /> */}
        <ScoreDistribution />
        <div className="grid lg:grid-cols-2 gap-3 w-full min-h-[400px]">
          <RecentAndTop type="recentPassed" />
          <RecentAndTop type="topScorers" />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
