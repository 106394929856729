import { useCallback, useEffect, useState } from "react";
import dbService from "../../firebase/dbService";

const useFetchJob = (jobId) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");

  const fetchData = useCallback(async () => {
    if (!jobId) return setLoading(false);
    try {
      const data = await dbService.getdocument("jobsPosted", jobId);
      if (data) {
        setData(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [jobId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [data, loading];
};

export default useFetchJob;
