import React from "react";

const ExamLoginLeft = () => {
  return (
    <div className="login-container">
      <img
        src="/img/login.png"
        alt="Studying-img"
        className="w-[300px] lg:w-[350px] mb-10"
      />
      <div className="flex flex-col justify-center items-center text-white">
        <h1 className="font-bold w-80%] text-center text-xl capitalize mb-2.5">
          Thank You For Applying
        </h1>
        <p className="w-[90%] text-center mb-5 text-xs lg:text-sm xl:text-base">
          For this round, we're excited to evaluate your skills and knowledge.
          Please provide the necessary details to embark on this assessment
          journey with us!
        </p>
      </div>
    </div>
  );
};

export default ExamLoginLeft;
