import React from "react";
import { useNavigate } from "react-router-dom";

const JobRow = ({ index, columnList, jobData }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="flex w-full overflow-x-auto py-5 pl-5 text-xs font-medium even:bg-[#E9EAEB]/60 cursor-pointer"
        onClick={() => {
          navigate(jobData?.jobOpeningId);
        }}
      >
        <div className="w-[60px] flex items-center">{index + 1}.</div>
        {columnList.map((column) => {
          const width = column.open ? column.width : "0";
          const opacity = column.open ? "1" : "0";
          return (
            <div
              key={column.name}
              className="flex items-center gap-1 capitalize transition-all duration-200"
              style={{ width, opacity }}
            >
              {column.open &&
                (column.name === "jobPostedDate" ||
                column.name === "lastDateToApply" ||
                column.name === "targetDate"
                  ? new Date(jobData?.[column.name]).toLocaleDateString(
                      "en-in",
                      {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      }
                    )
                  : column.name === "jobPrefence"
                  ? `work from ${
                      jobData?.[column.name] === "wfh"
                        ? "home"
                        : jobData?.[column.name] === "hybrid"
                        ? "hybrid"
                        : "office"
                    }`
                  : jobData?.[column.name])}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default JobRow;
