import React from "react";
import Select from "react-select";
import { customStyles } from "./selectStyles";

const SelectInput = ({
  placeholderText,
  className,
  selectOptions,
  inputName,
  handleChange,
  value,
  defaultValue,
  isClearable,
  isSearchable,
  isRequired,
  isDisabled,
  isMulti,
}) => {
  return (
    <Select
      className={className}
      classNamePrefix="select"
      placeholder={placeholderText}
      options={selectOptions}
      name={inputName}
      styles={{
        ...customStyles,
        menuList: (provided) => ({
          ...provided,
          "::-webkit-scrollbar": {
            width: "6px",
          },
          "::-webkit-scrollbar-track": {
            background: "#fff",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#ddd",
            borderRadius: "3px",
          },
        }),
      }}
      value={value}
      defaultValue={defaultValue}
      onChange={handleChange}
      isClearable={isClearable}
      isSearchable={isSearchable}
      required={isRequired}
      isDisabled={isDisabled}
      components={{
        IndicatorSeparator: () => null,
      }}
      isMulti={isMulti}
    />
  );
};

export default SelectInput;
