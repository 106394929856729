import { FaGreaterThan, FaLessThan } from "react-icons/fa6";
const RoundsTabBar = ({
  assessments,
  selectedAssessment,
  setSelectedAssessment,
}) => {
  return (
    <div className="card h-12 flex items-center">
      {/* <div className="w-[5%] flex items-center justify-center">
        <div className="w-fit bg-[#F1EAF9] text-[#8A24F1] px-1 py-2 rounded-md">
          <FaLessThan className="w-3" />
        </div>
      </div> */}
      <div
        className={`w-[90%] h-full flex items-center px-4 overflow-x-scroll scrollable`}
      >
        {assessments?.map((assessment, index) => (
          <div
            key={index}
            className={`h-full px-6 flex justify-center items-center border-b-[3px] cursor-pointer 
                ${selectedAssessment === index ? "border-[#8A24F1]" : ""}
            `}
            onClick={() => {
              setSelectedAssessment(index);
              // add roundkey to the url as round = {round?.key}
            }}
          >
            <p className="text-sm font-medium capitalize flex gap-1 items-baseline">
              {/* {round?.roundType}
              <span className="text-xs">
                {round?.roundType !== "interview" && `(${round?.assessment})`}
              </span> */}
              Asssessment {index + 1}
            </p>
          </div>
        ))}
      </div>
      {/* <div className="w-[5%] flex items-center justify-center">
        <div className="w-fit bg-[#F1EAF9] text-[#8A24F1] px-1 py-2 rounded-md">
          <FaGreaterThan className="w-3" />
        </div>
      </div> */}
    </div>
  );
};

export default RoundsTabBar;
