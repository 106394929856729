import { useCallback, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollPosition = (element) => {
  const location = useLocation();

  const handleScroll = useCallback(() => {
    sessionStorage.setItem(
      `scrollPosition-${location.pathname}`,
      element.scrollTop.toString()
    );
  }, [element, location]);

  useEffect(() => {
    element &&
      element.addEventListener("scroll", () => {
        handleScroll();
      });
    return () => element && element.removeEventListener("scroll", handleScroll);
  }, [handleScroll, element]);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem(
      `scrollPosition-${location.pathname}`
    );

    if (savedScrollPosition && element) {
      element.scrollTo({
        top: parseInt(savedScrollPosition, 10)
      });
    }
  }, [location, element]);
};

export default useScrollPosition;
