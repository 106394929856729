import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dbService from "../../firebase/dbService";

export const fetchCategoryData = createAsyncThunk(
  "qeustions/fetchCategoryData",
  async (_, { rejectWithValue }) => {
    try {
      const categorydata = await dbService.getAllDocs(
        "questionLibraryCategories"
      );
      const data = categorydata.map((category) => {
        const { name, skills } = category;
        return {
          id: category.id,
          name,
          skills,
        };
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchQuestions = createAsyncThunk(
  "questions/fetchQuestions",
  async ({ sort }, { rejectWithValue }) => {
    try {
      //fetch library quesitions
      const libDocs = await dbService.getAllQuestions({
        collectionName: "questionLibrary",
      });
      const libraryQuestions = libDocs.map((doc) => {
        const { createdAt, updateAt } = doc;
        return {
          ...doc,
          id: doc.id,
          createdAt: createdAt ? createdAt.toMillis() : null,
          updateAt: updateAt ? updateAt.toMillis() : null,
        };
      });

      //fetch custom questions
      const costomDocs = await dbService.getAllQuestions({
        collectionName: "customQuestionLibrary",
        addedBy: "admin",
      });
      const customQuestions = costomDocs.map((doc) => {
        const { createdAt, updateAt } = doc;
        return {
          ...doc,
          id: doc.id,
          createdAt: createdAt ? createdAt.toMillis() : null,
          updateAt: updateAt ? updateAt.toMillis() : null,
        };
      });

      return { libraryQuestions, customQuestions };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addQuestion = createAsyncThunk(
  "questions/addQuestion",
  async ({ data }, { rejectWithValue }) => {
    try {
      //   await dbService.createDocumentWithId(
      //     "jobsPosted",
      //     data,
      //     data.jobOpeningId
      //   );
      //   const addedDoc = await dbService.getdocument(
      //     "jobsPosted",
      //     data.jobOpeningId
      //   );
      //   const {
      //     jobOpeningId,
      //     positionTitle,
      //     assignedRecruiter,
      //     departmentManager,
      //     jobType,
      //     jobStatus,
      //     jobPrefence,
      //     jobPostedDate,
      //     lastDateToApply,
      //     targetDate,
      //   } = addedDoc;
      //   const addedJob = {
      //     id: addedDoc.id,
      //     jobOpeningId,
      //     positionTitle,
      //     assignedRecruiter,
      //     departmentManager,
      //     jobType,
      //     jobStatus,
      //     jobPrefence,
      //     jobPostedDate: jobPostedDate ? jobPostedDate.toMillis() : null,
      //     lastDateToApply: lastDateToApply ? lastDateToApply.toMillis() : null,
      //     targetDate: targetDate ? targetDate.toMillis() : null,
      //   };
      //   const addedSelectData = {
      //     jobId: addedDoc.jobOpeningId,
      //     value: addedDoc.positionTitle,
      //     label:
      //       addedDoc.positionTitle.charAt(0).toUpperCase() +
      //       addedDoc.positionTitle.slice(1),
      //   };
      //   return { addedJob, addedSelectData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateQuestion = createAsyncThunk(
  "questions/updateQuestion",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      //   await dbService.updateDocument("jobsPosted", data, id);
      //   const updatedDoc = await dbService.getdocument("jobsPosted", id);
      //   const {
      //     jobOpeningId,
      //     positionTitle,
      //     assignedRecruiter,
      //     departmentManager,
      //     jobType,
      //     jobStatus,
      //     jobPrefence,
      //     jobPostedDate,
      //     lastDateToApply,
      //     targetDate,
      //   } = updatedDoc;
      //   const updatedJob = {
      //     id: updatedDoc.id,
      //     jobOpeningId,
      //     positionTitle,
      //     assignedRecruiter,
      //     departmentManager,
      //     jobType,
      //     jobStatus,
      //     jobPrefence,
      //     jobPostedDate: jobPostedDate ? jobPostedDate.toMillis() : null,
      //     lastDateToApply: lastDateToApply ? lastDateToApply.toMillis() : null,
      //     targetDate: targetDate ? targetDate.toMillis() : null,
      //   };
      //   const updatedSelectData = {
      //     jobId: updatedDoc.jobOpeningId,
      //     value: updatedDoc.positionTitle,
      //     label:
      //       updatedDoc.positionTitle.charAt(0).toUpperCase() +
      //       updatedDoc.positionTitle.slice(1),
      //   };
      //   return { id, updatedJob, updatedSelectData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  "questions/deleteQuestion",
  async ({ collectionName, id }, { rejectWithValue }) => {
    try {
      await dbService.updateDocument(collectionName, { isDeleted: true }, id);
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  categories: [],
  customQuestions: [],
  libraryQuestions: [],
  loading: false,
  error: null,
};

export const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategoryData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchCategoryData.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload;
      state.error = null;
    });
    builder.addCase(fetchCategoryData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchQuestions.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchQuestions.fulfilled, (state, action) => {
      state.loading = false;
      state.customQuestions = action.payload.customQuestions;
      state.libraryQuestions = action.payload.libraryQuestions;

      state.error = null;
    });
    builder.addCase(fetchQuestions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(addQuestion.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addQuestion.fulfilled, (state, action) => {
      //   state.jobData = [action.payload.addedJobData, ...state.jobData];
      //   state.jobSelectData = [
      //     action.payload.addedSelectData,
      //     ...state.jobSelectData,
      //   ];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addQuestion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteQuestion.pending, (state) => {
      // state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteQuestion.fulfilled, (state, action) => {
      state.customQuestions = state.customQuestions.filter(
        (ques) => ques.id !== action.payload
      );
      state.libraryQuestions = state.customQuestions.filter(
        (ques) => ques.id !== action.payload
      );
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteQuestion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateQuestion.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateQuestion.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;

      //   // Find the index of the job to update
      //   const jobIndex = state.jobData.findIndex(
      //     (job) => job.id === action.payload.id
      //   );
      //   // Update the job in the array if found
      //   if (jobIndex !== -1) {
      //     state.jobData[jobIndex] = {
      //       ...state.jobData[jobIndex],
      //       ...action.payload.updatedJob,
      //     };

      //     state.jobSelectData = [
      //       (state.jobSelectData[jobIndex] = {
      //         ...state.jobSelectData[jobIndex],
      //         ...action.payload.updatedSelectData,
      //       }),
      //     ];
      //   }
    });
    builder.addCase(updateQuestion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default questionsSlice.reducer;
