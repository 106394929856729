import { FaRegClock } from "react-icons/fa6";
import { FaRegStar } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import { RiFeedbackLine } from "react-icons/ri";

export const dashboardSmallCards = (dashboardData) => [
  {
    title: "Completion Rate",
    value: `${
      dashboardData?.completetionRate
        ? dashboardData?.completetionRate === 100
          ? dashboardData?.completetionRate
          : dashboardData?.completetionRate?.toFixed(1)
        : "00"
    } %`,
    icon: <FaRegStar className="w-7 h-7" />,
    color: "#0066FF",
  },
  {
    title: "Pass Rate",
    value: `${
      dashboardData?.passingRate
        ? dashboardData?.passingRate === 100
          ? dashboardData?.passingRate
          : dashboardData?.passingRate?.toFixed(1)
        : "00"
    } %`,
    icon: <FaRegCircleCheck className="w-7 h-7" />,
    color: "#01C851",
  },
  {
    title: "Average Time Completion",
    value: dashboardData?.averageTimeCompletion
      ? `${Math.floor(dashboardData?.averageTimeCompletion / 60)} min ${
          dashboardData?.averageTimeCompletion % 60
        } sec`
      : "00 min",

    icon: <FaRegClock className="w-7 h-7" />,
    color: "#8A24F1",
  },
  {
    title: "Average Feedback Rating",
    value: `${
      dashboardData?.averageFeedbackRating
        ? dashboardData?.averageFeedbackRating?.toFixed(1)
        : "00"
    } / 10`,
    icon: <RiFeedbackLine className="w-7 h-7" />,
    color: "#FFB800",
  },
];
