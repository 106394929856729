import { RxCross2 } from "react-icons/rx";

const AddedFilters = ({ filters, setFilters }) => {
  const removeFilter = (filterType, filterToRemove) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (updatedFilters[filterType]) {
        updatedFilters[filterType] = updatedFilters[filterType].filter(
          (filter) => filter.value !== filterToRemove.value
        );
      }
      return updatedFilters;
    });
  };
  return (
    <div className="flex items-center gap-2 justify-end">
      {["jobType", "jobPreference", "jobStatus"]?.map((filterType) =>
        filters?.[filterType]?.map((filter) => (
          <div className="flex gap-2 items-center p-1 pl-2 rounded-full bg-gray-200">
            <span className="text-sm font-medium text-gray-600 capitalize whitespace-nowrap">
              {filter.label}
            </span>
            <RxCross2
              className="w-5 h-5 bg-gray-300 rounded-full p-1 cursor-pointer"
              onClick={() => removeFilter(filterType, filter)}
            />
          </div>
        ))
      )}
    </div>
  );
};

export default AddedFilters;
