import { useCallback, useEffect } from "react";
import { SelectInput } from "../../components";
import { dashboardTimeIntervalOptions } from "../../constant/othersLists/dashboardTimeIntervalOptions";

const SelectInterval = ({ selectValue, setSelectValue, showAll }) => {
  const selectOptions = showAll
    ? [
        { value: null, label: "All time", days: null },
        ...dashboardTimeIntervalOptions,
      ]
    : dashboardTimeIntervalOptions;

  const updateTimeInterval = useCallback(
    (option) => {
      console.log(option);
      if (option.days === null) return setSelectValue(null);
      let lastTime = Number(option.days) * 24 * 60 * 60 * 1000;
      setSelectValue({
        ...option,
        currentDate: new Date(Date.now()).toLocaleDateString("en-in", {
          day: "numeric",
          month: "short",
          year: "numeric",
        }),
        lastDate: new Date(Date.now() - lastTime).toLocaleDateString("en-in", {
          day: "numeric",
          month: "short",
          year: "numeric",
        }),
        lastDateMillisecond: Date.now() - lastTime,
      });
    },
    [setSelectValue]
  );

  useEffect(() => {
    !showAll &&
      updateTimeInterval({ value: "1w", label: "Last 7 days", days: 7 });
  }, [showAll, setSelectValue, updateTimeInterval]);

  return (
    <div className="space-y-1">
      <SelectInput
        className="w-[160px]"
        defaultValue={selectOptions[0]}
        selectOptions={selectOptions}
        placeholderText="Select interval"
        handleChange={(option) => updateTimeInterval(option)}
      />
      <div className="text-xs font-medium text-[#888] text-center">
        {selectValue &&
          `${selectValue?.lastDate} - ${selectValue?.currentDate}`}
      </div>
    </div>
  );
};

export default SelectInterval;
