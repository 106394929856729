import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Logo, TextInput } from "../../components";
import { validateEmail, validatePassword } from "../../utils/validate";
import { validatePhoneNumber } from "../../utils/validate";
import { db } from "../../config/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import axios from "axios";
import registerEmailTemplate from "../../utils/emailTemplates/registrationSucess";
import authService from "../../firebase/authService";
import dbService from "../../firebase/dbService";
import { candidateRegistrationFields } from "../../constant/formFields/candidateRegistrationfields";
import envConfig from "../../config/envConfig";

const CandidateRegister = () => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const formFields = candidateRegistrationFields;

  useEffect(() => {
    const userString = localStorage.getItem("talentigo-candidateApplyData");
    const localStorageUser = JSON.parse(userString);
    if (localStorageUser) {
      //   setName(localStorageUser.name);
      //   setEmail(localStorageUser.email);
      //   setPhone(localStorageUser.phone);
    }
  }, []);

  const signup = async (e) => {
    e.preventDefault();
    setError(null);

    const formValues = new FormData(formRef.current);
    const name = formValues.get("name");
    const email = formValues.get("email");
    const phone = formValues.get("phone");
    const password = formValues.get("password");

    if (name === "")
      setError((prev) => ({ ...prev, name: "Name is required" }));
    if (email === "")
      setError((prev) => ({ ...prev, email: "Email is required" }));
    if (!validateEmail(email))
      setError((prev) => ({ ...prev, email: "Invalid Email" }));
    if (phone === "")
      setError((prev) => ({ ...prev, phone: "Phone number required" }));
    if (!validatePhoneNumber(phone))
      setError((prev) => ({ ...prev, phone: "Invalid Mobile number" }));
    if (password === "")
      setError((prev) => ({ ...prev, password: "Password is required" }));
    if (!validatePassword(password))
      setError((prev) => ({
        ...prev,
        password:
          "Password must be at least 6 characters, must include uppercase, lowercase, number and special character",
      }));

    // validate password

    if (
      name === "" ||
      !validateEmail(email) ||
      !validatePhoneNumber(phone) ||
      !validatePassword(password)
    )
      return;

    const phoneNumber = `+91${phone}`;
    setLoading(true);

    try {
      // 1. -  signup with email and password and update name
      await authService.createAccount({ email, password, name });

      // 2. send verification email -
      await authService.sendEmailVerification();

      // 3. send registration success email to candidate
      await sendEmail(name, email);

      // 4. check if the candidate is there in candidate profile
      // yes - add uid to candidateProfile
      // no - add candidate details to candidateProfile collection
      const currentUser = await authService.getCurrentUser();
      const userQuery = query(
        collection(db, "candidateProfile"),
        where("email", "==", email)
      );
      const candidateDetails = {
        name,
        email,
        phoneNumber,
        uid: currentUser?.uid,
      };

      await getDocs(userQuery).then(async (querySnapshot) => {
        if (querySnapshot.empty) {
          await dbService.createDocumentWithId(
            "candidateProfile",
            candidateDetails,
            currentUser?.uid
          );
        } else {
          querySnapshot.forEach((doc) => {
            updateDoc(doc.ref, {
              uid: currentUser?.uid,
            });
          });
        }
      });

      // 5. navigate to dashboard
      navigate("/candidate");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const sendEmail = async (name, email) => {
    const { subject, body } = registerEmailTemplate({ candidateName: name });
    const emailData = `${email}|${subject}|${body}`;
    const mailApi = envConfig.emailApi;
    const emailResponse = await axios.post(mailApi, emailData);
    return emailResponse;
  };

  return (
    <div className="admin-main">
      <div className="admin-card flex justify-center items-center h-full sm:py-5 w-full gradient2">
        <div className="h-full w-full flex flex-col justify-center items-center p-10 sm:px-14 md:px-20 gap-2">
          <Logo />

          <>
            <h1>Create an account</h1>
            <form
              ref={formRef}
              className="flex flex-col w-full gap-2"
              onSubmit={signup}
            >
              {formFields?.map((field) => {
                return (
                  <div className="flex flex-col" key={field.name}>
                    <label
                      htmlFor={field.name}
                      className="font-medium text-sm leading-[12px] mb-2"
                    >
                      {field.label}
                    </label>
                    <TextInput
                      type={field.type}
                      idName={field.name}
                      inputName={field.name}
                      placeholderText={field.placeholder}
                      innerLabel={field.name === "phone" ? "+91" : ""}
                      // isRequired={true}
                      isDisabled={loading}
                    />
                    <div className="text-xs h-4 text-red-500">
                      {field.name === "name"
                        ? error?.name
                        : field.name === "email"
                        ? error?.email
                        : field.name === "phone"
                        ? error?.phone
                        : field.name === "password"
                        ? error?.password
                        : ""}
                    </div>
                  </div>
                );
              })}
              <div className="flex flex-col items-center justify-center gap-2">
                {
                  <div className="font-medium text-xs h-3 text-[#f00]">
                    {error?.other}
                  </div>
                }
                <Button
                  width="200px"
                  text="Sign Up"
                  type="submit"
                  loading={loading}
                  // isDisabled={loading}
                />
              </div>
            </form>
          </>

          <div className="mt-2 flex flex-col justify-center items-center gap-2 w-full">
            <p className="text-sm">OR</p>
            <div className="flex justify-center gap-2 w-full">
              Already have an account?
              <Link
                to="/candidateLogin"
                className="font-medium hover:underline"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateRegister;
