const InstructionRight = () => {
  return (
    <div className="flex flex-col items-start justify-between h-full w-[80%] mx-auto">
      <div className="flex flex-col">
        <h1 className="heading1 mb-10">Don’t Refresh or Exit Full Screen</h1>
        <ul className="gap-4 text-[#919397] listDisc">
          <li>
            Do not switch/refresh the tab or try to open any other window.
          </li>
          <li>Do not open the development or any other tool for help.</li>
          <li>Do not move the cursor out of the assessment tab.</li>
          <li>Do not Press 'Back Button' while taking the assessment.</li>
          <li>Do not exit 'Full-Screen' mode while taking the assessment.</li>
        </ul>
      </div>
    </div>
  );
};

export default InstructionRight;
