import { useEffect, useState } from "react";
import dbService from "../../../firebase/dbService";

const useFetchExamDetails = () => {
    const [scheduleDetails, setScheduleDetails] = useState();
    const [assessmentDetails, setAssessmentDetails] = useState();
    const [candidateDetails, setCandidateDetails] = useState();
    const [applicationDetails, setApplicationDetails] = useState();
    const [jobDetails, setJobDetails] = useState();
    const [companyDetails, setCompanyDetails] = useState();
    const [currentRound, setCurrentRound] = useState(0);
    useEffect(() => {
        const localDetails = JSON.parse(localStorage.getItem("talentigo-examAuth"));
        const { schedule, assessment, candidate } = localDetails;
        setScheduleDetails(schedule);
        setAssessmentDetails(assessment);
        setCandidateDetails(candidate);
        const applicationId = schedule?.applicationId;
        const jobId = schedule?.jobId;
        const companyName = schedule?.companyName;

        // fetch application details
        const fetchApplicationDetails = async () => {
            const application = await dbService.getdocument(
                "candidateApplications",
                applicationId
            );
            setApplicationDetails(application);
        };
        const fetchJobDetails = async () => {
            const job = await dbService.getdocument("jobsPosted", jobId);
            setJobDetails(job);
        };

        const fetchCompanyInfo = async () => {
            const company = await dbService.getCompanyDetailsWithName(companyName);
            setCompanyDetails(company);
        };



        applicationId && fetchApplicationDetails();
        jobId && fetchJobDetails();
        companyName && fetchCompanyInfo();
    }, []);

    useEffect(() => {
        const round = jobDetails?.rounds.findIndex(
            (round) => round?.id === assessmentDetails?.id
        );
        setCurrentRound(round);
    }, [assessmentDetails, jobDetails]);

    return { scheduleDetails, assessmentDetails, candidateDetails, applicationDetails, currentRound, jobDetails, companyDetails };

}

export default useFetchExamDetails