import { useState, useRef, useEffect, useCallback } from "react";
import Webcam from "react-webcam";
import { LuLoaderCircle } from "react-icons/lu";
import * as faceapi from "face-api.js";
// import * as tf from "@tensorflow/models-coco-ssd";

const CameraProctoring = ({
  loading,
  setCameraProctorLoading,
  cameraDetection,
  showViolations,
  setWarningType,
}) => {
  const [modelsLoading, setModelsLoading] = useState(true);
  const [webcamError, setWebcamError] = useState(false);
  const [faceCount, setFaceCount] = useState(null);
  const [isCameraEnable, setIsCameraEnable] = useState(true);
  const [phoneDetected, setPhoneDetected] = useState(false);
  const [cocoModel, setCocoModel] = useState(null);

  useEffect(() => {
    if (!loading && cameraDetection) {
      if (faceCount === 0) {
        showViolations("noFace");
      } else if (faceCount > 1) {
        showViolations("multiplePerson");
      } else if (phoneDetected) {
        showViolations("phoneDetected");
      }
    }
  }, [faceCount, phoneDetected, cameraDetection, showViolations, loading]);

  useEffect(() => {
    if (!loading && !isCameraEnable && cameraDetection) {
      showViolations("cameraDisabled");
    } else {
      setWarningType("cameraEnabled");
    }
  }, [
    isCameraEnable,
    cameraDetection,
    showViolations,
    loading,
    setWarningType,
  ]);

  useEffect(() => {
    const loadModels = async () => {
      setModelsLoading(true);
      try {
        // Load face detection model
        await faceapi.nets.tinyFaceDetector.loadFromUri("/face-api-models");

        // Load COCO-SSD model for phone detection
        // const loadedModel = await tf.load();
        // setCocoModel(loadedModel);

        setCameraProctorLoading(false);
        console.log("All models loaded");
      } catch (error) {
        console.error("Error loading models:", error);
      } finally {
        setModelsLoading(false);
      }
    };

    loadModels();
  }, [setCameraProctorLoading]);

  const webcamRef = useRef();

  const handleWebCamError = () => {
    setWebcamError(true);
    setIsCameraEnable(false);
  };

  const detectObjects = useCallback(async () => {
    if (
      webcamRef.current &&
      webcamRef.current.video.readyState === 4 &&
      cocoModel
    ) {
      const video = webcamRef.current.video;
      video && setIsCameraEnable(true);

      // Detect faces
      const faceDetections = await faceapi.detectAllFaces(
        video,
        new faceapi.TinyFaceDetectorOptions()
      );
      setFaceCount(faceDetections?.length);

      // Detect objects (including phones)
      const predictions = await cocoModel.detect(video);

      // Check for cell phones, mobile phones, or similar objects
      const phoneObjects = predictions.filter((prediction) =>
        ["cell phone", "mobile phone"].includes(prediction.class.toLowerCase())
      );

      setPhoneDetected(phoneObjects.length > 0);

      if (phoneObjects.length > 0) {
        console.log("Phone detected in frame");
      }
    }
  }, [cocoModel, setIsCameraEnable]);

  useEffect(() => {
    const interval = setInterval(async () => {
      // await detectObjects();
    }, 1000);

    return () => clearInterval(interval);
  }, [detectObjects]);

  return (
    <div className="bg-[#aaa]/30 backdrop-blur-sm p-2 flex flex-col justify-center items-center rounded-xl text-gray-400">
      <div className="w-[240px] h-[180px] bg-gray-400 rounded-xl flex items-center justify-center">
        {modelsLoading && (
          <LuLoaderCircle className="w-16 h-16 animate-spin text-gray-300" />
        )}
        {webcamError && (
          <p className="text-white font-medium p-2 text-center">
            Please enable <br />
            your webcam
          </p>
        )}
        {!modelsLoading && !webcamError && (
          <div className="w-full h-full relative">
            <Webcam
              audio={false}
              className="rounded-xl"
              width={"100%"}
              ref={webcamRef}
              onUserMediaError={handleWebCamError}
              screenshotFormat="image/jpeg"
            />
            <span className="absolute top-5 left-5 flex h-3 w-3 z-50">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
            </span>
            {phoneDetected && (
              <div className="absolute top-5 right-5 bg-red-500 text-white px-2 py-1 rounded text-xs">
                Phone Detected!
              </div>
            )}
          </div>
        )}
      </div>
      <p className="text-sm font-semibold">WebCam Feed</p>
    </div>
  );
};

export default CameraProctoring;
