export const newAssessmentPopupFields = [
    {
        name: "name",
        label: "Name",
        placeholder: "Enter Assessment name",
        type: "text",
        isRequired: true,
    },
    {
        name: "position",
        label: "Position",
        placeholder: "Enter position",
        type: "text",
        isRequired: true,
    },
    {
        name: "experienceLevel",
        label: "Experience Level",
        placeholder: "Select Experience",
        isRequired: true,
        options: [
            { value: "Intern", label: "Intern" },
            {
                value: "Entry Level",
                label: "Entry Level",
            },
            { value: "Mid Level", label: "Mid Level" },
            { value: "Senior Level", label: "Senior Level" },
            { value: "Manager", label: "Manager" },
            { value: "Director", label: "Director" },
            { value: "Executive", label: "Executive" },
        ],
        type: "select",
    },
    {
        name: "domain",
        label: "Domain",
        placeholder: "Select Domain",
        isRequired: true,
        options: [
            { value: "IT", label: "IT" },
            { value: "Frontend", label: "Frontend" },
            { value: "Backend", label: "Backend" },
            { value: "Full Stack", label: "Full Stack" },
            { value: "DevOps", label: "DevOps" },
            { value: "Android", label: "Android" },
            { value: "iOS", label: "iOS" },
            { value: "Data Science", label: "Data Science" },
            { value: "Machine Learning", label: "Machine Learning" },
            { value: "Artificial Intelligence", label: "Artificial Intelligence" },
            { value: "Marketing", label: "Marketing" },
            { value: "Sales", label: "Sales" },
            { value: "Design", label: "Design" },
            { value: "UI/UX", label: "UI/UX" },
            { value: "Product Management", label: "Product Management" },
        ],
        type: "select",
    },
];