export const atsListingColumns = [
  {
    name: "name",
    label: "Name",
    width: "150px",
    open: true,
    sort: false,
  },
  {
    name: "email",
    label: "Email Id",
    width: "220px",
    open: true,
    sort: false,
  },
  {
    name: "positionTitle",
    label: "Position Title",
    width: "160px",
    open: true,
    sort: false,
  },
  {
    name: "appliedPosition",
    label: "Applied Position",
    width: "160px",
    open: true,
    sort: true,
    sortType: "",
  },
  {
    name: "appliedAt",
    label: "Applied On",
    width: "150px",
    open: true,
    sort: true,
    sortType: "asc",
  },
  {
    name: "source",
    label: "Source",
    width: "120px",
    open: true,
    sort: false,
  },
  // {
  //   name: "candidateOwner",
  //   label: "Candidate Owner",
  //   width: "150px",
  //   open: true,
  //   sort: false,
  // },
  {
    name: "phoneNumber",
    label: "Phone Number",
    width: "150px",
    open: true,
    sort: false,
  },
  {
    name: "expectedSalary",
    label: "Expected Salary",
    width: "150px",
    open: true,
    sort: false,
  },
  {
    name: "currentSalary",
    label: "Current Salary",
    width: "150px",
    open: true,
    sort: false,
  },
  {
    name: "workExperience",
    label: "Work Experience",
    width: "150px",
    open: true,
  },
  {
    name: "score",
    label: "Score",
    width: "80px",
    open: true,
  },
  {
    name: "schedule",
    label: "Schedule",
    width: "150px",
    open: true,
  },
];
