import { useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";

const LoadingComponent = () => (
  <div className="flex justify-center items-center w-full gap-10 p-5">
    <div className="bg-[#666]/10 w-[260px] h-[260px] rounded-full animate-pulse"></div>
    <div className="space-y-4">
      {[1, 2, 3, 4]?.map((item) => (
        <div
          key={item}
          className="bg-[#666]/10 w-[150px] h-[20px] rounded-md animate-pulse"
        />
      ))}
    </div>
  </div>
);

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy - 14} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <text x={cx} y={cy + 14} dy={8} textAnchor="middle" fill={fill}>
        {`${value}  (${(percent * 100).toFixed(1)}%)`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const PieGraph = ({ title, data, toalData, loading }) => {
  const [activePieIndex, setActivePieIndex] = useState(null);

  const onPieEnter = (_, index) => {
    setActivePieIndex(index);
  };

  return (
    <div className="border-gray-200 border-[1px] shadow-md rounded-xl p-2 px-4">
      <h3 className="p-3 {text-[#11031D] capitalize text-sm font-medium">
        {title}
      </h3>

      {loading ? (
        <LoadingComponent />
      ) : toalData > 0 ? (
        <div className="flex items-center justify-center gap-[8%]">
          <ResponsiveContainer width={300} height={300}>
            <PieChart onMouseLeave={() => setActivePieIndex(null)}>
              <Pie
                data={data || []}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={70}
                outerRadius={130}
                paddingAngle={2}
                fill="#aaa"
                activeIndex={activePieIndex}
                activeShape={renderActiveShape}
                onMouseEnter={onPieEnter}
              >
                {(data || [])?.map((element, i) => (
                  <Cell key={i} fill={element.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <div className="space-y-4">
            {(data || [])?.map((elem, i) => {
              return (
                <div
                  key={i}
                  className="text-xs p-1 px-4 rounded-md w-[140px] flex justify-between "
                  style={{
                    backgroundColor: `${elem.color}40`,
                    color: elem.color,
                  }}
                >
                  <span> {elem.name}</span>
                  {" - "}
                  <span>{elem.value}</span>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center w-full h-[320px]">
          <span className="text-gray-400 text-xl font-semibold">
            No Data Exists
          </span>
        </div>
      )}
    </div>
  );
};

export default PieGraph;
