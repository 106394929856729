import React, { useEffect, useState } from "react";

const View = ({ loading, assessment, questions }) => {
  const loadingStyle = "bg-gray-200 rounded-lg animate-pulse";

  const [mcqQuestions, setMcqQuestions] = useState(0);
  const [codingQuestions, setCodingQuestions] = useState(0);
  const [subjectiveQuestions, setSubjectiveQuestions] = useState(0);
  useEffect(() => {
    let mcq = 0;
    let coding = 0;
    let subjective = 0;
    questions?.map((ques) => {
      if (ques?.type === "mcq") {
        mcq = mcq + 1;
      } else if (ques?.type === "subjective") {
        subjective = subjective + 1;
      } else if (ques?.type === "code") {
        coding = coding + 1;
      }
      setMcqQuestions(mcq);
      setCodingQuestions(coding);
      setSubjectiveQuestions(subjective);
      return "";
    });
  }, [questions]);

  const Card = ({ children, heading, icon }) => {
    if (loading) {
      return <div className={`w-60 h-20 ${loadingStyle}`} />;
    }
    return (
      <div className="border-2 rounded-xl shadow-md py-3 px-4 text-sm flex items-center gap-5">
        <span>
          <img src={icon} alt="" />
        </span>
        <div className="space-y-2">
          <h2 className="font-semibold">{heading}</h2>
          {children}
        </div>
      </div>
    );
  };

  return (
    <div className="p-10 py-6 space-y-4">
      <div className="space-y-2">
        <h2 className="text-lg font-medium">Skills Covered</h2>
        {loading ? (
          <div className="grid grid-cols-2 space-y-2">
            <div className={`w-60 h-6 ${loadingStyle}`} />
            <div className={`w-60 h-6 ${loadingStyle}`} />
            <div className={`w-60 h-6 ${loadingStyle}`} />
            <div className={`w-60 h-6 ${loadingStyle}`} />
            <div className={`w-60 h-6 ${loadingStyle}`} />
            <div className={`w-60 h-6 ${loadingStyle}`} />
            <div className={`w-60 h-6 ${loadingStyle}`} />
          </div>
        ) : (
          <ul className="grid grid-cols-2 list-disc list-inside space-y-1">
            {Array.from(
              new Set(assessment?.skills?.map((skill) => skill?.skillName))
            ).map((skillName) => (
              <li className="capitalize" key={skillName}>
                {skillName}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="grid grid-cols-2 gap-3">
        <Card heading="Assessment Duration" icon="/img/duration.svg">
          <p>
            {Math.floor(assessment?.duration / 60)} Min{" "}
            {assessment?.duration % 60 === 0
              ? ""
              : `${assessment?.duration % 60} Sec`}
          </p>
        </Card>
        <Card heading="Difficulty Level" icon="/img/scale.svg">
          <p>{assessment?.experience}</p>
        </Card>
        <Card heading="Questions" icon="/img/list.svg">
          <ul className="list-disc list-inside space-y-1">
            {mcqQuestions ? <li>{mcqQuestions} MCQs</li> : null}
            {subjectiveQuestions ? (
              <li>{subjectiveQuestions} Subjective Ques</li>
            ) : null}
            {codingQuestions ? <li>{codingQuestions} Coding Ques</li> : ""}
          </ul>
        </Card>
        <Card heading="Availability" icon="/img/active.svg">
          <p>{assessment?.availability}</p>
        </Card>
      </div>
    </div>
  );
};

export default View;
