const CameraRight = () => {
  return (
    <div className="flex flex-col items-start justify-between h-full w-[80%] mx-auto my-2">
      <div className="flex flex-col">
        <h1 className="heading1 mb-8">
          Webcam Mandatory while giving Assessment Test
        </h1>
        <ul className="gap-4 text-[#919397] listDisc">
          <li>Always keep the Camera ON and working during the assessment.</li>
          <li>
            Please do not leave your Laptop/PC while the test is going on.
          </li>
          <li>
            Please sit in front of proper lighting so that the camera can detect
            your face and background.
          </li>
          <li>
            The test will get terminated if the webcam is turned off at any time
            while giving the test.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CameraRight;
