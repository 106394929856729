import { configureStore } from "@reduxjs/toolkit";
import adminAuthReducer from "./features/adminAuthSlice";
import jobReducer from "./features/jobSlice";
import questionsReducer from "./features/questionsSlice";
import assessmentsReducer from "./features/assessmentsSlice";
import applicationsReducer from "./features/applicationsSlice";
import pageTitleReducer from "./features/pageTitleSlice";
import dashboardReducer from "./features/dashboardSlice";
export const store = configureStore({
  reducer: {
    pageTitle: pageTitleReducer,
    adminAuth: adminAuthReducer,
    jobs: jobReducer,
    applications: applicationsReducer,
    assessments: assessmentsReducer,
    questions: questionsReducer,
    dashboardData: dashboardReducer,
  },
});
