import { useEffect, useState } from "react";
import { Button, TextInput } from "../../components";
import { Timestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import rescheduleEmailTemplate from "../../utils/emailTemplates/roundRescheduled";
import { sendEmailHandler } from "../../utils/helperFunctions/sendEmailHandler";
import dbService from "../../firebase/dbService";

const Schedule = ({ schedule, candidate, setRescheduleModule }) => {
  console.log(schedule, candidate);
  const [date, setDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (schedule) {
      setDate(
        new Date(schedule?.scheduleDate?.seconds * 1000)
          .toLocaleString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })
          .replace(/,/g, "")
          .replace(/\s/g, "T")
      );
    }
  }, [schedule]);

  useEffect(() => {
    if (date) {
      setExpiryDate(
        new Date(new Date(date).setDate(new Date(date).getDate() + 2))
          .toLocaleString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })
          .replace(/,/g, "")
          .replace(/\s/g, "T")
      );
    }
  }, [date]);

  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString().slice(0, 16);
  };

  const getMaxDateTime = () => {
    const now = new Date();
    now.setDate(now.getDate() + 7);
    return now.toISOString().slice(0, 16);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const scheduleDate = new Date(date);
    const expDate = new Date(expiryDate);

    setLoading(true);
    try {
      await rescheduleAssessment(scheduleDate, expDate);
      await sendEmail(schedule.examCode, scheduleDate, expDate);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const rescheduleAssessment = async (scheduleDate, expDate, schedule) => {
    const rescheduleData = {
      scheduleDate: Timestamp.fromDate(scheduleDate),
      expDate: Timestamp.fromDate(expDate),
      updatedAt: Timestamp.now(),
    };

    try {
      await dbService.updateDocument(
        "assessmentTests",
        rescheduleData,
        schedule.id
      );
      toast.success("Success! Your Assessment has been rescheduled.");
    } catch (error) {
      console.log(error);
    } finally {
      setRescheduleModule(false);
    }
  };

  const sendEmail = async (examCode, scheduleDate, expDate) => {
    const dateFormat = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const timeFormat = { hour: "numeric", minute: "2-digit", hour12: true };
    const emailDetails = {
      candidateName: candidate?.name,
      candidateEmail: candidate?.email,
      examCode,
      companyName: schedule?.companyName,
      startDate: {
        date: scheduleDate?.toLocaleDateString("en-IN", dateFormat),
        time: scheduleDate?.toLocaleTimeString("en-IN", timeFormat),
      },
      expDate: {
        date: expDate?.toLocaleDateString("en-IN", dateFormat),
        time: expDate?.toLocaleTimeString("en-IN", timeFormat),
      },
    };
    try {
      const { subject, body } = rescheduleEmailTemplate(emailDetails);

      await sendEmailHandler({
        emailId: candidate.email,
        subject,
        body,
        successMessage: "The Reschedule email has been sent your email.",
        autoClose: false,
      });
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  return (
    <form className="p-8 space-y-4 min-w-[500px]" onSubmit={handleSubmit}>
      <h1 className="heading2">Rescheule Assessment</h1>

      <div>
        <p className="text-xs font-medium">Select Assessment Date and Time :</p>
        <label className="text-xs font-medium" htmlFor="datetime">
          From:
        </label>
        <div className="flex border-2 rounded-xl overflow-hidden">
          <TextInput
            type="datetime-local"
            inputName="datetimeFrom"
            inputValue={date}
            isRequired={true}
            handleChange={(e) => setDate(e.target.value)}
            min={getCurrentDateTime()}
            max={getMaxDateTime()}
          />
        </div>
        <label className="text-xs font-medium" htmlFor="datetime">
          To :
        </label>
        <div className="flex border-2 rounded-xl overflow-hidden">
          <TextInput
            type="datetime-local"
            inputName="datetimeTo"
            inputValue={expiryDate}
            isDisabled={true}
          />
        </div>
      </div>
      <div className="flex w-full justify-end">
        {error && (
          <p className="text-red-500 text-xs self-center w-full">{error}</p>
        )}
        <Button type="submit" loading={loading}>
          <span className="capitalize">Reschedule</span>
        </Button>
      </div>
    </form>
  );
};

export default Schedule;
