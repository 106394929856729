import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { LogoLoading } from "../components";

const ExamAuthProtected = ({ children, authenticate = true }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const localDetails = JSON.parse(localStorage.getItem("talentigo-examAuth"));
    if (localDetails) {
      const isLoggedIn = localDetails.isCandidateLogin;
      if (authenticate && !isLoggedIn) {
        navigate("/assessment/login");
      } else if (!authenticate && isLoggedIn) {
        navigate("/assessment/start");
      }
    }
  }, [authenticate, navigate]);
  return children;
  // return loading ? <LogoLoading /> : children;
};

export default ExamAuthProtected;
