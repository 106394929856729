import React from "react";

const VacancyStatsLoader = () => {
  return (
    <div className="flex flex-col justify-center gap-2">
      <div className="flex flex-col justify-center items-center gap-2 mt-2">
        <div className="bg-gray-200 animate-pulse w-full h-96 rounded-xl" />
        <div className="bg-gray-200 animate-pulse w-80 h-8 rounded-xl" />
      </div>
      <div className="space-y-2">
        <div className="bg-gray-200 animate-pulse w-60 h-6 rounded-xl" />
        <div className="flex gap-3 items-center">
          <div className="bg-gray-200 animate-pulse w-40 h-8 rounded-xl" />
          <div className="bg-gray-200 animate-pulse w-40 h-8 rounded-xl" />
          <div className="bg-gray-200 animate-pulse w-40 h-8 rounded-xl" />
          <div className="bg-gray-200 animate-pulse w-40 h-8 rounded-xl" />
          <div className="bg-gray-200 animate-pulse w-40 h-8 rounded-xl" />
        </div>
      </div>
    </div>
  );
};

export default VacancyStatsLoader;
