import { useEffect, useState } from "react";
import envConfig from "../../../config/envConfig";
import { truncateText } from "../../../utils/helperFunctions/textFunctions";
import { useSelector } from "react-redux";
import { TextInput } from "../../../components";
import { PiLinkSimpleBold } from "react-icons/pi";
import { auth } from "../../../config/firebase";

const JobCreatedModule = ({ step1Data, applyLink, editJobData }) => {
  const { adminDetails } = useSelector((state) => state.adminAuth);
  const [link, setLink] = useState("");
  useEffect(
    () =>
      setLink(
        `${envConfig.appUrl}/${envConfig.applyLinkPhpUrl}?jid=${step1Data?.jobOpeningId}&comp=${auth.currentUser.displayName}&jt=${step1Data.positionTitle}&exp=${step1Data.workExperiance}`?.replaceAll(
          " ",
          "%20"
        )
      ),
    [applyLink, step1Data]
  );
  const [copied, setCopied] = useState(false);
  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const shareLink = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Link to Apply for - " + step1Data?.positionTitle,
          url: link,
        });
      } catch (error) {
        console.log("Error sharing link: ", error);
      }
    } else {
      // fallback for browsers that don't support the Web Share API
      console.log("Web Share API not supported");
    }
  };

  return (
    <div className=" flex flex-col items-center justify-center gap-5 w-[600px] p-4">
      <div className="flex items-center justify-center bg-green-600 text-white w-16 h-16 rounded-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.5}
          stroke="currentColor"
          className="w-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m4.5 12.75 6 6 9-13.5"
          />
        </svg>
      </div>
      <h1 className="text-xl font-semibold">
        {editJobData ? "Job Updated Successfully" : "Job Created Successfully"}
      </h1>
      <div className="border-2 rounded-2xl w-full p-4 px-6 space-y-2">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <div className="w-14 h-14 overflow-hidden border-2 rounded-full flex items-center justify-center bg-gray-300">
              {adminDetails?.logoUrl ? (
                <img src={adminDetails?.logoUrl} alt="profile" />
              ) : (
                <span className="text-2xl font-bold text-gray-400">
                  {adminDetails?.companyName[0]}
                </span>
              )}
            </div>
            <div>
              <div className="font-semibold">
                {step1Data?.positionTitle || "Job Title"}
              </div>
              <div className="text-[#949494] text-sm flex items-center">
                <span>{adminDetails?.companyName}</span>
                <span className="mx-2 text-lg"> • </span>
                <span>{step1Data?.numberOfPosition || 0} Positions</span>
              </div>
            </div>
          </div>
          <div className="flex gap-3 text-sm">
            <div className="py-1 px-3 bg-[#EFFFEC] rounded-lg text-[#18AE00]">
              {step1Data?.jobType}
            </div>
            <div className="py-1 px-3 bg-[#FFE4CD] rounded-lg text-[#FF881F]">
              {step1Data?.experianceLevel}
            </div>
            <div className="py-1 px-3 bg-[#F1EAF9] rounded-lg text-[#8A24F1]">
              {step1Data?.jobPrefence === "wfh" ? "Remote" : step1Data?.city}
            </div>
          </div>
        </div>
        <div>
          <div className="space-x-1">
            <span className="text-sm text-gray-500">Work Experience : </span>
            <span>{step1Data?.workExperiance}</span>
            {/* <span>2-3 years</span> */}
          </div>
          {step1Data?.lastDateToApply && (
            <div className="space-x-1">
              <span className="text-sm text-gray-500">
                Last Date to Apply :
              </span>
              <span>
                {new Date(step1Data?.lastDateToApply)?.toLocaleDateString(
                  "en-in",
                  {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  }
                )}
              </span>
              {/* <span>Jan 25, 2024</span> */}
            </div>
          )}
        </div>
        <div className="text-sm">
          {truncateText(step1Data?.description, 100)}
          {/* {truncateText(
            "Lorem ipsum, dolor si amet consectetur adipisicing elit. Accusantium ipsa, magni laborum dolor accusamus, quae eaque molestias cum dignissimos perferendis nostrum quaerat ullam, quis cupiditate ipsam officiapossimus explicabo reiciendis!",
            100
          )} */}
        </div>
      </div>

      <div className="flex w-full justify-center items-center gap-2">
        <div className="border-2 border-gray-300 rounded-xl overflow-hidden w-full">
          <TextInput
            placeholderText="jobLink"
            inputValue={link}
            innerLabel={<PiLinkSimpleBold className="w-5 h-5" />}
          />
        </div>

        <button
          className={`flex items-center justify-center p-[6px] border-2 rounded-xl w-[5rem]
                        ${
                          copied
                            ? "border-green-600 text-green-600"
                            : "border-[#8A24F1] text-[#8A24F1]"
                        }`}
          onClick={copyLink}
        >
          {copied ? "Copied" : "Copy"}
        </button>
        <button
          className="flex items-center justify-center p-[6px] border-2 rounded-xl w-[5rem] border-[#8A24F1] bg-[#8A24F1] text-white"
          onClick={shareLink}
        >
          Share
        </button>
      </div>
    </div>
  );
};

export default JobCreatedModule;
