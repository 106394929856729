import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

// import { IoKeyOutline } from "react-icons/io5";
// import { GoChevronRight } from "react-icons/go";
import { FiLogOut } from "react-icons/fi";
// import { IoSettingsOutline } from "react-icons/io5";

import { logoutAdmin } from "../../../redux/features/adminAuthSlice";
// import { toast } from "react-toastify";
const ProfileMenu = ({
  admin,
  isprofileMenuOpen,
  setIsprofileMenuOpen,
  setOpenPasswordChange,
  profileMenuRef,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await dispatch(logoutAdmin())
        .then(() => {
          setIsprofileMenuOpen(false);
          // toast.success("You have been logged out successfully");
          navigate("/login");
        })
        .catch((error) => {
          throw new Error(error);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <motion.div
      className="absolute w-[90%] sm:w-fit card flex-col"
      variants={{
        initial: { top: 0, display: "none" },
        animate: { top: 70, display: "flex" },
      }}
      initial="initial"
      transition={{ duration: 0.1 }}
      animate={isprofileMenuOpen ? "animate" : "initial"}
      ref={profileMenuRef}
    >
      <div className="p-4 flex flex-col gap-4 profile-icons">
        <Link
          to="profile"
          onClick={() => setIsprofileMenuOpen(false)}
          className="flex gap-3 cursor-pointer"
        >
          <div className="w-14 h-14">
            <img
              src={admin?.logoUrl ? admin?.logoUrl : "/img/adminProfile.png"}
              alt="admin"
              className="border-2 rounded-full"
            />
          </div>

          <div className="flex flex-col justify-center gap-1">
            <p className="font-medium capitalize">{admin?.companyName}</p>
            <p className="w-fit bg-[#01C851]/[8%] p-1 rounded-md px-3 text-[#01C851] text-xs">
              {admin?.companyEmail}
            </p>
          </div>
        </Link>
      </div>

      <div className="h-1 w-[90%] border-t-2 border-[#f5f5f5] m-auto"></div>
      <div className="p-4 flex flex-col gap-4 text-sm">
        {/* <div
          onClick={() => {
            setIsprofileMenuOpen(false);
            setOpenPasswordChange(true);
          }}
          className="flex items-center justify-between cursor-pointer hover:font-medium"
        >
          <div className="flex items-center gap-3">
            <div className="bg-[#EFEFEF] text-[#6F6F6F] p-2 rounded-full">
              <IoKeyOutline className="w-5 h-5" />
            </div>
            <p>Change Password </p>
          </div>
          <GoChevronRight className="w-5 h-5" />
        </div> */}
        {/* */}
        {/* <Link
          to="setting"
          onClick={() => setIsprofileMenuOpen(false)}
          className="flex items-center justify-between cursor-pointer hover:font-medium"
        >
          <div className="flex items-center gap-3">
            <div className="bg-[#EFEFEF] text-[#6F6F6F] p-2 rounded-full">
              <IoSettingsOutline className='w-5 h-5' />
            </div>
            <p>Settings</p>
          </div>
          <GoChevronRight className='w-5 h-5' />
        </Link> */}
        <div
          className="flex items-center gap-3 text-[#E5383B] hover:font-semibold cursor-pointer font-medium"
          onClick={handleLogout}
        >
          <div className="bg-[#E5383B]/10 p-2 rounded-full">
            <FiLogOut className="h-5 w-5" />
          </div>
          <p>Logout</p>
        </div>
      </div>
    </motion.div>
  );
};

export default ProfileMenu;
