import React from "react";

const CardCandidate = ({ candidate, key }) => {
  return (
    <div
      className="flex justify-between mt-4 border-b-2 last:border-b-0 pb-2 border-gray-100"
      key={key}
    >
      <div>
        <div className="text-sm font-semibold">{candidate?.name}</div>
        <div className="text-xs">({candidate?.position})</div>
      </div>
      <div className="flex flex-col gap-1">
        <div className="relative">
          <div className="w-[160px] bg-[#F1EAF9] h-[8px] rounded-lg"></div>
          <div
            className="absolute top-0 left-0  bg-[#8A24F1] h-[8px] rounded-lg"
            style={{ width: `${candidate?.result?.score}%` }}
          ></div>
        </div>
        <div className="text-xs text-right mt-1 flex items-center gap-2 justify-end">
          <span>Total score</span>
          <span>{candidate?.result?.score}/100</span>
        </div>
      </div>
    </div>
  );
};

export default CardCandidate;
