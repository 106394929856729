import React, { useEffect, useState } from "react";
import { Timestamp } from "firebase/firestore";
import Step1 from "./step1";
import Step2 from "./step2";
import JobCreatedModule from "./JobCreatedModule";
import { Button } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import Popup from "../../../components/popup/Popup";
import { auth } from "../../../config/firebase";
import { useDispatch } from "react-redux";
import {
  createJob,
  updateJob as updateJobReducer,
} from "../../../redux/features/jobSlice";
import { setTitle } from "../../../redux/features/pageTitleSlice";
import { useFetchJob } from "../../../utils/hooks";

const NewJobLayout = () => {
  const uniqueId = new Date().getTime();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step1Data, setStep1Data] = useState([]);
  const [step2Data, setStep2Data] = useState([{ key: uniqueId }]);
  const [step, setStep] = useState(1);
  const [step1Error, setStep1Error] = useState();
  const [step2Error, setStep2Error] = useState();
  const [showSuccessModule, setShowSuccessModule] = useState(false);
  const [applyLink, setApplyLink] = useState("");
  const [loading, setLoading] = useState(false);

  // get Edit Job Data Details
  const { editId } = useParams();
  const [editJobData, fetchJobLoading] = useFetchJob(editId);
  useEffect(() => {
    fetchJobLoading ? setLoading(true) : setLoading(false);
  }, [fetchJobLoading]);

  useEffect(() => {
    if (editJobData) {
      dispatch(
        setTitle({
          title: `edit job (${editJobData.positionTitle})`,
          showBack: true,
        })
      );
    } else {
      dispatch(setTitle({ title: "new job", showBack: true }));
    }
  }, [dispatch, editJobData]);

  const submitStep1 = () => {
    setStep1Error("");
    let errorObject = {};
    errorObject.positionTitleError =
      step1Data.positionTitle === "" && "Required";
    errorObject.departmentManagerError =
      step1Data.departmentManager === "" && "Required";
    errorObject.assignedRecruiterError =
      step1Data.assignedRecruiter === "" && "Required";
    errorObject.lastDateToApplyError =
      new Date(step1Data.lastDateToApply).getTime() < Date.now() &&
      "Date can't be of past";
    errorObject.targetDateError =
      new Date(step1Data.targetDate).getTime() < Date.now() &&
      "Date can't be of past";
    errorObject.jobTypeError = step1Data.jobType === "" && "Required";
    errorObject.workExperianceError =
      step1Data.workExperiance === "" && "Required";
    errorObject.experianceLevelError =
      step1Data.experianceLevel === "" && "Required";

    errorObject.numberOfPositionError =
      step1Data.numberOfPosition === "" && "Required";
    // errorObject.jobPrefenceError = step1Data.jobPrefence === "" && "Required";
    errorObject.countryError = step1Data.country === "" && "Required";
    errorObject.stateError =
      step1Data.jobPrefence === "wfo" && step1Data.state === "" && "Required";
    errorObject.cityError =
      step1Data.jobPrefence === "wfo" && step1Data.city === "" && "Required";
    errorObject.jobDescriptionError =
      step1Data.jobDescription === "" && "Required";
    setStep1Error(errorObject);
    if (Object.values(errorObject).some((value) => value !== false)) return;

    setStep((prev) => prev + 1);
  };
  const publishJob = async () => {
    setStep2Error("");
    if (
      step2Data.some(
        (obj) =>
          obj.roundType === undefined ||
          (obj.roundType !== "interview" &&
            obj.selectionCriteria === undefined) ||
          (obj.roundType === "assessment" && obj.assessment === undefined)
      )
    )
      return setStep2Error("Fill all the fields for rounds you have added");

    if (
      step2Data.some(
        (obj) => obj.selectionCriteria < 1 || obj.selectionCriteria > 100
      )
    ) {
      return setStep2Error(
        "Selection Criteria score should be between 1% to 100%"
      );
    }

    const link = `?jobId=${step1Data?.jobOpeningId}`;
    setApplyLink(link);

    const data = {
      jobOpeningId: step1Data.jobOpeningId,
      positionTitle: step1Data.positionTitle,
      departmentManager: step1Data.departmentManager,
      assignedRecruiter: step1Data.assignedRecruiter,
      jobType: step1Data.jobType,
      jobPrefence: step1Data.jobPrefence,
      jobDescription: step1Data.jobDescription,
      jobPostedDate: Timestamp.fromDate(new Date()),
      lastDateToApply: Timestamp.fromDate(new Date(step1Data.lastDateToApply)),
      targetDate: Timestamp.fromDate(new Date(step1Data.targetDate)),
      workExperiance: step1Data.workExperiance,
      numberOfPosition: Number(step1Data.numberOfPosition),
      salary: {
        min: Number(step1Data.minimumSalary),
        max: Number(step1Data.maximumSalary),
      },
      address: {
        country: step1Data.country,
        state: step1Data.state,
        city: step1Data.city,
        postalCode: step1Data.postalCode,
      },
      jobStatus: "in-progress",
      applyLink: link,
      companyName: auth?.currentUser?.displayName,
      rounds: step2Data,
      isDeleted: false,
      timeline: [
        {
          name: "job created",
          time: Timestamp.fromDate(new Date()),
        },
        {
          name: "job opened",
          time: Timestamp.fromDate(new Date()),
        },
        {
          name: "last date to apply",
          time: Timestamp.fromDate(new Date(step1Data.lastDateToApply)),
        },
        {
          name: "target date",
          time: Timestamp.fromDate(new Date(step1Data.targetDate)),
        },
      ],
    };
    try {
      setLoading(true);

      try {
        dispatch(createJob({ data }))
          .unwrap()
          .catch((error) => {
            throw new Error(error);
          });
      } catch (err) {
        console.log(err);
      }
      setShowSuccessModule(true);
    } catch (error) {
      setStep2Error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const closeSuccessModule = () => {
    setShowSuccessModule(false);
    navigate(-1);
  };

  const updateJob = async () => {
    let updatedTimeline = [
      ...editJobData.timeline,
      {
        name: "job updated",
        time: Timestamp.fromDate(new Date()),
      },
    ];
    if (
      Timestamp.fromDate(new Date(step1Data.lastDateToApply)) !==
      editJobData.lastDateToApply
    ) {
      updatedTimeline = updatedTimeline.filter(
        (obj) => obj.name !== "last date to apply"
      );
      updatedTimeline.push({
        name: "last date to apply",
        time: Timestamp.fromDate(new Date(step1Data.lastDateToApply)),
      });
    }
    if (
      Timestamp.fromDate(new Date(step1Data.targetDate)) !==
      editJobData.lastDateToApply
    ) {
      updatedTimeline = updatedTimeline.filter(
        (obj) => obj.name !== "target date"
      );
      updatedTimeline.push({
        name: "target date",
        time: Timestamp.fromDate(new Date(step1Data.targetDate)),
      });
    }
    const updateData = {
      positionTitle: step1Data.positionTitle,
      departmentManager: step1Data.departmentManager,
      assignedRecruiter: step1Data.assignedRecruiter,
      jobType: step1Data.jobType,
      jobPrefence: step1Data.jobPrefence,
      jobDescription: step1Data.jobDescription,
      lastDateToApply: Timestamp.fromDate(new Date(step1Data.lastDateToApply)),
      targetDate: Timestamp.fromDate(new Date(step1Data.targetDate)),
      workExperiance: step1Data.workExperiance,
      numberOfPosition: Number(step1Data.numberOfPosition),
      salary: {
        min: Number(step1Data.minimumSalary),
        max: Number(step1Data.maximumSalary),
      },
      updatedAt: Timestamp.fromDate(new Date()),
      address: {
        country: step1Data.country,
        state: step1Data.state,
        city: step1Data.city,
        postalCode: step1Data.postalCode,
      },
      rounds: step2Data,
      timeline: updatedTimeline,
    };
    try {
      setLoading(true);
      await dispatch(
        updateJobReducer({ id: editJobData.jobOpeningId, data: updateData })
      ).unwrap();
      setShowSuccessModule(true);
    } catch (error) {
      console.log(error);
      setStep2Error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="main-container w-full mx-auto md:ml-20 mt-1 flex flex-col lg-px-10">
      <div className="card w-full lg:h-[90vh] overflow-y-hidden">
        <div className="w-1/3 h-[18%] mx-auto flex flex-col justify-center gap-1 items-center">
          <div className="w-[60%] flex justify-between items-center relative">
            <div className="absolute h-[2px] w-full bg-gray-300">
              <div
                className="absolute top-0 left-0 h-full bg-green-500 transition-all duration-1000"
                style={{ width: `${(step - 1) * 100}%` }}
              />
            </div>
            <div
              className={`
              w-8 h-8 flex items-center justify-center rounded-full transition-all duration-1000 z-10
              ${step > 1 ? "bg-green-500" : "bg-[#8A24F1]"}
            `}
            >
              <span className="text-white font-semibold">
                {step > 1 ? "✓" : "1"}
              </span>
            </div>
            <div
              className={`
              w-8 h-8 flex items-center justify-center rounded-full transition-all duration-1000 z-10
               ${step < 2 ? "bg-gray-400" : "bg-[#8A24F1]"}
            `}
            >
              <span className="text-white font-semibold">2</span>
            </div>
          </div>
          <div className="w-[65%] flex justify-between items-center">
            <span className="text-xs font-medium">Job Details</span>
            <span className="text-xs font-medium">Rounds Details</span>
          </div>
        </div>

        <div className="w-full h-[72%] px-12">
          {step === 1 && (
            <Step1
              jobDetails={step1Data}
              setJobDetails={setStep1Data}
              jobError={step1Error}
              setJobError={setStep1Error}
              editJobData={editJobData}
            />
          )}
          {step === 2 && (
            <Step2
              rounds={step2Data}
              setRounds={setStep2Data}
              editJobData={editJobData}
            />
          )}
        </div>

        <div className="w-full h-[10%] flex flex-col justify-center items-end gap-2">
          <div className="text-xs text-red-500">{step2Error}</div>
          <div className="flex items-center gap-4">
            <Button
              version="secondary"
              text="Cancel"
              width="160px"
              handleClick={() => navigate(-1)}
            />
            {step === 2 && (
              <Button
                version="secondary"
                text="Back"
                width="160px"
                handleClick={() => {
                  setStep((prev) => prev - 1);
                  setStep2Error("");
                }}
              />
            )}
            <Button
              handleClick={() =>
                step === 1
                  ? submitStep1()
                  : editJobData
                  ? updateJob()
                  : publishJob()
              }
              loading={loading}
              width="200px"
            >
              {step === 1
                ? "Next"
                : editJobData
                ? "Update"
                : "Save and Publish"}
            </Button>
          </div>
        </div>
      </div>
      {showSuccessModule && (
        <Popup closePopup={closeSuccessModule}>
          <JobCreatedModule
            applyLink={applyLink}
            step1Data={step1Data}
            editJobData={editJobData}
          />
        </Popup>
      )}
    </div>
  );
};

export default NewJobLayout;
