import React from "react";
import { Logo } from "../../../components";
import { adminRegisterSteps } from "../../../constant/formFields/adminRegisterSteps";

const LeftPannel = ({ step }) => {
  const steps = adminRegisterSteps;
  return (
    <div className="w-[40%] h-full bg-gray-100 flex flex-col gap-6   justify-center items-center">
      <div className="absolute top-10 left-10">
        <Logo width="12rem" />
      </div>

      <div className="w-4/5">
        <img src="/img/AdminRegisterFrame.png" alt="left panel img" />
      </div>

      <div className="w-4/5 space-y-2 text-center h-24 mt-2">
        <p className="text-2xl font-semibold">
          {steps[step - 1]?.leftPanelTitle}
        </p>
        <p className="text-sm">{steps[step - 1]?.leftPanelText}</p>
      </div>

      <div className="w-full flex justify-center">
        <div className="flex gap-3 items-center">
          {steps.map((s, i) => {
            return (
              <div
                key={i}
                className={`w-3 h-3 rounded-full ${
                  step === s?.value ? "bg-[#8A24F1]" : "bg-gray-300"
                }`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LeftPannel;
