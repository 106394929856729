import { useEffect, useState } from "react";
import { Button, Popup } from "../../components";
import SelectRejectConfirmPopup from "./SelectRejectConfirmPopup";
import { sendEmailHandler } from "../../utils/helperFunctions/sendEmailHandler";
import dbService from "../../firebase/dbService";
import rejectedEmailTemplate from "../../utils/emailTemplates/roundNotCleared";
import selectedEmailTemplate from "../../utils/emailTemplates/selectedForInterview";
import { Timestamp } from "@firebase/firestore";

const Actions = ({ applicationDetails, candidateDetails }) => {
  const [updateStatusLoading, setUpdateStatusLoading] = useState(null);
  const [showConfirmUpdateModal, setShowConfirmUpdateModal] = useState(false);
  const [status, setStatus] = useState(null);
  useEffect(() => {
    setStatus(applicationDetails?.interview?.status);
  }, [applicationDetails]);
  const actionButtons = [
    { name: "select", label: "Select", span: 1, version: "green" },
    // {
    //   name: "hold",
    //   label: "Hold",
    //   span: 1,
    //   version: "outline-yellow",
    // },
    {
      name: "reject",
      label: "Reject",
      span: 1,
      version: "red",
    },
  ];

  const updateStatus = (state) => {
    setShowConfirmUpdateModal(state);
  };
  const confirmUpdate = async (state) => {
    let newStatus;
    let subject;
    let body;
    const emailId = candidateDetails?.email;
    if (state === "select") {
      newStatus = "selected";
      ({ subject, body } = selectedEmailTemplate({
        companyName: applicationDetails?.appliedAtCompany,
        candidateName: candidateDetails?.name,
        jobTitle: applicationDetails?.appliedPosition,
      }));
    } else if (state === "reject") {
      newStatus = "rejected";
      ({ subject, body } = rejectedEmailTemplate({
        companyName: applicationDetails?.appliedAtCompany,
        candidateName: candidateDetails?.name,
      }));
    }
    setUpdateStatusLoading((prev) => ({ ...prev, [state]: true }));

    //1.  update status to application document of candidate
    try {
      await dbService.updateDocument(
        "candidateApplications",
        {
          interview: {
            status: newStatus,
            statusUpdateAt: Timestamp.now(),
          },
        },
        applicationDetails?.id
      );

      // 2. update state
      setStatus(newStatus);

      // 3. send email
      await sendEmailHandler({
        emailId,
        subject,
        body,
        successMessage:
          state === "select"
            ? `Selected email sent successfully to ${emailId}`
            : `Rejected Email sent successfully to ${emailId}`,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setUpdateStatusLoading((prev) => ({ ...prev, [state]: false }));
      setShowConfirmUpdateModal(false);
    }
  };

  return (
    <div className="w-full flex flex-col justify-center">
      <span className="text-sm font-semibold">
        Select/Reject for final interview :
      </span>
      {status ? (
        <div className="flex items-center gap-2 w-full">
          <div
            className={`capitalize w-full flex justify-center items-center p-2 rounded-lg
                                ${
                                  status === "selected"
                                    ? "text-[#57AD55] bg-[#57AD55]/20"
                                    : status === "hold"
                                    ? "text-[#FFB800] bg-[#FFB800]/20"
                                    : status === "rejected"
                                    ? "text-[#E5383B] bg-[#E5383B]/20"
                                    : ""
                                }        
                            `}
          >
            {status}
          </div>
        </div>
      ) : (
        <div className="w-full flex gap-3 items-center">
          <div className="relative flex items-center justify-center w-full">
            <div className="grid gap-2 grid-cols-2 mt-2 w-full sm:w-2/3 md:w-1/2 lg:w-full text-sm">
              {actionButtons.map((item, index) => (
                <div className={`col-span-${item.span}`}>
                  <Button
                    text={item.label}
                    version={item.version}
                    handleClick={() => updateStatus(item.name)}
                    width="100%"
                    loading={updateStatusLoading?.[item.name] || false}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {showConfirmUpdateModal && (
        <Popup closePopup={() => setShowConfirmUpdateModal(false)}>
          <SelectRejectConfirmPopup
            state={showConfirmUpdateModal}
            closePopup={() => setShowConfirmUpdateModal(false)}
            confirmUpdate={confirmUpdate}
            loading={updateStatusLoading}
          />
        </Popup>
      )}
    </div>
  );
};

export default Actions;
