import React, { useEffect, useState } from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { MdOutlineAddComment, MdOutlineMail } from "react-icons/md";
import { HiOutlineBriefcase } from "react-icons/hi2";
import { FiPhone } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { FaRegClock } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
import Actions from "./Actions";
import { Popup } from "../../components";
import Comments from "./Comments";

const ProfileLeftPanel = ({
  candidateDetails,
  schedule,
  applicationDetails,
  results,
  setCandidateProfileView,
  roundsData,
}) => {
  const [resultColor, setResultColor] = useState();
  useEffect(() => {
    setResultColor(
      results?.score < 40
        ? "#E5383B"
        : results?.score < 70
        ? "#FFB800"
        : "#57AD55"
    );
  }, [results]);

  const [round, setRound] = useState();
  const [lastRound, setLastRound] = useState();
  useEffect(() => {
    const roundIndex = roundsData?.findIndex(
      (round) => round.id === schedule?.assessmentId
    );
    setRound(roundIndex);
    setLastRound(roundIndex === roundsData.length - 1);
  }, [roundsData, schedule]);

  const [showCommentsPopup, setShowCommentsPopup] = useState(false);

  return (
    <>
      <div className="card h-full w-full py-6 px-8 flex flex-col justify-between gap-4 overflow-y-scroll scrollable">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4 justify-center mb-4">
            {/* previous button  */}
            <h1 className="flex sm:hidden lg:flex justify-center font-bold uppercase text-xl lg:text-lg">
              {candidateDetails?.name}
            </h1>
            <BsBoxArrowUpRight
              className="w-4 h-4 cursor-pointer"
              onClick={() => setCandidateProfileView(true)}
            />
            {/* next button  */}
          </div>
          <div className="flex items-center gap-2">
            <div className="bg-[#9160D1]/10 text-[#9160D1] rounded-md p-1">
              <MdOutlineMail className="w-5 h-5" />
            </div>
            <div>
              <p className="flex flex-col sm:flex-row lg:flex-col">
                {candidateDetails?.email}
              </p>
            </div>
          </div>
          <div className="flex items-center capitalize gap-3">
            <div className="bg-[#0066FF]/10 text-[#0066FF] rounded-md p-1">
              <FiPhone className="w-5 h-5" />
            </div>
            {candidateDetails?.phoneNumber}
          </div>
          <div className="flex items-center capitalize gap-3">
            <div className="bg-[#00756A]/10 text-[#00756A] rounded-md flex p-1">
              <HiOutlineBriefcase className="w-5 h-5" />
            </div>
            <p className="flex flex-col sm:flex-row lg:flex-col">
              {applicationDetails?.appliedPosition}
            </p>
          </div>
          {candidateDetails?.location && (
            <div className="flex items-center capitalize gap-3">
              <div className="bg-[#FFD66B]/10 text-[#FFD66B] rounded-md flex p-1">
                <IoLocationOutline className="w-5 h-5" />
              </div>
              <p className="flex flex-col sm:flex-row lg:flex-col">
                {candidateDetails?.location}
              </p>
            </div>
          )}
          {schedule && (
            <div className="mt-4 space-y-3">
              <div className="space-y-1">
                <h2 className="font-semibold">Schedule</h2>
                <div className="flex items-center gap-3">
                  <div className="bg-[#E5EFFF] rounded-md text-[#3D8BFF]">
                    <FaRegClock className="w-5 h-5" />
                  </div>
                  <span className="text-sm">
                    {new Date(
                      schedule?.scheduleDate?.seconds * 1000
                    )?.toLocaleString("en-in", {
                      hour: "2-digit",
                      minute: "2-digit",
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      // weekday: "short",
                    })}
                  </span>
                </div>
              </div>
              <div className="space-y-1">
                <h2 className="font-semibold">
                  {schedule?.attempted ? "Attempted" : "Assessment Expiry"}
                </h2>
                <div className="flex items-center gap-3">
                  <div className="bg-[#E5EFFF] rounded-md text-[#3D8BFF]">
                    <FaRegClock className="w-5 h-5" />
                  </div>
                  <span className="text-sm">
                    {schedule?.attemptedAt
                      ? new Date(
                          results?.examDateTime?.seconds * 1000
                        )?.toLocaleString("en-in", {
                          hour: "2-digit",
                          minute: "2-digit",
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          // weekday: "short",
                        })
                      : new Date(
                          schedule?.expDate?.seconds * 1000
                        )?.toLocaleString("en-in", {
                          hour: "2-digit",
                          minute: "2-digit",
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          // weekday: "short",
                        })}
                  </span>
                </div>
              </div>
            </div>
          )}
          {(results?.score || results?.score === 0) && (
            <div className="my-4 mt-10 flex flex-col gap-4 items-center font-semibold">
              <div
                className={`resultProgressBar relative text-[${resultColor}]`}
                data-progress={results?.score === "NaN" ? 0 : results?.score}
                style={{
                  "--progress": `${results?.score * 3.6}deg`,
                  "--progressColor": resultColor,
                }}
              ></div>
              <div className="flex flex-col items-center">
                <p className="">Overall performance</p>
              </div>
            </div>
          )}
          {/* show only if it the last round of the interview */}
          {lastRound && results && results?.result !== "fail" && (
            <Actions
              applicationDetails={applicationDetails}
              candidateDetails={candidateDetails}
            />
          )}
          {/* {applicationDetails?.assessmentsFeedbacks && <div>Feedbacks</div>} */}

          <div className="w-full mt-4">
            <span className="text-sm font-semibold ">Comments :</span>
            <div
              className="w-full flex items-center h-10 rounded-md border-2 border-gray-300 px-4 justify-between cursor-pointer"
              onClick={() => setShowCommentsPopup(true)}
            >
              <div className="flex gap-2 items-center">
                <MdOutlineAddComment className="w-5 h-5 text-gray-500" />
                <span className="font-medium">HR Comments</span>
                {applicationDetails?.comments?.filter(
                  (comment) => comment.round === round
                ).length > 0 && (
                  <div className="bg-gray-400 text-white rounded-full text-sm h-6 w-6 flex justify-center items-center font-semibold">
                    {
                      applicationDetails?.comments?.filter(
                        (comment) => comment.round === round
                      ).length
                    }
                  </div>
                )}
              </div>
              <FaChevronRight />
            </div>
          </div>
        </div>
        {showCommentsPopup && (
          <Popup closePopup={() => setShowCommentsPopup(false)}>
            <Comments applicationDetails={applicationDetails} round={round} />
          </Popup>
        )}
      </div>
    </>
  );
};

export default ProfileLeftPanel;
