import React from "react";

const JobDescription = ({ jobData }) => {
  return (
    <div className="space-y-4 w-full p-6 lg:px-12">
      <h1 className="text-lg font-semibold">Job Description</h1>
      <div className="text-sm text-justify">{jobData?.jobDescription}</div>
    </div>
  );
};

export default JobDescription;
