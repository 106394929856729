export const violationTypes = {
    fullScreen: {
        type: 'fullScreen',
        violation: 'Exit fullscreen',
        message: ['Do not exit fullscreen mode', 'else your assessment will be terminated']

    },
    tabSwitch: {
        type: 'tabSwitch',
        violation: 'Tab/Window switched',
        message: ['Tab/Window Switching is not allowed', 'Do not switch Tab/Window or your assessment will be terminated']
    },
    devtools: {
        type: 'devtools',
        violation: 'Devtools opened',
        message: ['Do not open Inspect-tools.', 'Please close the dev tools, else the Assessment will be terminated.']
    },
    screenShot: {
        type: 'screenShot',
        violation: 'Screen shot taken',
        message: ['Screenshot not allowed.', 'Please dont try to take screenshots, else the Assessment will be terminated.']
    },
    refresh: {
        type: 'refresh',
        violation: 'Refreshed',
        message: ['']
    },
    noFace: {
        type: 'noFace',
        violation: 'No face detected',
        message: ['No Face Detected', 'Please look on the screen during exam.']
    },
    multiplePerson: {
        type: 'multipleFace',
        violation: 'Multiple person detected',
        message: ['More then one Face Detected', 'Please dont allow anyone to come in webcam view.']
    },
    cameraDisabled: {
        type: 'cameraDisabled',
        violation: 'Camera disabled',
        message: ['Camera Disabled', 'Please enable the web-cam to resume the exam.']
    },
    cellPhoneDetected: {
        type: 'cellPhoneDetected',
        violation: 'Cell phone detected',
        message: ['Cell phone Detected', 'You cannot use mobile phones.']
    },
    voice: {
        type: 'voice',
        violation: 'Voice detected',
        message: ['Background Voice Detected', "Please maintain silence and ensure you are in a quiet environment."]
    }
}