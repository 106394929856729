import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBbJmGRk3LBfeyOSlH2FeTbP_hm7tRzLV4",
  authDomain: "talentigo-f5906.firebaseapp.com",
  projectId: "talentigo-f5906",
  storageBucket: "talentigo-f5906.appspot.com",
  messagingSenderId: "906781057791",
  appId: "1:906781057791:web:1cba08eab51272e527a2ca",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
