import React from "react";

const LoadingCard = () => {
  return (
    <div className="card h-[4.5rem] sm:h-[5.5rem] w-full flex items-center gap-3 p-4">
      <div className="bg-[#666]/10 w-16 h-16 flex items-center justify-center rounded-xl animate-pulse" />
      <div className="flex flex-col justify-center h-full w-full gap-2 ">
        <div className="bg-[#666]/10 w-32 h-2/3 rounded-2xl animate-pulse"></div>
        <div className="bg-[#666]/10 w-60 h-1/3 rounded-2xl animate-pulse"></div>
      </div>
    </div>
  );
};

export default LoadingCard;
