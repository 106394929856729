import axios from "axios";
import { asyncHandler } from "./asyncHandler";
import { toast } from "react-toastify";
import envConfig from "../../config/envConfig";

export const sendEmailHandler = async ({
  emailId,
  subject,
  body,
  successMessage,
  autoClose,
}) => {
  const mailApi = envConfig.emailApi;
  const emailData = `${emailId}|${subject}|${body}`;
  try {
    const response = await asyncHandler({
      func: () => axios.post(mailApi, emailData),
      errorMessage: "Faild to send email.",
    });
    successMessage && toast.success(`Success! ${successMessage}`, {
      autoClose: autoClose,
    });
    return response;
  } catch (error) {
    toast.error(error.message);
    throw new Error(error.message);
  }
};
