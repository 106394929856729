import { useState } from "react";
import { BsLayoutThreeColumns } from "react-icons/bs";
import ColumnEditMenu from "../jobs/ColumnEditMenu";
import ATSrows from "./ATSrows";
import { LuChevronsUpDown, LuLoaderCircle } from "react-icons/lu";
// import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
// import { FaArrowCircleDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { atsListingColumns } from "../../constant/tableColumns/atsListing";
import { fetchApplications } from "../../redux/features/applicationsSlice";
import { useScrollPosition } from "../../utils/hooks";

const ATSlisting = ({
  setShowScheduleModule,
  setCandidateToSchedule,
  setCandidateToReschedule,
  scrollableContainerRef,
}) => {
  const dispatch = useDispatch();
  const columns = atsListingColumns;
  const [columnList, setColumnList] = useState(columns);
  const [openColumnEdit, setOpenColumnEdit] = useState(false);

  const {
    applications,
    loading,
    loadMoreLoading,
    hasMore,
    filterJobId,
    error: applicationsFetchError,
  } = useSelector((state) => state.applications);

  // const observerTarget = useCallback(
  //   (node) => {
  //     if (loadMoreLoading) return;
  //     const observer = new IntersectionObserver(
  //       (entries) => {
  //         if (entries[0].isIntersecting && hasMore) {
  //           console.log("intersecting");
  //           setLoadMoreLoading(true);
  //           dispatch(fetchApplications({ loadMore: true }));
  //         }
  //       },
  //       { threshold: 0.5 }
  //     );
  //     if (node) observer.observe(node);
  //   },
  //   [loadMoreLoading, hasMore, dispatch]
  // );

  useScrollPosition(scrollableContainerRef.current);

  return (
    <>
      <div className="w-fit h-full flex flex-col relative">
        <div className="flex w-full py-5 pl-5 bg-[#E9EAEB] font-medium text-sm text-black md:rounded-t-xl">
          <div className="w-[50px] flex items-center">
            <BsLayoutThreeColumns
              className="w-5 h-5 cursor-pointer"
              onClick={() => setOpenColumnEdit(!openColumnEdit)}
            />
          </div>
          {columnList.map((column, index) => {
            const width = column.open ? column.width : "0";
            const opacity = column.open ? "1" : "0";
            return (
              <div
                key={index}
                className="flex items-center gap-1 transition-all duration-200 px-2"
                style={{ width, opacity }}
              >
                {column.open && (
                  <div className="flex">
                    <span>{column.label}</span>{" "}
                    {/* {column.sort && (
                      <div
                        className="ml-1 cursor-pointer"
                        onClick={() => {
                          setSortValue({
                            fieldName: column.name,
                            fieldValue: column.sortType
                              ? column.sortType
                              : "asc",
                          });
                          columnList[index].sortType =
                            column.sortType === ""
                              ? "desc"
                              : column.sortType === "desc"
                              ? "asc"
                              : "desc";
                          columnList.map((_, ind) => {
                            if (index !== ind) {
                              columnList[ind].sortType = "";
                            }
                            return "";
                          });
                        }}
                      >
                        {column.sortType === "desc" ? (
                          <IoIosArrowUp className="w-4 h-4" />
                        ) : column.sortType === "asc" ? (
                          <IoIosArrowDown className="w-4 h-4" />
                        ) : (
                          <LuChevronsUpDown className="w-4 h-4" />
                        )}
                      </div>
                    )} */}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div
          className="h-full overflow-y-scroll scrollable"
          ref={scrollableContainerRef}
        >
          {loading ? (
            <div className="fixed w-[90vw] text-center font-semibold text-xl text-gray-400 mt-10">
              Loading...
            </div>
          ) : applicationsFetchError ? (
            <div className="fixed w-[90vw] text-center font-semibold text-xl text-gray-400 mt-10">
              Something went wrong while fetching data, please try again
            </div>
          ) : applications?.length === 0 ? (
            <div className="fixed w-[90vw] text-center font-semibold text-xl text-gray-400 mt-10">
              No Candidates to Show
            </div>
          ) : (
            <>
              {applications?.map((application, index) => (
                <ATSrows
                  key={index}
                  index={index}
                  columnList={columnList}
                  application={application}
                  setShowScheduleModule={setShowScheduleModule}
                  setCandidateToSchedule={setCandidateToSchedule}
                  setCandidateToReschedule={setCandidateToReschedule}
                />
              ))}

              {hasMore && (
                <div
                  // ref={observerTarget}
                  className="w-full flex flex-col items-center justify-center py-4 text-gray-500 px-5"
                >
                  {loadMoreLoading ? (
                    <span className="font-semibold text-lg cursor-pointer hover:underline hover:text-blue-300">
                      <LuLoaderCircle className="w-10 h-10 animate-spin text-gray-300" />
                    </span>
                  ) : (
                    <span
                      className="font-semibold text-lg cursor-pointer hover:underline w-full text-center hover:text-[#8A24F1] hover:bg-gray-200 rounded-full py-1"
                      onClick={() => {
                        dispatch(
                          fetchApplications({
                            loadMore: true,
                            sort: {
                              fieldName: "appliedAt",
                              fieldValue: "desc",
                            },
                            jobId: filterJobId,
                          })
                        );
                      }}
                    >
                      Load More
                    </span>
                  )}
                </div>
              )}
            </>
          )}
        </div>

        <ColumnEditMenu
          openColumnEdit={openColumnEdit}
          setOpenColumnEdit={setOpenColumnEdit}
          columnList={columnList}
          setColumnList={setColumnList}
        />
      </div>
    </>
  );
};

export default ATSlisting;
