import React, { useState, useCallback } from "react";
import { storage } from "../../config/firebase";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useDropzone } from "react-dropzone";
import { MdCancel, MdDelete } from "react-icons/md";
import { SlCloudUpload } from "react-icons/sl";

const UploadResume = ({ setResumeUrl, dragging, setDragging }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [fileRef, setFileRef] = useState();

  const uploadFile = useCallback(
    async (file) => {
      const uniqueId = (Math.random() + 1).toString(36).substring(4);
      if (!file) return;
      let isCancelled = false;
      try {
        setUploading(true);
        const fileRef = ref(storage, `talentigoResumes/resumeID_${uniqueId}`);
        setFileRef(fileRef);

        //upload progress
        const uploadTask = uploadBytesResumable(fileRef, file);

        // Function to cancel the upload
        const cancelUpload = () => {
          isCancelled = true;
          uploadTask.cancel();
          setUploading(false);
          setProgress(0);
          setSelectedFile(null);
          console.log("Uploading stopped");
        };
        // Expose cancelUpload function for external use
        window.cancelUpload = cancelUpload;

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            if (isCancelled) return; // Check if upload is cancelled
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
          },
          (error) => {
            if (isCancelled) return; // Check if upload is cancelled
            console.error(error);
            setError(error.message);
            setUploading(false);
          },

          async () => {
            if (isCancelled) return; // Check if upload is cancelled
            // Upload completed successfully
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            setResumeUrl(url);
            setUploading(false);
            setProgress(0);
          }
        );
      } catch (error) {
        console.error(error);
        setError(error.message);
      }
    },
    [setResumeUrl]
  );

  const handleDelete = async () => {
    try {
      await deleteObject(fileRef);
      setResumeUrl("");
    } catch (error) {
      throw error;
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
      uploadFile(acceptedFiles[0]);
    },
    [uploadFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [],
      "image/jpeg": [],
      "application/pdf": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      "application/vnd.ms-powerpoint": [],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [],
    },
  });

  return (
    <div>
      <label htmlFor="resume" className="capitalize text-xs">
        Upload your resume*
      </label>
      {selectedFile || uploading ? (
        <div
          className={`relative bg-gray-100 flex flex-col p-3 rounded-[10px] px-4 overflow-hidden
                        ${
                          uploading
                            ? "border-gray-200 border-[2px]"
                            : "border-[#8A24F1] border-[2px] "
                        }`}
        >
          <div className="flex items-center justify-between">
            <p> {selectedFile?.name} </p>
            <div>
              {uploading && (
                <div className="text-[#999] text-sm font-bold">Uploading</div>
              )}
            </div>
            {uploading ? (
              <div
                onClick={() => window.cancelUpload()}
                className="cursor-pointer"
              >
                <MdCancel className="w-6 h-6 text-[#8A24F1]" />
              </div>
            ) : (
              <div
                onClick={() => {
                  handleDelete();
                  setSelectedFile();
                }}
                className="cursor-pointer"
              >
                <MdDelete className="w-5 h-5 text-[#8A24F1]" />
              </div>
            )}
          </div>
          {uploading && (
            <div className="absolute bottom-0 left-0 w-full h-[3px]">
              <div
                style={{ width: `${progress}%` }}
                className="h-full bg-[#8A24F1] transition-width duration-300 ease-in-out"
              />
            </div>
          )}
        </div>
      ) : (
        <div
          className={`
                        rounded-xl cursor-pointer flex items-center justify-center 
                        ${
                          dragging
                            ? "absolute h-full w-full top-0 left-0 right-0 bottom-0 bg-[#fff]/40 backdrop-blur-[4px] border-[3px] border-[#8a24f1]/20 border-dashed"
                            : "bg-gray-100 border-2 boreder-gray-200 w-full h-[80px]"
                        }
                    `}
          onDragLeave={() => setDragging(false)}
          onDrop={() => setDragging(false)}
        >
          <div {...getRootProps({ className: "dropzone w-full h-full" })}>
            <input {...getInputProps()} />
            {selectedFile ? (
              <div className="flex flex-col items-center justify-center w-full h-full text-[#676767] font-bold text-lg">
                {selectedFile.name}
              </div>
            ) : (
              <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                {dragging ? (
                  <>
                    <SlCloudUpload className="w-20 h-20 text-[#8A24F1]/60" />
                    <p className="text-3xl text-[#34495E]/60 font-bold">
                      Drop Here
                    </p>
                  </>
                ) : (
                  <div className="flex justify-center gap-5 items-center px-4">
                    <SlCloudUpload className="w-10 h-10 text-[#8A24F1]" />
                    <div className="space-y-1">
                      <p className="text-sm text-[#34495E] font-semibold">
                        Drag & drop file or
                        <span className="text-[#8A24F1] underline ml-1">
                          Browse
                        </span>
                      </p>
                      <p className="text-xs text-[#444] text-center">
                        Supported formates: JPEG, PNG, PDF, Word, PPT
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="text-[#f00] text-sm ">{error}</div>
    </div>
  );
};

export default UploadResume;
