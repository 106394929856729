import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const SearchResults = ({
  searchResultRef,
  isSearchOpen,
  searchAssessmentData,
  searchApplicationData,
  setSearchInput,
}) => {
  // const [applicationsLength ,setApplicationsLength] = useState()
  // useEffect(() => {
  //     if(searchAssessmentData?.length >= 4){
  //         setApplicationsLength(4)
  //     }else{
  //         setApplicationsLength(8 - searchAssessmentData?.length)
  //     }
  // },[searchAssessmentData])

  const [assessmentsLength, setAssessmentsLength] = useState(8);
  // useEffect(() => {
  //     if(searchApplicationData?.length >= 4){
  //         setAssessmentsLength(4)
  //     }else{
  //         setAssessmentsLength(8 - searchApplicationData?.length)
  //     }
  // },[searchApplicationData])

  return (
    <motion.div
      className="absolute w-[90%] sm:w-1/2 md:w-[40%] lg:w-[30%] md:right-20 lg:right-[10.5rem] card flex-col"
      variants={{
        initial: { top: 0, display: "none" },
        animate: { top: 70, display: "flex" },
      }}
      initial="initial"
      transition={{ duration: 0.1 }}
      animate={isSearchOpen ? "animate" : "initial"}
      ref={searchResultRef}
    >
      <div className="py-4 overflow-hidden">
        <div className="font-semibold mb-2 px-6">Search Results : </div>
        {searchAssessmentData?.length !== 0 ||
        searchApplicationData?.length !== 0 ? (
          <div className="flex flex-col">
            {/* {
                            searchApplicationData?.length !== 0  && 
                            <p className='px-6 py-1 text-sm font-medium'>Applications</p>
                        }
                        
                        {searchApplicationData?.slice(0,applicationsLength)?.map((candidate) => (
                            <div key={candidate.uid} className='cursor-pointer px-6 py-2 hover:bg-[#eee]/40'>
                                <motion.div
                                    whileTap={{scale : 0.95}}                    
                                    className='relative'
                                >
                                    <Link
                                        to={`/candidateProfile?id=${candidate?.uid}`}
                                        className="absolute top-0 left-0 w-full h-full"
                                        onClick = {() => setSearchInput("")}
                                    ></Link>
                                    <div className="flex items-center gap-4">
                                        <div className='bg-[#0066FF]/10 w-10 h-10 rounded-xl flex justify-center items-center'>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none" viewBox="0 0 24 24"
                                                strokeWidth={2} stroke="currentColor"
                                                className='w-5 h-5 text-[#0066FF]'  
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                            </svg>
                                        </div>    
                                        <div>
                                            <div className='font-medium text-sm'>{candidate.name}</div>
                                            <div className='text-[#aaa] text-xs font-medium mt-[-2px]'>{candidate.email}</div>
                                        </div> 
                                    </div>
                                </motion.div>   
                            </div>                                   
                        ))}
                        {
                            searchAssessmentData?.length !== 0  &&
                            <p className='px-6 py-1 text-sm font-medium'>Assessments</p>
                        } */}
            {searchAssessmentData
              ?.slice(0, assessmentsLength)
              ?.map((candidate) => (
                <div
                  key={candidate.UID}
                  className=" cursor-pointer px-6 py-2 hover:bg-[#eee]/40"
                >
                  <motion.div whileTap={{ scale: 0.95 }} className="relative">
                    <Link
                      to={`/candidateProfile?id=${candidate?.UID}`}
                      className="absolute top-0 left-0 w-full h-full"
                      onClick={() => setSearchInput("")}
                    ></Link>
                    <div className="flex items-center gap-4">
                      <div
                        className={`${
                          candidate.Attempt === 1
                            ? "bg-[#57DD55]/20"
                            : "bg-[#0066FF]/10"
                        } w-10 h-10 rounded-xl flex justify-center items-center`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className={`w-5 h-5 ${
                            candidate.Attempt === 1
                              ? "text-[#57CD55]"
                              : "text-[#0066FF]"
                          }`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                          />
                        </svg>
                      </div>
                      <div>
                        <div className="font-medium text-sm">
                          {candidate.Name}
                        </div>
                        <div className="text-[#aaa] text-xs font-medium mt-[-2px]">
                          {candidate.Email}
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </div>
              ))}
          </div>
        ) : (
          <div className="flex justify-center py-5 text-sm font-medium text-[#aaa]">
            No Results Found
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default SearchResults;
