import { useEffect, useRef, useState } from "react";

const useAutoClosePopups = (popupRef) => {
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    if (openPopup) {
      const handleClickOutside = (event) => {
        popupRef?.current.contains(event.target) || setOpenPopup(false);
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [openPopup, popupRef]);
  return [openPopup, setOpenPopup];
};

export default useAutoClosePopups;
