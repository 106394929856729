import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  showBack: false,
  loading: false,
  error: null,
};

export const pageTitleSlice = createSlice({
  name: "pageTitle",
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload.title;
      state.showBack = action.payload.showBack ? action.payload.showBack : false;
    },
  },
});

export const { setTitle } = pageTitleSlice.actions;
export default pageTitleSlice.reducer;
