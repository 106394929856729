import { useCallback, useEffect, useState } from "react";

const useDevToolsCheck = () => {
    const [violation, setViolation] = useState(false);
    const [closeDevTools, setCloseDevTools] = useState(null);
    const handleDevToolsChange = useCallback((e) => {
        if (e.detail.isOpen) {
            setCloseDevTools(false);
            setViolation(true);
        } else {
            setCloseDevTools(true);
            setViolation(false);
        }
    }, [])

    useEffect(() => {


        window.addEventListener("devtoolschange", handleDevToolsChange);

        return () => {
            window.removeEventListener("devtoolschange", handleDevToolsChange);
        };
    }, [handleDevToolsChange]);

    return { violation, closeDevTools };
}

export default useDevToolsCheck