import React, { useEffect, useRef, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import CandidateResume from "./CandidateResume";
import CandidateDetails from "./CandidateDetails";

const CandidateView = ({
  candidateDetails,
  applicationDetails,
  candidateProfileView,
  setCandidateProfileView,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [tab, setTab] = useState("details");

  const candidateViewRef = useRef(null);
  // const menuPopupRef = useRef(null);

  const [resumeOpen, setResumeOpen] = useState(false);

  useEffect(() => {
    if (candidateProfileView || menuOpen) {
      const handleClickOutside = (event) => {
        candidateViewRef.current.contains(event.target) ||
          setCandidateProfileView(false);
        // menuPopupRef.current.contains(event.target) || setMenuOpen(false);
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [candidateProfileView, menuOpen]);

  return (
    <div className="absolute top-0 left-0 w-full h-screen bg-black/20 backdrop-blur-[2px] flex justify-end z-50">
      <div
        ref={candidateViewRef}
        className="relative w-full md:w-[80%] lg:w-1/2 h-[100vh] bg-white shadow-md rounded-l-xl overflow-hidden p-10"
      >
        <div className="absolute top-4 right-6 flex items-center gap-8">
          {/* <div className="relative" ref={menuPopupRef}>
            <div
              className="text-2xl bg-gray-200 rounded-md px-2 flex items-center justify-center cursor-pointer"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {menuOpen ? <FaAngleDown /> : <HiDotsHorizontal />}
            </div>
            {menuOpen && (
              <></>
              // <CandidateMenu
              //   setMenuOpen={setMenuOpen}
              //   setCandidateProfileView={setCandidateProfileView}
              // />
            )}
          </div> */}
          <div
            className="text-2xl bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center cursor-pointer"
            onClick={() => setCandidateProfileView(false)}
          >
            ×
          </div>
        </div>

        {resumeOpen && (
          <div className="absolute top-0 left-0 w-full h-full z-20">
            <div
              className="absolute top-[12px] right-[12px] text-2xl bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center cursor-pointer"
              onClick={() => setResumeOpen(false)}
            >
              ×
            </div>
            <div className="w-full h-full bg-white">
              <embed
                src={applicationDetails?.resumeUrl}
                type="application/pdf"
                width="100%"
                height="100%"
                toolbar={false}
              />
            </div>
          </div>
        )}

        <div className="flex items-center gap-4">
          <div className="w-24 h-24 bg-gray-200 rounded-full flex items-center justify-center">
            <span className="text-5xl text-gray-400 font-bold capitalize">
              {candidateDetails?.name
                ?.split(" ")
                ?.slice(0, 2)
                ?.map((name) => name[0])}
            </span>
          </div>
          <div className="flex flex-col">
            <div className="font-semibold text-2xl capitalize">
              {candidateDetails?.name}
            </div>
            <div className="text-[#979797] capitalize font-medium text-lg">
              {candidateDetails?.positionTitle ||
                applicationDetails?.appliedPosition}
            </div>
            <div className="text-[#979797]">
              Applied on{" : "}
              <span>
                {new Date(
                  applicationDetails?.appliedAt?.seconds * 1000
                )?.toLocaleDateString("en-in", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </span>
            </div>
          </div>
        </div>

        <div className="flex px-5 relative h-[5%] border-b-4 mt-6 border-gray-300 border-box">
          {["details", "Resume"].map((item) => (
            <div
              key={item}
              className={`z-10 px-4 py-1 cursor-pointer font-semibold -mb-1 capitalize
              ${
                tab === item
                  ? "border-b-4 border-[#8A24F1] text-[#8A24F1]"
                  : "text-[#888]"
              }`}
              onClick={() => setTab(item)}
            >
              {item}
            </div>
          ))}
        </div>

        <div className="w-full h-full overflow-y-scroll scrollable">
          {tab === "details" && (
            <CandidateDetails candidateDetails={candidateDetails} />
          )}
          {tab === "Resume" && (
            <CandidateResume
              candidateDetails={candidateDetails}
              applicationDetails={applicationDetails}
              setResumeOpen={setResumeOpen}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateView;
