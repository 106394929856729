import { useCallback, useEffect, useState } from "react";

const useFullScreenDetection = () => {
  const [violation, setViolation] = useState(false)
  const fullScreenCheck = useCallback(() => {
    if (!document.fullscreenElement) {
      setViolation(true);
    }
    else {
      setViolation(false);
    }
  }, []);

  useEffect(() => {
    // fullScreen check for Chrome,Edge,Safari
    document.addEventListener("webkitfullscreenchange", () =>
      fullScreenCheck()
    );
    // fullscreem check for mozilla Firefox
    document.addEventListener("mozfullscreenchange", () => fullScreenCheck());

    // cleanup
    return () => {
      document.removeEventListener("webkitfullscreenchange", () =>
        fullScreenCheck()
      );
      document.removeEventListener("mozfullscreenchange", () =>
        fullScreenCheck()
      );
    };
  }, [fullScreenCheck]);

  return violation;
};

export default useFullScreenDetection;
