import React, { useEffect, useState } from "react";
import LibraryAssessment from "./LibraryAssessment";
import CustomAssessment from "./CustomAssessment";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTitle } from "../../../redux/features/pageTitleSlice";
import NewAssessmentPopup from "./NewAssessmentPopup";

const NewAssessment = () => {
  const location = useLocation();
  const urlparams = new URLSearchParams(location.search);
  const nameParam = urlparams.get("name");
  const positionParam = urlparams.get("position");
  const experienceParam = urlparams.get("experience");
  const domainParam = urlparams.get("domain");
  const typeParam = urlparams.get("type");
  const { assessmentId } = useParams();

  const [assessmentDetails, setAssessmentDetails] = useState();
  const [showNewAssessmentModule, setShowNewAssessmentModule] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setTitle({
        title: assessmentId ? "Edit Assessment" : "New Assessment",
        showBack: true,
      })
    );
  }, [dispatch, assessmentId]);

  useEffect(() => {
    setAssessmentDetails({
      name: nameParam,
      position: positionParam,
      experience: experienceParam,
      domain: domainParam,
      type: typeParam,
      editDocId: assessmentId || "",
    });
  }, [
    nameParam,
    positionParam,
    experienceParam,
    domainParam,
    typeParam,
    assessmentId,
  ]);

  return (
    <div className="main-container w-full mx-auto md:ml-20 mt-1 flex flex-col lg-px-10">
      <div className="card px-6 py-4 w-full lg:h-[90vh] overflow-hidden">
        {typeParam === "custom" ? (
          <CustomAssessment
            assessmentDetails={assessmentDetails}
            setShowNewAssessmentModule={setShowNewAssessmentModule}
          />
        ) : (
          <LibraryAssessment
            assessmentDetails={assessmentDetails}
            setShowNewAssessmentModule={setShowNewAssessmentModule}
          />
        )}
      </div>
      {showNewAssessmentModule && (
        <NewAssessmentPopup
          assessmentDetails={assessmentDetails}
          setShowNewAssessmentModule={setShowNewAssessmentModule}
        />
      )}
    </div>
  );
};

export default NewAssessment;
