import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import AccountType from "./AccountType";
import DetailInfo from "./DetailInfo";
import CompanyDetail from "./CompanyDetail";
import Done from "./Done";
import { Button } from "../../../components";
import FormSteps from "./FormSteps";
import { useDispatch } from "react-redux";
import { registerAdmin } from "../../../redux/features/adminAuthSlice";
import {
  validatePhoneNumber,
  validatePassword,
  validateEmail,
} from "../../../utils/validate";

const RightPannel = ({ step, setStep }) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const [logoUrl, setLogoUrl] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const nextStep = () => {
    step < 4 && setStep((prev) => prev + 1);
  };

  const submitStep1 = () => {
    setError("");
    const formData = formRef.current;
    if (!formData.accountType.value) return setError("Select Account Type");
    nextStep();
  };

  const submitStep2 = () => {
    setError("");
    const formData = formRef.current;
    if (
      !formData.firstName?.value ||
      !formData.lastName?.value ||
      !formData.phoneNumber?.value ||
      !formData.role?.value ||
      !formData.password?.value ||
      !formData.rePassword?.value
    ) {
      return setError("Fill All the required field");
    }

    if (!validatePhoneNumber(formData.phoneNumber?.value))
      return setError("Phone Number should be valid");
    if (!validatePassword(formData.password?.value))
      return setError(
        "Password must be at least 6 characters and must include uppercase letter, lowercase letter, number and special character"
      );
    if (formData.password?.value !== formData.rePassword?.value)
      return setError("Password should be same");

    nextStep();
  };

  const submitStep3 = async () => {
    const formData = formRef.current;
    setError("");
    if (
      !formData.companyName?.value ||
      !formData.email?.value ||
      !formData.website?.value
    )
      return setError("Fill all the required fields");

    if (!validateEmail(formData.email?.value))
      return setError("Enter a valid email");

    // website validation

    if (!formData.acceptTerms?.checked)
      return setError("Accept the term and condition");

    try {
      await createAuthWithEmailAndPass();
    } catch (error) {
      console.log(error.message);
      setError(error.message);
    } finally {
      nextStep();
    }
  };

  const createAuthWithEmailAndPass = async () => {
    setLoading(true);
    const formData = formRef.current;
    const email = formData.email?.value;
    const password = formData.password?.value;
    const name = formData.companyName?.value;
    try {
      const adminData = {
        companyEmail: email,
        companyName: name,
        companyUrl: formData.website?.value,
        contactPersonFirstName: formData.firstName.value,
        contactPersonLastName: formData.lastName.value,
        accountType: formData.accountType.value,
        employeeSize: formData.employeSize?.value,
        logoUrl: logoUrl,
        phoneNumber: formData.phoneNumber.value,
        role: formData.role.value,
      };
      await dispatch(registerAdmin({ email, password, name, data: adminData }));
    } catch (error) {
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const loginCandidate = () => {
    setError("");
    navigate("/admin");
  };

  return (
    <div className="w-[60%] h-full p-10">
      <div className="w-full text-sm font-medium flex justify-end pr-8">
        Already Have an account ?
        <Link to="/login" className="text-[#892bcc] underline ml-2 ">
          Login
        </Link>
      </div>
      <div className="w-full h-full flex flex-col items-center overflow-y-scroll scrollable">
        <FormSteps step={step} />
        <form ref={formRef} className="w-2/3 flex flex-col gap-6">
          <div className={step !== 1 ? "hidden" : ""}>
            <AccountType />
          </div>
          <div className={step !== 2 ? "hidden" : ""}>
            <DetailInfo />
          </div>
          <div className={step !== 3 ? "hidden" : ""}>
            <CompanyDetail logoUrl={logoUrl} setLogoUrl={setLogoUrl} />
          </div>
          <div className={step !== 4 ? "hidden" : ""}>
            <Done />
          </div>

          <div className="space-y-2">
            <div className="text-xs text-red-500 min-h-3">{error}</div>
            <div
              className={`w-full flex gap-3 ${
                step === 4 ? "justify-center" : ""
              }`}
            >
              {(step === 2 || step === 3) && (
                <Button
                  handleClick={() =>
                    setStep((prev) => (prev > 1 ? prev - 1 : prev))
                  }
                  text="Back"
                  version="outline-primary"
                  width="10rem"
                />
              )}
              <Button
                handleClick={
                  step === 1
                    ? submitStep1
                    : step === 2
                    ? submitStep2
                    : step === 3
                    ? submitStep3
                    : loginCandidate
                }
                text={step === 3 ? "Submit" : step === 4 ? "Login" : "Next"}
                width="10rem"
                loading={loading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RightPannel;
