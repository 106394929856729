import React, { useEffect, useRef, useState } from "react";
// import axios from "axios";
import Editor from "@monaco-editor/react";
import { defineTheme } from "../../constant/defineTheme";
// import { languageOptions } from '../../constant/LanguageOptions';
// import parse from "html-react-parser";
import {
  Button,
  RadioInput,
  // SelectInput,
  TestCasesResult,
} from "../../components";
import envConfig from "../../config/envConfig";
import axios from "axios";
import { toast } from "react-toastify";

const ExamOptionsPanel = ({
  handleNext,
  questions,
  qusNum,
  alertSkip,
  loading,
}) => {
  const [question, setQuestion] = useState();
  const [subjectiveAnswer, setSubjectiveAnswer] = useState("");
  const [lastQuestion, setLastQuestion] = useState(false);

  const [language, setLanguage] = useState();
  const [theme, setTheme] = useState("cobalt");
  const [runRemaining, setRunRemaining] = useState();
  const [testCases, setTestCases] = useState();
  const [defaultCode, setDefaultCode] = useState();
  const [expectedOutput, setExpectedOutput] = useState();
  const [code, setCode] = useState("");

  const options = ["A", "B", "C", "D"];

  useEffect(() => {
    defineTheme("night-owl").then((_) =>
      setTheme({ value: "night-owl", label: "Night Owl" })
    );
  }, []);

  useEffect(() => {
    setQuestion(questions[qusNum]);
  }, [questions, qusNum]);

  useEffect(() => setRunRemaining(3), [question]);

  useEffect(() => {
    if (question && question.type === "code") {
      if (question?.language !== 0) {
        setLanguage(question?.language);
      }
      setTestCases(question?.testCases.map((testCase) => testCase.trim()));
      setDefaultCode(question?.defaultCode);
      setExpectedOutput(
        question?.expectedOutput?.map((output) => output.trim())
      );
    }
  }, [question, qusNum]);

  const formRef = useRef();
  const subjectiveAnswerRef = useRef();
  useEffect(() => {
    if (question && question.type === "subjective") {
      subjectiveAnswerRef.current.focus();
    }
  }, [question]);

  const [runProcessing, setRunProcessing] = useState(false);
  const [submitProcessing, setSubmitProcessing] = useState(false);
  const [runResultModule, setRunResultModule] = useState(false);

  const runCode = () => {
    setRunRemaining((prev) => prev - 1);
    compileCode("run");
  };

  const submitAnswer = (e) => {
    e.preventDefault();
    const form = formRef.current;

    let userAnswer =
      question?.type === "code"
        ? code
        : question?.type === "subjective"
        ? form[`q${qusNum + 1}`]?.value
        : form[`q${qusNum + 1}option`]?.value;
    if (userAnswer === "") {
      alertSkip();
    } else {
      if (question?.type === "code") {
        compileCode("submit");
      } else {
        handleNext({ userAnswer });
        formRef.current.reset();
      }
    }
  };

  const compileCode = async (type) => {
    try {
      // Set processing state
      type === "run" ? setRunProcessing(true) : setSubmitProcessing(true);

      // Prepare submissions data
      const submissions = testCases
        ? testCases.map((test, index) => ({
            language_id: language.id,
            source_code: btoa(code),
            stdin: btoa(test),
            expected_output: btoa(expectedOutput[index]),
          }))
        : {
            language_id: language.id,
            source_code: btoa(code),
            expected_output: btoa(expectedOutput[0]),
          };

      // Request configuration
      const options = {
        method: "POST",
        url: envConfig.rapidApiUrlBatch,
        params: { base64_encoded: "true", fields: "*" },
        headers: {
          "content-type": "application/json",
          "Content-Type": "application/json",
          "X-RapidAPI-Host": envConfig.rapidApiHost,
          "X-RapidAPI-Key": envConfig.rapidApiKey,
        },
        data: { submissions },
      };

      // Make the API request
      const response = await axios.request(options);
      const tokens = response.data.map((data) => data.token);
      await checkStatus(tokens, type);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const status = error.response?.status;
        const errorData = error.response?.data;
        switch (status) {
          case 429:
            console.error("Rate limit exceeded:", error);
            break;
          case 401:
          case 403:
            console.error("Authentication error:", error);
            break;
          case 400:
            console.error("Invalid request:", errorData);
            break;
          case 404:
            console.error("Resource not found:", error);
            break;
          case 500:
          case 502:
          case 503:
          case 504:
            console.error("Server error:", error);
            break;
          default:
            console.error("Network error:", error);
        }
        toast.error("An unexpected error occurred.");
      } else {
        console.error("An unexpected error occurred:", error);
        toast.error(error.message || "An unexpected error occurred.");
      }
      type === "run" ? setRunProcessing(false) : setSubmitProcessing(false);

      // Handle submission errors
      if (type === "submit") {
        handleNext({ error });
      }
    }
  };

  const checkStatus = async (tokens, type) => {
    const tokenString = tokens?.join(",");
    const options = {
      method: "GET",
      url: `${envConfig.rapidApiUrlBatch}?tokens=${tokenString}`,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Host": envConfig.rapidApiHost,
        "X-RapidAPI-Key": envConfig.rapidApiKey,
      },
    };
    try {
      let response = await axios.request(options);

      let output = response.data.submissions;
      let statusIds = output?.map((data) => data.status.id);

      // Processed - we have a result
      if (statusIds.includes(1) || statusIds.includes(2)) {
        // still processing
        setTimeout(() => {
          checkStatus(tokens, type);
        }, 2000);
        return;
      } else {
        //displaying results for running code
        type === "run" && showRunResult(output);

        //saving output data from api response
        let testCasesResult = statusIds.map((id) =>
          id === 3 ? "pass" : "fail"
        );
        let evaulate = testCasesResult.includes("fail")
          ? "incorrect"
          : "correct";
        console.log(evaulate);
        type === "submit" &&
          handleNext({
            userAnswer: code,
            codeOutput: output,
            codeEvaluate: evaulate,
          });
      }
    } catch (err) {
      console.log("error in request check status - ", err);
      type === "submit" && handleNext({ error: err });
    } finally {
      type === "run" ? setRunProcessing(false) : setSubmitProcessing(false);
      formRef.current.reset();
    }
  };

  const [outputDetails, setOutputDetails] = useState();
  const showRunResult = (output) => {
    setOutputDetails(output);
    setRunResultModule(true);
  };

  const [closeTestCases, setCloseTestCases] = useState(0);

  return (
    <div className="w-[50%] px-8 h-full flex flex-col justify-between">
      {
        <div
          className={`${
            runResultModule ? "flex" : "hidden"
          } absolute w-full h-full left-0 top-0 items-center justify-center backdrop-blur-sm z-10`}
        >
          <div className="relative shadow-lg border-gray-200 border-2 card w-1/2 p-6">
            <div
              onClick={() => {
                setRunResultModule(false);
                setCloseTestCases((prev) => prev + 1);
              }}
              className="flex justify-center items-center absolute top-4 right-4 rounded-full w-6 h-6 bg-[#F9FAFB] cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
              </svg>
            </div>
            <TestCasesResult
              outputDetails={outputDetails}
              closeTestCases={closeTestCases}
            />
          </div>
        </div>
      }
      {loading ? (
        <div className="w-full h-full flex justify-center items-center">
          <p className="font-bold text-2xl text-[#bbb]">Loading...</p>
        </div>
      ) : (
        <>
          <div>
            <div className="font-medium text-[#333] mb-3">
              {question?.type === "subjective" ? (
                "Your Answer - "
              ) : question?.type === "code" ? (
                <p>Your Code Here - </p>
              ) : (
                <div className="flex items-center justify-between">
                  <span>Select any one -</span>
                  <span
                    className="text-xs cursor-pointer border-[1px] border-gray-300 bg-gray-200 rounded-md px-2 py-1"
                    onClick={() => formRef.current.reset()}
                  >
                    ✕ Clear
                  </span>
                </div>
              )}
            </div>
            <div className="w-full overflow-y-auto scrollbar-none mb-2 h-full">
              <form ref={formRef} className="h-full flex flex-col gap-4">
                {question?.type === "subjective" ? (
                  <div className="flex flex-col gap-5">
                    <textarea
                      id="subjectiveAnswer"
                      ref="subjectiveAnswerRef"
                      className="border-[3px] border-[#ECECEC] rounded-[10px] bg-gray-50 focus:ring-0"
                      rows="14"
                      name={`q${qusNum + 1}`}
                      placeholder="Enter your answer here ..."
                      value={subjectiveAnswer}
                      onChange={(e) => setSubjectiveAnswer(e.target.value)}
                    />
                  </div>
                ) : question?.type === "code" ? (
                  <>
                    <div className="h-full">
                      {/* {question?.language === 0 && (
                        <>
                          <span>Select a programming language -</span>
                          <SelectInput
                            placeholderText={`Select programming language`}
                            selectOptions={languageOptions}
                            handleChange={(selectedOption) =>
                              setLanguage(selectedOption)
                            }
                            isSearchable={true}
                          />
                        </>
                      )} */}

                      <div className="h-full">
                        <div className="h-[450px] rounded-xl bg-[#011627] overflow-hidden">
                          <Editor
                            className="pt-3"
                            language={language?.value}
                            value={code}
                            theme={theme.value}
                            defaultValue={defaultCode}
                            onChange={(value) => setCode(value)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col gap-5">
                    {question?.options?.map((option, index) => (
                      <div key={index}>
                        <RadioInput
                          inputName={`q${qusNum + 1}option`}
                          inputValue={index}
                          iniputId={`q${qusNum + 1}${options[index]}`}
                          assessmentInput={true}
                          // text={parse(option)}
                          text={option}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </form>
            </div>
          </div>

          <div className="flex justify-between">
            <div>
              {question?.type === "code" && (
                <Button
                  text=""
                  version="green"
                  width="6rem"
                  handleClick={runCode}
                  isDisabled={runRemaining < 1}
                  loading={runProcessing}
                >
                  <p className="flex gap-1 items-center">
                    Run
                    {runRemaining > 0 && (
                      <span className="text-xs">({runRemaining})</span>
                    )}
                  </p>
                </Button>
              )}
            </div>

            <Button
              text={
                qusNum < questions.length - 1 ? "Save & Next" : "Submit Test"
              }
              inputId="next"
              handleClick={submitAnswer}
              loading={submitProcessing}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ExamOptionsPanel;
