import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../../firebase/authService";
import dbService from "../../firebase/dbService";
import { Timestamp } from "firebase/firestore";

export const registerAdmin = createAsyncThunk(
  "adminAuth/registerAdmin",
  async ({ email, password, name, data }, { rejectWithValue }) => {
    try {
      const user = await authService.createAccount({
        email,
        password,
        name,
      });
      const adminData = {
        ...data,
        createdAt: Timestamp.now(),
        modifiedAt: Timestamp.now(),
        uid: user.uid,
      };
      await dbService.createDocument("adminProfile", adminData);
      let userAuth = await authService.getCurrentUser();
      return {
        uid: userAuth.uid,
        email: userAuth.email,
        companyName: userAuth?.companyName || "",
        companyEmail: userAuth?.email || "",
        companyUrl: data?.companyUrl || "",
        accountType: data?.accountType || "",
        logoUrl: data?.logoUrl || "",
        phoneNumber: data?.phoneNumber || "",
        employeeSize: data?.employeeSize || "",
        contactPersonFirstName: data?.contactPersonFirstName || "",
        contactPersonLastName: data?.contactPersonLastName || "",
        role: data?.role || "",
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const loginAdmin = createAsyncThunk(
  "adminAuth/loginAdmin",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      let userAuth = await authService.getCurrentUser();

      if (!userAuth) {
        try {
          userAuth = await authService.login({ email, password });
        } catch (error) {
          throw new Error(error.message);
        }
      }

      let userDetails;

      // Fetch user details from Firestore
      userDetails = await dbService
        .getAuthDetails("adminProfile", userAuth.uid)
        .catch(() => {
          throw new Error("User not found");
        });

      if (!userDetails) {
        throw new Error("User not found");
      }

      return {
        uid: userAuth.uid,
        email: userAuth.email,
        companyName: userDetails?.companyName || "",
        companyEmail: userDetails?.companyEmail || "",
        companyUrl: userDetails?.companyUrl || "",
        accountType: userDetails?.accountType || "",
        logoUrl: userDetails?.logoUrl || "",
        phoneNumber: userDetails?.phoneNumber || "",
        employeeSize: userDetails?.employeeSize || "",
        contactPersonFirstName: userDetails?.contactPersonFirstName || "",
        contactPersonLastName: userDetails?.contactPersonLastName || "",
        role: userDetails?.role || "",
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const logoutAdmin = createAsyncThunk(
  "adminAuth/logoutAdmin",
  async (_, { rejectWithValue }) => {
    try {
      await authService.logout();
      return null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  adminDetails: null,
  isAuthenticated: false,
  loading: false,
  error: null,
};

export const adminAuthSlice = createSlice({
  name: "adminAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.adminDetails = action.payload;
        state.error = null;
      })
      .addCase(registerAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loginAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginAdmin.fulfilled, (state, action) => {
        state.adminDetails = action.payload;
        state.isAuthenticated = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(loginAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(logoutAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutAdmin.fulfilled, (state) => {
        state.adminDetails = null;
        state.isAuthenticated = false;
        state.loading = false;
        state.error = null;
      })
      .addCase(logoutAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default adminAuthSlice.reducer;
