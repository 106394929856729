import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../config/firebase";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import ProfileMenu from "./ProfileMenu";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { Logo } from "../../components";

const CandidateDashboard = () => {
  const navigate = useNavigate();

  const [user] = useAuthState(auth);
  const [userDetails, setUserDetails] = useState();

  useEffect(() => {
    if (!user) {
      // navigate("/candidate/login");
    } else {
      //fetch user details
      let q = query(
        collection(db, "candidateProfile"),
        where("uid", "==", auth?.currentUser?.uid)
      );

      onSnapshot(q, (querySnapshot) => {
        const users = [];
        querySnapshot.forEach((doc) => {
          users.push({ id: doc.id, ...doc.data() });
        });
        setUserDetails(users[0]);
      });
    }
  }, [user, navigate]);

  const [candidateApplications, setCandidateApplications] = useState();

  useEffect(() => {
    const fetchCandidateApplications = () => {
      const userRef = doc(db, "candidateProfile", userDetails?.id);
      const appliedJobsQuery = query(
        collection(db, "candidateApplications"),
        where("candidateProfileRef", "==", userRef)
      );

      const querySnapshot = onSnapshot(appliedJobsQuery, (querySnapshot) => {
        const applications = [];
        querySnapshot.forEach((appDoc) => {
          applications.push({
            id: appDoc.id,
            ...appDoc.data(),
          });
        });
        setCandidateApplications(applications);
      });
      return querySnapshot;
    };

    if (userDetails?.id) fetchCandidateApplications();
  }, [userDetails?.id]);

  const [emailVerified, setEmailVerified] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setEmailVerified(user?.emailVerified);
    });
  }, []);

  // const tabs = ["dashboard", "my jobs", "all posted jobs"];
  const tabs = [
    { name: "dashboard", label: "Dashboard" },
    { name: "myJobs", label: "My Jobs" },
    { name: "jobs", label: "All Jobs" },
  ];
  // const [selectedTab, setSelectedTab] = useState();
  // useEffect(() => {
  //   setSelectedTab(tabParam ? tabParam : tabs[0]);
  // }, [tabParam]);

  return (
    <div className="w-full h-full p-3 bg-gray-200 space-y-3 overflow-y-scroll scrollable">
      <div className="fixed card w-[98%] mx-auto h-[10%] flex justify-between items-center p-2 px-6 z-20">
        <div>
          <Logo width="10rem" />
        </div>
        <div className="flex gap-2">
          {tabs?.map((tab) => (
            <NavLink
              key={tab.name}
              to={tab.name}
              className={({ isActive }) =>
                `capitalize text-sm font-medium rounded-full px-8 py-2 cursor-pointer ${
                  isActive
                    ? "bg-[#8A24F1] text-white"
                    : "text-[#666] bg-[#F8F8F8]"
                } `
              }
            >
              <span className="capitalize text-sm font-semibold">
                {tab.label}
              </span>
            </NavLink>
          ))}
        </div>
        <ProfileMenu />
      </div>
      <div className="h-[68px]"></div>
      <div className="card w-full h-[88%]">
        {!emailVerified ? (
          <div className="flex flex-col gap-5 items-center justify-center h-full w-full">
            <h1 className="text-xl font-semibold text-[#f95]">
              Email Id not verified !
            </h1>
            <h1 className="text-3xl font-semibold">Please verify your email</h1>
            <p className="text-center">
              An email verification link has been sent to your email address.{" "}
              <br />
              <span className="text-blue-500 font-medium ml-2">
                {auth?.currentUser?.email}
              </span>
            </p>
            <p className="text-center flex flex-col gap-2">
              <span>Please try to reload, If you have already verified.</span>
              <span
                className="font-semibold text-blue-500 cursor-pointer"
                onClick={() => window.location.reload()}
              >
                ↻ Reload
              </span>
            </p>
          </div>
        ) : (
          <>
            <Outlet context={{ userDetails, candidateApplications }} />
            {/* {selectedTab === "dashboard" ? (
              <DashboardPage />
            ) : (
              <Jobs
                userDetails={userDetails}
                selectedTab={selectedTab}
                candidateApplications={candidateApplications}
              />
            )} */}
          </>
        )}
      </div>
    </div>
  );
};

export default CandidateDashboard;
