const MicRight = () => {
  return (
    <div className="flex flex-col items-start justify-between h-full w-[80%] mx-auto">
      <div className="flex flex-col">
        <h1 className="heading1 mb-10">
          Microphone Mandatory while giving assessment test
        </h1>
        <ul className="gap-4 text-[#919397] listDisc">
          <li>Always keep your microphone ON.</li>
          <li>
            Please do not mute or disconnect the microphone during the
            assessment
          </li>
          <li>Please make sure that no background noise is detected</li>
        </ul>
      </div>
    </div>
  );
};

export default MicRight;
