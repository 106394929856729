import { useEffect, useRef, useState } from "react";
import { TextInput } from "../../components";
import { IoSend } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import dbService from "../../firebase/dbService";
import { Timestamp } from "firebase/firestore";
import { useSelector } from "react-redux";
import displayTime from "../../utils/helperFunctions/displayTime";

const Comments = ({ applicationDetails, round }) => {
  const [addComment, setAddComment] = useState(false);
  const [commentExpanded, setCommentExpanded] = useState(false);
  const [comment, setComment] = useState("");
  const [commentedBy, setCommentedBy] = useState("");
  const [comments, setComments] = useState([]);
  const [filteredCommnets, setFilteredCommnets] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const editFormRef = useRef(null);
  const addCommentInputRef = useRef(null);
  const editCommnetInputRef = useRef(null);
  const { adminDetails } = useSelector((state) => state.adminAuth);

  useEffect(() => {
    (addComment || !comments.length > 0) && addCommentInputRef.current.focus();
    editComment !== false && editCommnetInputRef.current.focus();
  }, [addComment, comments, editComment]);

  useEffect(() => {
    setComments(applicationDetails.comments || []);
    setFilteredCommnets(
      applicationDetails.comments?.filter(
        (comment) => comment.round === round
      ) || []
    );
  }, [applicationDetails, round]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const commentData = {
      id: Date.now(),
      commentText: comment,
      round: round,
      commentedBy: commentedBy,
      admin: adminDetails?.companyName,
      createdAt: Timestamp.fromDate(new Date()),
    };
    try {
      await dbService.updateDocument(
        "candidateApplications",
        { comments: [...comments, commentData] },
        applicationDetails?.id
      );
    } catch (error) {
      console.error(error);
    } finally {
      setComment("");
      setCommentedBy("");
      setAddComment(false);
    }
  };

  const handleEdit = async (e, comment) => {
    e.preventDefault();
    const formData = editFormRef.current;
    const editedCommentData = {
      ...comment,
      commentText: formData?.editCommentText?.value,
      commentedBy: formData?.editCommentedBy?.value,
      updatedAt: Timestamp.fromDate(new Date()),
    };

    try {
      const updatedComments = comments;
      const index = updatedComments.findIndex((cmnt) => cmnt.id === comment.id);
      updatedComments[index] = { ...editedCommentData };
      await dbService.updateDocument(
        "candidateApplications",
        { comments: updatedComments },
        applicationDetails?.id
      );
    } catch (error) {
      console.error(error);
    } finally {
      setEditComment(false);
    }
  };

  const deleteComment = async (e, commentId) => {
    e.stopPropagation();
    const newCommensts = comments.filter((c) => c.id !== commentId);
    try {
      await dbService.updateDocument(
        "candidateApplications",
        { comments: newCommensts },
        applicationDetails?.id
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="py-4 px-5 w-full lg:w-[600px]">
      <h1 className="heading2">
        {comments?.length ? "Comments" : "Add Comment"}
      </h1>
      {filteredCommnets.length > 0 && (
        <div className="mt-2 border-[1px] border-gray-300 rounded-2xl p-2">
          {filteredCommnets?.map((comment, index) => (
            <div
              key={index}
              className={`border-b-2 last:border-b-0 ${
                editComment === index ? "relative" : "cursor-pointer px-2"
              }`}
              onMouseOver={() => setShowOptions(index)}
              onMouseOut={() => setShowOptions(false)}
              onClick={() =>
                editComment !== index &&
                setCommentExpanded((prev) => (prev === index ? false : index))
              }
            >
              {editComment === index ? (
                <form
                  onSubmit={(e) => handleEdit(e, comment)}
                  ref={editFormRef}
                  className="relative"
                >
                  {comments?.length > 0 && (
                    <RxCross2
                      className="absolute top-2 right-2 w-5 h-5 bg-gray-300 text-gray-500 p-[2px] rounded-full cursor-pointer z-10"
                      onClick={() => setEditComment(false)}
                    />
                  )}
                  <TextInput
                    type="textbox"
                    inputName="editCommentText"
                    placeholderText="Write your Commnet here..."
                    inputRef={editCommnetInputRef}
                    defaultValue={comment?.commentText}
                  />
                  <div className="flex items-center gap-2">
                    <TextInput
                      type="text"
                      inputName="editCommentedBy"
                      placeholderText="Commented By"
                      defaultValue={comment?.commentedBy}
                    />
                    <div className="flex gap-2 px-2">
                      <button type="submit">
                        <IoSend className="w-6 h-6 text-blue-600" />
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <>
                  <p className="text-sm mt-1">
                    {comment.commentText.length < 100 ||
                    commentExpanded === index
                      ? comment.commentText
                      : `${comment.commentText.slice(0, 80)} . . .`}{" "}
                    {comment?.updatedAt && (
                      <span className="text-xs text-gray-500">(edited)</span>
                    )}
                  </p>
                  <div className="flex items-center justify-between my-1">
                    <div className="text-xs font-medium text-gray-500 flex items-center">
                      <span className="font-medium capitalize">
                        {comment.commentedBy}
                      </span>
                      <span className="border-l-2 border-gray-400 ml-2 pl-2">
                        {displayTime({ dateTime: comment.createdAt })}
                      </span>
                    </div>
                    {showOptions === index && (
                      <div className="text-xs font-medium text-gray-500 flex items-center gap-3">
                        <div
                          className="text-blue-500"
                          onClick={() => {
                            setEditComment(index);
                            setAddComment(false);
                          }}
                        >
                          Edit
                        </div>
                        <div
                          className="text-red-500"
                          onClick={(e) => deleteComment(e, comment?.id)}
                        >
                          Delete
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      )}
      {!addComment && comments?.length ? (
        <div className="mt-2">
          <span
            className="font-medium text-blue-500 hover:underline cursor-pointer"
            onClick={() => {
              setAddComment(true);
              setEditComment(false);
              setCommentExpanded(false);
            }}
          >
            + Add Comment
          </span>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="border-[1px] border-gray-300 rounded-2xl p-2 mt-2 relative"
        >
          {comments?.length > 0 && (
            <RxCross2
              className="absolute top-2 right-2 w-5 h-5 bg-gray-300 text-gray-500 p-[2px] rounded-full cursor-pointer z-10"
              onClick={() => setAddComment(false)}
            />
          )}
          <TextInput
            type="textbox"
            placeholderText="Write your Commnet here..."
            inputValue={comment}
            inputRef={addCommentInputRef}
            handleChange={(e) => setComment(e.target.value)}
          />
          <div className="flex items-center gap-2">
            <TextInput
              type="text"
              placeholderText="Commented By"
              inputValue={commentedBy}
              handleChange={(e) => setCommentedBy(e.target.value)}
            />
            <div className="flex gap-2 px-2">
              <button type="submit" disabled={!comment || !commentedBy}>
                <IoSend
                  className={`w-6 h-6 ${
                    comment && commentedBy ? "text-blue-600" : "text-gray-400"
                  }`}
                />
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Comments;
