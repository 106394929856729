import React from "react";

import { Button } from "..";

const ConfirmationModule = ({
  confirmationMessage,
  confirmationButton,
  cancelAlert,
  confirmSkip,
  confirmSubmit,
  submitLoading,
}) => {
  return (
    <div className="flex flex-col justify-center items-center p-12">
      <p className="font-semibold text-xl mb-5">{confirmationMessage[0]}</p>
      <p className="text-sm text-[#919397]">{confirmationMessage[1]}</p>

      <div className="flex mt-8 gap-5">
        <Button
          text={confirmationButton[0]}
          idName="confirmSkip"
          version="secondary"
          loading={submitLoading}
          handleClick={
            confirmationButton[0] === "Skip Question"
              ? confirmSkip
              : confirmSubmit
          }
        />
        <Button text={confirmationButton[1]} handleClick={cancelAlert} />
      </div>
    </div>
  );
};

export default ConfirmationModule;
