import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Webcam from "react-webcam";
import { BsCameraVideoOff } from "react-icons/bs";
// import {
//   BROWSER_CHROME,
//   BROWSER_EDGE,
//   BROWSER_SAFARI,
//   BROWSER_FIREFOX,
//   BROWSER_OTHER,
// } from "../../constant/Constants";
import { Button } from "../../components";

const CameraLeft = ({ hasCameraPermission, sethasCameraPermission }) => {
  const [browser, setBrowser] = useState();
  useEffect(() => {
    let userAgent = navigator.userAgent;
    // console.log(JSON.stringify(userAgent))
    if (userAgent.match(/chrome|chromium|crios/i)) {
      setBrowser("chrome");
    } else if (userAgent.match(/firefox|fxios/i)) {
      setBrowser("firefox");
    } else if (userAgent.match(/safari/i)) {
      setBrowser("safari");
    } else if (userAgent.match(/edg/i)) {
      setBrowser("edge");
    } else {
      setBrowser("other");
    }
  }, []);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        sethasCameraPermission(true);
      })
      .catch((err) => {
        console.log(err);
        sethasCameraPermission(false);
      });
  }, [sethasCameraPermission]);

  return (
    <div className="flex flex-col justify-center items-center">
      <motion.div
        className="z-10"
        transition={{ ease: "linear", duration: 0.5 }}
        variants={{
          initial: { scale: 2 },
          final: { scale: 1 },
        }}
        animate={
          hasCameraPermission === true || hasCameraPermission === false
            ? "final"
            : "initial"
        }
      >
        <img src="/img/camera.png" alt="camera" width="120px" />
      </motion.div>
      {hasCameraPermission ? (
        <motion.div
          transition={{ ease: "linear", duration: 0.5 }}
          variants={{
            initial: { scale: 0, y: 0 },
            final: { scale: 1, y: -30 },
          }}
          animate={hasCameraPermission === true ? "final" : "initial"}
        >
          <Webcam width="400" className="rounded-3xl" audio={false} />
        </motion.div>
      ) : hasCameraPermission === false ? (
        <>
          {browser === "safari" ? (
            <motion.div
              className="small-card w-full py-5 px-12 flex flex-col gap-2 items-center"
              transition={{ ease: "linear", duration: 0.5 }}
              variants={{
                initial: { scale: 0, y: 0 },
                final: { scale: 1, y: -20 },
              }}
              animate={hasCameraPermission === false ? "final" : "initial"}
            >
              <h1 className="text-xl font-semibold text-center">
                Camera access is blocked
              </h1>
              <p className="text-lg text-[#666] font-medium text-center">
                App requires access to your camera.
              </p>
              <ul className="list-disc ml-8 flex flex-col gap-1 mb-2">
                <li>
                  Go to the safari browser's settings ( by clicking on "safari"
                  in top bar )
                </li>
                <li>
                  Give camara and microphone permission from safari settings
                  dialog{" "}
                </li>
                <li>and then Retry</li>
              </ul>
              <Button
                text="Retry"
                width="6rem"
                handleClick={() => window.location.reload()}
              />
            </motion.div>
          ) : (
            <motion.div
              className="small-card w-full py-5 px-12 flex flex-col gap-2 items-center"
              transition={{ ease: "linear", duration: 0.5 }}
              variants={{
                initial: { scale: 0, y: 0 },
                final: { scale: 1, y: -20 },
              }}
              animate={hasCameraPermission === false ? "final" : "initial"}
            >
              <h1 className="text-xl font-semibold text-center">
                Camera access is blocked
              </h1>
              <p className="text-lg text-[#666] font-medium text-center">
                App requires access to your camera.
              </p>
              <ul className="list-disc ml-8 flex flex-col gap-1 mb-2">
                <li>
                  <span className="flex items-center">
                    Click the camerea blocked icon
                    <BsCameraVideoOff className="mx-2 text-red-400 w-5 h-5" />
                  </span>
                  <span>in your browser's address bar</span>
                </li>
                <li>Select always allow from the popup</li>
                <li>and then Retry</li>
              </ul>
              <Button
                text="Retry"
                width="6rem"
                handleClick={() => window.location.reload()}
              />
            </motion.div>
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default CameraLeft;
