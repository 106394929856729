import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, query, where, onSnapshot } from "firebase/firestore";

import { auth, db } from "../../config/firebase";
import SideNavigation from "./SideNavigation";
import CandidateDetails from "./CandidateDetails";
import Skills from "./Skills";
import EducationDetails from "./EducationDetails";
import ExperienceDetails from "./ExperienceDetails";
import Projects from "./Projects";

import { useNavigate } from "react-router-dom";
import ProfessionalDetails from "./ProfessionalDetails";
// import { ToastContainer } from "react-toastify";

// import { LinkedIn } from "react-linkedin-login-oauth2";
// import {
//   LINKEDIN_CLIENT_ID,
//   REDIRECT_URI,
//   SCOPE,
//   constgetLinkedInUserInfoFromAccessCode,
// } from "../../constant/Constants";
// import ScheduleTestCandidate from './__ScheduleTestCandidate'
// import InputMask from "react-input-mask";

const CandidateDetailsForm = () => {
  const [userAuth] = useAuthState(auth);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [userDb, setUserDb] = useState({});
  const stepsName = [
    "personal",
    "professional",
    "education",
    "experience",
    "skills",
    "projects",
  ];

  useEffect(() => {
    if (!userAuth) {
      navigate("/candidateLogin");
    }
  }, [userAuth, navigate]);

  useEffect(() => {
    const fetchUser = async () => {
      if (userAuth) {
        const userRef = query(
          collection(db, "candidateProfile"),
          where("uid", "==", auth?.currentUser?.uid)
        );

        onSnapshot(userRef, (querySnapshot) => {
          const jobData = [];
          querySnapshot.forEach((doc) => {
            jobData.push({ ...doc.data(), id: querySnapshot.docs[0].id });
          });

          setUserDb(...jobData);
        });
      }
    };
    fetchUser();
  }, [userAuth]);

  // console.log(userDb)
  return (
    <div className="w-full h-full flex flex-col lg:flex-row justify-center gap-4 bg-gray-200">
      <div className="hidden lg:flex h-full card w-1/4 p-12">
        <SideNavigation step={step} setStep={setStep} stepsName={stepsName} />
      </div>

      <div className="card w-full lg:w-3/4 h-full lg:h-[85vh] py-5 px-4 md:px-10 flex flex-col justify-between">
        <div className="flex flex-col gap-6 overflow-y-scroll scrollable pr-4">
          <h1 className="heading1">{stepsName[step - 1]}</h1>

          {step === 1 && <CandidateDetails setStep={setStep} userDb={userDb} />}
          {step === 2 && (
            <ProfessionalDetails setStep={setStep} userDb={userDb} />
          )}
          {step === 3 && <EducationDetails setStep={setStep} userDb={userDb} />}
          {step === 4 && (
            <ExperienceDetails setStep={setStep} userDb={userDb} />
          )}
          {step === 5 && <Skills setStep={setStep} userDb={userDb} />}
          {step === 6 && <Projects setStep={setStep} userDb={userDb} />}
        </div>
      </div>
    </div>
  );
};

export default CandidateDetailsForm;
