// this email is sent to candidate when he cleared the final round of assessments


const emailTemplate = ({ candidateName, jobTitle, companyName }) => {
    //content to be changed
    const subject = `Congratulations! You've Cleared the assessment rounds`
    const body = `
    Hello <b>${candidateName}</b>,<br>

    We're thrilled to inform you that you've cleard all the assessment rounds for the <b>${jobTitle}</b> position at <b>${companyName}</b>! Congratulations!<br><br>

    You will soon recive email for the further proccess from the Hiring Team from ${companyName}.
    <br><br>

    <b>Please follow our LinkedIn page for Future Job Updates</b> <br>
    LinkedIn - <a href='https://www.linkedin.com/company/talentigo/'>Linkedin-Talentigo</a> <br><br>
        
    Best regards,<br>
    <b>${companyName}</b>
    `
    return { subject, body }
}

export default emailTemplate