import {
  collection,
  doc,
  documentId,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../config/firebase";
import JobList from "./JobList";
import JobView from "./JobView";
import { useOutletContext } from "react-router-dom";

const Jobs = ({ tab }) => {
  const [jobs, setJobs] = useState([]);
  const [jobLoading, setJobLoading] = useState();
  const [openJob, setOpenJob] = useState(null);
  const [openJobDetails, setOpenJobDetails] = useState();
  const [myJobsTab, setMyJobsTab] = useState("applied");

  const { userDetails, candidateApplications } = useOutletContext();

  useEffect(() => {
    const fetchJobData = () => {
      setJobLoading(true);
      let q = query(
        collection(db, "jobsPosted"),
        where("isDeleted", "==", false)
        // orderBy("jobPostedDate", "desc")
      );

      if (tab === "myJobs" && myJobsTab === "applied") {
        let jobIds;
        if (candidateApplications?.length) {
          jobIds = candidateApplications?.map((item) => item?.appliedJobId);
        } else {
          jobIds = ["id"];
        }

        q = query(q, where(documentId(), "in", jobIds));
      } else if (tab === "myJobs" && myJobsTab === "favourite") {
        const favJobsId = userDetails?.favJobs?.length
          ? userDetails?.favJobs
          : ["id"];
        q = query(q, where(documentId(), "in", favJobsId));
      } else {
        q = query(q, where("jobStatus", "==", "in-progress"));
      }

      const querySnapshot = onSnapshot(q, (querySnapshot) => {
        const jobData = [];
        querySnapshot.forEach((doc) => {
          jobData.push({ id: doc.id, ...doc.data() });
        });
        setJobs(jobData);
      });
      setJobLoading(false);
      return querySnapshot;
    };

    if (candidateApplications && tab) fetchJobData();
  }, [candidateApplications, tab, myJobsTab, userDetails]);

  useEffect(() => {
    if (openJob) {
      const openedJob = jobs?.filter((item) => item?.id === openJob);
      setOpenJobDetails(openedJob && openedJob[0]);
    }
  }, [openJob, jobs]);

  useEffect(() => {
    setOpenJobDetails(jobs && jobs[0]);
  }, [tab, jobs]);

  const handleFav = async (e, jobId) => {
    e.stopPropagation();
    const candidateRef = doc(db, "candidateProfile", userDetails?.id);

    if (userDetails?.favJobs) {
      if (userDetails?.favJobs?.includes(jobId)) {
        const newFavJobs = userDetails?.favJobs?.filter(
          (item) => item !== jobId
        );
        await updateDoc(candidateRef, {
          favJobs: newFavJobs,
        });
      } else {
        await updateDoc(candidateRef, {
          favJobs: [...userDetails?.favJobs, jobId],
        });
      }
    } else {
      await updateDoc(candidateRef, {
        favJobs: [jobId],
      });
    }
  };

  return (
    <div className="w-full h-[95%] p-5 px-8 space-y-6">
      {tab === "myJobs" ? (
        <div className="flex items-center">
          {["applied", "favourite"]?.map((item) => {
            return (
              <h1
                className={`text-xl font-semibold border-b-4 px-4 cursor-pointer capitalize ${
                  myJobsTab === item
                    ? "text-[#8A24F1] border-[#8A24F1]/60"
                    : "text-gray-500 border-gray-300"
                }`}
                onClick={() => setMyJobsTab(item)}
              >
                {item} Jobs
              </h1>
            );
          })}
        </div>
      ) : (
        <h1 className="text-2xl font-bold">All Posted Jobs</h1>
      )}

      <div className="w-full h-full flex gap-2">
        <JobList
          userDetails={userDetails}
          jobs={jobs}
          handleFav={handleFav}
          openJobDetails={openJobDetails}
          setOpenJob={setOpenJob}
          selectedTab={tab}
          myJobsTab={myJobsTab}
        />
        <JobView
          jobDetails={openJobDetails}
          userDetails={userDetails}
          handleFav={handleFav}
          candidateApplications={candidateApplications}
        />
      </div>
    </div>
  );
};

export default Jobs;
