import React, { useState, useEffect } from "react";
import { Button, TextInput } from "../../components";
import { FaTrash } from "react-icons/fa6";
// import { FaEdit } from "react-icons/fa";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { useNavigate } from "react-router-dom";

const Projects = ({ setStep, userDb }) => {
  const navigate = useNavigate();
  const initialProjectDetails = {
    name: "",
    tenure: "",
    role: "",
    peopleInvolved: "",
    contribution: "",
    recognition: "",
    description: "",
  };
  const [projects, setProjects] = useState([]);
  const [newProject, setNewProject] = useState(initialProjectDetails);
  const [addProjectOpen, setAddProjectOpen] = useState(true);
  const [deleting, setDeleting] = useState("");

  useEffect(() => {
    const setInitailProjects = async () => {
      userDb?.projects && setProjects(userDb.projects);
    };
    setInitailProjects();
  }, [userDb]);
  const handleSave = async (e) => {
    e.preventDefault();

    setAddProjectOpen(false);
    try {
      await updateDoc(doc(db, "candidateProfile", userDb.id), {
        projects: [...projects, newProject],
      });
      setNewProject(initialProjectDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (index) => {
    const updatedProject = projects.filter((_, i) => i !== index);
    setDeleting(index);

    try {
      await updateDoc(doc(db, "candidateProfile", userDb.id), {
        projects: updatedProject,
      });
      setDeleting();
      setAddProjectOpen(false);
    } catch (error) {
      console.log(error);
      setDeleting();
    }
  };

  return (
    <div className="space-y-6 mb-5">
      {projects?.length > 0 && (
        <div>
          <div className="grid md:grid-cols-2 gap-x-4 gap-y-2">
            {projects?.map((project, index) => (
              <div
                key={index}
                className={`relative bg-gray-200 border-2 border-gray-300 py-2 px-4 rounded-xl 
                  ${deleting === index && "opacity-50"} 
                `}
              >
                <div className="absolute top-4 right-4">
                  <div className="flex items-center gap-2">
                    {/* <FaEdit
                      className="text-blue-400 cursor-pointer"
                      onClick={() => {
                        // setProjects(projects.filter((proj) => proj !== project));
                      }}
                    /> */}
                    <FaTrash
                      className="text-red-400 cursor-pointer"
                      onClick={() => {
                        handleDelete(index);
                      }}
                    />
                  </div>
                </div>

                <h1 className="font-semibold text-lg capitalize">
                  {project.name}
                </h1>
                <p className="text-sm">{project.role}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {addProjectOpen && (
        <form
          onSubmit={handleSave}
          className="relative border-2 rounded-lg py-5 px-5 md:px-10"
        >
          <div className="absolute top-[-10px] left-5 text-sm bg-white px-2">
            Add Project
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="flex flex-col w-full">
              <label className="font-medium text-xs leading-[12px] mb-2">
                Project Name*
              </label>
              <TextInput
                type="text"
                isRequired={true}
                placeholderText="Name of your project"
                inputName="projectName"
                inputValue={newProject.name}
                handleChange={(e) => {
                  setNewProject({ ...newProject, name: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-col w-full">
              <label className="font-medium text-xs leading-[12px] mb-2">
                Project Tenure*
              </label>
              <TextInput
                type="text"
                placeholderText="Project Tenure"
                inputName="projectTenure"
                inputValue={newProject.tenure}
                isRequired={true}
                handleChange={(e) => {
                  setNewProject({ ...newProject, tenure: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-col w-full">
              <label className="font-medium text-xs leading-[12px] mb-2">
                Role in Project*
              </label>
              <TextInput
                type="text"
                placeholderText="Your role in project"
                inputName="roleInProject"
                isRequired={true}
                inputValue={newProject.role}
                handleChange={(e) => {
                  setNewProject({ ...newProject, role: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-col w-full">
              <label className="font-medium text-xs leading-[12px] mb-2">
                Total people involved in project*
              </label>
              <TextInput
                type="number"
                placeholderText="Total people involved in project"
                inputName="peopleInProject"
                inputValue={newProject.peopleInvolved}
                isRequired={true}
                handleChange={(e) => {
                  setNewProject({
                    ...newProject,
                    peopleInvolved: e.target.value,
                  });
                }}
              />
            </div>
            <div className="flex flex-col w-full">
              <label className="font-medium text-xs leading-[12px] mb-2">
                Your contribution
              </label>
              <TextInput
                type="text"
                placeholderText="Your contribution"
                inputName="contribution"
                inputValue={newProject.contribution}
                handleChange={(e) => {
                  setNewProject({
                    ...newProject,
                    contribution: e.target.value,
                  });
                }}
              />
            </div>
            <div className="flex flex-col w-full">
              <label className="font-medium text-xs leading-[12px] mb-2">
                Recognition / Rewards / Accolade
              </label>
              <TextInput
                type="text"
                placeholderText="Recognition/rewards/accolades for this project"
                inputName="recognition"
                inputValue={newProject.recognition}
                handleChange={(e) => {
                  setNewProject({ ...newProject, recognition: e.target.value });
                }}
              />
            </div>
            <div className="md:col-span-2 lg:col-span-3 flex flex-col">
              <label className="font-medium text-xs leading-[12px] mb-2">
                Project Description*
              </label>
              <TextInput
                type="textbox"
                placeholderText="Description on this project"
                inputName="projectDescription"
                inputValue={newProject.description}
                isRequired={true}
                handleChange={(e) => {
                  setNewProject({ ...newProject, description: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="mt-4 flex justify-end gap-3">
            <Button
              text="Cancel"
              version="secondary"
              width="100px"
              handleClick={() => setAddProjectOpen(false)}
            />
            <Button type="submit" text="Save" version="primary" width="100px" />
          </div>
        </form>
      )}
      {!addProjectOpen && (
        <div>
          <Button
            text=""
            width="10rem"
            version="small-outline-primary"
            handleClick={() => setAddProjectOpen(true)}
          >
            <div className="flex items-center">
              <span className="text-xl font-semibold mr-2">+</span>
              <span className="text-sm">Add More</span>
            </div>
          </Button>
        </div>
      )}
      <div className="flex w-full items-center justify-between gap-8">
        <Button
          version="secondary"
          text="Previous"
          width="160px"
          handleClick={() => setStep((prev) => prev - 1)}
        />
        <Button
          version="secondary"
          handleClick={() => navigate("/candidate")}
          text="Dashboard"
          width="160px"
        />
      </div>
    </div>
  );
};

export default Projects;
