const QuestionRow = ({
  key,
  index,
  columnList,
  question,
  setViewQuestion,
  setViewQuestionData,
}) => {
  return (
    <>
      <div
        className={`flex w-full overflow-x-auto py-5 pl-5 text-xs font-medium even:bg-[#E9EAEB]/60 cursor-pointer`}
        key={key}
      >
        <div className="w-[6%] flex items-center">{index + 1}.</div>
        {columnList.map((column) => {
          const width = column.open ? column.width : "0";
          const opacity = column.open ? "1" : "0";
          return (
            <div
              key={column.name}
              className="flex items-center gap-1 capitalize transition-all duration-200"
              style={{ width, opacity }}
              onClick={() => {
                setViewQuestionData(question);
                setViewQuestion(true);
              }}
            >
              {column.open && column.name === "createdAt"
                ? `${new Date(question[column.name]).toLocaleDateString(
                    "en-in",
                    {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }
                  )} - ${new Date(question[column.name]).toLocaleTimeString(
                    "en-in",
                    {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    }
                  )}`
                : column.name === "time"
                ? `${Math.floor(question[column.name] / 60)}${
                    question[column.name] % 60 === 0 ? " minutes" : "min"
                  } ${
                    question[column.name] % 60 === 0
                      ? ""
                      : (question[column.name] % 60) + "sec"
                  }`
                : column.name === "difficulty"
                ? question[column.name] === 3
                  ? "Hard"
                  : question[column.name] === 2
                  ? "Medium"
                  : "Easy"
                : column.name === "type" && question[column.name] === "mcq"
                ? question[column.name].toUpperCase()
                : question[column.name]}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default QuestionRow;
