import { useEffect, useLayoutEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { Button, SelectInput, TextInput } from "../../../components";
import { useSelector } from "react-redux";

const Step2 = ({ rounds, setRounds, editJobData }) => {
  const [roundTypesOptions, setRoundTypesOptions] = useState([]);
  const [assessmentOptions, setAssessmentOptions] = useState([]);
  const [showAssessmentInput, setShowAssessmentInput] = useState([]);
  const [update, setUpdate] = useState(); // to be removed with line - 134

  useEffect(() => {
    editJobData && setRounds(editJobData?.rounds);
  }, [editJobData, setRounds]);

  useEffect(() => {
    rounds.forEach((round, index) => {
      if (
        round?.roundType === "assessment" ||
        round?.roundType === "screening"
      ) {
        setShowAssessmentInput((prev) => [...prev, index]);
      }
    });
  }, [rounds]);

  useLayoutEffect(() => {
    const roundType = ["assessment", "coding", "interview"];
    const roundTypeOption = roundType.map((option) => ({
      label: option.slice(0, 1).toUpperCase() + option.slice(1),
      value: option,
    }));
    setRoundTypesOptions(roundTypeOption);
  }, []);

  const { assessmentSelectData, loading } = useSelector(
    (state) => state.assessments
  );

  useEffect(() => {
    const options = assessmentSelectData.map((item) => ({
      id: item.assessmentId,
      value: item.assessmentName,
      label: (
        <div className="flex items-center justify-between">
          <span className="capitalize">
            {item.assessmentName.charAt(0).toUpperCase() +
              item.assessmentName.slice(1)}
          </span>
          <span className="text-xs">({item.questions} questions)</span>
        </div>
      ),
    }));
    setAssessmentOptions(!loading && options);
  }, [assessmentSelectData, loading]);

  // const createNewAssessment = async (assessmentName) => {
  // const sheetData = JSON.stringify([admin?.questionsSheetId, assessmentName]);
  // const response = await axios.post(
  // process.env.REACT_APP_CREATE_ASSESSMENT_TYPE,
  // sheetData
  // );
  // if (response.data.status === "success") {
  // setUpdateAssessmentTypes((prev) => prev + 1);
  // }
  // };

  // const handleCreate = async (inputValue, index) => {
  //   setIsLoading(true);
  //   const newOption = createOption(inputValue);
  //   setAssessmentOptions((prev) => [...prev, newOption]);
  //   await createNewAssessment(newOption.value);
  //   setIsLoading(false);
  // };

  return (
    <div className="flex flex-col gap-5 w-[90%] mx-auto h-full">
      <div className="flex justify-between items-center pr-8">
        <h1 className="text-xl font-semibold">
          Select the rounds for this job profile
        </h1>
        <div
          className="cursor-pointer"
          onClick={() => {
            const uniqueKey = new Date().getTime();
            setRounds([...rounds, { key: uniqueKey }]);
          }}
        >
          <p className="font-semibold text-[#8A24F1]">
            <span className="text-lg mr-1"> + </span>Add More Round
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-2 h-full overflow-y-scroll pr-4 scrollable">
        {rounds?.map((round, index) => (
          <div className="space-y-4 border-2 rounded-xl px-10 py-4" key={index}>
            <div className="flex items-center  justify-between">
              <h1 className="text-lg font-semibold">Round {index + 1}</h1>
              {rounds.length > 1 && (
                <div
                  className="cursor-pointer bg-red-200 p-[2px] rounded-lg"
                  onClick={() => {
                    const updatedRounds = rounds.filter((_, i) => i !== index);
                    setRounds(updatedRounds);
                  }}
                >
                  <MdDelete className="w-6 h-6 text-red-500" />
                </div>
              )}
            </div>
            <div className="flex gap-20 items-center">
              <div className="w-full flex flex-col gap-4">
                <div
                  className={`space-y-1 ${
                    rounds[index].roundType === "interview" && "w-[50%]"
                  } `}
                >
                  <label htmlFor="RoundType" className="font-medium text-sm">
                    Type of Round
                  </label>
                  <div className="w-full xl:w-3/4">
                    <SelectInput
                      className={"basic-single capitalize"}
                      isClearable={true}
                      inputName="RoundType"
                      selectOptions={roundTypesOptions}
                      value={{
                        label: round.roundType,
                        value: round.roundType,
                      }}
                      placeholderText="Select Type of Round"
                      handleChange={(newValue) => {
                        setUpdate(newValue); // to be removed with line - 10
                        const updatedRounds = rounds;
                        updatedRounds[index].roundType = newValue?.value;
                        setRounds(updatedRounds);
                        newValue?.value === "assessment" ||
                        newValue?.value === "coding"
                          ? setShowAssessmentInput((prev) =>
                              prev.includes(index) ? prev : [...prev, index]
                            )
                          : setShowAssessmentInput((prev) =>
                              prev.includes(index)
                                ? prev.filter((item) => item !== index)
                                : prev
                            );
                      }}
                    />
                  </div>
                </div>
                {showAssessmentInput.includes(index) && (
                  <div className="space-y-1">
                    <label
                      htmlFor="assessmentName"
                      className="font-medium text-sm"
                    >
                      Assessmet Name
                    </label>
                    <div className="w-full xl:w-3/4">
                      <SelectInput
                        className="capitalize"
                        isClearable={true}
                        inputName="assessmentName"
                        value={{
                          label: round.assessment,
                          value: round.assessment,
                        }}
                        handleChange={(newValue) => {
                          const updatedRounds = rounds;
                          updatedRounds[index].assessment = newValue?.value;
                          updatedRounds[index].id = newValue?.id;
                          setRounds(updatedRounds);
                        }}
                        selectOptions={
                          round.roundType === "coding"
                            ? assessmentOptions?.filter(
                                (assessment) =>
                                  assessment?.assessmentType === "coding"
                              )
                            : assessmentOptions?.filter(
                                (assessment) =>
                                  assessment?.assessmentType !== "coding"
                              )
                        }
                        placeholderText="Select Assessment"
                        // onCreateOption={(inputValue) =>
                        //   handleCreate(inputValue, index)
                        // }
                      />
                    </div>
                  </div>
                )}
              </div>
              {round.roundType !== "interview" && (
                <div className="w-full flex flex-col gap-2">
                  <p className="font-medium">
                    Selection Criteria for Round {index + 1}
                  </p>
                  <div className="w-full lg:w-1/2 xl:w-2/3 space-y-1">
                    <label htmlFor="criteria" className="text-xs text-gray-400">
                      Pass for next round if Score is greater then
                    </label>
                    <TextInput
                      type="number"
                      inputName="criteria"
                      placeholderText="eg. - 90"
                      innerLabel="%"
                      inputValue={rounds[index].selectionCriteria}
                      handleChange={(e) => {
                        const updatedRounds = rounds;
                        updatedRounds[index].selectionCriteria = Number(
                          e.target.value
                        );
                        setRounds(updatedRounds);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
        <div>
          <Button
            text=""
            width="200px"
            version="outline-primary"
            handleClick={() => {
              const uniqueKey = new Date().getTime();
              setRounds([...rounds, { key: uniqueKey }]);
            }}
          >
            <span className="text-sm">+ Add Round</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Step2;
