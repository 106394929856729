import { useCallback, useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import envConfig from "../../../config/envConfig";
import { Button } from "../../../components";
import UpdateJobStatus from "./UpdateJobStatus";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa";
import { LuClipboard, LuClipboardCheck } from "react-icons/lu";
import { useAutoClosePopups } from "../../../utils/hooks";
import { auth } from "../../../config/firebase";

const JobViewHeader = ({ jobData }) => {
  const [jobStatus, setJobStatus] = useState("");
  const editStatusPopupRef = useRef(null);
  const [openEditStatus, setOpenEditStatus] =
    useAutoClosePopups(editStatusPopupRef);

  useEffect(() => {
    setJobStatus(jobData?.jobStatus);
  }, [jobData]);

  const [link, setLink] = useState("");
  useEffect(
    () =>
      setLink(
        `${envConfig.appUrl}/${envConfig.applyLinkPhpUrl}?jid=${jobData?.jobOpeningId}&comp=${auth.currentUser.displayName}&jt=${jobData.positionTitle}&exp=${jobData.workExperiance}`?.replaceAll(
          " ",
          "%20"
        )
      ),
    [jobData]
  );

  const [copied, setCopied] = useState(false);
  const copyLink = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }, [link]);

  const shareLink = useCallback(async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Link to Apply for - " + jobData?.positionTitle,
          url: link,
        });
      } catch (error) {
        console.log("Error sharing link: ", error);
      }
    } else {
      console.log("Web Share API not supported");
    }
  }, [link, jobData]);

  return (
    <div className="w-full h-[20%] p-10 flex gap-4">
      <div className="space-y-3">
        <div className="font-semibold text-2xl capitalize">
          {jobData?.positionTitle}
        </div>
        <div className="">
          <span className="text-[#979797]">
            Posted on{" : "}
            {new Date(
              jobData?.jobPostedDate?.seconds * 1000
            ).toLocaleDateString("en-in", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </span>

          <div className="flex items-center gap-4">
            <div className="relative" ref={editStatusPopupRef}>
              <Button
                text=""
                width="120px"
                version={
                  jobStatus === "in-progress"
                    ? "outline-green"
                    : jobStatus === "closed"
                    ? "outline-red"
                    : jobStatus === "on hold"
                    ? "outline-yellow"
                    : "outline-gray"
                }
                handleClick={() => setOpenEditStatus(!openEditStatus)}
              >
                <div className="flex items-center gap-2">
                  <span className="capitalize">{jobStatus}</span>
                  <FaAngleDown
                    className={`w-4 h-4 transition duration-400 ${
                      openEditStatus ? "-rotate-180" : ""
                    }`}
                  />
                </div>
              </Button>
              {openEditStatus && (
                <div className="absolute top-[44px] left-0 h-screen flex justify-center z-50">
                  <UpdateJobStatus
                    setOpenEditStatus={setOpenEditStatus}
                    jobData={jobData}
                    jobStatus={jobStatus}
                    setJobStatus={setJobStatus}
                  />
                </div>
              )}
            </div>
            <div className="flex">
              <button
                data-tooltip-id="copyTooltip"
                className={`flex items-center justify-center gap-1 px-4 border-2 border-r-0 rounded-l-xl py-[6px]
                    ${
                      copied
                        ? "bg-[#18AE00] hover:bg-[#18AE00]/90 text-white border-[#18AE00]"
                        : "bg-gray-200 border-gray-300 hover:bg-gray-200/60"
                    }
                `}
                onClick={() => copyLink()}
              >
                {copied ? (
                  <LuClipboardCheck className="w-6 h-6" />
                ) : (
                  <LuClipboard className="w-6 h-6" />
                )}
              </button>
              <button
                data-tooltip-id="shareTooltip"
                className="flex items-center justify-center gap-1 px-4 border-2 rounded-r-xl bg-gray-200 border-gray-300 hover:bg-gray-200/60 py-[6px]"
                onClick={() => shareLink()}
              >
                <FaRegShareFromSquare className="w-6 h-6" />
              </button>
              <Tooltip id="copyTooltip" place="bottom" style={tooltipStyles}>
                {copied ? "Copied" : "Copy Link"}
              </Tooltip>
              <Tooltip id="shareTooltip" place="bottom" style={tooltipStyles}>
                Share Link
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobViewHeader;

const tooltipStyles = {
  backgroundColor: "transparent",
  color: "#8A24F1",
  fontSize: "12px",
  fontWeight: "600",
  marginTop: "-8px",
  padding: "2px 8px",
  borderRadius: "50px",
};
