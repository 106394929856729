import React from "react";
import { Button } from "../../components";

const SelectRejectConfirmPopup = ({
  state,
  closePopup,
  confirmUpdate,
  loading,
}) => {
  return (
    <div className="p-10 flex flex-col gap-2 items-center justify-center">
      <h1 className="heading2">Do you want to {state} the candiate ?</h1>
      <h2 className="font-medium text-gray-600">
        {state === "select"
          ? "The candidate will be promoted to final interview round"
          : "The candidate will be rejected for this application"}
      </h2>
      <div className="flex gap-3 mt-2">
        <Button
          version="secondary"
          text="Cancel"
          width={"120px"}
          handleClick={closePopup}
        />
        <Button
          version={state === "reject" ? "red" : "primary"}
          text={state}
          className="capitalize"
          width={"120px"}
          handleClick={() => confirmUpdate(state)}
          loading={loading?.[state] || false}
        />
      </div>
    </div>
  );
};

export default SelectRejectConfirmPopup;
