import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LogoLoading } from "../components";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";
const AdminAuthProtected = ({ children, authenticate = true }) => {
  const [userAuth, authLoading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authLoading) {
      if (authenticate && !userAuth) {
        navigate("/login");
      } else if (!authenticate && userAuth) {
        navigate("/admin");
      }
    }
  }, [authenticate, navigate, authLoading, userAuth]);

  return authLoading ? <LogoLoading /> : children;
};

export default AdminAuthProtected;
