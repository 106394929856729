// this email is sent to admin when a candidate cleared the final round

const emailTemplate = ({ candidateName, jobTitle, companyDetails }) => {
    //content to be changed
    const subject = `${candidateName} - Successful Completion of all Assessment Rounds for ${jobTitle}`
    const body = `
    <b>Dear ${companyDetails?.companyName} Team,</b><br>

    This email is to inform you that ${candidateName} has successfully completed the assessment rounds for the ${jobTitle} position.<br>

    We believe ${candidateName} would be a valuable asset to the ${companyDetails?.companyName} team. You can select the candidate for the final interview from the candidate profile in Talentigo dashboard.<br><br>

    Best regards,
    Team Talentigo
    `
    return { subject, body }
}

export default emailTemplate