const LoadingCard = () => (
  <div className="w-full h-full space-y-2 py-2">
    {[1, 2, 3, 4, 5].map((item) => (
      <div
        key={item}
        className="bg-[#666]/10 animate-pulse w-full h-16 rounded-lg"
      ></div>
    ))}
  </div>
);

export default LoadingCard;
