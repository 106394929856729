import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dbService from "../../firebase/dbService";
import {
  collection,
  getCountFromServer,
  getDocs,
  query,
  where,
} from "@firebase/firestore";
import { auth, db } from "../../config/firebase";

const initialState = {
  applicationsData: [],
  dashboardData: {},
  selectedJobId: null,
  loading: false,
  error: null,
};

export const fetchDashboardData = createAsyncThunk(
  "dashboard/fetchDashboardData",
  async ({ jobId }, { rejectWithValue }) => {
    try {
      // fetch appkications
      let applicationsData = await dbService.getAllApplications(jobId);
      applicationsData = applicationsData.map((application) => {
        const {
          appliedAt,
          appliedJobId,
          appliedPosition,
          assessmentsResults,
          candidateProfileRef,
          assessmentsFeedbacks,
          interview,
          source,
          processed,
        } = application;
        return {
          appliedAt,
          appliedJobId,
          appliedPosition,
          assessmentsResults,
          candidateId: candidateProfileRef?.id,
          assessmentsFeedbacks,
          interview,
          source,
          processed,
        };
      });

      // making small cards data
      let allRatings = [];
      let allResults = [];
      let allTimes = [];
      let completetionRate;
      if (applicationsData?.length > 0) {
        //
        // total assessments count
        const assessmentsQuery = query(
          collection(db, "assessmentTests"),
          where("companyName", "==", auth.currentUser.displayName)
        );
        const assessmentsCountSnapshot = await getCountFromServer(
          assessmentsQuery
        );
        const assessmentsCount = assessmentsCountSnapshot.data().count;
        // total results count
        const resultsCount = applicationsData.filter(
          (application) => application?.assessmentsResults?.length > 0
        ).length;
        // completetion rate
        completetionRate = (resultsCount / assessmentsCount) * 100;

        applicationsData.forEach((data) => {
          data?.assessmentsFeedbacks?.forEach((feedback) => {
            allRatings.push(feedback.rating);
          });
          data?.assessmentsResults?.forEach((result) => {
            allResults.push(result.result);
            allTimes.push(result.timeTaken);
          });
        });
      }
      const averageFeedbackRating =
        allRatings?.length === 0
          ? 0
          : allRatings
              ?.map((item) => (item === "91" ? 10 : item))
              ?.reduce((a, b) => a + b, 0) / allRatings.length;

      const averageTimeCompletion =
        allTimes.length === 0
          ? 0
          : Math.round(
              allTimes.reduce((a, b) => a + b, 0) / allTimes.length / 1000
            ); // in seconds

      const passingRate =
        allResults.length === 0
          ? 0
          : (allResults.filter((data) => data === "pass").length /
              allResults.length) *
            100;

      const allAssessments = applicationsData
        ?.filter((data) => data?.assessmentsResults?.length > 0)
        ?.map((data) => {
          return data?.assessmentsResults?.map((result) => ({
            candiateId: data.candidateId,
            position: data.appliedPosition,
            attemptedAt: result?.examDateTime?.seconds * 1000,
            result,
          }));
        })
        ?.flat();

      // making top scorers data
      let topScorers = allAssessments
        ?.sort((a, b) => {
          return b?.result?.score - a?.result?.score;
        })
        ?.slice(0, 6);

      // making recent passed data
      let recentPassed = allAssessments
        ?.filter((data) => data?.result?.result === "pass")
        ?.sort((a, b) => {
          return b?.attemptedAt - a?.attemptedAt;
        })
        ?.slice(0, 6);

      const candiateIds = [...topScorers, ...recentPassed]?.map(
        (data) => data.candiateId
      );
      let candidateProfiles = [];
      if (candiateIds.length !== 0) {
        const candidateProfilesRef = collection(db, "candidateProfile");
        const candidateProfilesQuery = query(
          candidateProfilesRef,
          where("__name__", "in", candiateIds)
        );
        const candidateProfilesSnapshot = await getDocs(candidateProfilesQuery);
        candidateProfiles = new Map(
          candidateProfilesSnapshot.docs.map((doc) => [doc.id, doc.data()])
        );
        topScorers = topScorers.map((item) => {
          const candidateProfile = candidateProfiles.get(item.candiateId) || {};
          return {
            ...item,
            name: candidateProfile.name || "-",
          };
        });
        recentPassed = recentPassed.map((item) => {
          const candidateProfile = candidateProfiles.get(item.candiateId) || {};
          return {
            ...item,
            name: candidateProfile.name || "-",
          };
        });
      }

      return {
        applicationsData,
        dashboardData: {
          allAssessments,
          completetionRate,
          averageFeedbackRating,
          averageTimeCompletion,
          passingRate,
          topScorers,
          recentPassed,
        },
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setSelectedJobId: (state, action) => {
      state.selectedJobId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchDashboardData.fulfilled, (state, action) => {
      state.applicationsData = action.payload.applicationsData;
      state.dashboardData = action.payload.dashboardData;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchDashboardData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});
export const { setSelectedJobId } = DashboardSlice.actions;

export default DashboardSlice.reducer;
