import { useEffect, useRef, useState } from "react";
import { LuLoaderCircle } from "react-icons/lu";
import JobDescription from "./JobDescription";
import JobDetails from "./JobDetails";
import JobTimeline from "./JobTimeline";
import JobMenu from "./JobMenu";
import { useNavigate } from "react-router-dom";
import { useFetchJob } from "../../../utils/hooks";
import JobViewHeader from "./JobViewHeader";
import { Button } from "../../../components";
import { setFilterJobId } from "../../../redux/features/applicationsSlice";
import { useDispatch } from "react-redux";

const JobView = ({ openJobView, jobId }) => {
  const [tab, setTab] = useState("details");
  const jobViewRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [jobData, loading] = useFetchJob(jobId);
  useEffect(() => {
    if (!loading && !jobData) {
      navigate("/admin/jobs");
    }
  }, [jobData, loading, navigate]);

  useEffect(() => {
    if (openJobView) {
      const handleClickOutside = (event) => {
        jobViewRef.current.contains(event.target) || navigate(-1);
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [openJobView, navigate]);

  return (
    <div className="absolute top-0 left-0 w-full h-screen bg-black/20 backdrop-blur-[2px] flex justify-end z-50">
      <div
        ref={jobViewRef}
        className="relative w-full md:w-[80%] lg:w-[40%] xl:w-[36%] h-[100vh] bg-white shadow-md rounded-l-xl overflow-hidden"
      >
        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <LuLoaderCircle className="w-24 h-24 animate-spin text-gray-300" />
          </div>
        ) : (
          <>
            <div className="absolute top-4 right-6">
              <JobMenu jobId={jobData?.jobOpeningId} />
            </div>

            <JobViewHeader jobData={jobData} />

            {/* tab navigator start */}
            <div className="flex px-5 relative h-[5%] border-b-2 border-gray-300 border-box">
              {["details", "job description", "timeline"].map((item) => (
                <div
                  key={item}
                  className={`z-10 px-4 py-1 cursor-pointer font-semibold -mb-[2px] capitalize
              ${
                tab === item
                  ? "border-b-4 border-[#8A24F1] text-[#8A24F1]"
                  : "text-[#888]"
              }`}
                  onClick={() => setTab(item)}
                >
                  {item}
                </div>
              ))}
            </div>
            {/* tab navigator end */}

            {/* tab Pages view start  */}
            <div className="w-full h-[74%] overflow-y-scroll scrollable pb-12">
              {tab === "details" && <JobDetails jobData={jobData} />}
              {tab === "job description" && (
                <JobDescription jobData={jobData} />
              )}
              {tab === "timeline" && <JobTimeline jobData={jobData} />}
            </div>
            {/* tab Pages view end  */}
          </>
        )}

        <div className="absolute bottom-0 left-0 bg-white/30 backdrop-blur-[4px] border-t-2 border-gray-200 w-full py-3 px-8 flex items-center justify-end shadow-[0px_-4px_10px_0px_#523F690D]">
          <Button
            text="View Applicants"
            handleClick={() => {
              dispatch(setFilterJobId(jobData?.jobOpeningId));
              navigate("/admin/ats");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default JobView;
