export const customStyles = {
  control: (styles) => ({
    ...styles,
    width: "100%",
    borderRadius: "8px",
    border: "2px solid #e5e7eb",
    padding: "0 3px",
    fontSize: "14px",
    backgroundColor: "#F5F5F5",
    cursor: "pointer",
    outline: "none",
    boxShadow: "none",

    ":hover": {
      outline: "none",
      border: "2px solid #ddd",
      boxShadow: "none",
    },
    ":active": {
      outline: "none",
      border: "2px solid #ccc",
      boxShadow: "none",
    },
    ":focus": {
      outline: "none",
      border: "2px solid #ccc",
      boxShadow: "none",
    },
  }),

  option: (styles, { isSelected }) => {
    return {
      ...styles,
      color: isSelected ? "#fff" : "#292D32",
      backgroundColor: isSelected ? "#8A24F1" : "#fff",
      fontSize: "14px",
      ":hover": {
        backgroundColor: isSelected ? "#8A24F1" : "#eee",
        color: isSelected ? "#fff" : "#000",
        cursor: "pointer",
      },
    };
  },

  menu: (styles) => {
    return {
      ...styles,
      width: "100%",
      borderRadius: "8px",
    };
  },

  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#929292",
      fontSize: "14px",
    };
  },
};
