import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//constants
const day = 24 * 60 * 60 * 1000;
const dayArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const d = new Date();

function compareDates(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

const useVacancyStatsData = ({ chartPeriod = 7 }) => {
  const [chartData, setChartData] = useState([]);
  const { dashboardData, applicationsData, loading } = useSelector(
    (state) => state.dashboardData
  );

  useEffect(() => {
    if (!loading) {
      const dataArray = [];
      for (let i = chartPeriod - 1; i >= 0; i--) {
        dataArray.push({
          name:
            i === 0
              ? "Today"
              : chartPeriod <= 7
              ? dayArray[new Date(d - day * i).getDay()]
              : new Date(d - day * i).toLocaleDateString("en-in", {
                  day: "numeric",
                  month: "short",
                }),

          Applications:
            applicationsData?.filter((application) =>
              compareDates(
                new Date(d - day * i),
                new Date(application?.appliedAt?.seconds * 1000)
              )
            )?.length || 0,
          Attempted:
            dashboardData?.allAssessments?.filter((assessment) =>
              compareDates(
                new Date(d - day * i),
                new Date(assessment?.attemptedAt)
              )
            )?.length || 0,
          Shortlisted:
            applicationsData
              ?.filter(
                (application) => application.interview?.status === "selected"
              )
              ?.filter((application) =>
                compareDates(
                  new Date(d - day * i),
                  new Date(
                    application?.interview?.statusUpdateAt?.seconds * 1000
                  )
                )
              )?.length || 0,
          Rejected:
            applicationsData
              ?.filter((application) =>
                compareDates(
                  new Date(d - day * i),
                  new Date(
                    application?.interview?.statusUpdateAt?.seconds * 1000
                  )
                )
              )
              ?.filter(
                (application) => application.interview?.status === "rejected"
              )?.length || 0,
          Pending:
            dashboardData?.allAssessments?.filter(
              (assessment) =>
                compareDates(
                  new Date(d - day * i),
                  new Date(assessment?.attemptedAt)
                ) && assessment?.result?.result === "pending"
            )?.length || 0,
          Invited:
            applicationsData?.filter(
              (application) =>
                compareDates(
                  new Date(d - day * i),
                  new Date(application?.appliedAt?.seconds * 1000)
                ) && application?.source !== "by candidate"
            )?.length || 0,
          Unprocessed:
            applicationsData
              ?.filter((application) => application?.processed === false)
              ?.filter(
                (application) =>
                  compareDates(
                    new Date(d - day * i),
                    new Date(application?.appliedAt?.seconds * 1000)
                  ) && application?.source !== "by candidate"
              )?.length || 0,
        });
      }
      console.log(dataArray);
      setChartData(dataArray);
    }
  }, [applicationsData, loading, chartPeriod, dashboardData]);

  return { chartData, loading };
};

export default useVacancyStatsData;
