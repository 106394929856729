import React, { useEffect, useState } from "react";
import PieGraph from "./PieGraph";
import { useSelector } from "react-redux";
import SelectInterval from "../SelectInterval";

const ScoreDistribution = () => {
  const { applicationsData, loading } = useSelector(
    (state) => state.dashboardData
  );
  const [selectValue, setSelectValue] = useState(null);
  const [percentageData, setPercentageData] = useState();
  const [cumulativeData, setCumulativeData] = useState();
  const [total, setTotal] = useState({
    percentageScored: 0,
    commulativeScored: 0,
  });

  useEffect(() => {
    let allAssementResults = applicationsData
      ?.filter((data) => data?.assessmentsResults?.length > 0)
      ?.map((data) => data.assessmentsResults)
      .flat();

    if (selectValue) {
      allAssementResults = allAssementResults.filter(
        (data) =>
          data?.examDateTime?.seconds * 1000 > selectValue.lastDateMillisecond
      );
    }

    let allPass = 0;
    let allFail = 0;
    let allTerminate = 0;
    let total0To20 = 0;
    let total20To40 = 0;
    let total40To60 = 0;
    let total60To80 = 0;
    let total80To100 = 0;
    allAssementResults?.forEach((data) => {
      const percentage = (data.score / (data.totalQuestions * 10)) * 100;
      if (data.result === "pass") {
        allPass = allPass + 1;
      } else if (data.result === "fail") {
        allFail = allFail + 1;
      } else if (data.result === "terminate") {
        allTerminate = allTerminate + 1;
      }
      if (percentage >= 0 && percentage <= 20) {
        total0To20++;
      } else if (percentage > 20 && percentage <= 40) {
        total20To40++;
      } else if (percentage > 40 && percentage <= 60) {
        total40To60++;
      } else if (percentage > 60 && percentage <= 80) {
        total60To80++;
      } else if (percentage > 80) {
        total80To100++;
      }
    });

    setTotal(allAssementResults?.length);
    setPercentageData([
      { name: "0% - 20%", value: total0To20, color: "#006E50" },
      {
        name: "20% - 40%",
        value: total20To40,
        color: "#00563E",
      },
      {
        name: "40% - 60%",
        value: total40To60,
        color: "#4E9144",
      },
      {
        name: "60% - 80%",
        value: total60To80,
        color: "#6CA031",
      },
      {
        name: "80% - 100%",
        value: total80To100,
        color: "#8DBE54",
      },
    ]);

    setCumulativeData([
      {
        name: "Passed",
        value: allPass,
        color: "#18AE00",
      },
      {
        name: "Failed",
        value: allFail,
        color: "#E11414",
      },
      {
        name: "Terminated",
        value: allTerminate,
        color: "#FFB800",
      },
    ]);
  }, [applicationsData, selectValue]);

  return (
    <div className="card w-full pt-4 p-6 space-y-2">
      <div className="flex justify-between text-[16px] items-center">
        <h3 className="font-semibold text-lg">Score Distribution</h3>
        <SelectInterval
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          showAll={true}
        />
      </div>
      <div className="grid lg:grid-cols-2 gap-3">
        <PieGraph
          title="Percentage Scored"
          data={percentageData}
          loading={loading}
          toalData={total}
        />
        <PieGraph
          title="Cumulative"
          data={cumulativeData}
          loading={loading}
          toalData={total}
        />
      </div>
    </div>
  );
};

export default ScoreDistribution;
