import React, { useEffect, useState } from "react";
import { Button, TextInput } from "../../components";
import { useNavigate } from "react-router-dom";
import QuestionsListing from "./QuestionsListing";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../redux/features/pageTitleSlice";
import { IoIosSearch } from "react-icons/io";
import CategorySelect from "./CategorySelect";
import { FaArrowCircleUp } from "react-icons/fa";
import QuestionView from "./questionView/QuestionView";
import { auth } from "../../config/firebase";

const tabs = [
  {
    name: "custom",
    label: "Custom Questions",
    collection: "customQuestionLibrary",
  },
  {
    name: "library",
    label: "Pre-Defined Library",
    collection: "questionLibrary",
  },
];

const QuestionLibrary = ({ tab }) => {
  const navigate = useNavigate();

  // const location = useLocation();
  // const urlparams = new URLSearchParams(location.search);
  // const categoryParam = urlparams.get("category");
  // const skillParam = urlparams.get("skill");

  const [libraryType, setlibraryType] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTitle({ title: "Question Bank" }));
  }, [dispatch]);

  useEffect(() => {
    setlibraryType(tab || "custom");
  }, [tab]);

  const {
    customQuestions,
    libraryQuestions,
    loading,
    // error
  } = useSelector((state) => state.questions);

  const [allQuestions, setAllQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    !loading &&
      setAllQuestions(
        libraryType === "custom"
          ? customQuestions
          : libraryType === "library"
          ? libraryQuestions
          : []
      );
  }, [customQuestions, libraryQuestions, libraryType, loading]);

  useEffect(() => {
    const compare = (string) =>
      string?.toLowerCase()?.includes(searchQuery?.toLowerCase());
    let filteredQuestions;
    if (selectedCategory) {
      filteredQuestions = allQuestions.filter((question) =>
        question.category?.includes(selectedCategory?.value)
      );
    }
    if (selectedSkill) {
      filteredQuestions = filteredQuestions.filter((question) =>
        question.skills?.includes(selectedSkill?.value)
      );
    }
    if (selectedTopic) {
      filteredQuestions = filteredQuestions.filter((question) =>
        question.topic?.includes(selectedTopic?.value)
      );
    }
    if (searchQuery) {
      const data = filteredQuestions || allQuestions;
      filteredQuestions = data.filter(
        (question) =>
          compare(question?.statement) ||
          compare(question.questionName) ||
          compare(question?.type)
      );
    }

    selectedCategory || selectedSkill || selectedTopic || searchQuery
      ? setQuestions(filteredQuestions)
      : setQuestions(allQuestions);
  }, [
    searchQuery,
    allQuestions,
    selectedCategory,
    selectedSkill,
    selectedTopic,
  ]);

  // const [filterQuestion, setFilterQuestion] = useState();
  // const [filterValue, setFilterValue] = useState("");
  // const [sortValue, setSortValue] = useState();

  //   // ........ query on the basic of search............................

  //   // if (searchValue) {
  //   // const searched = searchValue.value;
  //   // // console.log(searched)
  //   // console.log(categories);
  //   // let searchTopic = "";
  //   // for (let i = 0; i < categories.length; i++) {
  //   //   if (categories[i].name === searched) {
  //   //     // searchTopic.push("category");
  //   //     searchTopic = "category";
  //   //   } else {
  //   //     for (let j = 0; j < categories[i].skills.length; j++) {
  //   //       if (categories[i].skills[j].skill === searched) {
  //   //         // searchTopic.push("topic");
  //   //         searchTopic = "topic";
  //   //       }
  //   //     }
  //   //   }
  //   // }
  //   // console.log(searchTopic);

  //   // if (searchTopic === "category") {
  //   //   q = query(q, where("category", "array-contains-any", [searched]));
  //   // } else if (searchTopic === "topic") {
  //   //   q = query(q, where("skills", "array-contains-any", [searched]));
  //   // } else {
  //   //   q = query(q, where("topic", "array-contains-any", [searched]));
  //   // }

  //   // }

  //   const querySnapshot = onSnapshot(q, (querySnapshot) => {
  //     const questionsData = [];
  //     querySnapshot.forEach((doc) => {
  //       questionsData.push({ id: doc.id, ...doc.data() });
  //     });
  //     setQuestions(questionsData);
  //   });
  //   setLoading(false);
  //   return querySnapshot;
  // };
  // useEffect(() => {
  //   fetchQuestions();
  // }, [
  //   sortValue,
  //   selectedCategory,
  //   selectedSkill,
  //   selectedTopic,
  //   collectionName,
  // ]);

  // get Filter value
  // useEffect(() => {
  //   const newFilterQuestionData = questions?.filter((data) => {
  //     return (
  //       data.category.some((cat) => cat.includes(filterValue)) ||
  //       data.skills.some((skill) => skill.includes(filterValue)) ||
  //       data.topic.some((topic) => topic.includes(filterValue))
  //     );
  //   });
  //   setFilterQuestion(newFilterQuestionData);
  // }, [filterValue, questions]);

  //
  // const updateSearchData = (searchValue) => {
  //   const newFilterQuestionData = questions?.filter((data) => {
  //     return (
  //       data.category.some((cat) => cat.includes(searchValue)) ||
  //       data.skills.some((skill) => skill.includes(searchValue)) ||
  //       data.topic.some((topic) => topic.includes(searchValue))
  //     );
  //   });
  //   setFilterQuestion(newFilterQuestionData);
  // };

  const [viewQuestionData, setViewQuestionData] = useState(null);
  const [viewQuestion, setViewQuestion] = useState(false);

  return (
    <div className="main-container w-full mx-auto md:ml-20 mt-1 flex flex-col lg-px-10">
      <div className="card w-full relative lg:h-[90vh] overflow-y-hidden">
        {/*  */}
        <div className="application-filter-bar flex gap-2 h-fit py-6 pr-6">
          <div className="left-item w-full px-4">
            <div className="flex w-fit border-b-2 border-gray-300">
              {tabs?.map(({ name, label }) => {
                return (
                  <div
                    onClick={() => {
                      navigate(`/admin/questionLibrary/${name}`);
                      setSelectedCategory(null);
                    }}
                    key={name}
                    className={`libraryType-btn capitalize py-1 px-4  font-semibold cursor-pointer text-lg  ${
                      libraryType === name
                        ? "text-[#8A24F1] border-[#8A24F1] border-b-4"
                        : "text-[#11031D]"
                    }`}
                  >
                    {label}
                  </div>
                );
              })}
            </div>
          </div>
          {/* <div className="right-item">
            <div className="invite-candidate flex items-center justify-center py-2">
              <Button
                width="200px"
                text=""
                handleClick={() => ()}
              >
                <div> Add Questions </div>
              </Button>
            </div>
          </div> */}
        </div>
        <div className="application-filter-bar flex gap-2 h-fit pr-4 py-2 justify-between">
          <div className="flex justify-between w-full">
            <CategorySelect
              libraryType={libraryType}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedSkill={selectedSkill}
              setSelectedSkill={setSelectedSkill}
              selectedTopic={selectedTopic}
              setSelectedTopic={setSelectedTopic}
            />
            <div>
              <div className="flex items-center justify-center space-x-3 mr-4">
                <div className="w-[250px] ">
                  <TextInput
                    type="text"
                    inputValue={searchQuery}
                    placeholderText="Search Questions"
                    handleChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    innerLabel={
                      <IoIosSearch className="w-5 h-5 text-gray-400" />
                    }
                  />
                </div>
                {/* <div
                  className="relative flex items-center gap-4 py-2"
                  // onClick={() => setOpenfilterMenu(!openfilterMenu)}
                >
                  <Button
                    text=""
                    version="outline-white"
                    className="filter-btn w-32 flex items-center gap-2"
                  >
                    <span className="text-base">Filter</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                      />
                    </svg>
                  </Button>
                </div> */}
                {(auth.currentUser.displayName === "Naskay Technologies" ||
                  auth.currentUser.displayName === "Testing" ||
                  libraryType === "custom") && (
                  <div className="flex items-center justify-center">
                    <Button
                      className="h-full w-[200px]"
                      text=""
                      handleClick={() =>
                        navigate(
                          `/admin/questionLibrary/createNew?libraryType=${libraryType}`
                        )
                      }
                    >
                      <div className="flex items-center gap-2">
                        <span className="text-xl font-semibold">+</span>
                        <span> Add Question </span>
                      </div>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-[100%] h-[calc(100vh-34%)] overflow-hidden px-4 py-1">
          <div className="w-full h-full overflow-x-auto scrollable rounded-xl border-2 border-gray-200">
            <QuestionsListing
              questions={questions}
              loading={loading}
              // libraryType={libraryType}
              // setSortValue={setSortValue}
              setViewQuestion={setViewQuestion}
              setViewQuestionData={setViewQuestionData}
            />
            {/* <div
              className={`absolute right-12 rounded-full shadow-md transition-all duration-300 backdrop-blur-[2px] ${
                showScrollToTop
                  ? "bottom-8 opacity-100"
                  : "-bottom-12 opacity-0"
              }`}
            >
              <FaArrowCircleUp
                className="w-12 h-12 text-gray-600/60 cursor-pointer"
                // onClick={scrollToTop}
              />
            </div> */}
          </div>
        </div>
      </div>
      {viewQuestion && (
        <QuestionView
          question={viewQuestionData}
          setViewQuestion={setViewQuestion}
          libraryType={libraryType}
          viewQuestion={viewQuestion}
        />
      )}
    </div>
  );
};

export default QuestionLibrary;
