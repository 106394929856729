import { useState } from "react";
import { Button, Logo, TextInput } from "../../components";
import { useNavigate } from "react-router-dom";
import dbService from "../../firebase/dbService";

const ExamLogin = () => {
  const [userEmail, setUserEmail] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // fetchSchedule from firebase db 'assessmentTests' Based on email and exam code
    const fetchSchedule = async () => {
      try {
        const schedule = await dbService.assessmentLoginFetchSchedule(
          "assessmentTests",
          userEmail,
          code
        );

        if (!schedule)
          return setError((prev) => ({
            ...prev,
            other: "Invalid Email Id or Exam Code",
          }));

        if (schedule?.attempted)
          return setError((prev) => ({
            ...prev,
            other: "You have already attempted the test.",
          }));

        // saving schedule Details to local storage
        let scheduleDetails = {
          schedule,
          isCandidateLogin: true,
        };
        localStorage.removeItem("talentigo-examAuth");
        localStorage.setItem(
          "talentigo-examAuth",
          JSON.stringify(scheduleDetails)
        );
        navigate("/assessment/start");
      } catch (error) {
        throw new Error(error);
      }
    };

    if (userEmail && code) {
      if (window.innerWidth <= 820)
        return setError((prev) => ({
          ...prev,
          other: "Please join via a Laptop/PC.",
        }));
      setLoading(true);
      try {
        await fetchSchedule();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    if (userEmail === "") {
      setError((prev) => ({
        ...prev,
        email: "Please fill your email Id",
      }));
    }
    if (code === "") {
      setError((prev) => ({
        ...prev,
        code: "Please fill the Assessment Code",
      }));
    }
  };

  return (
    <div className="right-item w-[70%] h-full mx-auto flex flex-col justify-center">
      <div className="flex flex-col">
        <div className="mb-10 flex items-start">
          <Logo width={"12rem"} />
        </div>
        <span className="font-medium text-lg leading-[27px] mb-3">
          Welcome to
        </span>
        <h1 className="heading1 mb-10">Technical Skills Assessment Test</h1>
      </div>
      <div className="w-full">
        <form onSubmit={handleSubmit}>
          <div className="mb-[14px]">
            <label
              htmlFor="email"
              className="font-medium text-sm leading-[12px] mb-3"
            >
              Email Id<sup>*</sup>
            </label>
            <TextInput
              type="email"
              placeholderText="Enter your Email id"
              error={error?.email && "error"}
              inputName="email"
              isRequired={true}
              inputValue={userEmail}
              handleChange={(e) => setUserEmail(e.target.value)}
            />
            {
              <div className="font-medium text-xs leading-3 h-3 text-[#f00] mt-1">
                {error?.email}
              </div>
            }
          </div>
          <div className="mb-[14px]">
            <label
              htmlFor="examCode"
              className="font-medium text-sm leading-[12px] mb-3"
            >
              Assessment Code<sup>*</sup>
            </label>

            <TextInput
              type="password"
              placeholderText="Assessment Code"
              error={error?.code && "error"}
              inputName="examCode"
              inputValue={code}
              handleChange={(e) => setCode(e.target.value)}
              isRequired={true}
            />

            {
              <div className="font-medium text-xs leading-3 h-3 text-[#f00] mt-1">
                {error?.code}
              </div>
            }
          </div>

          <div className="flex items-start gap-4 flex-col">
            <>
              <Button
                width="10rem"
                text="Proceed"
                type="submit"
                inputId="loginButton"
                loading={loading}
              />
              {error?.other && (
                <div className="font-medium text-sm leading-4 text-[#f00]">
                  {error.other}
                </div>
              )}
            </>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExamLogin;
