import {
  getFirestore,
  collection,
  doc,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  setDoc,
  onSnapshot,
} from "firebase/firestore";

import { app } from "../config/firebase";
import authService from "./authService";

export class FirebaseDbService {
  db;
  constructor() {
    this.db = getFirestore(app);
  }

  // Database service methods

  // Read
  async getAuthDetails(collectionName, uid) {
    try {
      const collectionRef = collection(this.db, collectionName);
      const q = query(collectionRef, where("uid", "==", uid));

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return null;
      } else {
        const userDoc = querySnapshot.docs[0];
        return {
          id: userDoc.id,
          ...userDoc.data(),
        };
      }
    } catch (error) {
      console.log("Error in getAdminDetails():", error);
      return false;
    }
  }

  async getCompanyDetailsWithName(companyName) {
    try {
      const q = query(
        collection(this.db, "adminProfile"),
        where("companyName", "==", companyName)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        return null;
      } else {
        const adminDeails = querySnapshot.docs.map((doc) => ({
          applicationId: doc.id,
          ...doc.data(),
        }));
        const { companyName, companyEmail, companyUrl, logoUrl } =
          adminDeails[0];
        return { companyName, companyEmail, companyUrl, logoUrl };
      }
    } catch (error) {
      console.log("Error in getCompanyDetailsWithName():", error);
      return false;
    }
  }

  async getAllDocs(collectionName) {
    try {
      const q = query(collection(this.db, collectionName));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    } catch (error) {
      console.log("Error in getAllDocs:", error);
      return false;
    }
  }

  async getSchedulesForApplication(applicationId) {
    let q = query(
      collection(this.db, "assessmentTests"),
      where("applicationId", "==", applicationId),
      orderBy("scheduleDate", "asc")
    );
    const data = [];
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return null;
    } else {
      querySnapshot.docs.forEach((doc) => {
        data.push({
          id: doc.id,
          ...doc.data(),
        });
      });
    }
    return data;
  }

  async getAllJobs(sort) {
    try {
      const admin = await authService.getCurrentUser();
      const jobsRef = collection(this.db, "jobsPosted");
      let q = query(
        jobsRef,
        where("companyName", "==", admin?.displayName),
        where("isDeleted", "==", false),
        orderBy(sort.fieldName, sort.fieldValue)
      );

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    } catch (error) {
      console.log("Error in getAllJobs():", error);
      return false;
    }
  }

  async getAllQuestions({ collectionName, addedBy, sort }) {
    try {
      const admin = await authService.getCurrentUser();
      const questionsRef = collection(this.db, collectionName);
      let q = query(questionsRef, where("isDeleted", "==", false));
      if (addedBy) {
        q = query(q, where("addedBy", "==", admin?.displayName));
      }
      if (sort) {
        q = query(q, orderBy(sort.fieldName, sort.fieldValue));
        console.log("sortvalue");
      }
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    } catch (error) {
      console.log("Error in getAllQuesitons():", error);
      return false;
    }
  }

  async getAllAssessments(sort) {
    try {
      const admin = await authService.getCurrentUser();
      const jobsRef = collection(this.db, "assessments");
      let q = query(
        jobsRef,
        where("companyName", "==", admin?.displayName),
        where("isDeleted", "==", false),
        orderBy(sort.fieldName, sort.fieldValue)
      );

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    } catch (error) {
      console.log("Error in getAllJobs():", error);
      return false;
    }
  }

  async getAllApplications(jobId) {
    try {
      const admin = await authService.getCurrentUser();
      const applicationRef = collection(this.db, "candidateApplications");
      let q = query(
        applicationRef,
        where("appliedAtCompany", "==", admin?.displayName)
      );
      if (jobId) {
        q = query(q, where("appliedJobId", "==", jobId));
      }
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    } catch (error) {
      console.log("Error in getAllJobs():", error);
      return false;
    }
  }

  async getdocument(collectionName, id) {
    try {
      const docRef = doc(this.db, collectionName, id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return { ...docSnap.data(), id: docSnap.id };
      } else {
        console.log("Document not found!");
        return false;
      }
    } catch (error) {
      console.log("Error in getDocument():", error);
      return false;
    }
  }

  getLiveDocument(collectionName, id, callback) {
    try {
      const docRef = doc(this.db, collectionName, id);

      // Set up real-time listener
      const unsubscribe = onSnapshot(
        docRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            // Call the callback with the updated document data
            callback({
              ...docSnapshot.data(),
              id: docSnapshot.id,
            });
          } else {
            console.log("Document not found!");
            callback(false);
          }
        },
        (error) => {
          console.log("Error in getLiveDocument():", error);
          callback(false);
        }
      );

      // Return the unsubscribe function so the caller can stop listening when needed
      return unsubscribe;
    } catch (error) {
      console.log("Error in getLiveDocument():", error);
      callback(false);
      return () => {}; // Return empty function in case of error
    }
  }

  async assessmentLoginFetchSchedule(collectionName, email, code) {
    try {
      const collectionRef = collection(this.db, collectionName);
      const q = query(
        collectionRef,
        where("candidateEmail", "==", email),
        where("examCode", "==", code)
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return null;
      } else {
        const userDoc = querySnapshot.docs[0];
        return {
          id: userDoc.id,
          ...userDoc.data(),
        };
      }
    } catch (error) {
      console.log("Error in getAdminDetails():", error);
      return false;
    }
  }

  // Create
  async createDocument(collectionName, data) {
    try {
      const docRef = await addDoc(collection(this.db, collectionName), data);
      return docRef;
    } catch (error) {
      console.log("Error in createDocumentWithId():", error);
      return false;
    }
  }

  async createDocumentWithId(collectionName, data, id) {
    try {
      await setDoc(doc(this.db, collectionName, id), data);
      return true;
    } catch (error) {
      console.log("Error in createDocumentWithId():", error);
      return false;
    }
  }

  // Update
  async updateDocument(collectionName, data, id) {
    try {
      const docRef = doc(this.db, collectionName, id);
      await updateDoc(docRef, data);
      return docRef;
    } catch (error) {
      console.log("Error in updateDocument():", error);
      return false;
    }
  }

  // Delete
  async deleteDocument(collectionName, id) {
    try {
      const docRef = doc(this.db, collectionName, id);
      await deleteDoc(docRef);
      return true;
    } catch (error) {
      console.log("Error in deleteDoc():", error);
      return false;
    }
  }
}

const dbService = new FirebaseDbService();
export default dbService;
