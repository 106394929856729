import { getMetadata, getStorage, ref } from "firebase/storage";
import React, { useCallback, useEffect, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { LuPrinter } from "react-icons/lu";
import { IoEyeOutline } from "react-icons/io5";
import { storage } from "../../../config/firebase";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";
// import { ref } from "firebase/storage";

const CandidateResume = ({
  candidateDetails,
  applicationDetails,
  setResumeOpen,
}) => {
  const [fileMeta, setFileMeta] = useState();
  useEffect(() => {
    const fileUrl = applicationDetails?.resumeUrl;
    if (fileUrl) {
      const storage = getStorage();
      const storageRef = ref(storage, fileUrl);
      getMetadata(storageRef)
        .then((metadata) => {
          setFileMeta(metadata);
        })
        .catch((error) => {
          console.error(`Error getting file metadata: ${error}`);
        });
    }
  }, [applicationDetails]);

  // const downloadFile =  async ()=> {
  //   const filePath =applicationDetails?.resumeUrl
  //   console.log("file path", filePath)
  //   try {
  //     // Get a reference to the file
  //     // const fileRef = await storage.ref().child(filePath);

  //     // // Get the download URL for the file
  //     // const downloadURL = await fileRef.getDownloadURL();

  //     // // Create a temporary anchor element to initiate the download
  //     // const link = document.createElement("a");
  //     // link.href = downloadURL;
  //     link.href = filePath ;

  //     link.download = "filename"; // Specify the desired filename for the downloaded file
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   }
  // }

  const shareResume = useCallback(
    async (link) => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: `Resume Link for - ${candidateDetails?.name}`,
            url: applicationDetails?.resumeUrl,
          });
        } catch (error) {
          console.log("Error sharing link: ", error);
        }
      } else {
        console.log("Web Share API not supported");
      }
    },
    [applicationDetails, candidateDetails]
  );

  return (
    <div className="p-4 py-5 w-full h-full">
      {applicationDetails?.resumeUrl ? (
        <div className="flex justify-between w-full items-center">
          <div className="text-lg font-medium">
            {candidateDetails?.name} Resume{" "}
            <span className="text-[#979797] text-sm">
              {fileMeta
                ? `(${fileMeta?.name}.${fileMeta?.contentType?.split("/")[1]})`
                : ""}
            </span>
          </div>
          <div className="flex gap-4">
            <div
              className="py-2 px-4 rounded-md bg-[#8A24F1]/10 text-[#8A24F1] cursor-pointer"
              data-tooltip-id="viewTooltip"
              onClick={() => setResumeOpen(true)}
            >
              <IoEyeOutline className="w-5 h-5" />
            </div>
            <a
              href={applicationDetails?.resumeUrl}
              target="_blank"
              rel="noreferrer"
              download="resume.png"
            >
              <div
                className="py-2 px-4 rounded-md bg-[#8A24F1]/10 text-[#8A24F1] cursor-pointer"
                data-tooltip-id="downloadTooltip"
              >
                <FiDownload className="w-5 h-5" />
              </div>
            </a>

            <div
              className="py-2 px-4 rounded-md bg-[#8A24F1]/10 text-[#8A24F1]  cursor-pointer"
              data-tooltip-id="shareTooltip"
              onClick={shareResume}
            >
              <FaRegShareFromSquare className="w-5 h-5" />
            </div>
            {/* <div className="py-2 px-4 rounded-md bg-[#8A24F1]/10 text-[#8A24F1]">
              <LuPrinter
                className="w-5 h-5 cursor-pointer"
                onClick={() => {
                  window.print();
                }}
                // onClick={() => window.print()}
              />
            </div> */}
            <Tooltip id="viewTooltip" place="bottom" className="text-xs">
              View
            </Tooltip>
            <Tooltip id="downloadTooltip" place="bottom" className="text-xs">
              Download
            </Tooltip>

            <Tooltip id="shareTooltip" place="bottom" className="text-xs">
              Share Link
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className=" text-gray-500 font-semibold text-lg">
          Resume not available
        </div>
      )}
    </div>
  );
};

export default CandidateResume;
