import React from "react";

const JobDetails = ({ jobData }) => {
  const jobDetails = [
    { title: "Job Opening Id", name: "jobOpeningId" },
    { title: "Position Title", name: "positionTitle" },
    { title: "Department Manager", name: "departmentManager" },
    { title: "Assigned Recruiter", name: "assignedRecruiter" },
    { title: "Date Opened", name: "jobPostedDate" },
    { title: "Target Date", name: "targetDate" },
    { title: "Last Date To Apply", name: "lastDateToApply" },
    { title: "Created Time", name: "jobPostedDate" },
    { title: "Job Type", name: "jobType" },
    { title: "Job Preference", name: "jobPreference" },
    { title: "Work Experiance", name: "workExperiance" },
    { title: "Job Status", name: "jobStatus" },
    { title: "Salary", name: "salary" },
    // { title: "Created By", name: "createdBy"},
    // { title: "Modified By", name: "modifiedBy" },
    // { title: "Modified Time", name: "modifiedTime" },
    { title: "Number Of Position", name: "numberOfPosition" },
    // { title: "Number Of Candidate Hired", name: "numberOfCandidateHired" },
  ];
  const addressDetails = [
    { title: "Country", name: "country" },
    { title: "State", name: "state" },
    { title: "City", name: "city" },
    { title: "Pin/Zip Code", name: "postalCode" },
  ];
  return (
    <div className="space-y-4 w-full p-6 lg:px-12">
      <h1 className="text-lg font-semibold">Job Opening Information</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-5">
        {jobDetails.map((job) => (
          <div key={job.title} className="text-sm font-medium">
            <div className="text-[#666]">{job.title}</div>
            <div className="capitalize">
              {job.title === "Created Time"
                ? new Date(jobData[job.name]?.seconds * 1000).toLocaleString(
                    "en-in",
                    {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    }
                  )
                : job.name === "jobPostedDate" ||
                  job.name === "targetDate" ||
                  job.name === "lastDateToApply"
                ? new Date(
                    jobData[job.name]?.seconds * 1000
                  ).toLocaleDateString("en-in", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                : job.name === "salary"
                ? isNaN(jobData[job.name]?.max) || jobData[job.name]?.max === 0
                  ? "Not Disclosed"
                  : `₹ ${jobData[job.name]?.min} - ₹ ${jobData[job.name]?.max}`
                : job.name === "jobPreference"
                ? "Work from " +
                  (jobData[job.name] === "wfh" ? " Home" : "Office")
                : job.name === "workExperiance"
                ? jobData[job.name] === 0
                  ? "Fresher"
                  : jobData[job.name] + " years"
                : jobData[job.name]
                ? jobData[job.name]
                : "-"}
            </div>
          </div>
        ))}
      </div>

      <h1 className="text-md font-semibold">Address Information</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-7">
        {addressDetails.map((address) => (
          <div key={address.name} className="text-sm font-medium">
            <div className="text-[#666]">{address.title}</div>
            <div>
              {jobData?.address && jobData?.address[address.name]
                ? jobData?.address[address.name]
                : "-"}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobDetails;
