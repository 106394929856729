import React, { useEffect, useRef, useState } from "react";
import BasicInfo from "./BasicInfo";
import ProfessionalDetails from "./ProfessionalDetails";
import EducationDetails from "./EducationDetails";
import ExperienceDetails from "./ExperienceDetails";
import PositionTitle from "./PositionTitle";
import { validateEmail } from "../../../utils/validate";
import { validatePhoneNumber } from "../../../utils/validate";
import { Button, UploadResume } from "../../../components";
import OtherInfo from "./OtherInfo";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { createApplication } from "../../../redux/features/applicationsSlice";
import { setTitle } from "../../../redux/features/pageTitleSlice";

const NewCandidate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [resumeUrl, setResumeUrl] = useState("");
  const [dragging, setDragging] = useState(false);
  const [jobId, setJobId] = useState("");

  useEffect(() => {
    dispatch(setTitle({ title: "New Candidate", showBack: true }));
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const formElement = formRef?.current;

    if (!validateEmail(formElement?.email?.value)) {
      return setError("Invalid Email");
    }
    if (!validatePhoneNumber(formElement?.phoneNumber?.value)) {
      return setError("Invalid Phone Number");
    }
    // if (!resumeUrl) {
    //   return setError("Upload your resume");
    // }

    // education details
    const educationCount = formElement?.institute?.length;
    let educationDetails = [];
    if (educationCount > 1) {
      for (let i = 0; i < educationCount; i++) {
        educationDetails.push({
          grade: formElement?.grade[i]?.value,
          institute: formElement?.institute[i].value,
          educationBoard: formElement?.grade[i].value.includes("Class")
            ? formElement.board[i].value
            : "",
          course: formElement.grade[i].value.includes("Class")
            ? ""
            : formElement.course[i].value,
          specialization: formElement.grade[i].value.includes("Class")
            ? ""
            : formElement.specialization[i].value,
          duration: {
            from: formElement.eduFromDate[i].value,
            pursuing: formElement.pursuing[i].checked,
            to: formElement.pursuing[i].checked
              ? null
              : formElement.eduToDate[i].value,
          },
        });
      }
    } else if (educationCount) {
      educationDetails.push({
        institute: formElement.institute.value,
        grade: formElement.grade.value,
        educationBoard: formElement.board ? formElement.board.value : "",
        course: formElement.course ? formElement.course.value : "",
        specialization: formElement.specialization
          ? formElement.specialization.value
          : "",
        marks: formElement.marks.value,
        duration: {
          from: formElement.eduFromDate.value,
          pursuing: formElement.pursuing.checked,
          to: formElement.pursuing.checked ? null : formElement.eduToDate.value,
        },
      });
    }

    // ExperienceDetails
    const experianceCount = formElement.occupation.length;
    let experianceDetails = [];
    if (experianceCount > 1) {
      for (let i = 0; i < experianceCount; i++) {
        experianceDetails.push({
          title: formElement.occupation[i].value,
          companyName: formElement.companyName[i].value,
          ctc: formElement.ctc[i].value,
          duration: {
            from: formElement.expFromDate[i].value,
            working: formElement.working[i]?.checked,
            to: formElement.working[i]?.checked
              ? null
              : formElement.expToDate[i].value,
          },
        });
      }
    } else if (experianceCount) {
      experianceDetails.push({
        title: formElement.occupation.value,
        companyName: formElement.companyName.value,
        ctc: formElement.ctc.value,
        duration: {
          from: formElement.expFromDate.value,
          working: formElement.working?.checked,
          to: formElement.working?.checked ? null : formElement.expToDate.value,
        },
      });
    }

    const skillsArray = formElement.skills.value.split(",");
    const skills = skillsArray?.map((s) => s.trim())?.filter((s) => s !== "");

    const data = {
      profile: {
        email: formElement.email.value,
        phoneNumber: `+91${formElement.phoneNumber.value}`,
        name: formElement.candidateName.value,
        location: formElement.location.value,
        postalCode: formElement.postalCode.value,
        workExperience: formElement.workExperiance.value
          ? Number(formElement.workExperiance.value)
          : "",
        positionTitle: formElement.positionTitle.value,
        currentJobTitle: formElement.currentJobTitle.value,
        currentEmployer: formElement.currentEmployer.value,
        currentSalary: Number(formElement.currentSalary.value) || "",
        expectedSalary: Number(formElement.expectedSalary.value) || "",
        skills: skills,
        education: educationDetails,
        experiance: experianceDetails,
        resumeUrl,
      },
      application: {
        appliedPosition: formElement.jobPosition.value,
        appliedJobId: jobId,
        source: formElement.source.value,
        resumeUrl,
        processed: false,
      },
    };

    setLoading(true);
    try {
      await dispatch(createApplication({ data }))
        .unwrap()
        .catch((error) => {
          throw new Error(error);
        });
      toast.success("Success! The candidate has been added.");
      navigate("/admin/ats");
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="main-container w-full mx-auto md:ml-20 mt-1 flex flex-col lg-px-10">
      <div
        className="card py-6 px-10 w-full relative h-[90vh] overflow-y-hidden"
        onDragOver={() => setDragging(true)}
        onDrop={() => setDragging(false)}
      >
        {/* <h1 className="heading1">Add New Candidate</h1> */}
        <form
          className="space-y-8 mt-2 h-full overflow-y-scroll scrollable pr-6 pb-10"
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <PositionTitle setJobId={setJobId} />
          <BasicInfo />
          <ProfessionalDetails />
          <OtherInfo />
          <EducationDetails />
          <ExperienceDetails />
          <div className="md:w-1/2 xl:w-1/3">
            <UploadResume
              setResumeUrl={setResumeUrl}
              dragging={dragging}
              setDragging={setDragging}
            />
          </div>

          <div className="text-sm text-red-500">{error}</div>
          <div className="w-full flex gap-4 justify-end">
            <Link to={-1}>
              <Button width="160px" version="secondary" text="Cancel" />
            </Link>

            <Button width="160px" loading={loading} text="Save" type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewCandidate;
