import React, { useEffect, useRef, useState } from "react";
import { Button, SelectInput, TextInput } from "../../../components";
import { FaRegEdit, FaSearch, FaRegCheckCircle } from "react-icons/fa";
import { IoAddCircleOutline } from "react-icons/io5";
import {
  Timestamp,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";

import { db } from "../../../config/firebase";
import AddedSkillsTable from "./AddedSkillsTable";
import Popup from "../../../components/popup/Popup";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { auth } from "../../../config/firebase";
import { useDispatch } from "react-redux";
import {
  createAssessment,
  updateAssessment,
} from "../../../redux/features/assessmentsSlice";

const LibraryAssessment = ({
  assessmentDetails,
  setShowNewAssessmentModule,
}) => {
  const [questionsOptions, setQuestionsOptions] = useState([]);
  const [documentData, setDocumentData] = useState();
  useEffect(() => {
    let options = [];
    for (let i = 5; i <= 100; i = i + 5) {
      options.push({ value: i, label: `${i} Questions` });
    }
    setQuestionsOptions(options);
  }, []);

  const navigate = useNavigate();
  const [totalQuestions, setTotalQuestions] = useState(0);

  // const [totalQuestions, setTotalQuestions] = useState({value: 0, label: 'select no of question'});
  const [totalSelectedQuestions, setTotalSelectedQuestions] = useState(0);
  const [addedSkillObjects, setAddedSkillObjects] = useState([]);
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [openSearch, setOpenSearch] = useState(false);
  const [selectQuesWarning, setSelectQuesWarning] = useState(false);
  const [error, setError] = useState([]);
  const searchMenuRef = useRef();
  const [showSuccessModule, setShowSuccessModule] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const docRef = doc(db, "assessments", assessmentDetails.editDocId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setDocumentData(data);
          setAddedSkillObjects(data.skills);
          setTotalQuestions({
            value: data.totalQuestions,
            label: `${data.totalQuestions} Questions `,
          });
          setTotalSelectedQuestions(data.totalQuestions);
        } else {
          // Document does not exist
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };

    if (assessmentDetails?.editDocId) {
      fetchDocumentData();
    }
  }, [assessmentDetails]);

  const fetchSkillsData = () => {
    setLoading(true);
    let q = query(collection(db, "questionLibraryCategories"));
    const querySnapshot = onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      const skillData = data
        .map((d) => d.skills)
        .flat()
        .map((skill) => {
          return {
            value: skill.skill,
            label: skill.skill,
          };
        })
        ?.filter((value, index, self) => {
          return index === self.findIndex((t) => t.value === value.value);
        });

      setSkills(skillData);
    });
    setLoading(false);
    return querySnapshot;
  };
  useEffect(() => {
    fetchSkillsData();
  }, []);

  useEffect(() => {
    addedSkillObjects?.map((skill) => {
      const weightage = skill?.weightage ? skill?.weightage : 0;
      skill.questions = Math.round(
        (weightage / 100) *
          (totalQuestions ? totalQuestions.value : totalQuestions)
      );

      return "";
    });
  }, [addedSkillObjects, totalQuestions]);

  useEffect(() => {
    const selectedQuestions = addedSkillObjects
      ?.map((skill) => skill?.questions)
      .reduce((a, b) => a + b, 0);

    setTotalSelectedQuestions(selectedQuestions);
  }, [addedSkillObjects]);

  useEffect(() => {
    if (openSearch) {
      const handleClickOutside = (event) => {
        searchMenuRef?.current?.contains(event.target) || setOpenSearch(false);
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [openSearch]);

  const fetchQuestions = async (skill) => {
    let q = query(
      collection(db, "questionLibrary"),
      where("isDeleted", "==", false),
      where("skills", "array-contains-any", [skill?.skillName])
    );
    if (skill?.type !== "all" && skill?.type !== undefined) {
      // filter according to question type
      q = query(q, where("type", "==", skill?.type));
    }
    if (skill?.difficulty !== undefined) {
      // filter according to question difficulty
      q = query(q, where("difficulty", "==", skill?.difficulty));
    }

    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    // random order
    data.sort(() => Math.random() - 0.5);
    //slice on no. of question
    const sliceData = data.slice(0, skill.questions);

    return sliceData;
  };

  const handleCreateAssessment = () => {
    setError([]);
    let err;
    addedSkillObjects.filter(
      (skill, index) =>
        (!skill.type || skill.type === "") &&
        (err = true) &&
        setError((prev) => [
          ...prev,
          {
            column: "type",
            index: index,
          },
        ])
    );
    addedSkillObjects.filter(
      (skill, index) =>
        (!skill.difficulty || skill.difficulty === "") &&
        (err = true) &&
        setError((prev) => [
          ...prev,
          {
            column: "difficulty",
            index: index,
          },
        ])
    );
    if (totalSelectedQuestions === 0) {
      err = true;
      setError((prev) => [
        ...prev,
        {
          column: "totalQuesZero",
          message: "Number of Questions cannot be zero.",
        },
      ]);
    }
    if (totalSelectedQuestions !== totalQuestions.value) {
      err = true;
      setError((prev) => [
        ...prev,
        {
          column: "totalQuesMatch",
          message:
            "Total Questions Selected do not match with sum of total Ques in all skills combined.",
        },
      ]);
    }

    if (!err) {
      saveAssessment();
    }
  };

  const saveAssessment = async () => {
    const questions = await Promise.all(
      addedSkillObjects.map(async (skill) => {
        const questions = await fetchQuestions(skill);
        return questions;
      })
    );
    const questionData = questions.flat().sort(() => Math.random() - 0.5);

    const quesIds = questionData?.map((data) => data?.id);
    const assessmentDuration = questionData
      ?.map((data) => Number(data?.time))
      .reduce((a, b) => a + b, 0);

    const assessmentData = {
      domain: assessmentDetails?.domain,
      experience: assessmentDetails?.experience,
      name: assessmentDetails?.name,
      position: assessmentDetails?.position,
      type: assessmentDetails?.type,
      totalQuestions: totalSelectedQuestions,
      skills: addedSkillObjects,
      duration: assessmentDuration,
      questions: quesIds,
      createdBy: "admin",
      companyName: auth?.currentUser.displayName,
      isDeleted: false,
      availability: "Ready to use",
    };
    if (assessmentDetails?.editDocId) {
      try {
        await dispatch(
          updateAssessment({
            data: {
              ...assessmentData,
              updatedAt: Timestamp.fromDate(new Date()),
            },
            id: assessmentDetails.editDocId,
          })
        );
        toast.success("Assessment Update Successfully");
        navigate("/admin/assessments");
      } catch (error) {
        console.error("Error updating document:", error);
      }
    } else {
      try {
        setSubmitLoading(true);
        await dispatch(
          createAssessment({
            data: {
              ...assessmentData,
              createdAt: Timestamp.fromDate(new Date()),
            },
          })
        );
        setShowSuccessModule(true);
        toast.success("Assessment Created Successfully");
        navigate("/admin/assessments");
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitLoading(false);
      }
    }
  };
  return (
    <div className="w-full h-full relative">
      {showSuccessModule && (
        <Popup
          closePopup={() => {
            setShowSuccessModule(false);
            navigate("/admin/assessments");
          }}
        >
          <div className="flex items-center justify-center flex-col gap-2 w-80">
            <img src="/img/working.png" className="w-24 mb-4" alt="" />
            <h1 className="text-2xl font-semibold">Congratulations</h1>
            <p className="font-medium text-center">
              The Assessment has been created successfully.
            </p>
          </div>
        </Popup>
      )}
      <div className="flex flex-col-reverse xl:flex-row items-center gap-4">
        <div className="flex gap-4 items-center">
          <div className="relative w-52 border-2 border-gray-200 rounded-xl">
            <TextInput
              innerLabel={<FaSearch className="text-gray-500" />}
              placeholderText="Search Skills"
              inputValue={searchInput}
              handleChange={(e) => setSearchInput(e.target.value)}
              handleClick={() => setOpenSearch(true)}
            />
            {openSearch ? (
              <div
                ref={searchMenuRef}
                className="absolute top-12 left-0 w-full max-h-[25rem] overflow-y-scroll scrollable rounded-lg bg-white shadow-md z-10"
              >
                {skills
                  ?.filter(
                    (skill) =>
                      skill?.value
                        ?.toLowerCase()
                        ?.includes(searchInput?.toLocaleLowerCase()) ||
                      skill?.label
                        ?.toLowerCase()
                        ?.includes(searchInput?.toLocaleLowerCase())
                  )
                  ?.map((skill) => {
                    const checked = addedSkillObjects.some(
                      (s) => s.skillName === skill.value
                    );
                    return (
                      <div
                        className={`flex py-2 px-4  
                          ${
                            checked
                              ? "bg-green-100"
                              : "cursor-pointer hover:bg-gray-200"
                          }
                        `}
                        key={skill.value}
                        onClick={() => {
                          !checked &&
                            setAddedSkillObjects([
                              ...addedSkillObjects,
                              { skillName: skill.value },
                            ]);
                        }}
                      >
                        <div className="text-gray-500 capitalize text-sm font-medium flex justify-between w-full">
                          {skill.label}
                          {checked ? (
                            <FaRegCheckCircle className="w-4 h-4 text-green-600" />
                          ) : (
                            <IoAddCircleOutline className="w-5 h-5" />
                          )}
                        </div>
                      </div>
                    );
                  })}
                <div className="sticky bottom-0 w-full flex justify-center py-1 backdrop-blur-[2px]">
                  <Button
                    text="Apply"
                    width="160px"
                    handleClick={() => {
                      setOpenSearch(false);
                      setSearchInput("");
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="relative w-60">
            <SelectInput
              placeholderText="Select total No. of Ques"
              selectOptions={questionsOptions}
              value={totalQuestions}
              handleChange={(e) => {
                setSelectQuesWarning(false);
                setTotalQuestions(e);
              }}
            />
            {selectQuesWarning && (
              <div className="top-14 h-fit absolute bg-yellow-100 border-2 border-yellow-200 rounded-xl backdrop-blur-[1px] animate-bounce flex items-center px-2 w-[16rem]">
                <div className="text-gray-700 text-xs h-full p-2">
                  Select No. of Questions for the assessment
                </div>
                <div
                  className="text-gray-600 h-full p-2 font-bold cursor-pointer"
                  onClick={() => setSelectQuesWarning(false)}
                >
                  ✕
                </div>
                <div className="absolute bg-yellow-100 w-4 h-4 -top-2 left-10 rotate-45 -z-10 border-t-2 border-l-2 border-yellow-200"></div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="bg-gray-100 shadow-md rounded-lg flex items-center p-[1px] px-4 text-sm gap-4">
            {["name", "position", "experience", "domain", "type"].map(
              (item) => (
                <div
                  className="font-medium capitalize flex flex-wrap gap-1"
                  key={item}
                >
                  <span className="text-gray-500">{item} : </span>
                  <span className="">
                    {assessmentDetails &&
                      (item === "type"
                        ? assessmentDetails[item] === "library"
                          ? "Pre-defined Library"
                          : "Custom Questions"
                        : assessmentDetails[item])}
                  </span>
                </div>
              )
            )}

            <span className="hover:bg-gray-300 p-2 rounded-full cursor-pointer">
              <FaRegEdit
                className="w-5 h-5"
                onClick={() => setShowNewAssessmentModule(true)}
              />
            </span>
          </div>
        </div>
      </div>
      <div>
        {!addedSkillObjects.length ? (
          <div className="mt-20 flex flex-col gap-4 items-center justify-center h-full w-full">
            <img
              src="/img/noSkillSelected.png"
              alt="no skills selected"
              className="grayscale"
            />
            <div className="flex flex-col gap-2 items-center justify-center">
              <h1 className="text-xl font-bold text-[#333]">
                No Skills Selected.
              </h1>
              <p className="text-[#929292] font-medium">
                Choose a skill and the number of questions on the top left.
              </p>
            </div>
          </div>
        ) : (
          <div className="py-8">
            <AddedSkillsTable
              addedSkillObjects={addedSkillObjects}
              setAddedSkillObjects={setAddedSkillObjects}
              // totalQuestions={totalQuestions.value}
              totalQuestions={
                totalQuestions ? totalQuestions.value : totalQuestions
              }
              totalSelectedQuestions={totalSelectedQuestions}
              setSelectQuesWarning={setSelectQuesWarning}
              error={error}
            />
          </div>
        )}
      </div>
      <div className="absolute bottom-4 left-0 w-full px-4 flex items-center justify-between">
        <div className="relative flex items-center gap-8 font-medium">
          {error.some(
            (e) => e.column === "totalQuesMatch" || e.column === "totalQuesZero"
          ) && (
            <div className="text-xs text-red-400 absolute -top-14 bg-white p-2 rounded-xl border-2">
              {error.some((e) => e.column === "totalQuesMatch") &&
                "Total Questions Selected do not match with sum of total Ques in all skills combined."}
              {error.some((e) => e.column === "totalQuesZero") &&
                "Total Number of Questions cannot be Zero."}
              <div className="absolute bg-white w-4 h-4 -bottom-2 left-40 rotate-45 border-b-2 border-r-2" />
            </div>
          )}

          <div>Skills : {addedSkillObjects.length}</div>
          <div>No. of Questions : {totalSelectedQuestions}</div>
          {/* <div>Total Duration : 0</div> */}
        </div>
        <div className="flex gap-2">
          <Button
            version="secondary"
            text="Cancel"
            width="200px"
            handleClick={() => navigate("/admin/assessments")}
            // loading={submitLoading}
          />
          <Button
            text={
              assessmentDetails?.editDocId
                ? "Update Assessment"
                : "Create Assessment"
            }
            width="240px"
            handleClick={handleCreateAssessment}
            loading={submitLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default LibraryAssessment;
