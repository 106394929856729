import { useCallback, useEffect, useState } from "react";

const usePreventCopyPasteScrnShot = () => {
    const [violation, setViolation] = useState(null)
    const preventCopyPaste = useCallback((e) => {
        // Prevent Ctrl+S, Ctrl+C & Ctrl+V
        e = e || window.event;
        if (e.ctrlKey) {
            let eventCode = e.code;
            if (
                ["ShiftLeft", "ShiftRight", "KeyS", "KeyC", "KeyX", "KeyV"].includes(
                    eventCode
                )
            ) {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    }, [])

    const preventScreenShot = useCallback((e) => {
        // Prevent PrintScreen
        e = e || window.event;
        if (e.code === "PrintScreen") {
            e.preventDefault();
            e.stopPropagation();
            return true;
        } else {
            return false;
        }
    }, [])

    useEffect(() => {
        const handleKeyDown = (e) => {
            preventCopyPaste(e);
        };

        const handleKeyUp = (e) => {
            if (preventScreenShot(e)) {
                setViolation(Date.now());
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        // cleanup 
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [preventCopyPaste, preventScreenShot]);


    return violation
}

export default usePreventCopyPasteScrnShot