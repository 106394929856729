import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAdmin } from "../../../redux/features/adminAuthSlice";
import { Logo, Button, TextInput } from "../../../components";
import authService from "../../../firebase/authService";
import { toast } from "react-toastify";

const AdminLogin = () => {
  document.title = "Talentigo | Login";
  const [userEmail, setUserEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [showPasswordField, setShowPasswordField] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.adminAuth);
  const [resetPassLoading, setResetPassLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!userEmail)
      setError((prev) => ({
        ...prev,
        email: "Please enter your email",
      }));
    if (!pass)
      setError((prev) => ({
        ...prev,
        password: "Please enter your password",
      }));

    if (!userEmail || !pass) return;

    dispatch(loginAdmin({ email: userEmail, password: pass }))
      .unwrap()
      .then(() => {
        navigate("/admin");
      })
      .catch((error) => {
        toast.error(error);
        if (error === "No user found for the this email id.") {
          setError((prev) => ({
            ...prev,
            email: error,
          }));
        } else if (error === "Invalid password.") {
          setError((prev) => ({
            ...prev,
            password: error,
          }));
        } else {
          setError((prev) => ({
            ...prev,
            other: error,
          }));
        }
      });
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError(null);
    if (!userEmail)
      return setError((prev) => ({
        ...prev,
        email: "Please enter your email",
      }));

    setResetPassLoading(true);
    try {
      await authService.passwordReset(userEmail);
      alert(`Password reset email sent to your emailId - ${userEmail}`);
      toast.success(
        `Password reset email sent to your emailId - ${userEmail}`,
        {
          autoClose: false,
        }
      );
      setShowPasswordField(true);
    } catch (err) {
      setError((prev) => ({
        ...prev,
        other: err.code,
      }));
    } finally {
      setResetPassLoading(false);
    }
  };

  return (
    <div className="admin-main">
      <div className="admin-card flex justify-center items-center h-full sm:py-5 w-full gradient2">
        <div className="h-full w-full flex flex-col justify-center items-center p-10 sm:px-14 md:px-20 gap-4">
          {/* <div className='ribbon-box'>{envConfig.appVersion}</div> */}
          <Logo />
          <form
            onSubmit={showPasswordField ? handleSubmit : handleForgotPassword}
            className="flex flex-col gap-4 w-full"
          >
            <h1>Login to your account</h1>

            <div className="flex flex-col mb-[14px]">
              <label
                htmlFor="email"
                className="font-medium text-sm leading-[12px] mb-2"
              >
                Email id
              </label>
              <TextInput
                type="email"
                placeholderText="Enter your Email id"
                inputName="email"
                // isRequired={true}
                inputValue={userEmail}
                handleChange={(e) => setUserEmail(e.target.value)}
              />
              {error?.email && (
                <div className="font-medium text-xs leading-3 h-3 text-[#f00] mt-1">
                  {error.email}
                </div>
              )}
            </div>
            <div className="flex flex-col forgot-field password-field">
              {showPasswordField && (
                <>
                  <label
                    htmlFor="password"
                    className="font-medium text-sm leading-[12px] mb-2"
                  >
                    Password
                  </label>

                  <TextInput
                    type={passwordType}
                    placeholderText="Enter your Password"
                    inputName="password"
                    // isRequired={true}
                    inputValue={pass}
                    handleChange={(e) => setPass(e.target.value)}
                  />
                  <img
                    src={`./img/eye-${
                      passwordType === "text" ? "open" : "close"
                    }.png`}
                    alt="eye-icon"
                    onClick={() =>
                      passwordType === "password"
                        ? setPasswordType("text")
                        : setPasswordType("password")
                    }
                  />

                  {error?.password && (
                    <div className="font-medium text-xs leading-3 h-3 text-[#f00] mt-1">
                      {error.password}
                    </div>
                  )}
                  <Link
                    className="forgot-txt text-sm w-full font-medium text-right hover:underline mt-1"
                    onClick={() => setShowPasswordField(false)}
                  >
                    Forgot Password
                  </Link>
                </>
              )}
            </div>

            <div className="flex flex-col items-center justify-center gap-2 mt-4">
              <div className="font-medium text-xs leading-3 h-3 text-[#f00]">
                {error?.other || ""}
              </div>

              <Button
                version="primary"
                text={showPasswordField ? "Login" : "Reset Password"}
                type="submit"
                width="15rem"
                inputId="loginButton"
                loading={loading || resetPassLoading}
              />
            </div>
          </form>
          {
            <div className="mt-4 flex flex-col justify-center items-center gap-4 w-full">
              {showPasswordField ? (
                <>
                  <p className="text-sm">OR</p>
                  <div className="flex justify-center gap-1 w-full">
                    Don't have any account?
                    <Link
                      to="/register"
                      className="font-medium hover:underline"
                    >
                      Register
                    </Link>
                  </div>
                </>
              ) : (
                <Link
                  onClick={() => setShowPasswordField(true)}
                  className="font-medium hover:underline"
                >
                  Cancel
                </Link>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
