const FIREBASE_AUTH_ERRORS = {
    // User-related errors
    'auth/user-not-found': {
        code: 'auth/user-not-found',
        message: 'No user found for the this email id.',
        solution: 'Verify the email address or prompt user to sign up.'
    },
    'auth/wrong-password': {
        code: 'auth/wrong-password',
        message: 'Invalid password.',
        solution: 'Ask user to check their password or use "Forgot Password" functionality.'
    },
    'auth/email-already-in-use': {
        code: 'auth/email-already-in-use',
        message: 'The email address is already registered with another account.',
        solution: 'Suggest user login or use a different email address.'
    },
    'auth/invalid-email': {
        code: 'auth/invalid-email',
        message: 'The email address is not formatted correctly.',
        solution: 'Validate email format before submission.'
    },
    'auth/user-disabled': {
        code: 'auth/user-disabled',
        message: 'The user account has been disabled by an administrator.',
        solution: 'Contact support or administrator to reactivate the account.'
    },

    // Password-related errors
    'auth/weak-password': {
        code: 'auth/weak-password',
        message: 'The password is too weak and does not meet security requirements.',
        solution: 'Enforce stronger password criteria (e.g., minimum length, complexity).'
    },

    // Operation-related errors
    'auth/operation-not-allowed': {
        code: 'auth/operation-not-allowed',
        message: 'The authentication method is not enabled in the Firebase project.',
        solution: 'Enable email/password authentication in Firebase Console.'
    },
    'auth/too-many-requests': {
        code: 'auth/too-many-requests',
        message: 'Too many unsuccessful login attempts. Account temporarily blocked.',
        solution: 'Wait for a short period or reset password, implement rate limiting.'
    },

    // Network and configuration errors
    'auth/network-request-failed': {
        code: 'auth/network-request-failed',
        message: 'Network error occurred during authentication request.',
        solution: 'Check internet connection, retry the operation.'
    }
};



// error handling function
export function handleFirebaseAuthError(error) {
    const errorCode = error.code;
    const errorDetails = FIREBASE_AUTH_ERRORS[errorCode] || {
        code: 'auth/unknown-error',
        message: 'An unknown authentication error occurred.',
        solution: 'Please try again or contact support.'
    };

    console.error(`Firebase Auth Error: ${errorDetails.message}`);
    return errorDetails;
}