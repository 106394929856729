import React, { useCallback, useEffect, useRef, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import SearchResults from "./SearchResults";
import NotificationPanel from "./NotificationPanel";
import ProfileMenu from "./ProfileMenu";
import ChangePassword from "../../../admin/adminPanel/ChangePassword";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Topbar3 = ({ sideBarOpen, setSideBarOpen }) => {
  const navigate = useNavigate();
  // const location = useLocation().pathname;
  // const params = new URLSearchParams(window.location.search);
  // const listName = params.get("list");

  const [isprofileMenuOpen, setIsprofileMenuOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [openPasswordChange, setOpenPasswordChange] = useState(false);

  const profileButtonRef = useRef(null);
  const profileMenuRef = useRef(null);
  const notificationBtnRef = useRef(null);
  const notificationMenuRef = useRef(null);
  const searchInputRef = useRef(null);
  const searchResultRef = useRef(null);

  const [admin, setAdmin] = useState(null);
  const { adminDetails } = useSelector((state) => state.adminAuth);

  const { title, showBack } = useSelector((state) => state.pageTitle);

  useEffect(() => {
    adminDetails && setAdmin(adminDetails);
  }, [adminDetails]);

  useEffect(() => {
    searchInput === "" && setSearchOpen(false);
  }, [searchInput, setSearchOpen]);

  // handle auto closing of dialogs if clicked outside
  const clickOutside = useCallback((event, btnRef, menuRef, setMenuOpen) => {
    if (
      btnRef.current &&
      !btnRef.current.contains(event.target) &&
      menuRef.current &&
      !menuRef.current.contains(event.target)
    ) {
      setMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isprofileMenuOpen) {
      document.addEventListener("mousedown", (e) =>
        clickOutside(e, profileButtonRef, profileMenuRef, setIsprofileMenuOpen)
      );
    } else {
      document.removeEventListener("mousedown", (e) =>
        clickOutside(e, profileButtonRef, profileMenuRef, setIsprofileMenuOpen)
      );
    }
    if (isNotificationOpen) {
      document.addEventListener("mousedown", (e) =>
        clickOutside(
          e,
          notificationBtnRef,
          notificationMenuRef,
          setIsNotificationOpen
        )
      );
    } else {
      document.removeEventListener("mousedown", (e) =>
        clickOutside(
          e,
          notificationBtnRef,
          notificationMenuRef,
          setIsNotificationOpen
        )
      );
    }
    if (isSearchOpen) {
      document.addEventListener("mousedown", (e) =>
        clickOutside(e, searchInputRef, searchResultRef, setSearchOpen)
      );
    } else {
      document.removeEventListener("mousedown", (e) =>
        clickOutside(e, searchInputRef, searchResultRef, setSearchOpen)
      );
    }

    return () => {
      document.removeEventListener("mousedown", (e) =>
        clickOutside(e, profileButtonRef, profileMenuRef, setIsprofileMenuOpen)
      );
      document.removeEventListener("mousedown", (e) =>
        clickOutside(
          e,
          notificationBtnRef,
          notificationMenuRef,
          setIsNotificationOpen
        )
      );
      document.removeEventListener("mousedown", (e) =>
        clickOutside(e, searchInputRef, searchResultRef, setSearchOpen)
      );
    };
  }, [
    isprofileMenuOpen,
    isNotificationOpen,
    isSearchOpen,
    setIsprofileMenuOpen,
    setIsNotificationOpen,
    setSearchOpen,
    clickOutside,
  ]);

  return (
    <div className="main-header py-2 h-[3.5rem] mx-auto md:ml-20 flex items-center justify-center sm:justify-between gap-2">
      <div className="flex h-full gap-4">
        <div
          className="flex md:hidden small-card h-full px-3 justify-center items-center text-[#555] cursor-pointer z-10"
          onClick={() =>
            sideBarOpen === "none"
              ? setSideBarOpen("open")
              : setSideBarOpen("none")
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </div>

        <div className="hidden sm:flex items-center z-10">
          {showBack && (
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-8 h-8 cursor-pointer"
              >
                <path
                  fillRule="evenodd"
                  d="M15 10a.75.75 0 01-.75.75H7.612l2.158 1.96a.75.75 0 11-1.04 1.08l-3.5-3.25a.75.75 0 010-1.08l3.5-3.25a.75.75 0 111.04 1.08L7.612 9.25h6.638A.75.75 0 0115 10z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
          <h1 className="heading1">
            {title === "ats" ? title.toUpperCase() : title}
          </h1>
        </div>
      </div>

      <div className=" h-full flex items-center justify-end gap-2">
        <div
          className="flex small-card items-center px-3 z-10 h-full"
          ref={searchInputRef}
        >
          <input
            type="text"
            onChange={(e) => {
              setSearchInput(e.target.value);
              setSearchOpen(true);
            }}
            value={searchInput}
            className="w-full border-none text-xs outline-none rounded-xl focus:ring-0 text-[#929292]"
            placeholder="Search Candidates"
          />
          <img src="/img/search.png" alt="search icon" />
        </div>

        <SearchResults
          searchResultRef={searchResultRef}
          isSearchOpen={isSearchOpen}
          setSearchOpen={setSearchOpen}
          setSearchInput={setSearchInput}
        />

        {/* Notification Icon */}
        <motion.div
          whileTap={{ scale: 0.9 }}
          className="notification-icon flex justify-center items-center px-3 h-full small-card cursor-pointer z-10"
          onClick={() => setIsNotificationOpen((prev) => !prev)}
          ref={notificationBtnRef}
        >
          <img src="/img/bell.png" alt="notification-icon" />
          {/* {notifications?.filter((notification) => notification?.read !== true)
            .length ? (
            <div className="absolute bg-[#EA4335] border-50 border-white text-white top-[0] right-1 p-1 h-4 w-auto flex justify-center items-center rounded-full text-xs">
              {
                notifications?.filter(
                  (notification) => notification?.read !== 1
                ).length
              }
            </div>
          ) : (
            ""
          )} */}
        </motion.div>

        <NotificationPanel
          isNotificationOpen={isNotificationOpen}
          setIsNotificationOpen={setIsNotificationOpen}
          notificationMenuRef={notificationMenuRef}
        />

        {/* profile Icon */}
        <motion.div
          whileTap={{ scale: 0.9 }}
          className="profile-icon z-10 cursor-pointer"
          ref={profileButtonRef}
          onClick={() => setIsprofileMenuOpen((prev) => !prev)}
        >
          <img
            src={admin?.logoUrl ? admin?.logoUrl : "/img/adminProfile.png"}
            alt="profile"
          />
        </motion.div>

        {openPasswordChange && (
          <ChangePassword setOpenPasswordChange={setOpenPasswordChange} />
        )}

        <ProfileMenu
          admin={admin}
          isprofileMenuOpen={isprofileMenuOpen}
          setIsprofileMenuOpen={setIsprofileMenuOpen}
          setOpenPasswordChange={setOpenPasswordChange}
          profileMenuRef={profileMenuRef}
        />
      </div>
    </div>
  );
};

export default Topbar3;
