import { useCallback, useEffect, useState } from "react";
import JobRow from "./JobRow";
import { BsLayoutThreeColumns } from "react-icons/bs";
import ColumnEditMenu from "./ColumnEditMenu";
import { LuChevronsUpDown } from "react-icons/lu";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";
import { jobListingColumns } from "../../constant/tableColumns/jobListing";

const JobListing = ({ scrollableContainerRef, filters }) => {
  const {
    jobData,
    loading: jobLoading,
    error: jobFetchError,
  } = useSelector((state) => state.jobs);

  const columns = jobListingColumns;
  const [openColumnEdit, setOpenColumnEdit] = useState(false);
  const [columnList, setColumnList] = useState(columns);
  const [filterdJobs, setFilteredJobs] = useState([]);
  const [sortedJobs, setSortedJobs] = useState([]);
  useEffect(() => setFilteredJobs(jobData), [jobData]);
  useEffect(() => setSortedJobs(filterdJobs), [filterdJobs]);

  useEffect(() => {
    if (filters?.jobType?.length) {
      const filtersArr = filters?.jobType?.map((filter) =>
        filter?.value?.toLowerCase()
      );
      setFilteredJobs(
        jobData.filter((job) =>
          filtersArr?.includes(job?.jobType?.toLowerCase())
        )
      );
    } else if (filters?.jobPreference?.length) {
      const filtersArr = filters?.jobPreference?.map((filter) =>
        filter?.value?.toLowerCase()
      );
      setFilteredJobs(
        jobData.filter((job) =>
          filtersArr?.includes(job?.jobPrefence?.toLowerCase())
        )
      );
    } else if (filters?.jobStatus?.length) {
      const filtersArr = filters?.jobStatus?.map((filter) =>
        filter?.value?.toLowerCase()
      );
      setFilteredJobs(
        jobData.filter((job) =>
          filtersArr?.includes(job?.jobStatus?.toLowerCase())
        )
      );
    } else {
      setFilteredJobs(jobData);
    }
  }, [filters, jobData]);

  const handleSort = useCallback(
    (field, sortType) => {
      const sortTy =
        sortType === "" ? "desc" : sortType === "desc" ? "asc" : "desc";
      const updateCols = columns.map((column) => {
        if (column.name === field) {
          return {
            ...column,
            sortType: sortTy,
          };
        }
        return column;
      });
      setColumnList(updateCols);

      let sortedArray;
      if (sortTy === "asc") {
        sortedArray =
          field === "positionTitle" || field === "jobOpeningId"
            ? [...filterdJobs].sort((a, b) => a[field].localeCompare(b[field]))
            : [...filterdJobs].sort((a, b) => a[field] - b[field]);
      } else if (sortTy === "desc") {
        sortedArray =
          field === "positionTitle" || field === "jobOpeningId"
            ? [...filterdJobs].sort((a, b) => b[field].localeCompare(a[field]))
            : [...filterdJobs].sort((a, b) => b[field] - a[field]);
      }
      setSortedJobs(sortedArray);
    },
    [filterdJobs, columns]
  );
  return (
    <>
      <div className="w-fit h-full flex flex-col relative ">
        <div className="flex w-full py-5 pl-5 bg-[#E9EAEB] font-medium text-sm text-black md:rounded-t-xl">
          <div className="w-[60px] flex items-center">
            <BsLayoutThreeColumns
              className="w-5 h-5 cursor-pointer"
              onClick={() => setOpenColumnEdit(!openColumnEdit)}
            />
          </div>
          {columnList.map((column, index) => {
            const width = column.open ? column.width : "0";
            const opacity = column.open ? "1" : "0";
            return (
              <div
                key={index}
                className="flex items-center gap-1 transition-all duration-200"
                style={{ width, opacity }}
              >
                {column.open && (
                  <div className="flex items-center">
                    <span>{column.label}</span>
                    {column.sort && (
                      <div
                        className="ml-1 cursor-pointer"
                        onClick={() => {
                          handleSort(column.name, column.sortType);
                        }}
                      >
                        {column.sortType === "desc" ? (
                          <IoIosArrowUp className="w-4 h-4" />
                        ) : column.sortType === "asc" ? (
                          <IoIosArrowDown className="w-4 h-4" />
                        ) : (
                          <LuChevronsUpDown className="w-4 h-4" />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div
          className="h-full overflow-y-scroll scrollable"
          ref={scrollableContainerRef}
        >
          {jobLoading ? (
            <div className="text-center font-semibold text-xl text-gray-400 mt-4">
              Loading...
            </div>
          ) : jobFetchError ? (
            <div className="text-center font-semibold text-xl text-gray-400 mt-4">
              Something went wrong while fetching data, please try again
            </div>
          ) : sortedJobs?.length === 0 ? (
            <div className="text-center w-screen font-semibold text-xl text-gray-400 mt-4">
              No Jobs Posted Yet
            </div>
          ) : (
            sortedJobs?.map((jobData, index) => (
              <JobRow
                key={jobData?.jobOpeningId}
                index={index}
                columnList={columnList}
                jobData={jobData}
              />
            ))
          )}
        </div>

        <ColumnEditMenu
          openColumnEdit={openColumnEdit}
          setOpenColumnEdit={setOpenColumnEdit}
          columnList={columnList}
          setColumnList={setColumnList}
        />
      </div>
    </>
  );
};

export default JobListing;
