import { useState } from "react";
import LeftPannel from "./LeftPannel";
import RightPannel from "./RightPannel";
const Register = () => {
  const [step, setStep] = useState(1);
  return (
    <div className="w-full h-screen bg-gray-200 p-10 mb-4">
      <div className="w-full h-full flex card overflow-hidden">
        <LeftPannel step={step} />
        <RightPannel step={step} setStep={setStep} />
      </div>
    </div>
  );
};

export default Register;
