import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  query,
  where,
  limit,
  startAfter,
  orderBy,
  Timestamp,
  // doc,
} from "firebase/firestore";
import { auth, db } from "../../config/firebase";
import dbService from "../../firebase/dbService";
import authService from "../../firebase/authService";

const initialState = {
  applications: [],
  loading: true,
  loadMoreLoading: false,
  error: null,
  lastVisible: null,
  hasMore: false,
  filterJobId: null,
};

export const fetchApplications = createAsyncThunk(
  "applications/fetchApplications",
  async (
    { pageSize = 30, loadMore = false, sort, jobId, tab },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const applicationsRef = collection(db, "candidateApplications");

      let applicationsQuery = query(
        applicationsRef,
        where("appliedAtCompany", "==", auth?.currentUser?.displayName),
        orderBy(sort.fieldName, sort.fieldValue),
        limit(pageSize)
      );

      if (jobId) {
        applicationsQuery = query(
          applicationsQuery,
          where("appliedJobId", "==", jobId)
        );
      }

      if (tab) {
        if (tab === "assessed") {
          applicationsQuery = query(
            applicationsQuery,
            where("currentRound.roundType", "==", "assessment")
          );
        } else if (tab === "interviewed") {
          applicationsQuery = query(
            applicationsQuery,
            where("currentRound.roundType", "==", "interview")
          );
        } else if (tab === "offered") {
          applicationsQuery = query(
            applicationsQuery,
            where("currentRound.roundType", "==", "offered")
          );
        }
      }

      // If loading more, use last visible document
      if (loadMore && state.applications.lastVisible) {
        applicationsQuery = query(
          applicationsQuery,
          startAfter(state.applications.lastVisible)
        );
      }

      const applicationsSnapshot = await getDocs(applicationsQuery);

      const lastVisible =
        applicationsSnapshot.docs[applicationsSnapshot.docs.length - 1];

      // Extract unique candidateIds
      const uniqueCandidateIds = [
        ...new Set(
          applicationsSnapshot.docs.map(
            (doc) => doc.data().candidateProfileRef.id
          )
        ),
      ];

      let candidateProfiles = [];
      if (uniqueCandidateIds.length !== 0) {
        // Batch fetch candidate profiles
        const candidateProfilesRef = collection(db, "candidateProfile");
        const candidateProfilesQuery = query(
          candidateProfilesRef,
          where("__name__", "in", uniqueCandidateIds)
        );
        const candidateProfilesSnapshot = await getDocs(candidateProfilesQuery);

        // Create a map of candidate profiles
        candidateProfiles = new Map(
          candidateProfilesSnapshot.docs.map((doc) => [doc.id, doc.data()])
        );
      }

      const applicationIds = applicationsSnapshot.docs.map((doc) => doc.id);
      let candidateSchedules = [];
      if (applicationIds.length !== 0) {
        const candidateSchedulesRef = collection(db, "assessmentTests");
        const candidateSchedulesQuery = query(
          candidateSchedulesRef,
          where("applicationId", "in", applicationIds)
        );
        const candidateSchedulesSnapshot = await getDocs(
          candidateSchedulesQuery
        );
        candidateSchedules = new Map(
          candidateSchedulesSnapshot.docs.map((doc) => [
            doc.data().applicationId,
            candidateSchedulesSnapshot.docs
              .map((doc) => ({
                ...doc.data(),
                id: doc.id,
              }))
              .filter(
                (item) => item.applicationId === doc.data().applicationId
              ),
          ])
        );
      }

      // Map applications with candidate details
      const newApplications = applicationsSnapshot.docs.map(
        (applicationDoc) => {
          const applicationData = applicationDoc.data();
          const candidateProfile =
            candidateProfiles.get(applicationData.candidateProfileRef.id) || {};
          const candidateSchedule =
            candidateSchedules.get(applicationDoc.id) || [];

          return {
            id: applicationDoc.id,
            appliedAtCompany: applicationData.appliedAtCompany,
            appliedPosition: applicationData.appliedPosition,
            appliedJobId: applicationData.appliedJobId,
            resumeUrl: applicationData.resumeUrl,
            source: applicationData.source,
            candidateId: applicationData.candidateProfileRef.id,
            appliedAt: applicationData.appliedAt
              ? applicationData.appliedAt.toMillis()
              : null,
            currentRound: applicationData.currentRound || {},
            round: applicationData.round,
            assessmentsResults: applicationData.assessmentsResults || [],
            name: candidateProfile.name || "-",
            email: candidateProfile.email || "-",
            positionTitle: candidateProfile.positionTitle || "-",
            phoneNumber: candidateProfile.phoneNumber || "-",
            expectedSalary: candidateProfile.expectedSalary || "-",
            currentSalary: candidateProfile.currentSalary || "-",
            workExperience: candidateProfile.workExperience || "-",
            schedules: candidateSchedule,
          };
        }
      );

      return {
        applications: loadMore
          ? state.applications.applications.concat(newApplications)
          : newApplications,

        lastVisible: lastVisible,
        hasMore: newApplications.length === pageSize,
        loadMore,
      };
    } catch (error) {
      return rejectWithValue("Failed to fetch applications");
    }
  }
);

export const createApplication = createAsyncThunk(
  "applications/createApplication",
  async ({ data }, { rejectWithValue }) => {
    try {
      const profileData = data.profile;
      const canidateProfileRef = await dbService.createDocument(
        "candidateProfile",
        profileData
      );
      const currentCompany = await authService.getCurrentUser();
      const applicationData = {
        ...data.application,
        candidateProfileRef: canidateProfileRef,
        appliedAt: Timestamp.fromDate(new Date()),
        appliedAtCompany: currentCompany?.displayName,
      };

      const application = await dbService.createDocument(
        "candidateApplications",
        applicationData
      );

      const addedApplication = {
        id: application.id,
        appliedAtCompany: applicationData.appliedAtCompany,
        appliedPosition: applicationData.appliedPosition,
        appliedJobId: applicationData.appliedJobId,
        resumeUrl: applicationData.resumeUrl || "",
        source: applicationData.source,
        candidateId: applicationData.candidateProfileRef.id,
        appliedAt: applicationData.appliedAt
          ? applicationData.appliedAt.toMillis()
          : null,
        currentRound: applicationData.currentRound || {},
        round: applicationData.round,
        assessmentsResults: applicationData.assessmentsResults || [],
        name: profileData.name || "-",
        email: profileData.email || "-",
        positionTitle: profileData.positionTitle || "-",
        phoneNumber: profileData.phoneNumber || "-",
        expectedSalary: profileData.expectedSalary || "-",
        currentSalary: profileData.currentSalary || "-",
        workExperience: profileData.workExperience || "-",
      };
      return addedApplication;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createSchedule = createAsyncThunk(
  "applications/addSchedule",
  async ({ data }, { rejectWithValue, getState }) => {
    try {
      const applicationId = data.applicationId;
      const schedule = await dbService.createDocument("assessmentTests", data);

      await dbService.updateDocument(
        "candidateApplications",
        { processed: true },
        applicationId
      );
      const scheduleId = schedule.id;
      const addedSchedule = await dbService.getdocument(
        "assessmentTests",
        scheduleId
      );

      // update scheudules array of the application with applicationId
      const { applications: applicationState } = getState();
      const updatedApplications = [...applicationState.applications];
      const foundIndex = updatedApplications.findIndex(
        (application) => application.id === applicationId
      );
      if (foundIndex !== -1) {
        updatedApplications[foundIndex] = {
          ...updatedApplications[foundIndex],
          schedules: [
            ...updatedApplications[foundIndex].schedules,
            addedSchedule,
          ],
        };
      }
      return updatedApplications;

      // altenatively : using map
      // return applicationState.applications.map((application) =>
      //   application.id === applicationId
      //     ? { ...application, schedules: [...application.schedules, addedSchedule] }
      //     : application
      // );
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateSchedule = createAsyncThunk(
  "applications/updateSchedule",
  async (
    { data, scheduleId, applicationId },
    { rejectWithValue, getState }
  ) => {
    try {
      await dbService.updateDocument("assessmentTests", data, scheduleId);
      const addedSchedule = await dbService.getdocument(
        "assessmentTests",
        scheduleId
      );

      // update scheudules array of the application with applicationId
      const { applications: applicationState } = getState();
      const updatedApplications = [...applicationState.applications];
      const foundIndex = updatedApplications.findIndex(
        (application) => application.id === applicationId
      );
      if (foundIndex !== -1) {
        updatedApplications[foundIndex] = {
          ...updatedApplications[foundIndex],
          schedules: [
            ...updatedApplications[foundIndex].schedules,
            addedSchedule,
          ],
        };
      }
      return updatedApplications;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const ApplicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    setFilterJobId: (state, action) => {
      state.filterJobId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplications.pending, (state, action) => {
        if (action.meta.arg.loadMore) {
          state.loadMoreLoading = true;
        } else {
          state.loading = true;
        }
        state.error = null;
      })
      .addCase(fetchApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.loadMoreLoading = false;
        state.applications = action.payload.applications;
        state.lastVisible = action.payload.lastVisible;
        state.hasMore = action.payload.hasMore;
        state.error = null;
      })
      .addCase(fetchApplications.rejected, (state, action) => {
        state.loading = false;
        state.loadMoreLoading = false;
        state.error = action.payload;
      });
    builder.addCase(createApplication.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createApplication.fulfilled, (state, action) => {
      state.applications = [action.payload, ...state.applications];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(createApplication.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(createSchedule.fulfilled, (state, action) => {
      state.loading = false;
      state.applications = action.payload;
      state.error = null;
    });
    builder.addCase(createSchedule.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateSchedule.fulfilled, (state, action) => {
      state.loading = false;
      // state.applications = action.payload
      state.error = null;
    });
    builder.addCase(updateSchedule.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { setFilterJobId } = ApplicationsSlice.actions;
export default ApplicationsSlice.reducer;
