import React from "react";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteJob as deleteJobReducer } from "../../../redux/features/jobSlice";
import { toast } from "react-toastify";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

const JobMenu = ({ setMenuOpen, jobId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ToDo -   apply edit job, clone , print oreview
  const deleteJob = async () => {
    try {
      await dispatch(deleteJobReducer({ id: jobId })).unwrap();
      setMenuOpen(false);
      navigate(-1);
      toast.success("Success! Job has been deleted");
    } catch (error) {
      toast.error(`Error! ${error.message}`);
      console.log(error.message);
    }
  };

  const editJob = () => {
    navigate(`/admin/jobs/edit/${jobId}`);
  };

  return (
    <div className="flex items-center gap-4">
      <FaRegEdit className="w-5 h-5 cursor-pointer" onClick={editJob} />
      <MdDeleteOutline
        onClick={deleteJob}
        className="w-6 h-6 cursor-pointer text-red-500"
      />
      <RxCross2
        onClick={() => navigate(-1)}
        className="w-6 h-6 cursor-pointer"
      />
    </div>
  );
};

export default JobMenu;
