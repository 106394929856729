import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProfileLeftPanel from "./ProfileLeftPanel";
import ProfileRightPanel from "./ProfileRightPanel";
import CandidateView from "./candidateDetails/CandidateView";
import dbService from "../../firebase/dbService";
import { useDispatch } from "react-redux";
import { setTitle } from "../../redux/features/pageTitleSlice";

const CandidateProfile = () => {
  let { applicationId } = useParams();

  // fetch application details with id
  const [applicationDetails, setApplicationDetails] = useState();
  const [candidateDetails, setCandidateDetails] = useState();
  const [assessments, setAssessments] = useState();
  const [roundsData, setRoundsData] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTitle({ title: "Candidate", showBack: true }));
  }, [dispatch]);

  const fetchCandidateDetails = useCallback(async (profileId) => {
    dbService.getdocument("candidateProfile", profileId).then((data) => {
      setCandidateDetails(data);
    });
  }, []);

  const fetchSchedulesDetails = useCallback(async () => {
    dbService.getSchedulesForApplication(applicationId).then((data) => {
      setAssessments(data);
    });
  }, [applicationId]);

  useEffect(() => {
    if (applicationId) {
      // Set up the real-time listener
      const unsubscribe = dbService.getLiveDocument(
        "candidateApplications",
        applicationId,
        (data) => {
          setApplicationDetails(data);
        }
      );
      // Clean up
      return () => {
        unsubscribe();
        setApplicationDetails(null);
      };
    }
  }, [applicationId]);

  useEffect(() => {
    const profileId = applicationDetails?.candidateProfileRef?.id;
    const jobId = applicationDetails?.appliedJobId;
    if (profileId) {
      fetchCandidateDetails(profileId);
    }
    if (jobId) {
      dbService.getdocument("jobsPosted", jobId).then((data) => {
        setRoundsData(data.rounds?.filter((r) => r.roundType === "assessment"));
      });
    }
    return () => {
      setCandidateDetails(null);
    };
  }, [fetchCandidateDetails, applicationDetails]);

  useEffect(() => {
    if (applicationId) fetchSchedulesDetails();
    return () => {
      setAssessments(null);
    };
  }, [fetchSchedulesDetails, applicationId]);

  const [selectedAssessment, setSelectedAssessment] = useState(0);
  const [results, setResults] = useState();
  const [notAttempted, setNotAttempted] = useState(false);

  useEffect(() => {
    if (applicationDetails?.assessmentsResults) {
      setResults(applicationDetails?.assessmentsResults[selectedAssessment]);
      if (
        applicationDetails?.assessmentsResults.length <
        selectedAssessment + 1
      ) {
        setNotAttempted(true);
      } else {
        setNotAttempted(false);
      }
    } else {
      setNotAttempted(true);
    }
  }, [applicationDetails, selectedAssessment]);

  const [schedule, setScheudle] = useState();
  useEffect(() => {
    if (assessments) {
      const sch =
        selectedAssessment === 0
          ? assessments.filter((sch) => sch.hasOwnProperty("assessmentId"))[0]
          : assessments?.find(
              (sch) => sch?.assessmentId === assessments[selectedAssessment]?.id
            );
      console.log(assessments);
      setScheudle(sch);
    }
    //    To do : needed review
  }, [assessments, selectedAssessment]);

  const [candidateProfileView, setCandidateProfileView] = useState(false);

  return (
    <div className="w-full mt-1 lg-px-10 main-container mx-auto md:ml-20 lg:h-[90vh] flex flex-col lg:flex-row gap-3 overflow-hidden]">
      {candidateProfileView && (
        <CandidateView
          candidateDetails={candidateDetails}
          applicationDetails={applicationDetails}
          candidateProfileView={candidateProfileView}
          setCandidateProfileView={setCandidateProfileView}
        />
      )}
      <div className="w-full lg:w-1/4">
        <ProfileLeftPanel
          candidateDetails={candidateDetails}
          schedule={schedule}
          applicationDetails={applicationDetails}
          roundsData={roundsData}
          results={results}
          setCandidateProfileView={setCandidateProfileView}
        />
      </div>

      <div className="w-full lg:w-3/4">
        <ProfileRightPanel
          assessments={assessments}
          selectedAssessment={selectedAssessment}
          setSelectedAssessment={setSelectedAssessment}
          candidateDetails={candidateDetails}
          applicationDetails={applicationDetails}
          results={results}
          notAttempted={notAttempted}
        />
      </div>
    </div>
  );
};

export default CandidateProfile;
