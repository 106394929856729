import { useCallback, useEffect, useState } from 'react'

const useTerminateOnRefresh = () => {
    const [violation, setViolation] = useState(false);
    // Terminate on Refresh
    useEffect(() => {
        const isRefresh = localStorage.getItem("talentigo-refresh");
        if (isRefresh) {
            setViolation(true);
            // showViolations("refresh");
        }
    }, []);

    const handleBeforeUnload = useCallback((e) => {
        e.preventDefault();
        localStorage.setItem("talentigo-refresh", true);
    }, [])
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [handleBeforeUnload]);

    return violation
}

export default useTerminateOnRefresh