import React, { useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import Popup from "../../../components/popup/Popup";
import { SingleQuestionUpload } from "../newAssessment/SingleQuestionUpload";

const QuesRow = ({
  index,
  columnList,
  question,
  openQuestion,
  setOpenQuestion,
  editFunction,
  deleteFunction,
  setDeleteQuestionId,
  setQuestionUpdate,
  setAddedQuestions,
  setAddedQuestionsId,
}) => {
  const [openSingleQuestion, setOpenSingleQuestion] = useState(false);
  console.log(question);
  return (
    <>
      <div
        className="flex items-center w-full overflow-x-auto py-5 pl-5 text-xs font-medium even:bg-[#E9EAEB]/60 cursor-pointer"
        onClick={() => {
          setOpenQuestion &&
            setOpenQuestion(
              openQuestion === question?.id ? false : question?.id
            );
        }}
      >
        <div className="w-[50px]">
          <FaAngleRight
            className={`${
              openQuestion === question?.id ? "rotate-90" : ""
            } transition-all duration-300`}
          />
        </div>
        {columnList.map((column) => {
          const width = column.open ? column.width : "0";
          const opacity = column.open ? "1" : "0";
          return (
            <div
              key={column.name}
              className="flex items-center gap-1 capitalize transition-all duration-200 overflow-x-hidden"
              style={{ width, opacity }}
            >
              {column.name === "sno" ? (
                index + 1
              ) : column.name === "difficulty" ? (
                question[column.name] === 3 ? (
                  "Hard"
                ) : question[column.name] === 2 ? (
                  "Medium"
                ) : (
                  "Easy"
                )
              ) : column.name === "skills" ? (
                question[column.name].join(", ")
              ) : column.name === "time" ? (
                `${Math.floor(question[column.name] / 60)}${
                  question[column.name] % 60 === 0 ? " minutes" : "min"
                } ${
                  question[column.name] % 60 === 0
                    ? ""
                    : (question[column.name] % 60) + "sec"
                }`
              ) : column.name === "type" && question[column.name] === "mcq" ? (
                question[column.name].toUpperCase()
              ) : column.name === "action" ? (
                <div
                  className="flex items-center gap-4 cursor-default"
                  onClick={(e) => e.stopPropagation()}
                >
                  {editFunction && (
                    <FaRegEdit
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => {
                        setOpenSingleQuestion(true);
                      }}
                    />
                  )}
                  {deleteFunction && (
                    <MdDeleteOutline
                      className="w-6 h-6 cursor-pointer text-red-500"
                      onClick={() => {
                        setAddedQuestions((prev) =>
                          prev.filter((data) => data.id !== question.id)
                        );
                        setAddedQuestionsId((prev) =>
                          prev.filter((data) => data !== question.id)
                        );
                      }}
                    />
                  )}
                </div>
              ) : (
                question[column.name]
              )}
            </div>
          );
        })}
      </div>
      {openQuestion === question?.id && (
        <div className="w-full py-3 pl-16 text-sm space-y-2">
          <div className="space-y-1">
            <h3 className="font-semibold">Question.</h3>
            <p>{question?.statement}</p>
          </div>
          {question?.type === "mcq" && (
            <>
              <div className="space-y-1">
                <h3 className="font-semibold">Options.</h3>
                <ol className="w-fit grid grid-cols-2 gap-x-10 gap-y-1 list-inside">
                  {question?.options.map((option, index) => {
                    const alpha = ["A", "B", "C", "D"];
                    return (
                      <div className="list-item">
                        <span className="font-semibold">{alpha[index]}.</span>{" "}
                        {option}
                      </div>
                    );
                  })}
                </ol>
              </div>
              <div className="space-y-1">
                <h3 className="font-semibold">Answer.</h3>
                <p>{question?.correctOption}</p>
              </div>
            </>
          )}
          {question?.type === "subjective" && (
            <>
              <div className="space-y-1">
                <h3 className="font-semibold">Answer.</h3>
                <p>{question?.answerSummary}</p>
              </div>
            </>
          )}
          {question?.type === "code" && (
            <div className="space-y-1">
              {question.type === "code" && (
                <div className="col-span-2 grid grid-cols-2 gap-4">
                  <div>
                    <div className="text-gray-500 text-sm">Sample Input</div>
                    <div>{question?.sampleInput}</div>
                  </div>
                  <div>
                    <div className="text-gray-500 text-sm">Sample Output</div>
                    <div>{question.sampleOutput}</div>
                  </div>
                  <div>
                    <div className="text-gray-500 text-sm">Default Code</div>
                    <div>{question.defaultCode}</div>
                  </div>
                  <div>
                    <div className="text-gray-500 text-sm">Constraints</div>
                    <div>{question.constraints}</div>
                  </div>
                  <div>
                    <div className="text-gray-500 text-sm">Test Cases</div>
                    <div>{question.testCases?.join(", ")}</div>
                  </div>
                  <div>
                    <div className="text-gray-500 text-sm">Expected Output</div>
                    <div>{question.expectedOutput?.join(", ")}</div>
                  </div>
                  <div>
                    <div className="text-gray-500 text-sm">
                      Programming Language
                    </div>
                    <div>{question.language?.label}</div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {openSingleQuestion && (
        <Popup closePopup={() => setOpenSingleQuestion(false)}>
          <div className="overflow-y-auto">
            <SingleQuestionUpload
              // setAddedQuestionsId={setAddedQuestionsId}
              assessmentDetails={question}
              // admin={admin}
              setOpenSingleQuestion={setOpenSingleQuestion}
              // setAddedQuestions={setAddedQuestions}
              setQuestionUpdate={setQuestionUpdate}
            />
          </div>
        </Popup>
      )}
    </>
  );
};

export default QuesRow;
