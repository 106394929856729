import React from "react";
import { adminRegisterSteps } from "../../../constant/formFields/adminRegisterSteps";

const FormSteps = ({ step }) => {
  const steps = adminRegisterSteps;
  return (
    <div className="relative w-2/3 flex items-start my-12 justify-between">
      <div className="absolute w-[90%] ml-6 top-6 h-[2px] -z-10 bg-[#892bcc]/20">
        <div
          className="h-full bg-[#892bcc]"
          style={{ width: `${((step - 1) * 100) / (steps.length - 1)}%` }}
        />
      </div>
      {steps.map((stepItem, index) => {
        return (
          <div
            key={stepItem.value}
            className="flex flex-col items-center justify-center w-[84px]"
          >
            <div className="bg-white">
              <div
                className={`flex justify-center items-center w-12 h-12 border-[1px] border-[#892bcc] rounded-md
                    ${step > index ? "" : "opacity-40"}`}
              >
                <img src={stepItem.icon} alt={step.label} width="20px" />
              </div>
            </div>

            <div className="text-xs mt-2 text-center">{stepItem.label}</div>
          </div>
        );
      })}
    </div>
  );
};

export default FormSteps;
