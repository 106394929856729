import { useState, useEffect, useCallback } from "react";
import { Topbar2 } from "../../components";
import InstructionsTopPanel from "./InstructionsTopPanel";
import InstructionsMainPanel from "./InstructionsMainPanel";
import dbService from "../../firebase/dbService";

const ExamInstructions = () => {
  const [localDetails, setLocalDetails] = useState();
  const [scheduleDetails, setScheduleDetails] = useState();
  const [candidateDetails, setCandidateDetails] = useState();
  const [assessmentDetails, setAssessmentDetails] = useState();
  useEffect(() => {
    const localDetials = JSON.parse(localStorage.getItem("talentigo-examAuth"));
    setLocalDetails(localDetials);
    if (localDetials?.schedule) {
      setScheduleDetails(localDetials.schedule);
    }
    if (localDetials?.candidate) {
      setCandidateDetails(localDetials.candidate);
    }
  }, []);

  const fetcheAssessmentDetails = useCallback(
    async (assessmentId) => {
      try {
        const assessment = await dbService.getdocument(
          "assessments",
          assessmentId
        );
        if (assessment) {
          setAssessmentDetails(assessment);
          localStorage.setItem(
            "talentigo-examAuth",
            JSON.stringify({
              ...localDetails,
              assessment,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    [localDetails]
  );

  useEffect(() => {
    const assessmentId = scheduleDetails?.assessmentId;
    if (assessmentId) fetcheAssessmentDetails(assessmentId);
  }, [scheduleDetails, fetcheAssessmentDetails]);

  return (
    <div className="w-full overflow-y-auto scrollable">
      <div className="fixed w-full z-20">
        <Topbar2
          name={candidateDetails?.name}
          email={candidateDetails?.email}
        />
      </div>

      <div className="sticky z-10 top-[-52px]">
        <InstructionsTopPanel assessmentName={assessmentDetails?.name} />
      </div>

      <div>
        <InstructionsMainPanel
          assessmentDetails={assessmentDetails}
          scheduleId={scheduleDetails?.id}
        />
      </div>
    </div>
  );
};

export default ExamInstructions;
