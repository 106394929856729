import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const SideNavigation = ({ step, setStep, stepsName }) => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-full flex flex-col justify-start items-start lg:gap-8">
      <button
        onClick={() => navigate("/candidate")}
        className="font-semibold hover:text-[#8A24F1] hover:underline underline-offset-2 flex items-center gap-2"
      >
        <IoArrowBack className="w-5 h-5" /> Dashboard
      </button>
      {stepsName.map((stepName, index) => (
        <div
          key={index}
          className="flex items-center gap-4 cursor-pointer"
          onClick={() => {
            setStep(index + 1);
          }}
        >
          <div
            className={`font-semibold hidden sm:flex capitalize
                ${step === index + 1 ? "text-[#8A24F1]" : ""}
            `}
          >
            {stepName}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SideNavigation;
