import React from "react";

const Card = ({ cardData: { title, value, icon, color } }) => {
  return (
    <div className="card h-[4.5rem] sm:h-[5.5rem] w-full flex items-center gap-4 p-4">
      <div
        className="w-16 h-16 flex items-center justify-center rounded-xl border-2"
        style={{
          backgroundColor: `${color}20`,
          color: color,
          borderColor: `${color}20`,
        }}
      >
        {icon}
      </div>
      <div className="flex flex-col justify-center">
        <span className="font-semibold text-2xl">{value}</span>
        <span className="text-[#757575] text-sm font-semibold">{title}</span>
      </div>
    </div>
  );
};

export default Card;
