import { useEffect, useState } from "react";
import { Button, Popup, Topbar2 } from "../../components";
import { useNavigate } from "react-router-dom";
import Schedule from "./Schedule";
import dbService from "../../firebase/dbService";

const Start = () => {
  const [scheduleDetails, setScheduleDetails] = useState();
  const [candidateDetails, setCandidateDetails] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const localDetails = JSON.parse(localStorage.getItem("talentigo-examAuth"));
    const schedule = localDetails?.schedule;

    if (schedule) {
      setScheduleDetails(schedule);
      const fetchCandidateDetails = async () => {
        try {
          const candidate = await dbService.getdocument(
            "candidateProfile",
            schedule?.candidateId
          );
          if (candidate) {
            setCandidateDetails(candidate);
            localStorage.setItem(
              "talentigo-examAuth",
              JSON.stringify({
                ...localDetails,
                candidate,
              })
            );
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchCandidateDetails();
    }
  }, []);

  const [duration, setDuration] = useState();
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [dateNow, setDateNow] = useState();
  useEffect(() => {
    const nowInterval = setInterval(() => {
      setDateNow(new Date());
    }, 1000);
    // Cleanup function
    return () => clearInterval(nowInterval);
  }, []);
  useEffect(() => {
    setDuration(
      Math.floor(
        (new Date(scheduleDetails?.scheduleDate?.seconds * 1000) - dateNow) /
          1000
      )
    );
    return () => setDuration();
  }, [scheduleDetails, dateNow]);

  useEffect(() => {
    setDays(Math.floor(duration / (60 * 60 * 24)));
    setHours(Math.floor((duration / 3600) % 24));
    setMinutes(Math.floor((duration / 60) % 60));
    setSeconds(Math.floor(duration % 60));
  }, [duration]);

  const [rescheduleModule, setRescheduleModule] = useState(false);

  return (
    <div className="w-full h-full">
      <Topbar2 name={candidateDetails?.name} email={candidateDetails?.email} />
      <div className="relative w-[95%] h-[80%] mx-auto py-8 flex card my-6 flex-col gap-5 justify-center items-center overflow-hidden">
        {scheduleDetails?.scheduleDate?.seconds * 1000 < Date.now() &&
          scheduleDetails?.expDate?.seconds * 1000 > Date.now() && (
            <>
              <Button
                version="green"
                text="Start Assessment"
                handleClick={() => {
                  navigate("/assessment/setup");
                }}
              />
              <div className="my-2">OR</div>
              <div>
                <Button
                  text="Reschedule Assessment"
                  handleClick={() => {
                    setRescheduleModule(true);
                  }}
                />
              </div>
            </>
          )}
        {scheduleDetails?.scheduleDate?.seconds * 1000 > Date.now() && (
          <>
            <div className="w-full p-10 flex absolute top-0 justify-between">
              <div>
                <p>Your Assesment is Scheduled for</p>
                <p className="flex gap-2 justify-center text-xl gradientText">
                  <span>
                    {new Date(
                      scheduleDetails?.scheduleDate?.seconds * 1000
                    )?.toLocaleDateString("en-IN", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  {" - "}
                  {new Date(
                    scheduleDetails?.expDate?.seconds * 1000
                  )?.toLocaleTimeString()}
                </p>
              </div>
            </div>

            <div className="text-2xl font-semibold">Time Left to Start</div>
            <div className="text-lg flex gap-5">
              {days !== 0 && (
                <div className=" w-[100px]">
                  <span className="text-4xl gradientText w-[45px] inline-block font-medium">
                    {days ? (days < 10 ? `0${days}` : days) : "00"}
                  </span>{" "}
                  {days === 1 ? "Day" : "Days"}
                </div>
              )}

              {(days || hours !== 0) && (
                <div className="b w-[105px]">
                  <span className="text-4xl gradientText w-[45px] inline-block font-medium">
                    {hours ? (hours < 10 ? `0${hours}` : hours) : "00"}
                  </span>{" "}
                  {hours === 1 ? "Hour" : "Hours"}
                </div>
              )}

              {(days || hours || minutes || seconds !== 0) && (
                <>
                  <div className="w-[120px]">
                    <span className="text-4xl gradientText w-[45px] inline-block font-medium">
                      {minutes
                        ? minutes < 10
                          ? `0${minutes}`
                          : minutes
                        : "00"}
                    </span>{" "}
                    {minutes === 1 ? "Minute" : "Minutes"}
                  </div>

                  <div className="w-[128px]">
                    <span className="text-4xl gradientText w-[45px] inline-block font-medium">
                      {seconds
                        ? seconds < 10
                          ? `0${seconds}`
                          : seconds
                        : "00"}
                    </span>{" "}
                    {seconds === 1 ? "Second" : "Seconds"}
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {scheduleDetails?.expDate?.seconds * 1000 < Date.now() && (
          <div className="flex flex-col gap-5 justify-center items-center">
            <div className="text-2xl font-semibold">
              Your Assesment has been expired on
            </div>
            <div className="text-4xl gradientText font-medium">
              {new Date(
                scheduleDetails?.expDate?.seconds * 1000
              ).toLocaleString("en-in", {
                weekday: "short",
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </div>
            <div className="text-4xl gradientText font-medium">
              {new Date(
                scheduleDetails?.expDate?.seconds * 1000
              ).toLocaleString("en-in", {
                hour: "numeric",
                minute: "numeric",
              })}
            </div>
            <div className="mt-5">
              <Button
                text="Reschedule Assessment"
                handleClick={() => setRescheduleModule(true)}
              />
            </div>
            <div>OR</div>
            <div className="text-lg font-semibold">
              Contact -
              <a
                href={`mailto:${scheduleDetails?.companyEmail}`}
                className="gradientText mx-2"
              >
                {scheduleDetails?.companyName}
              </a>
            </div>
          </div>
        )}
      </div>
      {rescheduleModule && (
        <Popup closePopup={() => setRescheduleModule(false)}>
          <Schedule
            schedule={scheduleDetails}
            candidate={candidateDetails}
            setRescheduleModule={setRescheduleModule}
          />
        </Popup>
      )}
    </div>
  );
};

export default Start;
