import { useEffect, useState } from "react";
import { SelectInput } from "../../components";
import { useSelector } from "react-redux";
import { capitalize } from "../../utils/helperFunctions/textFunctions";
import { useNavigate } from "react-router-dom";

const CategorySelect = ({
  libraryType,
  selectedCategory,
  setSelectedCategory,
  selectedSkill,
  setSelectedSkill,
  selectedTopic,
  setSelectedTopic,
}) => {
  const navigate = useNavigate();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  const [topicOptions, setTopicOptions] = useState([]);
  const {
    categories,
    loading,
    // error
  } = useSelector((state) => state.questions);

  // create category options
  useEffect(() => {
    setCategoryOptions(
      categories.map((category) => ({
        id: category.id,
        value: category.name,
        label: capitalize(category.name),
      }))
    );
  }, [categories, loading]);

  // create skill options
  useEffect(() => {
    const skillData = selectedCategory
      ? categories?.filter((data) => data.name === selectedCategory?.value)[0]
          ?.skills
      : [];

    const options = skillData.length
      ? skillData.map((data) => ({
          id: data.skill,
          value: data.skill,
          label: capitalize(data.skill),
        }))
      : [];
    setSkillOptions(options);
  }, [selectedCategory, categories]);

  // create topics options
  useEffect(() => {
    const topics = selectedSkill
      ? categories
          ?.filter((category) => category.name === selectedCategory?.value)[0]
          ?.skills?.filter((skill) => skill.skill === selectedSkill?.value)[0]
          ?.topics
      : [];

    const options = topics?.length
      ? topics.map((topic) => ({
          id: topic,
          value: topic,
          label: capitalize(topic),
        }))
      : [];
    setTopicOptions(options);
  }, [categories, selectedCategory, selectedSkill, skillOptions]);

  return (
    <div className="flex space-x-3 ml-4">
      <div className="w-[250px]">
        <SelectInput
          className="basic-single w-[260px]"
          selectOptions={categoryOptions}
          value={selectedCategory}
          handleChange={(selectedOption) => {
            setSelectedCategory(selectedOption);
            setSelectedSkill(null);
            // setSearchValue(null);
            // navigate(
            //   `/admin/questionLibrary/${libraryType}?category=${selectedOption?.value}`
            // );
          }}
          placeholderText="Select Category"
          isClearable
        />
      </div>
      <div
        className={`w-[190px] ${selectedCategory?.label ? "" : "opacity-50"} `}
      >
        <SelectInput
          selectOptions={skillOptions}
          value={selectedSkill}
          handleChange={(selectedOption) => {
            setSelectedSkill(selectedOption);
            setSelectedTopic(null);
            // setSearchValue(null);
            // navigate(
            //   `/admin/questionLibrary/${libraryType}category=${selectedCategory?.value}&skill=${selectedOption?.value}`
            // );
          }}
          placeholderText="Select Skill"
          isClearable
          isDisabled={!selectedCategory?.label}
        />
      </div>
      <div
        className={`w-[170px] ${
          selectedSkill?.value && selectedSkill?.value !== "all"
            ? ""
            : "opacity-50"
        } `}
      >
        <SelectInput
          className="basic-single w-[200px]"
          classNamePrefix="select"
          value={selectedTopic}
          name="topics"
          placeholderText="Select a topic"
          selectOptions={topicOptions}
          isClearable
          handleChange={(selectedOption) => {
            setSelectedTopic(selectedOption);
            // setSearchValue(null);
            // navigate(
            //   `/admin/questionLibrary/${libraryType}category=${selectedCategory?.value}&skill=${selectedSkill?.value}&topic=${selectedOption?.value}`
            // );
          }}
          isDisabled={!selectedSkill?.label}
        />
      </div>
    </div>
  );
};

export default CategorySelect;
