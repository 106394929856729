import { SelectInput } from "../../../components";
import { RxCross2 } from "react-icons/rx";

const JobFilterMenu = ({ setOpenFilterMenu, setFilters, filters }) => {
  const handleFilter = (type, selectedOptions) => {
    setFilters({ ...filters, [type]: [...selectedOptions] });
  };

  return (
    <div className="absolute top-[54px] right-0 card w-[500px] border-2 z-10 p-4 px-6">
      <div className="absolute top-4 right-4">
        <RxCross2
          className="w-5 h-5 cursor-pointer"
          onClick={() => setOpenFilterMenu(false)}
        />
      </div>
      <h1 className="font-semibold text-center">Filter By</h1>
      <div className="grid grid-cols-2 gap-3">
        <div>
          <label htmlFor="jobTypeFilter" className="text-xs text-gray-500">
            Job Types :
          </label>
          <SelectInput
            inputName="jobTypeFilter"
            placeholderText="Select Job Types"
            selectOptions={[
              { value: "full-time", label: "Full-Time" },
              { value: "part-time", label: "Part-Time" },
              { value: "contract", label: "Contract" },
              { value: "freelance", label: "Freelance" },
              { value: "internship", label: "Internship" },
            ]}
            value={filters.jobType}
            handleChange={(selectedOption) => {
              handleFilter("jobType", selectedOption);
            }}
            isClearable
            isMulti
          />
        </div>
        <div>
          <label
            htmlFor="jobPreferenceFilter"
            className="text-xs text-gray-500"
          >
            Job Preference :
          </label>
          <SelectInput
            inputName="jobPreferenceFilter"
            placeholderText="Select Job Preference"
            selectOptions={[
              { value: "wfh", label: "Work From Home" },
              { value: "wfo", label: "Work From Office" },
              { value: "hybrid", label: "Hybrid" },
            ]}
            value={filters.jobPreference}
            handleChange={(selectedOption) => {
              handleFilter("jobPreference", selectedOption);
            }}
            isClearable
            isMulti
          />
        </div>
        <div>
          <label htmlFor="jobStatusFilter" className="text-xs text-gray-500">
            Job Status :
          </label>
          <SelectInput
            inputName="jobStatusFilter"
            placeholderText="Select Job Status"
            selectOptions={[
              { value: "in-progress", label: "In-Progress" },
              { value: "on hold", label: "On Hold" },
              { value: "closed", label: "Closed" },
              { value: "inactive", label: "Inactive" },
            ]}
            value={filters.jobStatus}
            handleChange={(selectedOption) => {
              handleFilter("jobStatus", selectedOption);
            }}
            isClearable
            isMulti
          />
        </div>
      </div>
    </div>
  );
};

export default JobFilterMenu;
