import { useState } from "react";
import { Button } from "../../components";
import ExamFeedback from "./ExamFeedback";
// import SuccessFeedback from "../../components/popup/SuccessFeedback";

const SubmitModule = ({ applicationDetails, currentRound }) => {
  const [showFeedbackView, setshowFeedbackView] = useState(false);

  return (
    <div className="w-[95%] mx-auto mt-[95px] h-[calc(100vh-120px)] pb-5 px-[3%] card flex justify-between">
      {showFeedbackView ? (
        <ExamFeedback
          applicationDetails={applicationDetails}
          currentRound={currentRound}
        />
      ) : (
        <div className="w-full h-full flex flex-col justify-center items-center relative">
          <h1 className="font-semibold text-2xl capitalize">
            Thank you for taking the assessment !
          </h1>
          <p className="text-lg text-[#919397] mt-2.5 mb-12">
            Expect the feedback soon.
          </p>

          <Button
            text="Give your valuable feedback"
            className={"m-5"}
            version="secondary"
            handleClick={() => {
              setshowFeedbackView(true);
            }}
          />
          <Button
            text="Exit Window"
            handleClick={() => (window.location.href = "/")}
          />
        </div>
      )}
    </div>
  );
};

export default SubmitModule;
