export const candidateRegistrationFields = [
  {
    name: "name",
    label: "Full Name",
    type: "text",
    // state: name,
    // setState: setName,
    placeholder: "Joe Doe",
  },
  {
    name: "email",
    label: "Email Id",
    type: "email",
    // state: email,
    // setState: setEmail,
    placeholder: "myMail123@mail.com",
  },
  {
    name: "phone",
    label: "Phone Number",
    type: "tel",
    // state: phone,
    // setState: setPhone,
    placeholder: "9876543210",
  },
  {
    name: "password",
    label: "New Password",
    type: "password",
    // state: password,
    // setState: setPassword,
    placeholder: "• • • • • • • •",
  },
];
