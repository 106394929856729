export const adminRegisterSteps = [
  {
    label: "Choose Your Account Type",
    value: 1,
    icon: "/img/accountType.svg",
    leftPanelTitle: "Create your Account",
    leftPanelText:
      "Account creation is specifically intended to organizations such as companies, recruiter, colleges and schools or organizations",
  },
  {
    label: "Fill Detaild Info",
    value: 2,
    icon: "/img/FillDetailedInfo.svg",
    leftPanelTitle: "Complete your Profile",
    leftPanelText:
      "Attract top talent by showcasing your company culture and values in your profile . The more information you provide, the better candidates you'll attract",
  },
  {
    label: "Fill Company Detail",
    value: 3,
    icon: "/img/companyDetail.svg",
    leftPanelTitle: "Setup your Company",
    leftPanelText:
      "Unlease your potential. Find your dream job and a commpany culture that thrives, complete your profile to showcase your skills and connect with the perfect fit",
  },
  {
    label: "All Done",
    value: 4,
    icon: "/img/done.svg",
    leftPanelTitle: "Thank You",
    leftPanelText:
      "Welcome to the talent pool! start building your dream team by posting jobs and attracting qualified candidates.",
  },
];
