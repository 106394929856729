import React from "react";
import "./TextInput.css";

const TextInput = ({
  type,
  placeholderText,
  innerLabel,
  width,
  idName,
  inputName,
  inputValue,
  handleChange,
  handleClick,
  inputRef,
  isRequired,
  isDisabled,
  selectOptions,
  textRows,
  accept,
  defaultValue,
  error,
  min,
  max,
  // isValidate
}) => {
  return (
    <>
      {type === "select" ? (
        <select
          className={`${error} selectInput`}
          id={idName && idName}
          name={inputName}
          required={isRequired}
          value={inputValue}
          onChange={handleChange}
          ref={inputRef}
          disabled={isDisabled}
        >
          <option className="text-[#C4C4C4] text-sm" value="">
            {placeholderText}
          </option>
          {selectOptions?.map((selectOption) => {
            return (
              <option
                className="font-medium text-sm capitalize text-[#666]"
                key={selectOption}
                value={selectOption}
              >
                {selectOption}
              </option>
            );
          })}
        </select>
      ) : type === "textbox" ? (
        <textarea
          className={`${error} textArea`}
          rows={textRows}
          name={inputName}
          placeholder={placeholderText}
          required={isRequired}
          value={inputValue}
          defaultValue={defaultValue}
          onChange={handleChange}
          ref={inputRef}
          disabled={isDisabled}
        />
      ) : type === "file" ? (
        <div className="fileInputContainer">
          <label className="fileInputPlaceholder">{placeholderText}</label>
          <input
            className={`${error} fileInput`}
            name={inputName}
            type="file"
            style={{ width: `${width}` }}
            value={inputValue}
            onChange={handleChange}
            required={isRequired}
            accept={accept}
            ref={inputRef}
            disabled={isDisabled}
          />
        </div>
      ) : (
        <div
          className={`flex items-center w-full rounded-lg overflow-hidden ${error} ${
            innerLabel && "gap-1 bg-[#F5F5F5] rounded-lg px-3 text-sm"
          }`}
        >
          <span className="w-fit">{innerLabel}</span>

          <input
            className={`textInput`}
            name={inputName}
            type={type}
            style={{ width: `${width}` }}
            placeholder={placeholderText}
            value={inputValue}
            onChange={handleChange}
            onClick={handleClick}
            required={isRequired}
            defaultValue={defaultValue}
            ref={inputRef}
            disabled={isDisabled}
            min={min}
            max={max}
            // validate={isValidate}
          />
        </div>
      )}
    </>
  );
};

export default TextInput;
