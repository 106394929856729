import React from "react";

const CandidateDetails = ({ candidateDetails }) => {
  return (
    <div className="p-4 py-5 w-full h-full">
      <div className="space-y-4">
        <div className="space-y-2">
          <h2 className="font-semibold text-lg">Basic Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4">
            <div className="flex flex-col gap-1">
              <span className="text-[#666] font-medium">Full Name</span>
              <span className="capitalize">{candidateDetails?.name}</span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[#666] font-medium">Phone Number</span>
              <span className="capitalize">
                {candidateDetails?.phoneNumber}
              </span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[#666] font-medium">E-mail Id</span>
              <span className="">{candidateDetails?.email}</span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[#666] font-medium">Position Title</span>
              <span className="">
                {candidateDetails?.positionTitle
                  ? candidateDetails?.positionTitle
                  : "-"}
              </span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[#666] font-medium">Location</span>
              <span className="">
                {candidateDetails?.location ? candidateDetails?.location : "-"}
              </span>
            </div>
          </div>
        </div>
        {candidateDetails?.skills?.length > 0 ? (
          <div className="space-y-2">
            <h2 className="font-semibold text-lg">Skills</h2>

            <ul className="list-disc list-inside grid grid-cols-3">
              {candidateDetails?.skills?.map((skill) => (
                <li>{skill}</li>
              ))}
            </ul>
          </div>
        ) : null}

        {/* <div className="space-y-2">
          <h2 className="font-semibold text-lg">Professional Details</h2>
        </div>
        <div className="space-y-2">
          <h2 className="font-semibold text-lg">Educational Details</h2>
        </div>
        <div className="space-y-2">
          <h2 className="font-semibold text-lg">Projects</h2>
        </div> */}
      </div>
    </div>
  );
};

export default CandidateDetails;
